import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import Config from '../../../../services/config';
import api from '../../../../services/Users';
import AuthPage from '../../../../components/AuthPage';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import CustomerList from '../../../../components/AdminConsoleComponents/Customers/List';
// import NavigationMenu from '../../../../components/AdminConsoleComponents/Navigation';
// import WelcomeConsole from '../../../../components/AdminConsoleComponents/WelcomeConsole';

export default function List() {

  const [ customerCount, setCustomerCount ] = useState([]);
  const [ customerCountDisabled, setCustomerCountDisabled ] = useState([]);

  

  useEffect(() => {
      
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    async function loadCountCustomer() {
        const response = await api.get('/mobcustomer/count', {
            headers: { authorization, userid },
        })
        setCustomerCount(response.data.customers);
        setCustomerCountDisabled(response.data.customersDisabled);
    }
    loadCountCustomer();

    },[])


  return (
    <>
       <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner" //3 secs
      />
        <AuthPage />
        <div className="container-scroller">
            <HeaderAdminConsole />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                            <div className="row">

                            <div className="col-md-12">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="">
                                            <div className="">
                                                
                                                <div className="row mb-4">
                                                
                                                    <div className="col-md-8">
                                                        <h4 className="font-weight-bold text-uppercase"> Clientes Pagefy </h4>
                                                    </div>

                                                    <div className="col-md-4 text-right">
                                                        <Link to="/admin/mobcustomer/store" className="btn btn-outline-twitter btn-sm">
                                                            <i className="ti-plus btn-icon-prepend"></i> Adicionar 
                                                        </Link>
                                                    </div>

                                                    <div className="col-md-12 mt-3">
                                                        <Link to="/admin/customers?customers=enabled" className="btn btn-outline-twitter btn-sm mr-2">
                                                            Clientes Ativos ( {customerCount} ) 
                                                        </Link>
                                                        <Link to="/admin/customers?customers=disabled" className="btn btn-outline-secondary btn-sm">
                                                            Clientes Inativos ( {customerCountDisabled} ) 
                                                        </Link>
                                                    </div>

                                            </div>


                                                <CustomerList />

                                            </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
