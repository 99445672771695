import React from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Navigation from '../navigation';

export default function ChangeProfilePicture() {

    return (
        <>
         <AuthPage />
         <Header />
         <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-4">

                            <Navigation />
    
                        </div>
                        <div className="col-lg-8">
                            <div className="border-left-grey">
                                <div className="card-body">
                                  <h3> Trocar Imagem da Conta </h3>
                                  <p className="card-description text-grey mt-3 mb-6">
                                        Para alterarmos a imagem do seu perfil é necessário que a imagem esteja <br/> com os seguintes padrões: <br/><br/>
                                        Formato: <strong> JPG </strong> <br/>
                                        Tamanho Máximo de Até: <strong> 500Kb </strong>
                                    <br/>
                                    <br/>
                                  </p>
                                  <form className="forms-sample">
                                    
                                  <div className="col-lg-6 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                        <h4 className="card-title">Your so fresh input file — Default version</h4>
                                        <input type="file" className="dropify" />
                                        </div>
                                    </div>
                                    </div>
    
                                      <div className="row">
                                        <div className="col-md-12 text-left mt-20">
                                          <button 
                                            type="submit" 
                                            className="btn btn-info mr-2"
                                          > Enviar </button>
                                        </div>
                                      </div>
    
                                  </form>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
         <Footer />
        </>
      );
}