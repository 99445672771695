import React, { Component } from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Navigation from '../navigation';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Config from '../../../services/config';
import api from '../../../services/Users';
import Alerts from '../../../components/Alerts';

export default class WithDrawals extends Component {

  constructor(props) {

    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    super(props);
    this.state = {
      userdata: {},
      authorization,
      userid
    };
  }

  componentDidMount() {
    
    const authorization = this.state.authorization;
    const userid = this.state.userid;

    api.get('/user/withdrawls',{
        headers: { authorization, userid },
    })
      .then(res => {
        this.setState({ userdata: res.data.user });
        // console.log(res.data.user);
      });

  }

  handleChange = (e) => {
    const state = this.state.userdata
    state[e.target.name] = e.target.value;
    this.setState({userdata:state});
    // console.log(state);
  }

  onSubmit = (e) => {

    e.preventDefault();
    const authorization = this.state.authorization;
    const userid = this.state.userid;
    const { autoTransfer, autoTransferPeriod } = this.state.userdata;

    api.put('/user/withdrawls/', { autoTransfer, autoTransferPeriod }, { headers: { authorization, userid },

    })
      .then((result) => {
        Alerts("Dados Pessoais", 'Dados atualizados com sucesso' ,"success");
        this.props.history.push("/user/account/withdrawals");
      });
  }

  render() {
    return (
      <>
      <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner" //3 secs
      />
     <AuthPage />
     <Header />
     <div className="container-fluid page-body-wrapper" id="fscroll">
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="mt-4">
                  <div className="row">
                    
                    <div className="col-lg-8">
                        <div className="">
                            <div className="">
                              <h3> Transferências e Saques </h3>
                              
                              
                              <div className="options mt-4">

                              { this.state.userdata.bankAgency &&  this.state.userdata.bankAccount && this.state.userdata.bankNumber ? (

                                <form onSubmit={this.onSubmit}>
                                    
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1"> <strong> Conta Bancária a creditar o saldo </strong> </label>
                                      <select className="form-control">
                                          <option> Ag: {this.state.userdata.bankAgency || ""} / Cc: {this.state.userdata.bankAccount || ""} </option>
                                      </select>
                                      <p className="mt-3 text-muted"> Para adicionar uma nova conta 
                                        <Link to="/user/account/bank" className="ml-1">
                                          clique aqui 
                                        </Link> 
                                      </p>
                                    </div>

                                    <div className="form-group">

                                      <label htmlFor="autoTransfer"> 
                                        <strong> Habilitar transferência automáticas </strong> 
                                      </label>

                                      <select 
                                        id="autoTransfer"
                                        name="autoTransfer"
                                        value={this.state.userdata.autoTransfer || ""}
                                        onChange={this.handleChange}
                                        className="form-control"
                                      >
                                        <option></option>
                                        <option value="1"> Sim </option>
                                        <option value="2"> Não </option>

                                      </select>

                                    </div>

                                    {(this.state.userdata.autoTransfer === "1") ? (

                                        <div className="form-group">

                                        <label htmlFor="autoTransfer"> <strong> Período de transferência automáticas </strong> </label>

                                        <select 
                                          id="autoTransferPeriod"
                                          name="autoTransferPeriod"
                                          value={this.state.userdata.autoTransferPeriod || ""}
                                          onChange={this.handleChange}
                                          className="form-control"
                                        >
                                          <option></option>
                                          <option value="1"> Diário </option>
                                          <option value="2"> Semanal </option>
                                          <option value="3"> Mensal </option>

                                        </select>

                                        </div>

                                    ) : ''}

                                    <div className="row">
                                      <div className="col-md-12 text-left mt-20">
                                        <button 
                                          type="submit" 
                                          className="btn btn-info mr-2"
                                        > Salvar </button>
                                      </div>
                                    </div>

                                    <p className="card-description text-grey mt-3 mt-4 text-small">
                                      Lembre-se de deixar os dados de sua conta corrente sempre atualizados para que suas transferências <br/>
                                      sejam feitas corretamente.
                                    </p>

                                </form>
                              ) : (
                                <h4 className="text-twitter"> 
                                  Confira as configurações de sua conta bancária e tente novamente </h4>
                              )}

                              </div>
                            </div>
                        </div>
                    </div>
                     
                     <Navigation />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
     <Footer />
    </>
    );
  }
}
