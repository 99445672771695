import React from 'react';
import Loader from 'react-loader-spinner';
import AuthPage from '../../../../components/AuthPageAdmin';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import ContractsUpdate from '../../../../components/AdminConsoleComponents/Contracts/Update';
import Config from '../../../../services/config';

export default function Update(props) {

    const { match: { params } } = props;
    const contractId = params.id
    const customerId = params.userId
    
    return (
    <>
        <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner"
        />

        <AuthPage />

        <div className="container-scroller">
            <HeaderAdminConsole />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                        <div className="row">

                            <div className="col-md-12">
                                <div className="row">
                                    
                                    <div className="col-md-8">
                                        <ContractsUpdate customerId={customerId} contractId={contractId} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
