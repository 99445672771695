import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import swal from 'sweetalert';
import { Editor } from "@tinymce/tinymce-react";

import Config from '../../services/config';
import api from '../../services/login';

export default function CommerceProductCategoryDetail(props) {

  const authorization = Config.AuthApiSecretKey;
  const id = props.catId;
  const contractId = props.contract;
  const userId = props.user;

  const [appData, setAppData] = useState({
    title : '',
    slug : '',
    description : '',
    content : '',
    featuredImage : '',
    videoLink : '',
    privatePagePhrase : '',
    pageCategories : '',
    featuredImageBackground : '',
    seoPageDescription : '',
    seoPageAuthor : '',
    seoPageImage : '',
    tags : '',
    permLink : '',
    sitemap : '',
    status : '',
    contractid: contractId,
  });

  const [isMounted, setIsMounted] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppData((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    async function loadData() {
      try {
        const response = await api.get(`/user/cms/posts/${id}`, {
          headers: {
            authorization,
            userId,
            contractid: contractId,
          },
        });

        if (isMounted) {
          setAppData(response?.data);
        }

      } catch (error) {
        // Handle error (e.g., show an error message)
        console.error('Error fetching data:', error);
      }
    }

    loadData();

    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      setIsMounted(false);
    };
  }, [contractId, id, userId, isMounted]);

  async function onSubmit(e) {
    e.preventDefault();
  
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem('credencialData'));
    const userid = credencialdata._id;
  
    const {
      title,
          slug,
          description,
          content,
          featuredImage,
          videoLink,
          privatePagePhrase,
          pageCategories,
          featuredImageBackground,
          seoPageDescription,
          seoPageAuthor,
          seoPageImage,
          tags,
          permLink,
          sitemap,
          status
    } = appData;
  
    try {
      await api.put(
        `/user/cms/posts/${id}`,
        {
          title,
          slug,
          description,
          content,
          featuredImage,
          videoLink,
          privatePagePhrase,
          pageCategories,
          featuredImageBackground,
          seoPageDescription,
          seoPageAuthor,
          seoPageImage,
          tags,
          permLink,
          sitemap,
          status,
          contractId : contractId,
          customerId : userid
        },
        {
          headers: {
            authorization,
            userId,
            contractid: contractId,
          },
        }
      );
  
      swal({
        title: 'Alteração',
        text: 'Alteração realizada com sucesso',
        timer: 2000,
      });
  
      setAppData({}); // Clear the appData state
      window.location.reload(); // Reload the page
    } catch (error) {
      swal({
        title: 'Alteração',
        text: error.response.data.error,
        timer: 2000,
      });
    }
  }
  

  return (
    <>
      <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-6">
                  <h3> {appData?.title || 'Sem título'} </h3>
                  <small> Adicionando nova página </small> 
                </div>
                <div className="col-md-6 text-right">
                    <Link to={`/user/cms/posts`}>
                      <button className="btn btn-outline-dark btn-sm mt-3 mb-4 mr-3"> 
                        Ver Posts
                      </button>
                    </Link>
                </div>
              </div>
              
              <form className="forms-sample" onSubmit={onSubmit} >
              
              <div className="row border-top">
                <div className="col-md-8 mt-4">

                 <div className="form-group">
                    <label htmlFor="title"> Título da página </label>
                    <input 
                      placeholder="Título"
                      type="text" 
                      className="form-control" 
                      name="title" 
                      id="title"
                      value={appData?.title || ""} 
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="featuredImage"> Conteúdo </label>
                    {/* <input 
                      placeholder="Conteúdo da categoria"
                      type="text" 
                      className="form-control" 
                      name="content" 
                      id="content"
                      value={appData?.content || ""}
                      onChange={handleChange}
                    /> */}

                      <Editor
                        apiKey="accqwwf4mn4j1ct0c1vq8hf2rxl601ufv9dbhmq7ce5ztcos"
                        plugins="advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount"
                        initialValue={appData?.content || ""} 
                        init={{
                          height: 570
                      }}
                    />

                  </div>

                 <div className="form-group">
                    <label htmlFor="title"> Slug </label>
                    <input 
                      placeholder="Slug"
                      type="text" 
                      className="form-control" 
                      name="slug" 
                      id="slug"
                      value={appData?.slug || ""} 
                      onChange={handleChange}
                    />
                  </div>

                 <div className="form-group">
                    <label htmlFor="description"> Descrição </label>
                    <input 
                      placeholder="Descrição"
                      type="text" 
                      className="form-control" 
                      name="description" 
                      id="description"
                      value={appData?.description || ""} 
                      onChange={handleChange}
                    />
                  </div>

                 <div className="form-group">
                    <label htmlFor="featuredImage"> Featured Image </label>
                    <input 
                      placeholder="Imagem de destaque desta categoria"
                      type="text" 
                      className="form-control" 
                      name="featuredImage" 
                      id="featuredImage"
                      value={appData?.featuredImage || ""}
                      onChange={handleChange}
                    />
                  </div>

                 <div className="form-group">
                    <label htmlFor="featuredImageBackground"> Featured Image Background </label>
                    <input 
                      placeholder="Imagem de fundo do header da página"
                      type="text" 
                      className="form-control" 
                      name="featuredImageBackground" 
                      id="featuredImageBackground"
                      value={appData?.featuredImageBackground || ""}
                      onChange={handleChange}
                    />
                  </div>

                 

                  <div className="form-group">
                    <label htmlFor="videoLink"> Video Link ( Youtube / Vimeo ) </label>
                    <input 
                      placeholder="Link do vídeo do Youtube ou Vimeo"
                      type="text" 
                      className="form-control" 
                      name="videoLink" 
                      id="videoLink"
                      value={appData?.videoLink || ""}
                      onChange={handleChange}
                    />
                  </div>

                 <div className="form-group">
                    <label htmlFor="pageCategories"> Categorias </label>
                    <input 
                      placeholder="Digite as categorias separadas por vírgula"
                      type="text" 
                      className="form-control" 
                      name="pageCategories" 
                      id="pageCategories"
                      value={appData?.pageCategories || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="seoPageImage"> Tags </label>
                    <input 
                      placeholder="Tags para taxonomia da página"
                      type="text" 
                      className="form-control" 
                      name="tags" 
                      id="tags"
                      value={appData?.tags || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="seoPageDescription"> SEO ( descrição da página ) </label>
                    <input 
                      placeholder="Descrição da página para mecanismos de busca"
                      type="text" 
                      className="form-control" 
                      name="seoPageDescription" 
                      id="seoPageDescription"
                      value={appData?.seoPageDescription || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="seoPageAuthor"> SEO ( autor da página ) </label>
                    <input 
                      placeholder="Link do vídeo do Youtube ou Vimeo"
                      type="text" 
                      className="form-control" 
                      name="seoPageAuthor" 
                      id="seoPageAuthor"
                      value={appData?.seoPageAuthor || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="seoPageImage"> SEO ( imagem da página ) </label>
                    <input 
                      placeholder="Link do imagem para listar nas buscas"
                      type="text" 
                      className="form-control" 
                      name="seoPageImage" 
                      id="seoPageImage"
                      value={appData?.seoPageImage || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-dark mr-2"> Salvar </button>
                  </div>
                 {/*  */}
                </div>
                <div className="col-md-4 mt-4 ">
                  <div className="form-group">
                      <label htmlFor="status"> Status </label>
                      <select id="status"
                          name="status"
                          className="form-control"
                          value={appData?.status || ""}
                          onChange={handleChange}
                        >
                          <option> Escolha a opção </option>
                          <option value="enabled"> Online </option>
                          <option value="disabled"> Offline </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="sitemap"> Habilitar no Sitemap ? </label>
                      <select id="sitemap"
                          name="sitemap"
                          className="form-control"
                          value={appData?.sitemap || ""}
                          onChange={handleChange}
                        >
                          <option> Escolha a opção </option>
                          <option value="disabled"> Não </option>
                          <option value="enabled"> Sim </option>
                      </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="permLink"> Link da página ( permlink ) </label>
                        <input 
                          placeholder="Link permanente da página"
                          type="text" 
                          className="form-control" 
                          name="permLink" 
                          id="permLink"
                          value={appData?.permLink || ""}
                          onChange={handleChange}
                        />
                     </div>

                </div>

              </div>
            
            </form>
            
            </div>
          </div>
        </div>
    </>
  );
}
