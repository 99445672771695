import React from 'react';

export default function StatementTransactionsResume() {
  return (
    <div className="row">
        <div className="col-12">
            <div className="row mb-3">
                
                <div className="col-md-6">
                    <h3 className="card-title"> Relatórios / Transações </h3>
                    <p className="text-grey"> Relatório de extrato de transações. Utilize os filtros para customizar seu relatório  </p>
                </div>

                <div className="col-md-6">
                  <div class="template-demo text-right">

                    <div class="dropdown">
                      <button class="btn btn-outline-info btn-sm dropdown-toggle" type="button" id="dropdownMenuSizeButton3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Período
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuSizeButton3" x-placement="bottom-start" >
                        <a class="dropdown-item" href="dropdowns.html#"> Último 30 dias </a>
                        <a class="dropdown-item" href="dropdowns.html#"> Últimos 60 dias </a>
                        <a class="dropdown-item" href="dropdowns.html#"> Ultimos 90 dias </a>
                        <a class="dropdown-item" href="dropdowns.html#"> Neste Ano </a>
                        <a class="dropdown-item" href="dropdowns.html#"> Todo o Período </a>
                      </div>
                    </div>

                    <div class="dropdown">
                        <button class="btn btn-outline-info btn-sm dropdown-toggle" type="button" id="dropdownMenuSizeButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Meio de Pagamento
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuSizeButton2" x-placement="bottom-start" >
                          <a class="dropdown-item" href="dropdowns.html#"> Boleto Bancário </a>
                          <a class="dropdown-item" href="dropdowns.html#"> Cartão de Crédito </a>
                          <a class="dropdown-item" href="dropdowns.html#"> POS </a>
                        </div>
                    </div>

                    <div class="dropdown">
                    <button class="btn btn-outline-info btn-sm dropdown-toggle" type="button" id="dropdownMenuSizeButton3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Status
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuSizeButton3" x-placement="bottom-start" >
                    <a class="dropdown-item" href="dropdowns.html#"> Pago </a>
                    <a class="dropdown-item" href="dropdowns.html#"> Recusado </a>
                    <a class="dropdown-item" href="dropdowns.html#"> Aguardando Pagamento </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title text-success text-right"> <strong> Transações Aprovadas </strong> </h4>
                      <div class="d-flex justify-content-between">
                        <p class="text-muted">Últimos 30 dias </p>
                        <h4> 120 </h4>
                      </div>
                      <div class="progress progress-md">
                        <div class="progress-bar bg-success w-25" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title text-danger text-right"> <strong>Transações Recusadas </strong> </h4>                      
                      <div class="d-flex justify-content-between">
                        <p class="text-muted">Últimos 30 dias</p>
                        <h4> 150 </h4>
                      </div>
                      <div class="progress progress-md">
                        <div class="progress-bar bg-danger w-25" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title text-warning text-right"> <strong>Transações Aguardando Pagamento </strong> </h4>
                      <div class="d-flex justify-content-between">
                        <p class="text-muted">Últimos 30 dias</p>
                        <h4> 1000 </h4>
                      </div>
                      <div class="progress progress-md">
                        <div class="progress-bar bg-warning w-25" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                            <th>ID#</th>
                            <th>Criando em</th>
                            <th>Cliente</th>
                            <th>Meio</th>
                            <th>Valor</th>
                            <th className="text-right">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td> <strong className="text-twitter"> 10001 </strong> </td>
                            <td>06/11/2019</td>
                            <td>André Jaccon</td>
                            <td> Boleto</td>
                            <td>R$ 1500,00</td>
                            <td className="text-right">
                              <label className="badge badge-success"> Aprovado </label>
                            </td>
                        </tr>
                        <tr>
                            <td> <strong className="text-twitter"> 10001 </strong> </td>
                            <td>06/11/2019</td>
                            <td>André Jaccon</td>
                            <td> Boleto</td>
                            <td>R$ 1500,00</td>
                            <td className="text-right">
                              <label className="badge badge-danger"> Recusado</label>
                            </td>
                        </tr>
                        <tr>
                            <td> <strong className="text-twitter"> 10001 </strong></td>
                            <td>06/11/2019</td>
                            <td>André Jaccon</td>
                            <td> Boleto</td>
                            <td>R$ 1500,00</td>
                            <td className="text-right">
                              <label className="badge badge-warning text-white"> Aguardando</label>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
            </div>
    </div>
  );
}
