import React, { Component } from 'react';

import Config from '../../../services/config';
import AuthPage from '../../../components/AuthPage';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Navigation from '../navigation';
import Loader from 'react-loader-spinner';
import api from '../../../services/Users';
import Alerts from '../../../components/Alerts';

export default class ChangeAddress extends Component {

  constructor(props) {

    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    super(props);
    this.state = {
      userdata: {},
      authorization,
      userid
    };
  }

  componentDidMount() {
    
    const authorization = this.state.authorization;
    const userid = this.state.userid;

    api.get('/user/address',{
        headers: { authorization, userid },
    })
      .then(res => {
        this.setState({ userdata: res.data.user });
      });

  }

  handleChange = async  (e) => {

    const state = this.state.userdata
    state[e.target.name] = e.target.value;
    this.setState({userdata:state});
    
    // Zip auto complete API
    const authorization = this.state.authorization;
    const userid = this.state.userid;

    if(e.target.name === 'addrZip'){
      
      const queryZip = e.target.value

      if(queryZip.length >= 8){
          const response = await api.post('/user/address/zip', {
            zip: queryZip, 
        },{
          headers : { authorization, userid }
        });

        state.addr = response.data.logradouro;
        state.addrNeighborhood = response.data.bairro;
        state.addrCity = response.data.localidade;
        state.addrState = response.data.uf;
        this.setState({userdata:state});

      }
    }
     // Zip auto complete API

  }


  onSubmit = (e) => {

    e.preventDefault();
    const authorization = this.state.authorization;
    const userid = this.state.userid;
    const { addrZip, addr, addrNumber, addrNeighborhood, addrCity, addrState, addrComplement } = this.state.userdata;

    api.put('/user/address/', { addrZip, addr, addrNumber, addrNeighborhood, addrCity, addrState, addrComplement }, { headers: { authorization, userid },

    })
      .then((result) => {
        Alerts("Dados Pessoais", 'Dados atualizados com sucesso' ,"success");
        this.props.history.push("/user/account/address");
      });
  }

  render() {
    return (
        <>
         <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-12">
                  <div className="mt-4">
                    <div className="">
                      <div className="row">
                        
                        <div className="col-lg-8">
                            <div className="">
                                <div className="">
                                  <h3> Endereço de Cadastro </h3>
                                 

                                  <form onSubmit={this.onSubmit} className="mt-4">
    
                                      <div className="form-group">
                                        <label htmlFor="addrZip"> CEP * </label>

                                        <input 
                                            name="addrZip"
                                            placeholder="Informe o CEP Ex: 03378-060"
                                            type="text" 
                                            className="form-control"
                                            id="addrZip"
                                            value={this.state.userdata.addrZip || ""} 
                                            onChange={this.handleChange}
                                          />

                                      </div>

                                      <div className="form-group">
                                        <label htmlFor="exampleInputUsername1"> Endereço * </label>
                                        <input 
                                            name="addr"
                                            placeholder="Informe o Endereço Ex: Rua Borges Lagoa"
                                            type="text" 
                                            className="form-control"
                                            id="addr"
                                            value={this.state.userdata.addr || ""} 
                                            onChange={this.handleChange}
                                          />
                                      </div>

                                      
                                      
                                      <div className="row">
                                    
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"> Número * </label>
                                                <input 
                                                  placeholder="Número"
                                                  name="addrNumber"
                                                  type="text" 
                                                  className="form-control"
                                                  id="addrNumber"
                                                  value={this.state.userdata.addrNumber || ""} 
                                                  onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
    
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="addrNeighborhood"> Bairro </label>
                                                <input 
                                                  placeholder="Bairro"
                                                  name="addrNeighborhood"
                                                  type="text" 
                                                  className="form-control"
                                                  id="addrNeighborhood"
                                                  value={this.state.userdata.addrNeighborhood || ""} 
                                                  onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
    
                                      </div>

                                      <div className="row">
                                        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                              <label htmlFor="addrCity"> Cidade * </label>
                                              <input 
                                                  placeholder="Cidade"
                                                  name="addrCity"
                                                  type="text" 
                                                  className="form-control"
                                                  id="addrCity"
                                                  value={this.state.userdata.addrCity || ""} 
                                                  onChange={this.handleChange}
                                                />
                                          </div>
                                        </div>
    
                                        <div className="col-md-6">
                                            <div className="form-group">
                                              <label htmlFor="addrState"> Estado * </label>
                                              <input 
                                                placeholder="Estado"
                                                name="addrState"
                                                type="text" 
                                                className="form-control"
                                                id="addrState"
                                                value={this.state.userdata.addrState || ""} 
                                                onChange={this.handleChange}
                                              />
                                            </div>
                                        </div>
    
                                      </div>

                                      <div className="form-group">
                                        <label htmlFor="addrComplement"> Complemento </label>
                                        <input 
                                            placeholder="Informe o complemento caso tenha"
                                            name="addrComplement"
                                            type="text" 
                                            className="form-control"
                                            id="addrComplement"
                                            value={this.state.userdata.addrComplement || ""} 
                                            onChange={this.handleChange}
                                          />
                                      </div>
    
                                      
    
                                      <div className="row">
                                        <div className="col-md-12 text-left mt-20">
                                          <button 
                                            type="submit" 
                                            className="btn btn-info mr-2"
                                          > Salvar </button>
                                        </div>
                                      </div>

                                      <div className="row mt-4 ">
                                        <div className="col-md-12">
                                          <p className="text-grey mt-20 text-small">
                                            * É importante que todos os seus dados estejam atualizados em nosso cadastro para evitar qualquer tipo de inconformidade
                                            com o status de sua conta.
                                          </p>
                                        </div>
                                      </div>

    
                                  </form>
                                </div>
                            </div>
                        </div>
                        
                        <Navigation />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <Footer />
    </>
    );
  }
}
