import React from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPageAdmin';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import NavigationMenu from '../../../../components/AdminConsoleComponents/Navigation';
import TutorialVideoList from '../../../../components/AdminConsoleComponents/TutorialVideos/List';
import WelcomeConsole from '../../../../components/AdminConsoleComponents/WelcomeConsole';

export default function List(props) {

  const { match: { params } } = props;
  const tutoId = params.id

  return (
    <>
       <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <div className="container-scroller">
            <HeaderAdminConsole />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                            <div className="row">

                            <div className="col-md-12">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="">
                                        <div className="">
                                            
                                            <div className="row mb-4">
                                            
                                            <div className="col-md-8">

                                                <h3 className=""> Tutoriais Vídeos </h3>
                                                    <p className="text-grey mt-3"> Aqui você encontra a lista de todos os vídeos tutoriais da Pagefy  </p>
                                                </div>

                                                <div className="col-md-4 text-right">
                                                    <Link to="/admin/tutorials" className="btn btn-outline-twitter btn-sm mr-3">
                                                        Ver todos tutoriais 
                                                    </Link>
                                                    <Link to={`/admin/tutorial/video/store/${tutoId}`} className="btn btn-twitter btn-sm">
                                                        <i className="ti-plus btn-icon-prepend"></i> Adicionar Vídeo
                                                    </Link>
                                                </div>
                                            </div>

                                            <TutorialVideoList tutoId={tutoId} />

                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
