import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../services/config';


import './styles.css'

export default function Footer() {
  
  const d = new Date();
  const year = d.getFullYear();

  return (
    <footer className="footer">
        <div className="w-100 clearfix">
            <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © {year} - 
              <a href={config.defaultWebsite} target="_blank" rel="noopener noreferrer" className="text-dark"> {config.title} </a> -  Todos os direitos reservados.</span>

            <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center text-muted"> 

            <span className="footerMenu">
              Versão: {config.apiVersion} ( {config.environment} )
            </span>

              <Link to="/user/tickets" className="footerMenu text-dark">
                Tickets
              </Link>

              <Link to="/help" className="footerMenu text-dark">
                Ajuda
              </Link>

              <Link to="/apiStatus" className="footerMenu text-dark">
                Status
              </Link>
              
            </span>
        </div>
    </footer>
  );
}