import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import Config from '../../../services/config';
import api from '../../../services/login';
import StatusIcon from '../../Common/StatusIcon';
import CustomerPersonType from '../../Customers/CustomerPersonType';
import notFound from '../../../assets/images/notfound.png';

import './styles.css';

export default function List() {

   const [ customers, setCustomers ] = useState([]);
   const authorization = Config.AuthApiSecretKey;
   const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
   const userid = credencialdata._id;
   
   useEffect(() => {
       
       const authorization = Config.AuthApiSecretKey;
       const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
       const userid = credencialdata._id;
       
        async function loadUsers() {
            const response = await api.get('/customers', {
                headers: { authorization, mobcustomerid : userid },
            })
            setCustomers(response.data.customers);
        }
        loadUsers();
    },[])

    async function handleRemove(itemId, name, index) {

        swal(`Remover ${name}?`, {
            buttons: {
              cancel: "cancelar",
              catch: {
                text: "remover",
                value: "remover",
              },
            },
          })
          .then((value) => {
            switch (value) {

              case "cancelar": 
                break;
           
              case "remover":
                setCustomers(customers.filter(customer => customer._id !== itemId));
                swal("Remover Cliente", "cliente removido com sucesso", "success");
                
                async function removeUser() {
                    const response = await api.delete(`/customer/delete/${itemId}`, {
                        headers: { authorization, mobcustomerid : userid },
                    })
                    console.log('Remover cliente');
                    console.log(response);
                }
                removeUser();
                break;

                default:
                    swal(" OK, você escolheu cancelar... ");

            }
          });
    }
    
  return (
    
    <>
          <div className="table-responsive">

        { !customers.length ? (
            <>
                <div className="col-md-12 text-center text-grey mt-5">
                    <p> Não há dados para exibir aqui </p>
                    <img src={notFound} alt="Data not found " />
                </div>
            </>
            ) : (
            <table className="table">
            
            <tbody>
                {customers.map((customer, index) => (
                    <tr key={customer._id}>
                        <td className="py-1 pl-0">
                            <div className="d-flex align-items-center">
                            <div className="ml-3">

                                <Link to={'/customer/' + customer._id}>
                                    <p className="mb-0">  
                                        {customer.name} </p>
                                </Link>

                                <p className="mb-0 text-muted text-small">
                                    #ID: {customer._id} <br/>
                                    Atualizado: {customer.updatedAt} <br/> 
                                    E-mail: {customer.email} <br/>
                                    Documento: {customer.customerDocument || "Não informado"}
                                </p>
                            </div>
                            </div>
                        </td>
                        <td className="text-right">
                            <CustomerPersonType personType={customer.personType} />
                        </td>
                        <td className="text-right">
                            
                            <Link to={'/customer/' + customer._id} className="btn btn-outline-twitter btn-sm mr-2">
                                Editar
                            </Link>

                            <Link to={'/charges/store/' + customer._id} className="btn btn-twitter btn-sm ml-2">
                                Gerar Cobrança
                            </Link>

                            <StatusIcon iconStatus={customer.status} />

                            <button onClick={() => handleRemove(customer._id, customer.name, index)} className="remoteItemBt">
                                <i className="ti-close text-danger btn-icon-prepend ml-3"></i>
                            </button>

                        </td>
                    </tr>
                ))}
            </tbody>
            </table>
        )}
        </div>
    </>
  );
}
