import React from 'react';
import './App.css';
import Routes from './routes';

// import Loader from 'react-loader-spinner';
// import Config from './services/config';

function App() {
  return (
    <>
    <Routes />
    </>
  );
}

export default App;
