import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AuthPage from '../../../components/AuthPage'; 
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';

export default class Help extends Component {
  render() {
    return (
        <>
        <AuthPage />
          <div className="container-scroller">
          <Header />
          <div className="container-fluid page-body-wrapper">
            <div className="main-panel">
              <div className="content-wrapper">

                <div className="row">
                <div className="col-md-12">
                      <div className="">
                          <h3> Ajuda </h3>
                          <p className="card-description text-grey mt-3 mb-4">
                              Resumimos aqui algumas das dúvidas mais recorrentes para poder ajudar você.
                              Caso não tenha encontrado a resposta para sua dúvida abra um chamado
                          </p>
                      </div>
                  </div>
                </div>

                <div className="row">
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="faq-section">
                        <div class="container-fluid bg-success py-2">
                          <p class="mb-0 text-white"> Transações </p>
                        </div>
                        <div id="accordion-1" class="accordion">
                          <div class="card">
                            <div class="card-header" id="headingOne">
                              <h5 class="mb-0">
                                <a data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" href>
                                  O custo por transação varia de acordo com meu perfil de usuário ?
                                </a>
                              </h5>
                            </div>
                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-1">
                              <div class="card-body">
                                <p class="mb-0"> Sim, quando efetuado o cadastramento do cliente na adquirente é levado em consideração o ramo de atividade, estimativa de vendas e depois haverá um estudo em cima do comportamento de vendas do usuário/empresa. </p>
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div class="card-header" id="headingTwo">
                              <h5 class="mb-0">
                                <a data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" href>
                                  Eu posso utilizar minha conta Pagefy para efetuar cobranças ? Como eu faço ?
                                </a>
                              </h5>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion-1">
                              <div class="card-body">
                                <p class="mb-0"> Sim, sua conta na Pagefy permite que vocês efetue cobranças atravás do boleto bancário e também cartão de crédito através de nosso gateway de pagamento </p>
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div class="card-header" id="headingThree">
                              <h5 class="mb-0">
                                <a data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" href>
                                  Onde eu encontro o preço das taxas operacionais de meu contrato ?
                                </a>
                              </h5>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion-1">
                              <div class="card-body">
                                <p class="mb-0"> Os preços assim como os dados do contrato poderão ser vistas em /Minha Conta/Meus Contratos ou se preferir acessando através deste link <Link to="myContracts"> Meus Contratos </Link> </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div> 

            </div>
          </div>
      </div>
    <Footer />
    </div>
  </>
    );
  }
}
