import React from 'react';
import { Link } from 'react-router-dom';

import AuthPage from '../../../../components/AuthPageAdmin';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import NavigationMenu from '../../../../components/AdminConsoleComponents/Navigation';
import CustomerStore from '../../../../components/AdminConsoleComponents/Customers/Store';
import WelcomeConsole from '../../../../components/AdminConsoleComponents/WelcomeConsole';

export default function Store() {
  return (
    <>
        <AuthPage />
        <div className="container-scroller">
            <HeaderAdminConsole />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                            <div className="row">

                                <div className="col-md-12">
                                    <div className="row">
                                        
                                        <div className="col-md-8">
                                            <CustomerStore />
                                        </div>

                                        <div className="col-md-4 text-right">

                                            <Link to="/admin/customers" className="btn btn-outline-secondary btn-sm">
                                                Todos Clientes
                                            </Link>
                                            
                                        </div>
                                            
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
