import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';

import Config from '../../services/config';
import api from '../../services/login';
import PaymentStatus from '../ChargesComponents/PaymentStatus';
import PaymentType from '../ChargesComponents/PaymentType';

import './styles.css';

// import notFound from '../../assets/images/payment-build.svg';
export default function GivenList() {

   const [ payments, setPayments ] = useState([]);

   useEffect(() => {
       
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

     async function loadPayments() {
         const response = await api.get(`/given/${userid}`, {
             headers: { authorization },
         })
         setPayments(response.data.payments);
     }
     loadPayments();
 },[]);

  return (
    <>
    
    <div className="row">
        <div className="col-12">
            <div className="row">
        <div className="col-12">
            <div className="row mb-3">
                
                <div className="col-md-6">
                    <h3 className="card-title"> Doações Recorrentes </h3>
                    <p className="text-grey"> Abaixo você encontra todas as doações recorrentes geradas em seus websites  </p>
                </div>

                <div className="col-md-6">
                  <div className="template-demo text-right">

                    <Link to="/given/store" className="btn btn-outline-twitter btn-sm"> Cadastrar Doação </Link>

                </div>
              </div>
            </div>


            <div className="mt-5">
                  { !payments.length ? (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <p> Não há dados para exibir aqui. <br/> Quer saber como engajar o seu público com sua causa ? </p>
                          </div>
                        </div>
                      </>
                    ) : (
                    <table className="table">
                      <thead>
                        <tr>
                            <th> Doador / Transaction ID# </th>
                            <th> Cadastrado em: </th>
                            <th> Tipo de Pagamento </th>
                            <th>Valor</th>
                            <th className="text-right"> Status da Recorrência </th>
                            <th className="text-right"> Detalhes </th>
                        </tr>
                      </thead>
                      <tbody>
                      {payments.map((payment, index) => (
                          <tr key={payment._id}>
                              <td> 
                                <Link to={`/payment/detail/${payment._id}`} className="text-grey trLink">
                                  <span className="text-grey"> <strong className="text-dark">  {payment.customerName}  </strong>  <br/></span>
                                  {payment._id}
                                </Link>
                              </td>
                              <td> {payment.duoDate} </td>
                              <td> <PaymentType paymentType={payment.chargeType} /> </td>
                              <td> {payment.amount} </td>
                              <td className="text-right">
                                <PaymentStatus status={payment.status} />
                              </td>
                              <td className="text-right"> 
                                Ver Detalhes </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                  )}
                  </div>
            </div>
      </div>
    </div>
  </div>
</>
  );
}
