import React from 'react';

export default function Status(props) {

    const status = props.statusId;

    if(status === "1"){
      return (
          <span className="text-twitter"> Ativo </span>
      );
    } else {
        return (
            <span className="text-danger"> Inativo </span>
        );
    }
    
}
