import React, { useEffect, useState } from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import PostsList from  '../../../components/CmsPostList';
import Loader from 'react-loader-spinner';
import Config from '../../../services/config';

export default function Given(props) {

  const { match: { params } } = props;

  const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
  const userid = credencialdata._id;
  const [contractId, setContractId] = useState('');

  const getFromCookie = (cookieName) => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  };

  useEffect(() => {
    const getContractId = getFromCookie('selectedContractId');
    if (getContractId) {
      setContractId(getContractId);
    }
  }, []);

  return (
    <>
        <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <div className="container-scroller">
            <Header />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                    <div className="content-wrapper">
                        <PostsList 
                          contract={contractId}
                          user={userid}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>
  );
}