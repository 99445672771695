import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import Loader from 'react-loader-spinner';
import swal from 'sweetalert';

import Config from '../../../../services/config';
import api from '../../../../services/login';

import AuthPage from '../../../../components/AuthPageAdmin';
import Footer from '../../../../components/Footer';
import Header from '../../../../components/HeaderAdminConsole';
import TicketType from '../../../../components/Common/TicketType';
import TicketStatus from '../../../../components/Common/TicketStatus';
// import TicketReplies from '../../../../components/Support/TicketsReplies';

import './styles.css';

export default function Details(props) {

  const { match: { params } } = props;
  const [ ticketData, setTicketData ] = useState([]);
  const [ ticketMessages, setTicketMessages ] = useState([]);
  const [ ticketId, setTicketId ] = useState([]);
  const [ ticketSubject, setTicketSubject ] = useState([]);
  const [description, setDescription] = useState('');

  const authorization = Config.AuthApiSecretKey;
  const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
  const userid = credencialdata._id;

  useEffect(() => {
       
   async function loadData() {
       const response = await api.get(`/admin/ticket/${params.id}`, {
           headers: { authorization, userid },
       })
       setTicketData(response.data.tickets);
   }

   async function loadMessages() {
       const response = await api.get(`/admin/ticket/messages/${params.id}`, {
           headers: { authorization, userid },
       })
       setTicketMessages(response.data.tickets);
   }

   loadData();
   loadMessages();


},[]);

async function closeTicket(){

  try {
    await api.put(`/ticket/close/${params?.id}`, { 'status' : '1'},{
      headers: { authorization, userid }
    });

    swal({
      title: "Tickets",
      text: `Ticket fechado com sucesso!`,
      timer: 2000
    });

    props.history.push("/admin/tickets");

  } catch (error) {

    swal({
      title: "Tickets",
      text: `Erro ao executar! `,
      timer: 2000
    });
  }

}

async function deleteTicket(){
  try {
    await api.delete(`/admin/ticket/${params?.id}`, {
      headers: { authorization, userid }
    });

    swal({
      title: "Tickets",
      text: `Ticket removido com sucesso!`,
      timer: 2000
    });


    props.history.push("/admin/tickets");

  } catch (error) {

    swal({
      title: "Tickets",
      text: `Erro ao executar! `,
      timer: 2000
    });

  }

}

async function onSubmit(e){

  e.preventDefault();

  const authorization = Config.AuthApiSecretKey;
  const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
  const userid = credencialdata._id;
  const authorEmail = ticketData?.authorEmail;

  try {
    await api.post(`/admin/ticket/reply/${params.id}`, {
      description,
      authorName: credencialdata.name,
      authorEmail
    }, {
      headers: { authorization, userid  }
    });
      
      swal({
        title: "Tickets",
        text: "Ticket respondido com sucesso ",
        timer: 2000
      });

      setDescription('');
      let updateD = new Date();

    } catch (error) {
      // Alerts("Tickets ", error.response.data.error ,"error");
      swal({
        title: "Tickets",
        text: error.response.data.error,
        timer: 2000
      });
    }

}

  return (
    <>
        <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="">
                      <div className="row border-bottom pb-4">
                          <div className="col-md-6">
                                <div className="col-md-12">
                                  <h3> 
                                    #{params?.id}
                                  </h3>
                                </div>
                                <div className="col-md-6">
                                  <i className="ti-headphone-alt"></i> Assunto: <TicketType ticketType={ticketSubject} /> <br/>
                                    Status do chamado: <TicketStatus status={ticketData?.status} /> <br/>
                                    Data de Atualização: <Moment format="DD/MM/YYYY hh:mm" date={ticketData?.updatedAt || "" } />
                                </div>
                          </div>
                          <div className="col-md-6 text-right">

                            <Link to="/admin/tickets">
                                <button className="btn btn-outline-dark btn-sm mt-3 mb-4 mr-3"> 
                                  Todos Chamados 
                                </button>
                            </Link>

                            <button 
                              className="btn btn-outline-warning btn-sm mt-3 mb-4 mr-3"
                              onClick={closeTicket}
                            >
                              Fechar Chamado 
                            </button>

                            <button 
                              className="btn btn-outline-danger btn-sm mt-3 mb-4"
                              onClick={deleteTicket}
                            >
                              Deletar Chamado 
                            </button>
                          </div>

                      </div>
                      <div className="row border-bottom mb-5 bgGrey">

                          <div className="col-md-6">
                            <h4>  {ticketData?.title} </h4>
                            <p className="mt-3 text-grey"> 
                            <span className="text-grey"> {ticketData?.description} </span> </p>
                          </div>

                          <div className="col-md-6 text-right">
                            
                            <p className="text-grey mb-3"> Ticket Aberto em: <br/>
                              <Moment format="DD/MM/YYYY" date={ticketData?.createdAt || "" } />
                            </p> 

                          </div>
                      </div>

                      <div className="row">

                      {ticketData?.status !== "2" ? (
                          <div className="col-md-6"> 
                            <h4 className="text-grey ml-4 mt-4"> Este chamado já foi fechado </h4>
                          </div>
                      ) :(
                        <div className="col-md-6">
                          <form className="forms-sample" onSubmit={onSubmit} >
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="description"> Mensagem * </label>
                                    <textarea 
                                      id="description"
                                      name="description"
                                      className="form-control" 
                                      rows="5"
                                      value={description}
                                      onChange={e => setDescription(e.target.value)}
                                      ></textarea>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 text-left mt-20">
                                <button 
                                  type="submit" 
                                  className="btn btn-twitter mr-2" > 
                                  Enviar </button>
                              </div>
                            </div>
                        </form>
                        </div>
                      )}

                      <div className="col-md-6">
                          <h4 className="text-twitter ml-3"> 
                            Histórico deste chamado
                          </h4>
                            <div className="scroll">
                              <ul>
                                <>
                                  {ticketMessages.map((ticket, index) => (
                                    <li className="d-flex profile-feed-item" key={ticket._id}>
                                      <div className="mb-1">
                                          <h6> 
                                              {ticket.authorName}
                                              <small className="ml-4 text-muted"><i className="ti-time mr-1"></i> 
                                              <Moment format="DD/MM/YYYY hh:mm" date={ticket?.createdAt || ""} />
                                              </small>
                                          </h6>
                                          <p className="text-grey">
                                            {ticket.description}
                                          </p>
                                      </div>
                                    </li>
                                  ))}
                                </>
                              </ul>
                            </div>
                      </div>
                     </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <Footer />
    </>
  );
}
