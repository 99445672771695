import React from 'react';
import { Redirect } from 'react-router-dom';
import Alerts from '../../components/Alerts/index';
import api from '../../services/signUp';
// import Config from '../../services/config';
// import Alerts from '../../components/Alerts/index';

export default function ResetPasswordActivation(props) {
  
  const { match: { params } } = props;
  console.log(params.id);

  async function resetFunciton(params){
    
    // const self = this;
    await api.get(`passwordReset/${params}`)
    .then(function (response) {
        // console.log(response.data.message);
        if(response.data.message === "update password success"){
          // console.log('Senha Atualizada com sucesso');
          Alerts("Ativação de Senha", ' Uma senha temporária foi enviada para seu endereço de e-mail! ' ,"success");
        } 
    });
    
    
    }
    
    resetFunciton(params.id);
    return <Redirect to="/" />
}
