import React from 'react';
import { Link } from 'react-router-dom';

export default function CampaignDetails() {
  return (
        <>
        
        <div className="row mb-3">
                    
            <div className="col-md-6">
                <h3 className="card-title"> Doações /Detalhes da Campanha </h3>
                <p className="text-grey"> # Título da Campanha  </p>
            </div>

            <div className="col-md-6">
                <div class="text-right">
                        <div class="dropdown">
                            <Link to="/campaigns" className="btn btn-outline-twitter btn-sm">
                                <i className="ti-list-ol"></i> Lista de Campanhas
                            </Link>
                        </div>
                    </div>
                </div>
        </div>

        <div className="row mt-2">

            {/* If Dont have donations */}
            <div className="col-md-12 text-center noDonation">
                <i className="ti-face-sad iconSad text-grey"></i> 
                <h4 className="mt-3 mb-5 text-grey"> Você ainda não possui doações nesta campanha, veja também nossa área de ajuda <br/> para saber como engajar seus usuários em suas campanhas  </h4>
            </div>
    
    {/* Donations Resume */}
            <div className="col-md-12">
                <div class="card">
                        <div class="card-body">
                            <p> Total de doadores: 100 </p>
                            <p> Período da campanha: 00/00/0000 a 00/00/0000 </p>
                            <div class="d-flex justify-content-between">
                                <p class="text-muted"> Total arrecadado </p>
                                <h4 className="mb-0 font-weight-bold"> R$ 1.200,00 </h4>
                            </div>
                            <div class="progress progress-md">
                                <div class="progress-bar bg-danger w-25" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

{/* </div> */}

        <div className="row">

        <div className="col-md-12 mb-4 mt-4">
                <h4 class="card-title text-twitter"> Resumo da campanha </h4>
            </div>

            <div className="col-md-12">

            <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                            <th>ID#</th>
                            <th>Criando em</th>
                            <th>Cliente</th>
                            <th>Meio</th>
                            <th>Valor</th>
                            <th className="text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td> 
                              <Link to='/chargeDetail'>
                                <strong className="text-twitter"> 10001 </strong> 
                              </Link>
                            </td>
                            <td>06/11/2019</td>
                            <td>André Jaccon</td>
                            <td> Boleto</td>
                            <td>R$ 1500,00</td>
                            <td className="text-center">
                              <label className="badge badge-success"> Aprovado </label>
                              <Link to='/chargeDetail'>
                                <i className="ti-settings ml-4 text-muted"></i>
                              </Link>
                            </td>
                        </tr>
                        <tr>
                            <td> 
                            <Link to='/chargeDetail'>
                              <strong className="text-twitter"> 10001 </strong> 
                            </Link>
                            </td>
                            <td>06/11/2019</td>
                            <td>André Jaccon</td>
                            <td> Boleto</td>
                            <td>R$ 1500,00</td>
                            <td className="text-center">
                              <label className="badge badge-danger"> Recusado</label>
                              <Link to='/chargeDetail'>
                                <i className="ti-settings ml-4 text-muted"></i>
                              </Link>
                            </td>
                        </tr>
                        <tr>
                            <td> 
                              <Link to='/chargeDetail'>
                                <strong className="text-twitter"> 10001 </strong>
                              </Link>
                            </td>
                            <td>06/11/2019</td>
                            <td>André Jaccon</td>
                            <td> Boleto</td>
                            <td>R$ 1500,00</td>
                            <td className="text-center">
                              <label className="badge badge-warning text-white"> Aguardando</label>

                              <Link to='/chargeDetail'>
                                  <i className="ti-settings ml-4 text-muted"></i>
                              </Link>

                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
            </div>
            </div>
    </>
  );
}
