import React, { Component } from 'react';
import Moment from 'react-moment';

import Config from '../../services/config';
import api from '../../services/Users';
import './styles.css';

export default class AccounStatusComponent extends Component {

  constructor(props) {

    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    super(props);
    this.state = {
      userdata: {},
      authorization,
      userid
    };
  }

  componentDidMount() {
    
    const authorization = this.state.authorization;
    const userid = this.state.userid;

    api.get('/user/detail',{
        headers: { authorization, userid },
    })
    .then(res => {
        this.setState({ userdata: res.data.user });
    });

  }

  render() {
    return (
      <>
        <div className="row">
              <div className="col-12 mb-20 mt-20">

                <div className="row mb-4">
                  <div className="col-md-12">
                      <h3 className="card-title"> Status Conta </h3>
                      <p className="text-grey"> As chaves serão adicionadas neste painel automáticamente na medida que contratar novos serviços. </p>
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="card">
                      <div className="card-body">
                        <ul className="bullet-line-list">
                          <li className="success">
                            <h6> Abertura da Conta </h6>
                            <p className="text-muted mb-4 text-grey">
                              <i className="ti-time"></i> &nbsp; Atualizado em: <Moment format="DD/MM/YYYY" date={this.state.userdata.createdAt || ""}  />  <br/>
                                Status: <span className="text-success"> Concluído </span> 
                            </p>
                          </li>
                          <li className="bulletOff">
                            <h6> Atualização de cadastro </h6>
                            <p className="mb-4 text-grey">
                              <i className="ti-time"></i> &nbsp; Atualizado em: sem atualizações <br/>
                              Status: Aguardando atualização de cadastro 
                            </p>
                          </li>
                          <li className="bulletOff">
                            <h6 className="text-grey"> Aprovação da conta </h6>
                            <p className="text-grey">
                              <i className="ti-time"></i> &nbsp; Atualizado em: sem atualizações <br/>
                              Status: Aguardando atualização de cadastro 
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                </div>
              </div>
      </div>
    </>
    );
  }
}
