import React from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import PushCampaignsListComponent from '../../../components/PushNotifications/List';

export default function List(props) {

  const { match: { params } } = props;
  const contractId = params.contractId

  return (
    <>
    <AuthPage />
    <div className="container-scroller">
        <Header />
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                    <div className="content-wrapper">
                        <PushCampaignsListComponent contractId={contractId} />
                    </div>
                </div>
            </div>
        <Footer />
    </div>
    </>
  );
}
