import React from 'react';

export default function TicketType(props) {

    const subject = props.subject;

      return (
          <span className="text-twitter"> {subject} </span>
      );
    
}
