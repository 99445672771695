import React from 'react';

export default function TicketStatus(props) {

    const status = props.status;

    if(props.status === "2"){
      return (
          <span className="text-success" status={status}> Aberto </span>
      );
    } else {
        return (
            <span className="text-danger"> Fechado </span>
        );
    }
    
}
