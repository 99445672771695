import React from 'react';

import Config from '../../../services/config';
import Loader from 'react-loader-spinner';
import AuthPage from '../../../components/AuthPage';
import ExpressNext from  '../../../components/Payment/Next';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';

import './styles.css';

export default function Next() {
  return (
    <>

      <Loader
       type="BallTriangle"
       color="#000000"
       height={60}
       width={60}
       timeout={Config.loaderTimeout}
       className="overlaySpinner" //3 secs
      />
    
      <AuthPage />

      <div className="container-scroller">
        <Header />
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                <div className="content-wrapper">
                    <ExpressNext />
                </div>
            </div>
        </div>
        <Footer />
      </div>

    </>
  );
}
