import React from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import AuthPage from '../../../../components/AuthPageAdmin';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import NavigationMenu from '../../../../components/AdminConsoleComponents/Navigation';
import UsersList from '../../../../components/AdminConsoleComponents/UsersAdminConsole/List';
import WelcomeConsole from '../../../../components/AdminConsoleComponents/WelcomeConsole';
import Config from '../../../../services/config';

export default function Users() {
  return (
    <>
        <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner" //3 secs
      />
        <AuthPage />
        <div className="container-scroller">
            <HeaderAdminConsole />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                        <div className="row">

                            <div className="col-md-12">
                                <div className="row">

                                    <div className="col-md-12">
                                       
                                            <div className="row mb-4">
                                            
                                            <div className="col-md-8">
                                                <h3 className=""> Usuários  </h3>
                                                <p className="text-grey mt-3"> Lista de usuários com acesso ao console de administração  </p></div>

                                                <div className="col-md-4 text-right">
                                                    <Link to="/admin/user/store" className="btn btn-outline-twitter btn-sm">
                                                        <i className="ti-plus btn-icon-prepend"></i> Adicionar 
                                                    </Link>
                                                </div>
                                            </div>

                                            <UsersList />

                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
