import React from 'react';
import { Link } from 'react-router-dom';
import PlayAudio from 'react-simple-audio-player';

export default function AudioList() {

  return (
    <>
        <div className="row">
            <div className="col-md-6">
                <h3 className="card-title"> Áudios </h3>
                <p className="text-grey"> Aqui você pode adicionar áudios, músicas, podcasts e audio livros em seus apps. É permitido que você faça o upload de arquivos em formato
                MP3. </p>
            </div>

            <div className="col-md-6">
                
                <div className="template-demo mt-2 text-right">
                    <Link to="/audio/store" className="btn btn-outline-twitter btn-sm">
                        <i className="ti-plus"></i> Novo
                    </Link>
                </div>

            </div>

        </div>

        <div className="table-responsive mt-3">
                    <table className="table">
                      <thead>
                        <tr>
                            <th> #ID </th>
                            <th> Título </th>
                            <th className="text-left">  </th>
                            <th className="text-left"> Propriedades </th>
                            <th className="text-center"> Ações </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td className="text-muted"> #111 </td>
                            <td className="text-muted"> Reforma da Instituição </td>
                            <td className="text-left text-muted"> 
                                {/* Player */}
                                <div className="audioPlayer">
                                    <PlayAudio 
                                        url={'http://www.noiseaddicts.com/samples_1w72b820/4186.mp3'}
                                        width="30"
                                    />
                                </div>
                            </td>
                            <td className="text-left text-muted"> 25/12/2019 15:42 </td>
                            <td className="text-right text-twitter"> 
                                <Link to="audio/update/1">
                                  <button type="button" className="btn btn-outline-secondary btn-sm"> 
                                  <i className="ti-settings btn-icon-prepend"></i> Editar </button>
                                </Link>

                                <i className="ti-control-record text-success btn-icon-prepend ml-3 status-icon"></i>
                                <i className="ti-close text-danger btn-icon-prepend ml-3"></i>

                            </td>
                        </tr>

                        <tr>
                            <td className="text-muted"> #111 </td>
                            <td className="text-muted"> Reforma da Instituição </td>
                            <td className="text-left text-muted"> 
                                {/* Player */}
                                <div className="audioPlayer">
                                    <PlayAudio 
                                        url={'http://www.noiseaddicts.com/samples_1w72b820/4640.mp3'}
                                        width="30"
                                    />
                                </div>
                            </td>
                            <td className="text-left text-muted"> 25/12/2019 15:42 </td>
                            <td className="text-right text-twitter"> 
                                <Link to="audio/update/1">
                                  <button type="button" className="btn btn-outline-secondary btn-sm"> 
                                  <i className="ti-settings btn-icon-prepend"></i> Editar </button>
                                </Link>

                                <i className="ti-control-record text-success btn-icon-prepend ml-3 status-icon"></i>
                                <i className="ti-close text-danger btn-icon-prepend ml-3"></i>

                            </td>
                        </tr>
                        
                      </tbody>
                    </table>
        </div>
    </>
  );
}
