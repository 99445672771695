import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Config from '../../../services/config';
import api from '../../../services/Users';
import Alerts from '../../../components/Alerts';

import AuthPage from '../../../components/AuthPage';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

import image from '../../../assets/images/support.svg';

import "./styles.css";

export default class SupportAdd extends Component {

  constructor(props) {
        
    const authorization = Config.AuthApiSecretKey;
   
    super(props);
    this.state = {
        userdata: {},
        authorization,
        userid: this.props.customerId,
        redirect: false
    };
  }

  handleChange = async (e) => {

    const state = this.state.userdata
    state[e.target.name] = e.target.value;
    this.setState({userdata:state});

    const authorization = this.state.authorization;
    const userid = this.state.userid;

    if(e.target.name === 'addrZip'){
    
      const queryZip = e.target.value

          if(queryZip.length >= 8){
              const response = await api.post('/user/address/zip', {
                  zip: queryZip, 
              },{
              headers : { authorization, userid }
              });

              state.addr = response.data.logradouro;
              state.addrNeighborhood = response.data.bairro;
              state.addrCity = response.data.localidade;
              state.addrState = response.data.uf;
              this.setState({userdata:state});

          }
      }
  }

  onSubmit = async (e) => {

    e.preventDefault();

    const authorization = this.state.authorization;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    const { 
      title,
      subject,
      description,
      status } = this.state.userdata;
    try {
      const response = await api.post('/tickets', {
        title,
        subject,
        description,
        status,
        authorName : credencialdata.name,
        authorEmail: credencialdata.email
      }, {
        headers: { authorization, userid }
      });

      Alerts("Chamado", "Chamado cadastrado com sucesso! ","success");
      console.log(response);
      
      } catch (error) {
          Alerts("Chamado", error.response.data.error ,"error");
      }
  }


  render() {
    return (
      <>
        <AuthPage />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="">
                      <div className="row">

                        <div className="col-md-8">
                            <div className="border-bottom">
                                <h3> Novo Chamado </h3>
                                <p className="card-description text-grey mt-3 mb-3">
                                  Preencha o formulário abaixo com os dados que assim que possível entraremos em contato. 
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 text-right border-bottom">
                          <div className="">
                            <Link to="/user/tickets">
                                <button className="btn btn-outline-dark btn-sm mt-3 mb-3"> 
                                  Todos Chamados
                                </button>
                            </Link>
                          </div>
                        </div>
                        
                        <div className="col-lg-6 mt-4">
                            <div>
                                <div className="">
                                  
                                  <form onSubmit={this.onSubmit} className="forms-sample">
    
                                      <div className="form-group">
                                        <label htmlFor="exampleInputUsername1"> Título * </label>
                                        <input 
                                          id="title" 
                                          name="title" 
                                          type="text" 
                                          className="form-control" 
                                          placeholder="Descreva o assunto em até 120 caracteres" 
                                          onChange={this.handleChange}
                                        />
                                      </div>

                                      <div className="form-group">
                                        <label htmlFor="subject"> Assunto * </label>
                                        <select 
                                        name="subject"
                                        id="subject"
                                        onChange={this.handleChange}
                                        className="form-control">
                                            <option value=""> Selecione o assunto </option>
                                            <option value="giving"> Pagefy Giving </option>
                                            <option value="business"> Pagefy Business </option>
                                            <option value="payments"> Meios de Pagamento </option>
                                            <option value="cloudservices"> Serviços Cloud </option>
                                            <option value="asks"> Dúvidas em Produtos </option>
                                            <option value="cancel"> Cancelamentos </option>
                                        </select>
                                    </div>

                                      <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="message"> Mensagem * </label>
                                                <textarea 
                                                  id="description"
                                                  name="description"
                                                  className="form-control" 
                                                  rows="10" 
                                                  onChange={this.handleChange}
                                                ></textarea>
                                            </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-12 text-left mt-20">
                                          <button 
                                            type="submit" 
                                            className="btn btn-dark mr-2"> 
                                            Enviar </button>
                                        </div>
                                      </div>
    
                                  </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 support-image">

                            <img src={image} alt="Support" width="500" />

                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <Footer />
      </>
    );
  }
}
