import React from 'react';
import { Link } from 'react-router-dom';

export default function EventsDetails() {
  return (
    <>
        <div className="row mb-3">
                    
            <div className="col-md-6">
                <h3 className="card-title"> Doações / Detalhes do Evento </h3>
                <p className="text-grey"> # Título do Evento  </p>
            </div>

            <div className="col-md-6">
                <div class="text-right">
                        <div class="dropdown">
                            <Link to="/events" className="btn btn-outline-twitter btn-sm">
                                <i className="ti-list-ol"></i> Lista de Eventos
                            </Link>
                        </div>
                    </div>
                </div>
        </div>

        <div className="row mt-2">

            {/* If Dont have donations */}
            <div className="col-md-12 text-center noDonation">
                <i className="ti-face-sad iconSad text-grey"></i> 
                <h4 className="mt-3 mb-5 text-grey"> Você ainda não possui inscrições neste evento, veja também nossa área de ajuda <br/> para saber como engajar seus usuários em seus eventos  </h4>
            </div>
    
    {/* Donations Resume */}
            <div className="col-md-12">
                <div class="card">
                        <div class="card-body">
                            <p> Total de Inscritos: 100 </p>
                            <p> Data do Evento: 00/00/0000 a 00/00/0000 </p>
                            <div class="d-flex justify-content-between">
                                <p class="text-muted"> Total em inscrições </p>
                                <h4 className="mb-0 font-weight-bold"> R$ 1.200,00 </h4>
                            </div>
                            <div class="progress progress-md">
                                <div class="progress-bar bg-danger w-25" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

{/* </div> */}

        <div className="row">

            <div className="col-md-6 mb-4 mt-4">
                <h4 class="card-title text-twitter"> Lista de Inscritos </h4>
            </div>

            <div className="col-md-6 mb-4 mt-4 text-right">
                <Link to="/exportSubscribers" className="btn btn-outline-twitter btn-sm">
                    <i className="ti-list-ol"></i> Exportar Lista de Inscritos
                </Link>
            </div>

            <div className="col-md-12">

            <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                            <th> ID#</th>
                            <th> Inscrito em </th>
                            <th> Nome </th>
                            <th> E-mail </th>
                            <th className="text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td> 
                                <strong className="text-twitter"> 10001 </strong> 
                            </td>
                            <td>06/11/2019</td>
                            <td>André Jaccon</td>
                            <td> jaccon@gmail.com </td>
                            <td className="text-right">
                              <label className="badge badge-success"> Pago </label>
                              <Link to="/event/subscribe/1" className="btn btn-outline-secondary btn-sm ml-3">
                                <i className="ti-eye btn-icon-prepend"></i> Detalhes
                            </Link>
                            </td>
                        </tr>
                        <tr>
                            <td> 
                              <strong className="text-twitter"> 10001 </strong> 
                            </td>
                            <td>06/11/2019</td>
                            <td>André Jaccon</td>
                            <td> jaccon@gmail.com </td>
                            <td className="text-right">
                              <label className="badge badge-success"> Pago </label>
                              <Link to="/event/subscribe/1" className="btn btn-outline-secondary btn-sm ml-3">
                                <i className="ti-eye btn-icon-prepend"></i> Detalhes
                            </Link>
                            </td>
                        </tr>
                        <tr>
                            <td> 
                                <strong className="text-twitter"> 10001 </strong>
                            </td>
                            <td>06/11/2019</td>
                            <td>André Jaccon</td>
                            <td> jaccon@gmail.com </td>
                            <td className="text-right">
                              <label className="badge badge-success"> Pago </label>
                              <Link to="/event/subscribe/1" className="btn btn-outline-secondary btn-sm ml-3">
                                <i className="ti-eye btn-icon-prepend"></i> Detalhes
                            </Link>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
            </div>
            </div>
    </>
  );
}
