import React from 'react';

export default function PaymentStatusGtw1(props) {

    const SwitchColor = (str) => ({
      "aguardando Extorno" : "btn btn-sm btn-warning",
      "pago" : "btn btn-sm  btn-success",
      "pagamento autorizado" : "btn btn-sm  btn-success",
      "aguardando pagamento" : "btn btn-sm  btn-warning",
      "pagamento recusado" : "btn btn-sm btn-danger",
      "" : "btn btn-sm btn-grey",
    })[str] || 'text-grey';

    let getStatus = props.status;

    return (
      <span className={`text-uppercase disabled ${SwitchColor(getStatus)}`}>
        { getStatus }
      </span>
  );

  } 