import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Config from '../../../../services/config';
import api from '../../../../services/Users';
import Alerts from '../../../../components/Alerts';

export default class Store extends Component {


    constructor(props) {
        
        const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));

        super(props);

        const tutoId = this.props.tutoId;

        this.state = {
            tutorialdata: {},
            authorization,
            tutoId : tutoId,
            userid: credencialdata._id,
            redirect: false
        };
    }

    handleChange = (e) => {
        const state = this.state.tutorialdata
        state[e.target.name] = e.target.value;
        this.setState({tutorialdata:state});
        console.log(state);
    }

    onSubmit = async (e) => {

        e.preventDefault();
        const authorization = this.state.authorization;
        const userid = this.state.userid;
        const tId = this.state.tutoId

        const { 
            title, 
            description, 
            videoUrl,
            tutorialId,
            status,
            author
          } = this.state.tutorialdata;
    
        const response = await api.post('/admin/tutorial/video/store', {
              title, 
              description, 
              videoUrl,
              tutorialId : this.props.tutoId,
              status,
              author
          }, {
            headers: { authorization, userid }
          });
    
          if(response.data.cb !== "error"){
              Alerts("Tutorial", "Tutorial cadastrado com sucesso!","success");
              this.setState({
                redirect: true
            })
        } else {
            Alerts("Tutorial", response.data.message ,"error");
          }
      
      }

  render() {
  
    if(this.state.redirect === true){
      return <Redirect to={`/admin/tutorial/videos/${this.state.tutoId}`} />
    }

    return (
        <>
          <div className="row">
            <div className="col-md-8">

                <div className="row mb-3">
                    
                    <div className="col-md-6">
                        <h3> Tutorial </h3>
                    </div>

                   
                    </div>

                <div className="row">
                    <div className="col-md-12">

                        <form onSubmit={this.onSubmit}>

                            <div className="form-group">
                                <label htmlFor="title"> Título * </label>
                                <input 
                                placeholder="Título... " 
                                type="text" 
                                className="form-control" 
                                name="title"
                                id="title"
                                value={this.state.tutorialdata.title || ""}
                                onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="videoUrl"> URL do vídeo * </label>
                                <input 
                                placeholder="Insira aqui o endereço do vídeo... " 
                                type="text" 
                                className="form-control" 
                                name="videoUrl"
                                id="videoUrl"
                                value={this.state.tutorialdata.videoUrl || ""}
                                onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="description"> Descrição * </label>
                                <textarea 
                                    placeholder="Descreva ..." 
                                    type="text" 
                                    className="form-control" 
                                    name="description"
                                    id="description"
                                    value={this.state.tutorialdata.description || ""}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="status"> Status </label>
                                <select 
                                id="status"
                                name="status"
                                value={this.state.tutorialdata.status || ""}
                                onChange={this.handleChange}
                                className="form-control">
                                    <option value=""> Selecione o Status </option>
                                    <option value="1"> Ativo </option>
                                    <option value="2"> Inativo </option>
                                </select>
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <button 
                                    type="submit" 
                                    className="btn btn-info mr-2"
                                    > Salvar </button>
                                </div>
                            </div>
                </form>
                </div>

              </div>
            </div>
              
              <div className="col-md-4 text-right">
                <div className="dropdown">
                    <Link to={`/admin/tutorial/videos/${this.state.tutoId}`} className="btn btn-outline-twitter btn-sm">
                        Ver todos os vídeos deste tutorial
                    </Link>
                </div>
              </div>
            </div>
    </>
    );
  }
}
