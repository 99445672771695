import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InputMask from "react-input-mask";
import IntlCurrencyInput from "react-intl-currency-input"

import Config from '../../../services/config';
import api from '../../../services/Users';
import Alerts from '../../../components/Alerts';


import './styles.css';

export default class Next extends Component {

  constructor(props) {
        
    const authorization = Config.AuthApiSecretKey;

    super(props);
    this.state = {
        userdata: {},
        authorization,
        userid: this.props.userId,
        customerid: this.props.customerId
    };
    
  }

  handleChange = (e, value, maskedValue) => {

    e.preventDefault();
    const state = this.state.userdata

    if(e.target.name === 'amount'){
      state[e.target.name] = maskedValue;
    } else {
      state[e.target.name] = e.target.value;
    }
    this.setState({userdata:state});
  };


  handleSubmit = (e) => {
    
    e.preventDefault();

    const authorization = this.state.authorization;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    const { customerEmail,
      customerName,
      chargeType,
      chargeCycle,
      description,
      duoDate,
      interest,
      customerDocument,
      ourNumber,
      amount,
      enableInstallments
      } = this.state.userdata;

    if( customerEmail || chargeType || chargeCycle || description || duoDate || interest || customerDocument || ourNumber || amount || userid ) {

      api.post(`/payment/express/store`, { 
        customerId: userid,
        customerName,
        customerEmail,
        chargeType,
        chargeCycle,
        description,
        duoDate,
        interest,
        walletId: '00000001',
        customerDocument,
        ourNumber,
        amount,
        enableInstallments
        },{ 
          headers: { authorization }
      }).then((result) => {
          Alerts("Cobrança", `Cobrança efetuada com sucesso` ,"success");
      });

    } else {

      Alerts("Cobrança Express Error", "Por favor verifique se todos os campos foram preenchidos corretamente para que nossos robos criem sua cobrança ","error");

    }

  }

  render() {
    const currencyConfig = {
      locale: "pt-BR",
      formats: {
        number: {
          BRL: {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
      },
    };

    return (
      <>
      <div className="row">
        <div className="col-12">

        <div className="row mb-3 ">

            <div className="col-md-6">
                <h3> Cobranças </h3>
            </div>

            <div className="col-md-6 text-right ">

                <Link to='/payment/express' className="btn btn-outline-twitter btn-sm mr-2">
                    Cobrança Express
                </Link>

                <Link to="/payments" className="btn btn-twitter btn-sm mr-3">
                    Suas Cobranças
                </Link>

            </div>

        </div>

          <div className="row billingBg">

            <div className="col-md-6 mt-3">


              <div className="row">
                
              <div className="col-md-12">
                <h4 className="mb-4 text-dark border-bottom pb-3"> Tipo de Cobrança </h4>
              </div>

                <div className=" col-md-6">
                  <div className="form-group">
                      <label htmlFor="chargeType"> Cobrar através de * </label>
                      <select 
                          className="form-control" 
                          name="chargeType"
                          id="chargeType"
                          onChange={this.handleChange}
                          >
                          <option value=""> Selecione uma opção </option>
                          <option value="CREDIT_CARD"> Cartão de Crédito </option>
                          <option value="BOLETO"> Boleto Bancário </option>
                          <option value="UNDEFINED"> O cliente escolhe como pagar  </option>
                      </select>
                  </div>
                </div>

                <div className=" col-md-6">
                  <div className="form-group">
                      <label htmlFor="chargeCycle"> Ciclo de Cobrança:  </label>
                      <select className="form-control"
                        id="chargeCycle"
                        name="chargeCycle"
                        onChange={this.handleChange}
                      >
                          <option value=""> Selecione uma opção </option>
                          <option value="onetime" > Cobrança Única </option>
                          <option value="monthly"> Cobança Recorrente ( Mensal ) </option>
                          <option value="biannual"> Cobança Recorrente ( Semestral ) </option>
                          <option value="annual"> Cobança Recorrente ( Anual ) </option>
                          <option value="weekly"> Cobança Recorrente ( Semanal ) </option>
                          <option value="daily"> Cobança Recorrente ( Diária ) </option>
                      </select>
                  </div>
                </div>

              </div>

              <div className="row">

              <div className="col-md-12">
                <h4 className="mt-3 mb-4 text-dark border-bottom pb-3"> Dados do Cliente </h4>
              </div>

                <div className="col-md-6">
                    <div className="dataContainer">
                        <div className="form-group">
                              <label htmlFor="customerDocument"> CPF/CNPJ: </label>
                              <input 
                                id="customerDocument"
                                name="customerDocument"
                                // value={this.state.userdata.customerDocument || ""}
                                type="number" 
                                className="form-control form-control-sm" 
                                placeholder="Informe o CPF/CNPJ do seu cliente" 
                                onChange={this.handleChange}
                                />
                          </div>
                    </div>
                </div>

                <div className="col-md-6">
                  <div className="dataContainer">
                          <div className="form-group">
                              <label htmlFor="customerEmail"> E-mail para envio da cobrança </label>
                              <input 
                                id="customerEmail"
                                name="customerEmail"
                                // value={this.state.userdata.customerEmail || ""}
                                onChange={this.handleChange}
                                type="text" 
                                className="form-control form-control-sm" 
                                placeholder="Endereço de E-mail"
                                />
                          </div>
                      </div>
                  </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="dataContainer">
                        <div className="form-group">
                              <label htmlFor="customerDocument"> Nome Completo / Razão Social : </label>
                              <input 
                                id="customerName"
                                name="customerName"
                                // value={this.state.userdata.customerName || ""}
                                onChange={this.handleChange}
                                type="text" 
                                className="form-control form-control-sm" 
                                placeholder="Entre com o nome do cliente" 
                                />
                          </div>
                    </div>
                </div>
              </div>

              <div className="dataContainer">
                    <div className="row">
                      <div className="col-md-6">
                          <label> Salvar cliente para cobranças futuras  </label>
                      </div>
                      <div className="col-md-6">
                          <div className="form-check">
                            <label className="form-check-label">
                              <input type="checkbox" className="form-check-input" />
                              <i className="input-helper"></i></label>
                          </div>

                        </div>
                      </div>
                    </div>

                    
              </div>

              
              <div className="col-md-6 border-left p-4">

                  <h4 className="mb-4 text-dark pb-3 border-bottom"> Dados da Cobrança </h4>

                  <div className="dataContainer">
                    <div className="form-group">
                        <label htmlFor="amount"> Valor Total </label>
                          <IntlCurrencyInput 
                            id="amount"
                            name="amount"
                            type="text" 
                            className="form-control form-control-lg inputblue" 
                            placeholder="Informe o valor da cobrança"
                            currency="BRL" 
                            config={currencyConfig}
                            onChange={this.handleChange} 
                            />
                    </div>
                  </div>

                  <div className="row">

                    <div className="col-md-6">

                        <div className="dataContainer">
                          <div className="form-group">
                              <label htmlFor="billingAmount"> Nosso Número </label>
                              <input 
                                id="ourNumber"
                                name="ourNumber"
                                type="number" 
                                className="form-control form-control-sm" 
                                placeholder="Sua referência para identificação da Cobrança ( Nosso Número )"
                                onChange={this.handleChange}
                                />
                          </div>
                        </div>

                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="duoDate"> Vencimento </label>
                            
                            <InputMask 
                              mask="99/99/9999" 
                              placeholder="dd/mm/aa" 
                              id="duoDate"
                              name="duoDate"
                              className="form-control form-control-sm" 
                              onChange={this.handleChange}
                            />
                        </div>
                      </div>
                      
                  </div>

                  <div className="row">

                      <div className="col-md-6">
                            <div className="dataContainer">
                              <div className="form-group">
                                  <label htmlFor="interest"> Júros por inadimplência </label>
                                  <InputMask 
                                    mask="99\%" 
                                    id="interest"
                                    name="interest"
                                    className="form-control form-control-sm" 
                                    placeholder=" %"
                                    onChange={this.handleChange}
                                  />
                              </div>
                            </div>
                      </div>
                  
                      <div className="col-md-6">
                          <div className="form-group">
                              <label htmlFor="enableInstallments"> Parcelar em:  </label>
                              <select className="form-control"
                                id="enableInstallments"
                                name="enableInstallments"
                                onChange={this.handleChange}
                              >
                              <option value=""> Não Parcelar </option>
                              <option value="2" > Parcelar em 2x </option>
                              <option value="3">  Parcelar em 3x </option>
                              <option value="4"> Parcelar em 4x </option>
                              <option value="5"> Parcelar em 5x </option>
                              <option value="6"> Parcelar em 6x </option>
                              <option value="7"> Parcelar em 7x </option>
                              <option value="8"> Parcelar em 8x </option>
                              <option value="9"> Parcelar em 9x </option>
                              <option value="10"> Parcelar em 10x </option>
                              <option value="11"> Parcelar em 11x </option>
                              <option value="12"> Parcelar em 12x </option>
                              </select>
                          </div>
                        </div>

                    </div>
                 

                    {/*  */}

                  <div className="dataContainer">
                    <div className="form-group">
                        <label htmlFor="description"> Descrição da Cobrança: </label>
                          <textarea
                            id="description"
                            name="description"
                            className="form-control form-control-lg"
                            placeholder="Descreva em até 41 caracteres..."
                            maxLength="80"
                            row="3"
                            onChange={this.handleChange}
                            ></textarea>
                      </div>
                    </div>

                    <div className="dataContainer text-right">
                      <div className="form-group">
                        <button 
                            type="submit" 
                            className="btn btn-twitter mr-2"
                            onClick={this.handleSubmit}
                            > EMITIR COBRANÇA </button>
                      </div>
                    </div>

                  {/*  */}
              </div>

              </div>
              
              
            </div>

            </div>
    </>
    );
  }
}
