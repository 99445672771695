import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import Alerts from '../../../components/Alerts';
import Config from '../../../services/config';
import api from '../../../services/login';
import PaymentStatusGtw1 from '../../ChargesComponents/PaymentStatusGtw1';

import './styles.css';

export default function ChargeDetailResume(props) {

  const transactionID = props.transactionId;
  // const customerId = props.customerId;
  // const contractId = props.contractId;
  
  const [ paymentData, setPaymentData ] = useState();
  const [ customerData, setCustomerData ] = useState();

  async function handleChargeback(){
    await Alerts("Estorno de Transação", `Sua transação ${transactionID}, para confirmar clique em  confirmar ` ,"warning");
  }

  useEffect(() => {
    
    window.scrollTo(0, 0);
    
    const authorization = Config.AuthApiSecretKey;

    async function loadCustomerData(){
      const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
      console.log(credencialdata._id)
      setCustomerData(credencialdata);
    }

    async function loadData() {
      const response = await api.get(`/transaction/gtw1/${transactionID}`, {
          headers: { authorization },
      })
      setPaymentData(response.data.response);
    }
      
    loadData();
    loadCustomerData();

    },[]);

  return (
    <>
     <div className="row">
        <div className="col-12">

            <div className="row mb-4 border-bottom">
                
                <div className="col-md-4 col-sm-12">
                      <h3 className="card-title"> 
                        <small> Transação <span className="text-twitter"> {transactionID} </span> </small> <br/>
                      </h3>

                      Vencimento: <span className="text-twitter"> <Moment format="DD/MM/YYYY" date={paymentData?.date_created || ""} /> </span> <br/> 
                      <p className="text-muted mt-2"> 
                            <PaymentStatusGtw1 status={paymentData?.status || ""} />
                      </p>
                </div>
                
                <div className="col-md-6 col-sm-12 text-left border-left"> 

                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <h3> {paymentData?.amount} </h3>
                      <p className="text-grey"> 
                        <strong> Valor da Captura </strong> <br/>
                        Total de Parcelas: <span className="text-twitter"> {paymentData?.installments} </span>
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-12 border-left">
                        <h3> {paymentData?.amount_customer} </h3>
                        <p className="text-grey">
                        <strong>  Valor a receber </strong>  <br/>
                          Pago com: <span className="text-twitter"> {paymentData?.payment_method} </span> </p>
                    </div>
                  </div>
                     
                </div>

                <div className="col-md-2 text-right"> 

                  <Link to={`/user/transactions/${props?.contractId}`} className="btn btn-outline-twitter btn-sm">
                      Todas Transações 
                  </Link> 

                  {/* <button className="btn btn-outline-danger btn-sm mt-3" onClick={handleChargeback}>
                      Extornar Transação
                  </button>  */}

                </div>

            </div>

            <div className="row">

                  <div className="col-md-8 col-sm-12">

                    <h4 className="text-dark text-weight-bolder mb-4 mt-4"> Dados do Cliente </h4>

                    <div className="row softGrey">

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> 
                            <strong> CLIENTE </strong>  </p>
                          <p className="text-grey text-uppercase"> 
                            {paymentData?.customer_name} </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> 
                            <strong> DOCUMENTO </strong>  </p>
                          <p className="text-grey"> 
                            {paymentData?.document_number} </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom text-uppercase"> 
                            <strong>  E-mail </strong> </p>
                          <p className="text-grey"> 
                            {paymentData?.email} </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom text-uppercase"> 
                            <strong> Telefone </strong>  </p>
                          <p className="text-grey"> 
                            {paymentData?.phone} </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark"> 
                              <Link to={`/user/wcorder/${props?.contractId}/${paymentData?.metadata.order_number}/${customerData?._id}`}
                                className="btn btn-outline-twitter btn-sm mt-3">
                                Detalhes do Pedido no E-commerce </Link> 
                          </p>
                      </div>

                    </div>
                    
                  </div>

                  <div className="col-md-4 col-sm-12">

                    <h4 className="text-dark text-weight-bolder mb-4 mt-4"> Histórico da transação </h4>

                    <ul className="bullet-line-list">

                      <li>
                          <h6 className="chargeTitle"> Cobrança Gerada </h6>
                          <p> Valor: {paymentData?.amount} </p>
                          <p className="text-muted mb-4">
                              <i className="ti-time mr-2"></i>
                              <Moment format="DD/MM/YYYY" date={paymentData?.date_created || ""} />
                          </p>
                      </li>

                      { paymentData?.metadata.pagarme_original_transaction_id ? (
                        <li>
                            <h6 className="chargeTitle"> Reprocessamento </h6>
                            <p> ID da transação original: {paymentData?.metadata.pagarme_original_transaction_id} </p>
                            <p className="text-muted mb-4">
                                <i className="ti-reload mr-2"></i>
                                <a href={`/user/transaction/${paymentData?.metadata.pagarme_original_transaction_id}/${props?.contractId}`}
                                    target="_blank"
                                    className="btn btn-outline-light btn-sm">
                                    Detalhes da transação original </a> 
                            </p>
                        </li>
                      ) : ''}

                      { paymentData?.status === 'PENDING' ? '' : (
                      <>
                      <li>
                          <h6 className="chargeTitle"> Liquidação </h6>
                          <p> Valor: R$ {paymentData?.amount || ""} </p>
                          <p className="text-muted mb-4">
                              <i className="ti-time mr-2"></i>
                              <Moment format="DD/MM/YYYY" date={paymentData?.date_updated} />
                          </p>
                      </li>
                      { paymentData?.authorization_code ? (
                        <li>
                            <h6 className="chargeTitle"> Autorização </h6>
                            <p> Código de Autorização: {paymentData?.authorization_code || ""} </p>
                            <p className="text-muted">
                                <i className="ti-time mr-2"></i>
                                <Moment format="DD/MM/YYYY" date={paymentData?.date_updated} />
                            </p>
                        </li>
                      ) : ''}
                      </>
                      ) }

                    </ul>

                  </div>

            </div>
              <>
                <div className="row">
                  <div className="col-md-8 col-sm-12">
                  <h4 className="text-dark text-weight-bolder mb-4 mt-5"> Detalhes da Cobrança </h4>

                      <div className="row softGrey">

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> 
                            <strong> TID </strong> </p>
                          <p className="text-grey"> 
                            {transactionID} </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> 
                            <strong> VALOR DA COBRANÇA </strong></p>
                          <p className="text-grey"> 
                            {paymentData?.amount}  </p>
                      </div>

                        <div className="col-md-6 col-sm-12 mt-3">
                            <p className="text-dark border-bottom text-uppercase"> 
                              <strong> Meio de Pagamento Utilizado </strong> </p>
                            <p className="text-grey"> 
                            {paymentData?.payment_method} </p>
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3">
                            <p className="text-dark border-bottom"> 
                              <strong> STATUS </strong> </p>
                            <p className="text-grey"> 
                              {paymentData?.status}
                            </p>
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3">
                            <p className="text-dark border-bottom text-uppercase"> 
                              <strong> Total de Parcelas </strong></p>
                            <p className="text-grey"> 
                              {paymentData?.installments} </p>
                        </div>

                        { paymentData?.payment_method === 'Cartão de Crédito' ? (
                          <>
                            <div className="col-md-6 col-sm-12 mt-3">
                                <p className="text-dark border-bottom text-uppercase"> 
                                  <strong> Bandeira do Cartão </strong> </p>
                                <p className="text-grey"> 
                                 {paymentData?.card_brand} </p>
                            </div>

                            <div className="col-md-6 col-sm-12 mt-3">
                                <p className="text-dark border-bottom text-uppercase"> 
                                  <strong> Nome no Cartão </strong> </p>
                                <p className="text-grey"> 
                                {paymentData?.card_holder_name} </p>
                            </div>
                            
                            <div className="col-md-6 col-sm-12 mt-3">
                                <p className="text-dark border-bottom text-uppercase"> 
                                  <strong> Cartão Número </strong> </p>
                                <p className="text-grey"> 
                                  {paymentData?.credit_card} </p>
                            </div>

                            <div className="col-md-6 col-sm-12 mt-3">
                                <p className="text-dark border-bottom text-uppercase"> 
                                  <strong> Adquirente </strong> </p>
                                <p className="text-grey text-uppercase"> 
                                  {paymentData?.gtw} </p>
                            </div>
                          </>
                        ) : ''}

                        { paymentData?.payment_method === 'Boleto Bancário'  ? (
                          <>
                            <div className="col-md-6 col-sm-12 mt-3">
                              <p className="text-dark border-bottom text-uppercase"> <strong> Vencimento do boleto </strong></p>
                              <p className="text-grey"> 
                              <Moment format="DD/MM/YYYY" date={paymentData?.boleto_expiration_date || ""} />  </p>
                            </div>

                            <div className="col-md-6 col-sm-12 mt-3">
                              <p className="text-dark border-bottom text-uppercase"> 
                                <strong> Código de Barras do Boleto </strong> </p>
                              <p className="text-grey"> 
                              {paymentData?.boleto_barcode} </p>
                            </div>

                            <div className="col-md-6 col-sm-12 mt-3 mb-3">
                              <a href={paymentData?.boleto_url} className="btn btn-dark btn-sm" target="_blank"> Link para download do boleto </a>
                            </div>

                          </>
                        ) : ''}

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom text-uppercase"> 
                            <strong>  Metadados </strong> </p>
                          <p className="text-grey"> 
                          Número do pedido: 
                            {paymentData?.metadata.order_number || 'Não informado'}  
                          </p>
                      </div>

                      </div>

                  </div>
                </div>
              </>
            </div>
             
    </div>
    </>
  );
}
