import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPage';
import Footer from '../../../../components/Footer';
import api from '../../../../services/Users';
import PaymentStatusGtw1 from '../../../../components/ChargesComponents/PaymentStatusGtw1';
import AntiFraudRisk from '../../../../components/AntiFraud';
import GetPaymentMethod from '../../../../components/GetPaymentMethod';

import './styles.css';
import badget from '../../../../assets/images/badget.svg';

export default class Contracts extends Component {

    constructor(props) {

        const { match: { params } } = props;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const authorization = Config.AuthApiSecretKey;
        const contractId = params.id;
        const customerId = credencialdata._id;

        super(props);
        this.state = {
          trxdata: [],
          balances: [],
          authorization,
          contractId,
          customerId,
          userdata: {},
          trxdetail: [],
          gtw: {},
          pageCounter: 1,
          loading: true,
        };
      }

      loadTrx(p){
          const authorization = this.state.authorization;
          const customerId = this.state.customerId;

          api.get(`/transactionsv2/${this.state.contractId}/${p}/last`,{
              headers: { authorization, userid: customerId },
          })
          .then(res => {
              this.setState({ trxdata: res.data });
              this.setState({ loading: false });
          });
      }

      loadBalance(){
        const authorization = this.state.authorization;
        const customerId = this.state.customerId;

        api.get(`/transactions/balance/${this.state.contractId}`,{
            headers: { authorization, user: customerId },
        })
        .then(res => {
            this.setState({ balances: res.data });
        });

      }

      loadUserData(){
        
        const authorization = this.state.authorization;
        const customerId = this.state.customerId;

        api.get('/mobcustomer/detail', {
            headers: { authorization, userId: customerId },
        })
        .then(res => {
            this.setState({ userdata: res.data.user });
        });
      }

      transactionDetails(id, gtw){
        const authorization = this.state.authorization;

        api.get(`/transaction/${id}/gtw1`,{
            headers: { authorization },
        })
        .then(res => {
            console.log(res);
            this.setState({ trxdetail: res.data.response });
        });
      }
    
      componentDidMount() {
        this.loadTrx(1);
        this.loadUserData();
        this.loadBalance();
      }

      handlePaginationPlus = () => {
            this.setState({ loading: true });
          this.setState({ pageCounter: this.state.pageCounter + 1});
          this.loadTrx(this.state.pageCounter + 1);
      }

      handlePaginationLess = () => {
        if(this.state.pageCounter > 1){
            this.setState({ loading: true });
            this.setState({ pageCounter: this.state.pageCounter - 1});
            this.loadTrx(this.state.pageCounter - 1);
        }
      }

    render(){
        
      return (
        <>
          <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
          />

          <AuthPage />

          <div className="container-scroller">

            <Header />

                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                            <div className="row">

                            <div className="col-md-12">

                            <div className="row">
                                    <div className="col-md-4">
                                        <h3> Transações <br/>
                                            <span className="text-grey smallTitle"> 
                                                Contrato {this.state.contractId}  </span>
                                        </h3>
                                    </div>

                                    <div className="col-md-7 text-right border-left">
                                        <p className="font-weight-normal mb-0 text-grey">
                                          <h4 className="text-dark"> {this.state.userdata.name} </h4>
                                          Cliente desde: &nbsp; 
                                          <Moment format="DD/MM/YYYY" date={this.state.userdata.createdAt}  />
                                        </p>
                                    </div>

                                    {this.state.balances.transfered ? (
                                        <div className="col-md-1 text-center">
                                            <img src={badget} alt="Trilha do Vendedor" className="badget-Pagefy" title="Iniciando jornada vendedor de sucesso!" />
                                        </div>
                                    ) : ''}

                                </div>
                            </div>

                            <div className="col-md-12 mt-4 ">

                                <div className="row">
                                    <div className="col-md-12">

                                    <div className="row">
                                        <div className="col-md-4 grid-margin">
                                            <div className="card d-flex align-items-center">
                                                <div className="card-body">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <i className="ti-time text-twitter icon-md"></i>
                                                        <div className="ml-3">
                                                            <h4 className="text-twitter"> {this.state.balances.waiting_funds} </h4>
                                                            <p className="mt-2 text-muted card-text">  Aguardando Liberação </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 grid-margin">
                                            <div className="card d-flex align-items-center">
                                                <div className="card-body">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <i className="ti-check text-twitter icon-md"></i>
                                                        <div className="ml-3">
                                                            <h4 className="text-twitter"> {this.state.balances.available}  </h4>
                                                            <p className="mt-2 text-muted card-text"> Saldo Disponível 
                                                                
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 grid-margin">
                                            <div className="card d-flex align-items-center">
                                                <div className="card-body">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <i className="ti-pulse text-twitter icon-md"></i>
                                                        <div className="ml-3">
                                                            <h4 className="text-twitter"> {this.state.balances.transfered}  </h4>
                                                            <p className="mt-2 text-muted card-text"> Saldo Transferido </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                               

                                <div className="row mt-2">

                                    <div className="col-md-6 mb-4">
                                        <p className="text-grey"> Saldos liquídos com as taxas de operação descontadas </p>
                                    </div>
                                    <div className="col-md-6 text-right mb-4">

                                        <nav className="paginationRight">
                                            <ul className="pagination flex-wrap pagination-flat pagination-success">
                                                <li className="page-item">
                                                    <button className="page-link pgLess" onClick={this.handlePaginationLess}>
                                                        <i className="ti-angle-left"></i>
                                                    </button> 
                                                </li>
                                                <li className="page-item active">
                                                    <span className="page-link">{this.state.pageCounter}</span>
                                                </li>
                                                <li className="page-item">
                                                    <button className="page-link" onClick={this.handlePaginationPlus}>
                                                        <i className="ti-angle-right"></i>
                                                    </button> 
                                                </li>
                                            </ul>
                                        </nav>

                                    </div>

                                    <div className="col-md-12">
                                        <div className="">
                                        <div className="">

                                            <div className="table-responsive">
                                                        
                                                        {!this.state.trxdata.length ? (
                                                            <p className="text-grey mt-3"> Este cliente não possui transações para exibir neste momento</p>
                                                        ) : (
                                                            <>
                                                                {this.state.loading === false ? (
                                                                    <table className="table table-striped">
                                                                        <tbody>
                                                                            
                                                                            {this.state.trxdata.map((trx, index) => (
                                                                            <tr key={index}>
                                                                                    <td> 
                                                                                        <div className="col-md-12">
                                                                                            <Link to={`/user/transaction/${trx.transaction_id}/${this.state.contractId}`} className="text-grey trLink">
                                                                                                {trx.transaction_id} 
                                                                                            </Link>
                                                                                            <br/>
                                                                                            <small className="text-grey">{trx.gtw}</small>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="text-grey">
                                                                                        {trx.customer_name}<br/>
                                                                                        <small>Documento: {trx.document_number} </small> <br/>
                                                                                        <small>
                                                                                            <strong> Anti Fraude: 
                                                                                                <AntiFraudRisk level={trx.antifraud_risk_level} /> 
                                                                                            </strong>
                                                                                        </small>

                                                                                    </td>

                                                                                    <td className="text-grey">
                                                                                        <GetPaymentMethod string= {trx.payment_method} />
                                                                                    </td>

                                                                                    
                                                                                    <td className="text-grey"> 
                                                                                        <Moment format="DD/MM/YYYY" date={trx.date_created}  /> 
                                                                                    </td>

                                                                                    <td className="text-grey">
                                                                                        {trx.amount} <br/>
                                                                                        <small> Total de parcelas: {trx.installments} </small>
                                                                                    </td>

                                                                                    <td className="text-right"> 
                                                                                        <PaymentStatusGtw1 status={trx?.status || ""} className="mr-3"/>
                                                                                        <Link to={`/user/transaction/${trx.transaction_id}/${this.state.contractId}`} className="btn btn-outline-secondary btn-sm ml-3">
                                                                                            Detalhes
                                                                                        </Link> 
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                ) : (
                                                                    <div className="loader"> Carregando dados, por favor aguarde ... </div>
                                                                )}
                                                                
                                                            </>
                                                        )}
                                            </div>

                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
        </>
      )
    }
}