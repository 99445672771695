import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import ReactPlayer from "react-player";

import Config from '../../../../services/config';
import api from '../../../../services/login';
import StatusIcon from '../../../Common/StatusIcon';

export default function List(props) {

   const [ tutorialsVideos, setTutorialsVideos ] = useState([]);

   const authorization = Config.AuthApiSecretKey;
   const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
   const userid = credencialdata._id;

   const tutoId = props.tutoId;
   
   useEffect(() => {
       
       const authorization = Config.AuthApiSecretKey;
       const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
       const userid = credencialdata._id;

        async function loadProducts() {
            const response = await api.get(`/admin/tutorials/videos/${tutoId}`, {
                headers: { authorization, userid },
            })
            console.log(response.data.videos);
            setTutorialsVideos(response.data.videos);
        }
        loadProducts();
    },[])

    async function handleRemove(itemId, title, index) {

        swal(`Remover ${title}?`, {
            buttons: {
              cancel: "cancelar",
              catch: {
                text: "remover",
                value: "remover",
              },
            },
          })
          .then((value) => {
            switch (value) {

              case "cancelar": 
                break;
           
              case "remover":
                setTutorialsVideos(tutorialsVideos.filter(tutorialsVideos => tutorialsVideos._id !== itemId));
                swal("Remover", "conteúdo sucesso!", "success");

                async function removeItem() {
                    await api.delete(`/admin/tutorial/video/${itemId}`, {
                        headers: { authorization, userid },
                    })
                }
                removeItem();
                break;
            }
          });
    }
    
  return (
    <>
        <div className="table-responsive">
            { !tutorialsVideos.length ? 'Não há dados para exibir aqui' : (
                <table className="table">
                <thead>
                    <tr>
                    <th className="pt-1 pl-0">
                        Título
                    </th>
                    <th className="pt-1 text-center">
                        Status
                    </th>
                    </tr>
                </thead>
                
                <tbody>
                    {tutorialsVideos.map((tutorial, index) => (
                        <tr key={index}>
                            <td className="py-1 pl-0">
                                <div className="d-flex align-items-center">
                                <div className="ml-3">
                                    <p className="mb-0"> {tutorial.title} </p>
                                    <p className="mb-0 text-muted text-small"> 
                                        Atualizado: {tutorial.updatedAt} <br/>
                                    </p>
                                </div>
                                </div>
                            </td>
                            <td className="text-right">

                                <a href={tutorial.videoUrl} target="_blank" className="btn btn-youtube btn-sm mr-3">
                                    <i className="ti-control-play btn-icon-prepend"></i>
                                </a>

                                <Link to={`/admin/tutorial/video/update/${props.tutoId}/${tutorial._id}`} className="btn btn-outline-secondary btn-sm">
                                    <i className="ti-settings btn-icon-prepend"></i>  Editar
                                </Link>

                                <StatusIcon iconStatus={tutorial.status} />

                                <button onClick={() => handleRemove(tutorial._id, tutorial.title, index)} className="remoteItemBt">
                                    <i className="ti-close text-danger btn-icon-prepend ml-3"></i>
                                </button>

                            </td>
                        </tr>
                    ))}
                </tbody>
                </table>
            )}
        </div>
    </>
  );

}
