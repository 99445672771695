import React from 'react';

export default function WcOrderStatus(props) {

    const SwitchStatus = (str) => ({
      "cancelled" : "Cancelado",
      "processing" : "Pago",
      "pending" : "Pagamento Pendente",
      "completed" : "Pedido Pago e finalizado",
      "refunded" : "Reembolsado",
      "failed" : "Pagamento recusado/falha",
      "on-hold" : "Aguardando Pagamento",
      "" : "btn btn-sm btn-grey",
    })[str] || 'text-grey';

    let getStatus = props.status;

    return (
      <span className="text-grey">
        {SwitchStatus(getStatus)}
      </span>
  );

  } 