import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import swal from 'sweetalert';

import Alerts from '../Alerts';
import Config from '../../services/config';
import api from '../../services/login';
import axios from 'axios';

import './styles.css';

export default function CommerceProductproductDetail(props) {
  
  const authorization = Config.AuthApiSecretKey;

  const id = props.catId;
  const contractId = props.contract;
  const userId = props.user;

  const [product, setProduct] = useState();
  const [appData, setAppData] = useState({
    title: '',
    description: '',
    status: '',
    permLink: '',
    featuredImage: '',
    sku: '',
    priceList: '',
    priceSale: '',
    categories: '',
    tags: '',
    inventory: '',
    variationMaster: '',
    highlightInCategory: '',
    productType: ''
  });

  const [thumbnail, setThumbnail] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileUpload = async (event) => {

    event.preventDefault();

    const file = event.target.files[0];

    if (file.size > 1 * 1024 * 1024) {
      swal({
        title: "Upload",
        text: "A imagem deve ter menos que 01 megabyte"
      });
      return;
    }

    setThumbnail(URL.createObjectURL(file));

    const formData = new FormData();
    formData.append('file', file);

    axios({
      method: 'post',
      url: 'https://dash-api-v1.pagefai.com/cdn/upload',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization,
        userid: userId,
        contractid: contractId,  
      }
    })
      .then(response => {
        console.log('Upload success:', response.data);
        console.log('Upload success:', response.data.filename);

          setAppData((prevState) => ({
            ...prevState,
            featuredImage: response.data.filename,
          }));

      })
      .catch(error => {
        console.error('Upload error:', error);
    });

  };
  
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
    const authorization = Config.AuthApiSecretKey;
    async function loadData() {
      const response = await api.get(`/commerce/product/${id}`, {
        headers: {
          authorization,
          userId,
          contractid: contractId,
        },
      });
      setAppData(response.data);
    }
    loadData();
    },[]);

    async function onSubmit(e){

      e.preventDefault();
    
      const authorization = Config.AuthApiSecretKey;
      const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
      const userid = credencialdata._id;

      const  { title,
        description,
        status,
        permLink,
        featuredImage,
        sku,
        priceList,
        priceSale,
        categories,
        tags,
        inventory,
        variationMaster,
        highlightInCategory,
        productType } = appData;

      try {
        await api.post(`/commerce/product`, {
          title,
          description,
          status,
          permLink,
          featuredImage,
          sku,
          priceList,
          priceSale,
          categories,
          tags,
          inventory,
          variationMaster,
          highlightInCategory,
          productType
        }, {
          headers: { 
            authorization,
            userId,
            contractid: contractId,  
          }
        });
          
          swal({
            title: "Atualização",
            text: "Atualizado",
            timer: 2000
          });
          setProduct('');
        } catch (error) {
          swal({
            title: "Atualização",
            text: error.response.data.error,
            timer: 2000
          });
        }
    
    }

  return (
    <>
      <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-6">
                  <small> Categoria de Produto </small> <br/>
                  <h3> {appData?.title} </h3>
                </div>
                <div className="col-md-6 text-right">
                    <Link to={`/user/commerce/products/${contractId}`}>
                      <button className="btn btn-outline-dark btn-sm mt-3 mb-4 mr-3"> 
                        Todos produtos
                      </button>
                    </Link>
                </div>
              </div>
              
              <form className="forms-sample" onSubmit={onSubmit} >
              
              <div className="row border-top">
                <div className="col-md-8 mt-4">
                 {/*  */}

                 <div className="form-group">
                    <label htmlFor="title"> Título </label>
                    <input 
                      placeholder="Título da categoria"
                      type="text" 
                      className="form-control" 
                      name="title" 
                      id="title"
                      value={appData?.title || ""} 
                      onChange={handleChange}
                    />
                  </div>
                 <div className="form-group">
                    <label htmlFor="title"> Tags </label>
                    <input 
                      placeholder="Tags do produto"
                      type="text" 
                      className="form-control" 
                      name="tags" 
                      id="tags"
                      value={appData?.tags || ""} 
                      onChange={handleChange}
                    />
                  </div>
                 <div className="form-group">
                    <label htmlFor="description"> Descrição </label>
                    <input 
                      placeholder="Descrição"
                      type="text" 
                      className="form-control" 
                      name="description" 
                      id="description"
                      value={appData?.description || ""} 
                      onChange={handleChange}
                    />
                  </div>

                 <div className="form-group">
                    <label htmlFor="sku"> SKU </label>
                    <input 
                      placeholder="Product SKU"
                      type="text" 
                      className="form-control" 
                      name="sku" 
                      id="sku"
                      value={appData?.sku || ""} 
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="permLink"> Link Permanente </label>
                    <input 
                      placeholder="Link Permanante"
                      type="text" 
                      className="form-control" 
                      name="permLink" 
                      id="permLink"
                      value={appData?.permLink || ""} 
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="permLink"> Variação Master ( Em caso de produto com variação informe o ID do master )</label>
                    <input 
                      placeholder="Em caso de produto variante informe o ID do master"
                      type="text" 
                      className="form-control" 
                      name="variationMaster" 
                      id="variationMaster"
                      value={appData?.variationMaster || ""} 
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="priceSale"> Preço de Venda  ( De )</label>
                    <input 
                      placeholder="Preço do produto"
                      type="text" 
                      className="form-control" 
                      name="priceSale" 
                      id="priceSale"
                      value={appData?.priceSale || ""} 
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="priceList"> Preço de Lista ( Promocional ) ( Por )</label>
                    <input 
                      placeholder="Preço promocional do Produto"
                      type="text" 
                      className="form-control" 
                      name="priceList" 
                      id="priceList"
                      value={appData?.priceList || ""} 
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="inventory"> Inventário do produto </label>
                    <input 
                      placeholder="Preço promocional do Produto"
                      type="text" 
                      className="form-control" 
                      name="inventory" 
                      id="inventory"
                      value={appData?.inventory || ""} 
                      onChange={handleChange}
                    />
                  </div>
                 

                  <div className="form-group">
                    <button type="submit" className="btn btn-dark mr-2"> Salvar </button>
                  </div>
                 {/*  */}
                </div>
                <div className="col-md-4 mt-4 ">
                  <div className="form-group">
                      <label htmlFor="productType"> Tipo do produto </label>
                      <select id="productType"
                          name="productType"
                          className="form-control"
                          value={appData?.productType || ""}
                          onChange={handleChange}
                        >
                          <option> Escolha o Status </option>
                          <option value="regular"> Regular </option>
                          <option value="variation"> Variação </option>
                          <option value="master"> Master </option>
                      </select>
                  </div>

                  <div className="form-group">
                      <label htmlFor="highlightInCategory">Destacar na Categoria ? </label>
                      <select id="highlightInCategory"
                          name="highlightInCategory"
                          className="form-control"
                          value={appData?.highlightInCategory || ""}
                          onChange={handleChange}
                        >
                          <option> Escolha o Status </option>
                          <option value="yes"> Sim </option>
                          <option value="no"> Não </option>
                      </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="featuredImage"> Featured Image </label>
                    <input 
                      placeholder="Título da categoria"
                      type="text" 
                      className="form-control" 
                      name="featuredImage" 
                      id="featuredImage"
                      value={appData?.featuredImage || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <small className='mt-4'> Upload featured image </small> <br/>
                    <input 
                      type="file" 
                      name="file" 
                      id="file"  
                      accept="image/*"
                      onChange={handleFileUpload} 
                    />
                    {thumbnail && <img src={thumbnail} alt="Thumbnail" className='featuredImage' />}
                  </div>

                  <div className="form-group">
                    <label htmlFor="categories"> Categoria </label>
                    <input 
                      placeholder="Categoria do produto"
                      type="text" 
                      className="form-control" 
                      name="categories" 
                      id="categories"
                      value={appData?.categories || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                      <label htmlFor="status"> Status </label>
                      <select id="status"
                          name="status"
                          className="form-control"
                          value={appData?.status || ""}
                          onChange={handleChange}
                        >
                          <option> Escolha o Status </option>
                          <option value="enabled"> Online </option>
                          <option value="disabled"> Offline </option>
                      </select>
                  </div>
                
                </div>

              </div>
            
            </form>
            
            </div>
          </div>
        </div>
    </>
  );
}
