import React from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import HeaderDonation from '../../../components/DonationComponents/HeaderDonation'
import LastDonations from '../../../components/DonationComponents/LastDonations'

export default function Donation() {
  return (
    <>
        <AuthPage />
        <div className="container-scroller">
            <Header />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <HeaderDonation />
                            <LastDonations />
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
