import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';

import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPageAdmin';
import Footer from '../../../../components/Footer';
import api from '../../../../services/Users';
import Status from '../../../../components/Common/Status';

import './styles.css';

export default class Contracts extends Component {

    constructor(props) {

        const { match: { params } } = props;
        const authorization = Config.AuthApiSecretKey;
        const userid = params.id;
    
        super(props);
        this.state = {
          contractsdata: [],
          authorization,
          userid,
          userdata: {},
        };
      }

      loadContracts(){
          const authorization = this.state.authorization;
          const userid = this.state.userid;
      
          api.get(`/contracts/user/${userid}`,{
              headers: { authorization },
          })
          .then(res => {
              this.setState({ contractsdata: res.data.contracts });
          });
      }

      loadUserData(){
        
        const authorization = this.state.authorization;
        const userid = this.state.userid;

        api.get('/mobcustomer/detail', {
            headers: { authorization, userid },
        })
        .then(res => {
            this.setState({ userdata: res.data.user });
        });
      }
    
      componentDidMount() {
        this.loadContracts();
        this.loadUserData();
      }
    

    render(){
        
      return (
        <>
          <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
          />

          <AuthPage />

          <div className="container-scroller">

            <HeaderAdminConsole />

                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                            <div className="row">

                            <div className="col-md-12">

                            <div className="row">
                                    <div className="col-md-8">
                                    <h4 className="font-weight-bold"> CONTRATOS DE <span className="text-twitter text-uppercase"> {this.state.userdata.name} </span> </h4>
                                    <p className="font-weight-normal mb-0 text-grey"> 
                                      Customer ID: {this.state.userid} <br/>
                                      CPF: {this.state.userdata.cpf} <br/>
                                      Cliente desde: <Moment format="DD/MM/YYYY" date={this.state.userdata.createdAt}  />  <br/>
                                    </p>
                                    </div>
                                    <div className="col-md-4 text-right">
                                      <Link to={`/admin/contract/store/${this.state.userid}`} className="btn btn-outline-twitter btn-sm">
                                          Adicionar Contrato
                                      </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-4 ">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="">
                                        <div className="">

                                            <div className="table-responsive">
                                                        
                                                        {!this.state.contractsdata.length ? (
                                                            <p className="text-grey mt-3"> Este cliente não possui contratos ativos ;-( </p>
                                                        ) : (
                                                            <>
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="pt-1 pl-0">
                                                                                Contrato
                                                                            </th>
                                                                            <th className="pt-1">
                                                                                Contratado em 
                                                                            </th>
                                                                            <th className="pt-1 text-center">
                                                                                &nbsp;
                                                                            </th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        
                                                                        {this.state.contractsdata.map((contract, index) => (
                                                                            <tr key={index}>
                                                                                <td> 
                                                                                    <div className="col-md-12">
                                                                                        <Link to={`/admin/contract/${contract._id}`} className="text-grey trLink">
                                                                                            {contract.productName} 
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="col-md-12 mt-2">
                                                                                        <span className="text-grey mt-2"> Contract ID: {contract._id} </span> <br/>

                                                                                        <span className="text-grey mt-2"> Domínio: {contract.domainUrl ? ( <span className="text-success"> {contract.domainUrl} </span> )  : 
                                                                                            ( <span className="text-danger"> Não configurado </span> ) } </span> <br/>

                                                                                        <span className="text-grey mt-2"> Status do contrato: 
                                                                                            <Status statusId={contract.status} />  </span>
                                                                                        
                                                                                    </div>
                                                                                </td>
                                                                                
                                                                                <td className="text-grey"> 
                                                                                    <Moment format="DD/MM/YYYY hh:mm" date={contract.createdAt}  /> 
                                                                                </td>
                                                                                
                                                                                <td className="text-right"> 

                                                                                    <Link to={`/admin/transactions/${contract._id}/${this.state.userid}`} className="btn btn-outline-secondary btn-sm mr-3">
                                                                                        Transações
                                                                                    </Link> 

                                                                                    <Link to={`/admin/transfers/${contract._id}/${this.state.userid}`} className="btn btn-outline-secondary btn-sm mr-3">
                                                                                        Transferências
                                                                                    </Link> 
                                                                                   
                                                                                    <Link to={`/admin/contract/${contract._id}`} className="btn btn-outline-secondary btn-sm mr-3">
                                                                                        Detalhes
                                                                                    </Link> 

                                                                                    <Link to={`/admin/contract/update/${contract._id}/${this.state.userid}`} className="btn btn-outline-twitter btn-sm">
                                                                                        <i className="ti-settings btn-icon-prepend"></i>  Editar
                                                                                    </Link> 
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>

                                                                <div className="mt-4">
                                                                    <p className="card-description text-grey mt-3 text-small">
                                                                        Os custos de transação são baseados no CNAE de cada cliente. <br/>
                                                                        Estes valores são negociados diretamente com o seu gerente de contas Pagefy 
                                                                    </p>
                                                                </div>
                                                            </>
                                                        )}
                                            </div>

                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>



        </>
      )
    }
}