import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';

import Config from '../../services/config';
import api from '../../services/login';
import Moment from 'react-moment';
import OrderStatus from '../../components/CommerceOrderStatus';

import './styles.css';

export default function OrderList(props) {

   const [ orders, setOrders ] = useState([]);
  const contractid = props.contract;

   useEffect(() => {
       
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

     async function loadPayments() {
         const response = await api.get(`/admin/commerce/orders`, {
             headers: { authorization, userid, contractid },
         })
         setOrders(response.data);
     }
     loadPayments();
 },[]);

  return (
    <>
    
    <div className="row">
        <div className="col-12">
            <div className="row">
        <div className="col-12">
            <div className="row mb-3">
                
                <div className="col-md-6">
                    <h3 className="card-title"> Pedidos E-Commerce </h3>
                    <p className="text-grey"> Abaixo você encontra os pedidos que foram feitos no e-commerce  </p>
                </div>

                <div className="col-md-6">
                  <div className="template-demo text-right">
                    <Link to={`/user/commerce/main/${contractid}`} className="btn btn-outline-dark btn-sm"> Painel Commerce </Link>
                </div>
              </div>
            </div>

            <div className="mt-5">
                  { !orders.length ? (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <p> Não há dados para exibir aqui. <br/> Quer saber como engajar o seu público com sua causa ? </p>
                          </div>
                        </div>
                      </>
                    ) : (
                    <table className="table">
                      <thead>
                        <tr>
                            <th> Pedido </th>
                            <th> Data/Hora </th>
                            <th> Cliente </th>
                        </tr>
                      </thead>
                      <tbody>
                      {orders.map((order, index) => (
                          <tr key={order._id}>
                              <td> 
                                <Link to={`/user/commerce/order/${order.order}/${contractid}`} className="text-grey trLink">
                                  <span className="text-grey"> <strong className="text-dark">  {order.customerName}  </strong>  <br/></span>
                                    {order.order} <br/>
                                  <small> 
                                    Status: {order?.orderStatus}  <br/>
                                    <OrderStatus status={order?.orderStatus} /> <br/>
                                    Total do Pedido: {order?.orderAmount}
                                  </small> 
                                </Link>
                              </td>
                              <td> <Moment format="DD/MM/YYYY hh:mm:ss" date={order.createdAt}  />  </td>
                              <td> 
                                Nome: {order.name} <br/> 
                                E-mail: {order.email} <br/> 
                                CPF: {order.document}
                              </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                  )}
                  </div>
            </div>
      </div>
    </div>
  </div>
</>
  );
}
