import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

import './styles.css';
import camera from '../../../assets/images/camera.svg';

export default function VideoUpdate() {
   
    const [thumbnail, setThumbnail] = useState(null);

    const preview = useMemo(() => {
        return thumbnail ? URL.createObjectURL(thumbnail) : null;
    }, [thumbnail])

  return (
    <>
        <div className="col-lg-12">
        <div>
            <div>

                <div className="row mb-3">
                    
                    <div className="col-md-6">
                        <h3 className="card-title"> Video /Edição </h3>
                        <p className="text-grey"> Entre com o conteúdo de sua publicação preenchendo os campos abaixo  </p>
                    </div>

                    <div className="col-md-6">
                        <div class="text-right">
                                <div class="dropdown">
                                    <Link to="/videos" className="btn btn-outline-twitter btn-sm">
                                        <i className="ti-list-ol"></i> Lista de Vídeos
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                <div className="row">
                    <div className="col-md-8 borderRight">
                        <form className="forms-sample">

                            <h4 class="card-title text-twitter mb-4 mt-4"> Publicação </h4>
                            <div className="form-group">
                                <label for="campaignTitle"> Título * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="campaignTitle" 
                                    placeholder="Informe o título da campanha..." 
                                    value="" 
                                />
                            </div>

                            <div className="form-group">
                                <label for="campaignTitle"> Vídeo URL ( Embedded ) * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="campaignTitle" 
                                    placeholder="Entre com a URL do vídeo ( Compatível com Youtube e Vimeo )..." 
                                    value="" 
                                />
                            </div>

                            <div className="form-group">
                                <label for="description"> Descrição </label>
                                <textarea class="form-control" 
                                    id="description" 
                                    placeholder="Breve descrição do conteúdo  ..." 
                                    rows="3"></textarea>
                            </div>

                            <div className="form-group">
                                <label for="description"> Conteúdo * </label>
                                <textarea class="form-control" 
                                    id="description" 
                                    placeholder="Descreva o conteúdo aqui ..." 
                                    rows="20"></textarea>
                            </div>
                            

                </form>
                </div>
                    <div className="col-md-4 border-left-grey pd-l2">

                    <div className="row ">
                        <div className="col-md-12">

                            <h4 class="card-title text-twitter"> Tags e Categorias </h4>

                            <div className="form-group mt-4">
                                <label for="endCampaign"> Informe as tags separadas por vírgula </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="endCampaign" 
                                    placeholder="Ex: e-commerce, publicação sobre mercado..."
                                />
                            </div>


                            <div class="form-group">
                                <label for="endCampaign"> Categorias </label>
                                <div className="container-categories">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                        <input type="checkbox" class="form-check-input" />
                                          Publicação Comum
                                        <i class="input-helper"></i></label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                        <input type="checkbox" class="form-check-input" checked="" />
                                            Vídeos
                                        <i class="input-helper"></i></label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                        <input type="checkbox" class="form-check-input" />
                                        Disabled
                                        <i class="input-helper"></i></label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                        <input type="checkbox" class="form-check-input" checked="" />
                                        Disabled checked
                                        <i class="input-helper"></i></label>
                                    </div>
                                </div>
                            </div>




                            <h4 class="card-title text-twitter mb-4 mt-4"> Media </h4>

                            <div className="form-group">
                            <label for="thumbnail"> Imagem de Capa * </label>
                            <label 
                                id="thumbnail" 
                                style={{ backgroundImage: `url(${preview})` }}
                                className={thumbnail ? 'has-thumbnail' : ''}
                            >
                                <input type="file" onChange={event => setThumbnail(event.target.files[0])} />
                                <img src={camera} alt="Select img" />
                            </label>
                            <p className="text-grey"> Especificações: Imagem no formato JPG ou PNG com tamanho até 1.5MB </p>
                               
                            </div>

                            <div class="form-group">
                                <label for="exampleFormControlSelect2"> Status da Publicação </label>
                                <select class="form-control" id="exampleFormControlSelect2">
                                    <option> Publicado </option>
                                    <option> Offline </option>
                                    <option> Rascunho </option>
                                </select>
                            </div>



                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <p className="text-grey mt-20">
                                    * Saiba mais sobre SEO entrando em contato com nossos consultores.
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <button 
                                    type="submit" 
                                    className="btn btn-info mr-2"
                                    > Salvar </button>
                                </div>
                            </div>

                            


                        </div>
                    </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}
