import React from 'react';

import AuthPage from '../../../../components/AuthPage';
import Footer from '../../../../components/Footer';
import Header from '../../../../components/Header';
import PostListComponent from '../../../../components/Publications/PostList';

export default function List() {
  return (
    <>
    <AuthPage />
    <div className="container-scroller">
        <Header />
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                    <div className="content-wrapper">
                        <PostListComponent />
                    </div>
                </div>
            </div>
        <Footer />
    </div>
    </>
  );
}
