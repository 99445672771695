import React, { Component } from 'react';

import AuthPage from '../../../components/AuthPage';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Navigation from '../navigation';
import Config from '../../../services/config';
import api from '../../../services/Users';
import Alerts from '../../../components/Alerts';
import Loader from 'react-loader-spinner';
export default class MyCompany extends Component {

  constructor(props) {

    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    super(props);
    this.state = {
      userdata: {},
      authorization,
      userid
    };
  }

  componentDidMount() {
    
    const authorization = this.state.authorization;
    const userid = this.state.userid;

    api.get('/company/detail',{
        headers: { authorization, userid },
    })
      .then(res => {
        this.setState({ userdata: res.data.user });
        // console.log(res.data.user);
      });

  }

  handleChange = (e) => {
    const state = this.state.userdata
    state[e.target.name] = e.target.value;
    this.setState({userdata:state});
    // console.log(state);
  }

  onSubmit = (e) => {

    e.preventDefault();
    const authorization = this.state.authorization;
    const userid = this.state.userid;
    const { companyCnpj, companyName, companyType, companyTel, companyWebsite } = this.state.userdata;

    api.put('/company/detail/', { companyCnpj, companyName, companyType, companyTel, companyWebsite }, { headers: { authorization, userid },

    })
      .then((result) => {
        Alerts("Dados Pessoais", 'Dados atualizados com sucesso' ,"success");
        this.props.history.push("/user/account/company");
      });
  }

  render() {
    return (
        <>
        <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner" //3 secs
      />
        <AuthPage />
        <Header />
          <div className="container-fluid page-body-wrapper">
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-12">
                    <div className="">
                      <div className="mt-4">
                        <div className="row">
                          
                          <div className="col-lg-8">
                              <div className="">
                                  <div className="">
                                    <h3> Dados Comerciais </h3>
                                    
                                    <form onSubmit={this.onSubmit} className="mt-4">

                                        <div className="form-group">
                                          <label htmlFor="companyCnpj"> CNPJ * </label>
                                          <input 
                                            name="companyCnpj"
                                            placeholder="Digite o CNPJ de sua empresa..."
                                            type="text" 
                                            className="form-control"
                                            id="companyCnpj"
                                            value={this.state.userdata.companyCnpj || ""} 
                                            onChange={this.handleChange}
                                          />
                                        </div>

                                        <div className="form-group">
                                          <label htmlFor="companyName"> Razão Social * </label>
                                          <input 
                                            placeholder="Nome da Empresa" 
                                            type="text" 
                                            className="form-control" 
                                            name="companyName"
                                            id="companyName"
                                            value={this.state.userdata.companyName || ""}
                                            onChange={this.handleChange}
                                            />
                                        </div>

                                        <div className="form-group">
                                          <label htmlFor="companyType"> Tipo de Empresa * </label>
                                          <select 
                                            name="companyType"
                                            id="companyType"
                                            value={this.state.userdata.companyType || ""}
                                            onChange={this.handleChange}
                                            className="form-control">
                                                <option value=""> Selecione o tipo da conta </option>
                                                <option value="mei"> Micro Empreendedor Individual </option>
                                                <option value="limited"> Empresa Limitada </option>
                                                <option value="individual"> Empresa Individual </option>
                                                <option value="association"> Associação </option>
                                          </select>

                                        </div>

                                        <div className="form-group">
                                          <label htmlFor="comercialTel"> Telefone Comercial * </label>
                                          <input 
                                            name="companyTel"
                                            placeholder="Telefone Comercial" 
                                            type="text" 
                                            className="form-control" 
                                            id="companyTel"
                                            value={this.state.userdata.companyTel || ""} 
                                            onChange={this.handleChange}
                                            />
                                        </div>

                                        <div className="form-group">
                                          <label htmlFor="website"> Website Comercial * </label>
                                          <input 
                                            placeholder="Entre com o endereço do seu website Ex: https://www.Pagefy.com.br ..." 
                                            type="text" 
                                            className="form-control" 
                                            name="companyWebsite"
                                            id="companyWebsite"
                                            value={this.state.userdata.companyWebsite || ""}
                                            onChange={this.handleChange}
                                            />
                                        </div>

                                        <div className="row">
                                          <div className="col-md-12 text-left mt-20">
                                            <button type="submit" className="btn btn-info mr-2"> Salvar </button>
                                          </div>
                                        </div>

                                        <div className="row mt-4">
                                          <div className="col-md-12">
                                            <p className="text-grey mt-20 text-small">
                                              * É importante que todos os seus dados estejam atualizados em nosso cadastro para evitar qualquer tipo de inconformidade
                                              com o status de sua conta.
                                            </p>
                                          </div>
                                        </div>

                                    </form>
                                  </div>
                              </div>
                          </div>
                          
                          <Navigation />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        <Footer />
    </>
    );
  }
}
