import React, { useState }   from 'react';
import { Link } from 'react-router-dom';
import signUpApi from '../../services/signUp';
import Alerts from '../../components/Alerts/index';
import './styles.css'

import FooterLine from '../../components/FooterSlim';

export default function Register({ history }) {

  // const validarCpf = require('validar-cpf');
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ salesAgent, setSalesAgent ] = useState('');

  async function handleSubmit(e){
      e.preventDefault();

      const response = await signUpApi.post('/signUp', {
        name,
        email,
        password,
        phone,
        salesAgent,
      });

      if(response.data.cb === "error"){
          Alerts("Cadastro de Usuário", response.data.message ,"error");
      } else {
          Alerts("Cadastro de Usuário", "Cadastro efetuado com sucesso, Entre com o usuário e senha nos campos abaixo para continuar ","success");
          history.push('/');
      }

  }

  return (
    <>
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
          <div className="row flex-grow">

            <FooterLine />

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div className="auth-form-transparent text-left p-3">
                
                <h3> Cadastro de Usuário </h3>
                <span className="spacer"> &nbsp;</span>

                <h6 className="font-weight-light text-grey"> Crie sua conta para iniciar, é simples e rápido </h6>
                
                <form className="pt-3" onSubmit={handleSubmit} >

                    <div className="form-group">

                      <div className="input-group">
                        <input 
                          type="number" 
                          className="form-control form-control-sm border-left-0" 
                          placeholder="Celular com DDD ( Apenas Números )"
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                          required
                        />
                      </div>

                    </div>

                    
                    <div className="form-group">
                      <div className="input-group">
                        <input 
                          type="text" 
                          className="form-control form-control-sm border-left-0" 
                          placeholder="Nome Completo"
                          value={name}
                          onChange={e => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <input 
                          type="text" 
                          className="form-control form-control-lg border-left-0" 
                          placeholder="E-mail para cadastro" 
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    
                    
                    <div className="form-group">
                      <div className="input-group">
                        <input 
                          type="password" 
                          className="form-control form-control-lg border-left-0" 
                          placeholder="Password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          required
                        />                        
                      </div>
                    </div>

                    <div className="form-group">
                      <label> Código de identificação do parceiro caso tenha. <br/> ( Seja um parceiro saiba mais aqui ) </label>
                      <div className="input-group">
                        <input 
                          type="text" 
                          className="form-control form-control-lg border-left-0" 
                          placeholder="Identificação do parceiro"
                          value={salesAgent}
                          onChange={e => setSalesAgent(e.target.value)}
                        />                        
                      </div>
                    </div>
                    
                    <div className="mt-3">
                      <button 
                        className="btn btn-block btn-dark btn-lg font-weight-medium auth-form-btn"
                      >
                      ENVIAR
                      </button>
                    </div>

                    <div className="text-left mt-4 font-weight-light">
                      <Link to="/" className="btn btn-block btn-outline-dark btn-lg auth-form-btn text-uppercase">
                        Entrar agora
                      </Link>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  </>
  );
}
