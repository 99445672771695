import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import Config from '../../services/config';
import api from '../../services/login';

export default function AuditAccessResume() {

  const [ auditAccess, setAuditAccess ] = useState([]);

  useEffect(() => {
       
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;
    
     async function loadAuditAccess() {
         const response = await api.get('/user/audit', {
             headers: { authorization, userid },
         })
         setAuditAccess(response.data);
     }
     loadAuditAccess();
  },[])

  return (
    <div className="row">
    <div className="col-12 mb-20 mt-20">

        <div className="row">
        <div className="col-md-12">
            <h3 className="card-title"> Últimos Acessos </h3>
            <p className="text-grey"> Aqui você identifica os últimos acessos feitos com seu login e senha na plataforma Pagefy. </p>
        </div>
        </div>

        <div className="table-responsive">
        <table className="table">
            <thead>
            <tr>
                <th> Data do Acessos </th>
                <th> Endereço de IP </th>
                <th className="text-right"> Navegador </th>
            </tr>
            </thead>
            <tbody>

            {auditAccess.map((audit, index) => (
                <tr key={audit._id} >
                    <td> <Moment format="DD/MM/YYYY hh:mm" date={audit?.createdAt || ""} /> </td>
                    <td> {audit.userIp} </td>
                    <td className="text-grey text-right"> <small> {audit.userAgent} </small></td>
                </tr>
            ))}

            </tbody>
        </table>
        </div>
    </div>
</div>
  );
}
