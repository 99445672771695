import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';

import Config from '../../services/config';
import api from '../../services/login';
import PaymentStatus from '../ChargesComponents/PaymentStatus';
import PaymentType from '../ChargesComponents/PaymentType';

import './styles.css';
import notFound from '../../assets/images/payment-build.svg';

export default function ChargesList() {

   const [ contracts, setContracts ] = useState([]);
   const [ userId, setUserId ] = useState([]);

   useEffect(() => {
       
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;
    setUserId(userid);

     async function loadContracts() {
         const response = await api.get(`/contracts/user/${credencialdata._id}`, {
             headers: { authorization },
         })
         setContracts(response.data.contracts);
     }
     loadContracts();
 },[]);

  return (
    <>
    
    <div className="row">
        <div className="col-12">
            <div className="row">
        <div className="col-12">
            <div className="row mb-3">
                
                <div className="col-md-12">
                    <h3 className="card-title"> Faturas </h3>
                    <p className="text-grey"> Selecione o contrato que deseja consultar as faturas  </p>
                </div>
              
            </div>


            <div className="mt-3">
                  { !contracts.length ? (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <p> Não há dados para exibir aqui </p>
                          </div>
                        </div>
                      </>
                    ) : (
                    <table className="table">
                      <thead>
                        <tr>
                            <th> Assinatura </th>
                            <th> Descrição </th>
                            <th className="text-right"></th>
                        </tr>
                      </thead>
                      <tbody>
                      {contracts.map((contract, index) => (
                          <tr key={contract._id}>
                              <td> 
                                <Link to={`/user/charges/${contract._id}`} className="trLink">
                                  <span className="text-grey"> {contract.customerName} </span>
                                  {contract.productName}
                                </Link>
                              </td>
                              <td> {contract.domainUrl} </td>
                              <td className="text-right">
                              <Link to={`/user/charges/${contract._id}`} className="btn btn-outline-twitter">
                                Ver faturas
                              </Link>
                              </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                  )}
                  </div>
            </div>
      </div>
    </div>
  </div>
</>
  );
}
