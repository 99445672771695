import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import Config from '../../../../services/config';
import api from '../../../../services/login';
import StatusIcon from '../../../Common/StatusIcon';

export default function List() {

   const [ products, setProducts ] = useState([]);

   const authorization = Config.AuthApiSecretKey;
   const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
   const userid = credencialdata._id;
   
   useEffect(() => {
       
       const authorization = Config.AuthApiSecretKey;
       const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
       const userid = credencialdata._id;

        async function loadProducts() {
            const response = await api.get('/admin/products', {
                headers: { authorization, userid },
            })
            console.log(response.data);
            setProducts(response.data.products);
        }
        loadProducts();
    },[])

    async function handleRemove(itemId, title, index) {

        swal(`Remover ${title}?`, {
            buttons: {
              cancel: "cancelar",
              catch: {
                text: "remover",
                value: "remover",
              },
            },
          })
          .then((value) => {
            switch (value) {

              case "cancelar": 
                break;
           
              case "remover":
                setProducts(products.filter(product => product._id !== itemId));
                swal("Remover Produto", "produto removido com sucesso!", "success");

                // console.log(itemId);

                async function removeProduct() {
                    await api.delete(`/admin/product/delete/${itemId}`, {
                        headers: { authorization, userid },
                    })
                }
                removeProduct();
                break;

                default:
                    swal(" OK, você escolheu cancelar... ");
            }
          });
    }
    
  return (
    <>
        <div className="table-responsive">
            { !products.length ? 'Não há dados para exibir aqui' : (
                <table className="table">
                <thead>
                    <tr>
                    <th className="pt-1 pl-0">
                        Nome
                    </th>
                    <th className="pt-1">
                        Preço
                    </th>
                    <th className="pt-1 text-center">
                        Status
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product, index) => (
                        <tr key={product._id}>
                            <td className="py-1 pl-0">
                                <div className="d-flex align-items-center">
                                <div className="ml-3">
                                    <p className="mb-0"> {product.title} </p>
                                    <p className="mb-0 text-muted text-small"> 
                                        Atualizado: {product.updatedAt} <br/>
                                        Referência: <span className="text-twitter"> 
                                        {!product.product_reference ? 'sem referência' : product.product_reference} </span>
                                    </p>
                                </div>
                                </div>
                            </td>
                            <td>
                                R$ {product.price}
                            </td>
                            <td className="text-right">
                                <Link to={'/admin/product/' + product._id} className="btn btn-outline-secondary btn-sm">
                                    <i className="ti-settings btn-icon-prepend"></i>  Editar
                                </Link>

                                <StatusIcon iconStatus={product.status} />

                                <button onClick={() => handleRemove(product._id, product.title, index)} className="remoteItemBt">
                                    <i className="ti-close text-danger btn-icon-prepend ml-3"></i>
                                </button>

                            </td>
                        </tr>
                    ))}
                </tbody>
                </table>
            )}
        </div>
    </>
  );

}
