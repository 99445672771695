import React, { useEffect, useState, useRef, Component } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import swal from 'sweetalert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageUploader from "quill-image-uploader";
import Config from '../../services/config';
import api from '../../services/login';
import './styles.css';
import 'react-quill/dist/quill.snow.css';

export default function CommerceProductCategoryDetail(props) {

  const authorization = Config.AuthApiSecretKey;
  const id = props.catId;
  const contractId = props.contract;
  const userId = props.user;

//  
const quillRef = useRef(null); // Adicione esta linha

const insertImage = () => { // Adicione esta função
  const url = prompt('Insira o URL da imagem:');
  if (url) {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    editor.insertEmbed(range.index, 'image', url);
  }
};

const modules = { // Adicione esta configuração de módulos
  toolbar: {
    container: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'video'],
      ['image'],
      ['clean']
    ],
    handlers: {
      'image': insertImage
    }
  }
};

const formats = [ // Adicione esta configuração de formatos
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];
//  

  const getFromCookie = (cookieName) => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  };
  const [appData, setAppData] = useState({
    title: '',
    slug: '',
    description: '',
    content: '',
    featuredImage: '',
    videoLink: '',
    showAppNavigation: '',
    showWebNavigation: '',
    parentCategory: '',
    featuredImageBackground: '',
    sitemap: '',
    status: '',
    contractid:  getFromCookie('selectedContractId'),
  });

  const [isMounted, setIsMounted] = useState(true);
  const [editorState, setEditorState] = useState(() => {
    const initialContent = appData.content || '{"blocks":[{"text":""}],"entityMap":{}}';
    const contentState = (JSON.parse(initialContent));
    console.log(contentState);
    // return editorContent;
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppData((prevState) => ({ ...prevState, [name]: value }));
  };

  

  useEffect(() => {
    window.scrollTo(0, 0);

    async function loadData() {
      try {
        const response = await api.get(`/user/cms/categories/${id}`, {
          headers: {
            authorization,
            userId,
            contractid : appData?.contractid,
          },
        });

        if (isMounted) {
          setAppData(response?.data);

          const initialContent = response?.data?.content || '{"blocks":[{"text":""}],"entityMap":{}}';
          const contentState = (JSON.parse(initialContent));
          // setEditorState(contentState);
        }
      } catch (error) {
        console.error('Error fetching data');
      }
    }

    loadData();

    return () => {
      setIsMounted(false);
    };
  }, [contractId, id, userId, isMounted]);

  const handleEditorChange = (value) => {
    setAppData((prevState) => ({ ...prevState, content: value }));
  };

  async function onSubmit(e) {
    e.preventDefault();

    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem('credencialData'));
    const userid = credencialdata._id;

    const {
      title,
      slug,
      description,
      content,
      featuredImage,
      videoLink,
      showAppNavigation,
      showWebNavigation,
      parentCategory,
      featuredImageBackground,
      sitemap,
      status,
    } = appData;

    try {
      await api.put(
        `/user/cms/categories/${id}`,
        {
          title,
          slug,
          description,
          content,
          featuredImage,
          videoLink,
          showAppNavigation,
          showWebNavigation,
          parentCategory,
          featuredImageBackground,
          sitemap,
          status,
          contractId: contractId,
          customerId: userid,
        },
        {
          headers: {
            authorization,
            userId,
            contractid: contractId,
          },
        }
      );

      swal({
        title: 'Alteração',
        text: 'Alteração realizada com sucesso',
        timer: 2000,
      });

      setAppData({});
      window.location.reload();
    } catch (error) {
      swal({
        title: 'Alteração',
        text: error.response.data.error,
        timer: 2000,
      });
    }
  }

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-6">
                <h3> {appData?.title || 'Sem título'} </h3>
                <small> Atualizar categoria </small>
              </div>
              <div className="col-md-6 text-right">
                <Link to={`/user/cms/categories`}>
                  <button className="btn btn-outline-dark btn-sm mt-3 mb-4 mr-3">
                    Ver categorias
                  </button>
                </Link>

              </div>
            </div>
            <form className="forms-sample" onSubmit={onSubmit}>
              <div className="row border-top">
                <div className="col-md-9 mt-4">
                  <div className="form-group">
                    <label htmlFor="title"> Título da categoria </label>
                    <input
                      placeholder="Título da categoria"
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      value={appData?.title || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="slug"> Slug </label>
                    <input
                      placeholder="Slug"
                      type="text"
                      className="form-control"
                      name="slug"
                      id="slug"
                      value={appData?.slug || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description"> Descrição curta </label>
                    <input
                      placeholder="description"
                      type="text"
                      className="form-control"
                      name="description"
                      id="description"
                      value={appData?.description || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="featuredImage"> Featured Image </label>
                    <input
                      placeholder="featuredImage"
                      type="text"
                      className="form-control"
                      name="featuredImage"
                      id="featuredImage"
                      value={appData?.featuredImage || ''}
                      onChange={handleChange}
                    />
                  </div>

                  <label htmlFor="content"> Conteúdo </label>

                  <div className="form-group">
                    <ReactQuill
                      value={appData?.content || ""}
                      onChange={handleEditorChange}
                      placeholder="Conteúdo"
                      style={{ height: '300px' }}
                      modules={{
                        toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ 'size': [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                          ['link', 'video'],
                          ['image'],
                          ['code-block'],
                          ['clean']
                        ],
                        
                      }}
                      formats={[
                        'header', 'font', 'size',
                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                        'list', 'bullet', 'indent',
                        'link', 'image', 'video',
                        'script', 'direction', 'header', 'color', 'background', 'code-block'
                      ]}
                    />
                  </div>

                  <br/>
                  <br/>
                  <br/>

                  <div className="form-group">
                    <label htmlFor="parentCategory">
                      {' '}
                      Selecionar uma categoria pai{' '}
                    </label>
                    <input
                      placeholder="Digite o id da categoria pai"
                      type="text"
                      className="form-control"
                      name="parentCategory"
                      id="parentCategory"
                      value={appData?.parentCategory || ''}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-dark mr-2">
                      Salvar
                    </button>
                  </div>
                </div>

                <div className="col-md-3 mt-4 ">

                  <div className="form-group">
                    <label htmlFor="status"> Status </label>
                    <select
                      id="status"
                      name="status"
                      className="form-control"
                      value={appData?.status || ''}
                      onChange={handleChange}
                    >
                      <option> Escolha a opção </option>
                      <option value="enabled"> Online </option>
                      <option value="disabled"> Offline </option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="sitemap"> Habilitar no Sitemap ? </label>
                    <select
                      id="sitemap"
                      name="sitemap"
                      className="form-control"
                      value={appData?.sitemap || ''}
                      onChange={handleChange}
                    >
                      <option> Escolha a opção </option>
                      <option value="disabled"> Não </option>
                      <option value="enabled"> Sim </option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="showAppNavigation">
                      {' '}
                      Mostrar no menu do app ?{' '}
                    </label>
                    <select
                      id="showAppNavigation"
                      name="showAppNavigation"
                      className="form-control"
                      value={appData?.showAppNavigation || ''}
                      onChange={handleChange}
                    >
                      <option> Escolha a opção </option>
                      <option value="disabled"> Não </option>
                      <option value="enabled"> Sim </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="showWebNavigation">
                      {' '}
                      Mostrar no menu do site ?{' '}
                    </label>
                    <select
                      id="showWebNavigation"
                      name="showWebNavigation"
                      className="form-control"
                      value={appData?.showWebNavigation || ''}
                      onChange={handleChange}
                    >
                      <option> Escolha a opção </option>
                      <option value="disabled"> Não </option>
                      <option value="enabled"> Sim </option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}