import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../services/Users';
import Config from '../../../services/config';

import './styles.css';

export default function PushList(props) {

  const contractId = props.contractId;

  const [ messages, setMessages ] = useState([]);

  useEffect(() => {
    const authorization = Config.AuthApiSecretKey;

    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userId = credencialdata._id;

     async function loadMessages() {
         const response = await api.get(`/devices/push-messages/${contractId}`, {
             headers: { authorization, userId },
         })
         setMessages(response.data.response);
     }
     loadMessages();
 },[])

  return (
    <>
        
        <div className="row">
            <div className="col-md-6">
                <h3 className="card-title"> Histórico de Mensagens ( Push )</h3>
                <p className="text-grey"> Aqui você encontra a lista de mensagens push enviadas  </p>
            </div>

            <div className="col-md-6">
                <div className="text-right">
                    <div className="dropdown">
                        <Link to="/user/contracts/push" className="btn btn-outline-dark btn-sm">
                            Meus Contratos Push
                        </Link>

                        <Link to={`/user/push/${props?.contractId}`} className="btn btn-dark btn-sm ml-3">
                            Enviar
                        </Link>
                    </div>
                </div>
            </div>

        </div>

        <div className="table-responsive mt-3">
                    <table className="table">
                      <thead>
                        <tr>
                            <th> #Message Id </th>
                            <th className="text-left"> Enviada em: </th>
                            <th> Recebedores </th>
                            <th className="text-left"> Mensagem </th>
                            <th className="text-center"> Ações </th>
                        </tr>
                      </thead>
                      <tbody>
                      {messages.map((message, index) => (
                        <tr key={message._id}>
                            <td className="text-muted"> {message._id} </td>
                            <td className="text-muted"> {message.createdAt} </td>
                            <td className="text-left text-muted"> {message.totalDevices} </td>
                            <td className="text-left text-muted"> {message.content} </td>
                            <td className="text-center"> 
                              {message.status === "1" ? (
                                <span className="text-success"> Enviada </span>
                                ) : (
                                <span className="text-danger"> Problema no envio </span>
                              )}
                            </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
        </div>
    </>
  );
}
