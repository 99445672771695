import React from 'react';

import Config from '../../../services/config';
import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import ChargeDetailResume from  '../../../components/ChargeDetailResume';
import Loader from 'react-loader-spinner';

export default function ChargeDetail(props) {

  const { match: { params } } = props;
  const id = params.id

  return (
    <>

    <Loader
       type="BallTriangle"
       color="#000000"
       height={60}
       width={60}
       timeout={Config.loaderTimeout}
       className="overlaySpinner" //3 secs
   />

    <AuthPage />

    <div className="container-scroller">
        <Header />
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                <div className="content-wrapper">
                    <ChargeDetailResume transactionId={id} />
                </div>
            </div>
        </div>
        <Footer />
    </div>

    </>
  );
}
