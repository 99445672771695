import React from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPageAdmin';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import NavigationMenu from '../../../../components/AdminConsoleComponents/Navigation';
import ProductsList from '../../../../components/AdminConsoleComponents/Products/List';
import WelcomeConsole from '../../../../components/AdminConsoleComponents/WelcomeConsole';

export default function List() {
  return (
    <>
       <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <div className="container-scroller">
            <HeaderAdminConsole />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                            <div className="row">

                            <div className="col-md-12 grid-margin">
                                <WelcomeConsole />
                            </div>

                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <NavigationMenu />
                                            </div>
                                            </div>
                                        </div>

                                    <div className="col-md-8">
                                        <div className="card">
                                        <div className="card-body">
                                            
                                            <div className="row mb-4">
                                            
                                            <div className="col-md-8">

                                                <h3 className=""> Produtos/Serviços </h3>
                                                    <p className="text-grey mt-3"> Lista de produtos e serviços da Pagefy  </p>
                                                </div>

                                                <div className="col-md-4 text-right">
                                                    <Link to="/admin/product/store" className="btn btn-outline-twitter btn-sm">
                                                        <i className="ti-plus btn-icon-prepend"></i> Adicionar 
                                                    </Link>
                                                </div>
                                            </div>

                                            <ProductsList />

                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
