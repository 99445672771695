import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link, Redirect } from 'react-router-dom';

import { Editor } from "@tinymce/tinymce-react";

import Header from '../../../../components/Header';
import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPage';
import Footer from '../../../../components/Footer';
import api from '../../../../services/Users';

import './styles.css';

export default class Contracts extends Component {

  constructor(props) {
    
    const { match: { params } } = props;
    const authorization = Config.AuthApiSecretKey;

    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;
    
    super(props);

    this.state = {
      redirect: false,
      postData: [],
      categoriesData: [],
      authorization,
      pageCounter: 1,
      customerId: userid,
      contractId: params.contractId,
      id: params.id,
      loading: 1,
      loader: 'enable'
    };
  }

  loadTrx(){

      this.setState({ loading: true });

      const authorization = this.state.authorization;
  
      api.get(`/wpSinglePost/${this.state.contractId}/${this.state.id}`,{
          headers: { authorization, userid: `${this.state.customerId}` },
      })
      .then(res => {
        this.setState({ postData: res.data });
        this.setState({ loader: 'disable' });
      });

  }

  async loadCategories(){
    const authorization = this.state.authorization;

    api.get(`/wp-categories/${this.state.contractId}`,{
      headers: { authorization, userid: `${this.state.customerId}` },
    })
    .then(res => {
      this.setState({ categoriesData: res.data });
    });

  }

  async handleRemove (itemId, mobcustomerid) {
    
    const authorization = this.state.authorization;

    await api.delete(`/contract/${itemId}`, {
      headers: { authorization, mobcustomerid },
    }).then(() => this.setState({ redirect: true }));
    console.log(this.state.redirect);

  }
  
  componentDidMount() {
    this.loadTrx();
    this.loadCategories();
  }

  render(){
    
    if (this.state.redirect) {
      return <Redirect to={`/admin/contracts/${this.state.settingsdata.userId}`} />
    }
    
      return (
        <>

          {this.state.loader == 'enable' ? (        
            <Loader
              type="BallTriangle"
              color="#000000"
              height={60}
              width={60}
              timeout={Config.loaderTimeout}
              className="overlaySpinner" //3 secs
            />
          ) : ('')}

          <AuthPage />

          <div className="container-scroller">

              <Header />

              <div className="container-fluid page-body-wrapper" id="fscroll">
                  <div className="main-panel">
                  <div className="content-wrapper">
              
              <div className="row">
                <div className="col-12">
                  <div className="mt-4">
                    <div className="">

                      <div className="row">
                        <div className="col-md-8">
                          <h2>{this.state.postData.title?.rendered} </h2>
                          <p className="text-grey">
                            Post #{this.state.id}
                          </p>
                        </div>

                        <div className="col-md-4 text-right">
                            <Link to={`/user/wp-posts/${this.state.contractId}/1`} className="btn btn-outline-dark btn-sm text-left">
                               Ver Todos
                            </Link>

                            <a href={this.state.postData?.link} className="btn btn-outline-twitter btn-sm ml-4" target="_blank"> Preview </a>

                            <button type="submit" className="btn btn-dark ml-3" > Salvar </button>


                        </div>


                      </div>


                      <div className="row mt-4">

                        <div className="col-md-8">

                          <div className="xx">
                              <div className="xxx">

                                <form className="forms-sample">

                                  <div className="form-group">
                                    <label htmlFor="exampleInputUsername1"> Título do Post </label>
                                    <input type="text" 
                                      className="form-control" 
                                      placeholder="Descreva o título da publicação aqui..."
                                      value={this.state.postData.title?.rendered}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="exampleTextarea1"> Conteúdo do post </label>

                                    <Editor
                                      apiKey="accqwwf4mn4j1ct0c1vq8hf2rxl601ufv9dbhmq7ce5ztcos"
                                      plugins="advlist autolink lists link image charmap print preview anchor',
                                      'searchreplace visualblocks code fullscreen',
                                      'insertdatetime media table paste code help wordcount"
                                      initialValue={this.state.postData.content?.rendered}
                                      init={{
                                        height: 370
                                      }}
                                    />

                                  </div>

                                  <p className="text-grey">Link Permanente: {this.state.postData?.link} </p>

                                </form>
                              </div>
                            </div>

                        </div>

                        <div className="col-md-4">

                          <div className="xx">
                              <div className="xxx">


                              <div className="form-group">
                                <label htmlFor="exampleInputUsername1"> Data de Publicação </label>
                                <input type="text" className="form-control" value={this.state.postData?.date} placeholder="Username"/>
                              </div>

                              <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1"> Status da Publicação </label>
                                <select className="form-control form-control-lg" id="exampleFormControlSelect1">
                                  <option> Publicado </option>
                                  <option> Não Publicado </option>
                                  <option> Rascunho  </option>
                                </select>
                              </div>

                              <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1"> Visibilidade </label>
                                <select className="form-control form-control-lg" id="exampleFormControlSelect1">
                                  <option> Público </option>
                                  <option> Privado </option>
                                  <option> Protegido </option>
                                </select>
                              </div>

                                <label className="category"> Categorias </label>
                                
                                <div className="categories-card">

                                  {this.state.categoriesData.map((category, index) => (
                                    <div className="form-group" key={index}>
                                      <div className="form-check">
                                        <label className="form-check-label">

                                          <input type="checkbox" className="form-check-input" />
                                          {category.name}
                                          <i className="input-helper"></i></label>

                                      </div>
                                    </div>
                                  ))}

                                </div>

                              </div>
                            </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
                  </div>
              </div>
            </div>
            <Footer />

        </>
      )
    }
}