import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link, Redirect } from 'react-router-dom';

import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPageAdmin';
import Footer from '../../../../components/Footer';
import api from '../../../../services/Users';
import PaymentStatusGtw1 from '../../../../components/ChargesComponents/PaymentStatusGtw1';
import Alerts from '../../../../components/Alerts';

import './styles.css';

export default class Contracts extends Component {

    constructor(props) {

        const { match: { params } } = props;
        const authorization = Config.AuthApiSecretKey;
        const contractId = params.id;
        const customerId = params.customerId;

        super(props);
        this.state = {
          trxdata: [],
          balances: [],
          authorization,
          contractId,
          customerId,
          userdata: {},
          trxdetail: [],
          gtw: {},
          pageCounter: 1,
          loading: true,
          curTime: new Date().toLocaleString(),
          errordata: {}
        };
      }

      async loadTrx(p){
        const authorization = this.state.authorization;
        const customerId = this.state.customerId;

        await api.get(`/transactionsv2/${this.state.contractId}/${p}/last`,{
            headers: { authorization, userid: customerId },
        })
        .then(res => {
            console.log(res);
            this.setState({ trxdata: res.data });
            this.setState({ loading: false });
            console.log(this.state.loading)
        }).catch( error => {
            this.setState({ errordata: true });
            Alerts("Erro", 'Oops!, encontrei um erro ao carregar os dados. Pode ser que este recurso esteja indisponível no momento tente mais tarde' ,"error");
        });
    }

      loadBalance(){
        const authorization = this.state.authorization;
        const customerId = this.state.customerId;

        api.get(`/transactions/balance/${this.state.contractId}`,{
            headers: { authorization, user: customerId },
        })
        .then(res => {
            console.log(res.data);
            this.setState({ balances: res.data });
        });

      }

      loadUserData(){
        
        const authorization = this.state.authorization;
        const customerId = this.state.customerId;

        api.get('/mobcustomer/detail', {
            headers: { authorization, userId: customerId },
        })
        .then(res => {
            this.setState({ userdata: res.data.user });
        });
      }

      transactionDetails(id, gtw){
        const authorization = this.state.authorization;

        api.get(`/transaction/${id}/gtw1`,{
            headers: { authorization },
        })
        .then(res => {
            console.log(res);
            this.setState({ trxdetail: res.data.response });
        });
      }

        handlePaginationPlus = async () => {
        this.setState({ loading: true });
        this.setState({ pageCounter: this.state.pageCounter + 1});
            await this.loadTrx(this.state.pageCounter + 1);
        }

        handlePaginationLess = async () => {
            if(this.state.pageCounter > 1){
                this.setState({ loading: true });
                this.setState({ pageCounter: this.state.pageCounter - 1});
                await this.loadTrx(this.state.pageCounter - 1);
            }
        }
    
      componentDidMount() {
        this.loadTrx(1);
        this.loadUserData();
        this.loadBalance();
      }

    render(){

        if(this.state.errordata === true){
            return <Redirect to={'/admin/main'} />
        }
        
      return (
        <>
          <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
          />

          <AuthPage />

          <div className="container-scroller">

            <HeaderAdminConsole />

                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                            <div className="row">

                            <div className="col-md-12">

                            <div className="row">
                                    <div className="col-md-4">
                                    <h4 className="font-weight-bold text-uppercase"> 
                                        Transações 
                                    </h4>
                                    <p className="font-weight-normal mb-0 text-grey">
                                      <span className="text-twitter"> Contrato: {this.state.contractId} </span> <br/>
                                      {this.state.userdata.companyName ? (
                                        <>
                                            <span className="text-grey"> Empresa: {this.state.userdata.companyName} </span> <br/>
                                        </>
                                      ) : ''}
                                      <span className="text-grey"> Responsável financeiro: {this.state.userdata.name} </span> 
                                    </p>
                                    </div>
                                    <div className="col-md-8 text-right">
                                      <Link to={`/admin/contracts/${this.state.customerId}`} className="btn btn-twitter btn-sm mr-3">
                                          Todos contratos deste cliente 
                                      </Link>
                                      <Link to={`/admin/contract/update/${this.state.contractId}/${this.state.customerId}`} className="btn btn-outline-twitter btn-sm mr-3">
                                          Editar este contrato
                                      </Link>
                                      <Link to={`/admin/customers`} className="btn btn-outline-twitter btn-sm">
                                          Todos Clientes
                                      </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-4 ">

                                <div className="row">
                                    <div className="col-md-12">

                                    <div className="row">
                                        <div className="col-md-4 grid-margin">
                                            <div className="card d-flex align-items-center">
                                                <div className="card-body">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <i className="ti-time text-twitter icon-md"></i>
                                                        <div className="ml-3">
                                                            <h4 className="text-twitter"> {this.state.balances.waiting_funds} </h4>
                                                            <p className="mt-2 text-muted card-text">  Aguardando Pagamento </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 grid-margin">
                                            <div className="card d-flex align-items-center">
                                                <div className="card-body">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <i className="ti-check text-twitter icon-md"></i>
                                                        <div className="ml-3">
                                                            <h4 className="text-twitter"> {this.state.balances.available}  </h4>
                                                            <p className="mt-2 text-muted card-text"> Saldo Disponível 
                                                                
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 grid-margin">
                                            <div className="card d-flex align-items-center">
                                                <div className="card-body">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <i className="ti-pulse text-twitter icon-md"></i>
                                                        <div className="ml-3">
                                                            <h4 className="text-twitter"> {this.state.balances.transfered}  </h4>
                                                            <p className="mt-2 text-muted card-text"> Saldo Transferido </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                               

                                <div className="row mt-2">

                                    <div className="col-md-6 mb-4">
                                        <p className="text-grey"> Todos os saldos estão atualizados até a data de &nbsp;
                                        <Moment 
                                            format="DD/MM/YYYY" 
                                            date={this.state.curTime}
                                        />
                                        </p>
                                    </div>
                                    <div className="col-md-6 text-right mb-4">

                                        <nav className="paginationRight">
                                            <ul className="pagination flex-wrap pagination-flat pagination-twitter">
                                                <li className="page-item">
                                                    <button className="page-link pgLess" onClick={this.handlePaginationLess}>
                                                        <i className="ti-angle-left"></i>
                                                    </button> 
                                                </li>
                                                <li className="page-item active">
                                                    <span className="page-link">{this.state.pageCounter}</span>
                                                </li>
                                                
                                                <li className="page-item">
                                                    <button className="page-link" onClick={this.handlePaginationPlus}>
                                                        <i className="ti-angle-right"></i>
                                                    </button> 
                                                </li>
                                            </ul>
                                        </nav>

                                    </div>

                                    <div className="col-md-12">
                                        <div className="">
                                        <div className="">

                                            <div className="table-responsive">

                                                        {!this.state.trxdata.length ? (
                                                            <p className="text-grey mt-3"> Este cliente não possui transações para exibir neste momento</p>
                                                        ) : (
                                                            <>
                                                                {this.state.loading === false ? (
                                                                    <table className="table table-striped">
                                                                    <tbody>
                                                                        {this.state.trxdata.map((trx, index) => (
                                                                          <tr key={index}>
                                                                                <td> 
                                                                                    <div className="col-md-12">
                                                                                        <Link to={`/admin/transaction/${trx.transaction_id}/${this.state.contractId}/${this.state.customerId}`} className="text-grey trLink">
                                                                                            {trx.transaction_id} 
                                                                                        </Link>
                                                                                        <br/>
                                                                                        <small className="text-grey">{trx.gtw}</small>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="text-grey">
                                                                                    {trx.customer_name}<br/>
                                                                                    <small>Documento: {trx.document_number} </small>
                                                                                </td>

                                                                                <td className="text-grey">
                                                                                  {trx.payment_method}
                                                                                </td>

                                                                                
                                                                                <td className="text-grey"> 
                                                                                    <Moment format="DD/MM/YYYY" date={trx.date_created}  /> 
                                                                                </td>

                                                                                <td className="text-grey">
                                                                                  {trx.amount}
                                                                                </td>

                                                                                <td className="text-right"> 
                                                                                    <PaymentStatusGtw1 status={trx?.status || ""} className="mr-3"/>
                                                                                    <Link to={`/admin/transaction/${trx.transaction_id}/${this.state.contractId}/${this.state.customerId}`} className="btn btn-outline-secondary btn-sm ml-3">
                                                                                        Detalhes
                                                                                    </Link> 
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                ) : (
                                                                    <div className="loader"> Carregando dados, por favor aguarde ... </div>
                                                                )}
                                                                
                                                            </>
                                                        )}
                                            </div>

                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>



        </>
      )
    }
}