import React from 'react';

export default function PaymentCycle(props) {

    const Switch = (str) => ({
      "onetime" : "Cobrança Única",
      "monthly" : "Cobança Recorrente ( Mensal )",
      "biannual" : "Cobança Recorrente ( Semestral ) ",
      "annual" : "Cobança Recorrente ( Anual )  ",
      "weekly" : "Cobança Recorrente ( Semanal )   ",
      "daily" : "Cobança Recorrente ( Diária )    ",
      
    })[str] || '';

    let cycle = props.status;

    return (
      <span className='text-twitter'>
        { Switch(cycle) }
      </span>
  );

  } 

