import React from 'react';
import { Link } from 'react-router-dom';
// import config from '../../services/config';
import logoWhite from '../../assets/images/pagefy-logo-light.svg';

import './styles.css';


const AdminConsoleOption = (props) => {
  return (
    <>
        <li><hr/></li>
        <li className="nav-item">
            <Link to="/admin/main" className="adminConsole"> Admin Console </Link> </li>
      </>
  )
}

export default function HeaderAdminConsole() {
  
  const credencialData = JSON.parse(localStorage.getItem("credencialData"));
  
  return (
    <>
    <div className="horizontal-menu">
        <nav className="navbar top-navbar col-lg-12 col-12 p-0 adminConsoleBg">
          <div className="container">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">

              <Link to="/main" className="navbar-brand brand-logo">
                <img src={logoWhite} alt="logo" width="200" />
              </Link>
              
              <Link to="/main" className="navbar-brand brand-logo-mini">
                <img src={logoWhite} alt="logo" width="200" />
              </Link>

            </div>
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
              
              <ul className="navbar-nav navbar-nav-right">
                <li className="nav-item nav-profile dropdown">
                   <span className="text-white"> <strong> ( Console de Administração ) </strong>  <br/> Conectado como: <strong> {credencialData.email} </strong> </span>
                </li>
              </ul>
              <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
                <span className="ti-menu"></span>
              </button>
            </div>
          </div>
        </nav>
        <nav className="bottom-navbar">
          <div className="container">
            <ul className="nav page-navigation">
              <li className="nav-item">
                  <Link to="/main" className="nav-link">
                    <i className="ti-home menu-icon"></i>
                    <span className="menu-title">Dashboard</span>
                  </Link>
              </li>
              <li className="nav-item">
                <span className="nav-link">
                  <i className="ti-wallet menu-icon"></i>
                  <span className="menu-title"> Cobranças </span>
                  <i className="menu-arrow"></i>
                </span>
                <div className="submenu">
                  <ul className="submenu-item">
                    <li className="nav-item">
                        <Link to="/newcharge" className="nav-link"> 
                          Gerar Cobrança 
                        </Link> 
                    </li>
                    <li className="nav-item">
                      <Link to="/charges" className="nav-link"> 
                        Suas Cobranças
                      </Link> 
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <span className="nav-link">
                  <i className="ti-package menu-icon"></i>
                  <span className="menu-title"> Produtos </span>
                  <i className="menu-arrow"></i>
                </span>
                <div className="submenu">
                  <ul className="submenu-item">
                    <li className="nav-item"><a className="nav-link" href="pages/forms/basic_elements.html"> E-commerce </a></li>
                    <li className="nav-item"><a className="nav-link" href="pages/forms/advanced_elements.html"> E-mail Marketing </a></li>
                    <li className="nav-item"><a className="nav-link" href="pages/forms/validation.html"> Máquininhas  ( POS )</a></li>
                  </ul>
                </div>
              </li>
              
              <li className="nav-item">
                <span className="nav-link">
                  <i className="ti-heart menu-icon"></i>
                  <span className="menu-title"> Doamob </span>
                  <i className="menu-arrow"></i>
                </span>
                <div className="submenu">
                  <ul className="submenu-item">
                    <li className="nav-item">
                        <Link to="/donationDashboard" className="nav-link"> 
                          Doações 
                        </Link> 
                      </li>
                    <li className="nav-item"> 
                        <Link to="/campaigns" className="nav-link">
                          Campanhas 
                        </Link> 
                    </li>
                    <li className="nav-item"> 
                        <Link to="/events" className="nav-link">
                          Eventos 
                        </Link> 
                    </li>
                    <li className="nav-item"> 
                        <Link to="/posts" className="nav-link">
                          Posts 
                        </Link> 
                    </li>
                    <li className="nav-item"> 
                        <Link to="/pages" className="nav-link">
                          Páginas 
                        </Link> 
                    </li>
                    <li className="nav-item"> 
                        <Link to="/videos" className="nav-link">
                          Vídeos 
                        </Link> 
                    </li>
                    <li className="nav-item"> 
                        <Link to="/photos" className="nav-link">
                          Fotos 
                        </Link> 
                    </li>
                    <li className="nav-item"> 
                        <Link to="/audios" className="nav-link">
                          Áudio 
                        </Link> 
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <span className="nav-link">
                  <i className="ti-bar-chart-alt menu-icon"></i>
                  <span className="menu-title"> Relatórios </span>
                  <i className="menu-arrow"></i>
                </span>
                <div className="submenu">
                  <ul className="submenu-item">
                    <li className="nav-item">
                        <Link to="/statements" className="nav-link"> 
                          Extratos 
                        </Link> 
                      </li>
                    <li className="nav-item"> 
                        <Link to="/transactions" className="nav-link">
                          Transações 
                        </Link> 
                    </li>
                  </ul>
                </div>
              </li>
              
              <li className="nav-item">
                <span className="nav-link">
                  <i className="ti-user menu-icon"></i>
                  <span className="menu-title"> Minha Conta </span>
                  <i className="menu-arrow"></i>
                </span>
                <div className="submenu">
                  <ul className="submenu-item">
                    

                    <li className="nav-item">
                      <Link to="/myaccount"> Dados Cadastrais </Link> </li>
                    <li className="nav-item"> 
                      <Link to="/myServices"> Meus Serviços </Link> </li>
                    <li className="nav-item"> 
                      <Link to="/user/contracts">  Meus Contratos </Link> </li>
                    <li className="nav-item"> 
                      <Link to="/auditAccess"> Últimos Acessos </Link> </li>
                    <li className="nav-item"> 
                      <Link to="/support"> Suporte </Link> </li>
                    <li className="nav-item">
                        <Link to="/help"> Ajuda </Link> </li>

                    {  credencialData.group === 'root' ? <AdminConsoleOption/> : null }

                  </ul>
                </div>
              </li>
              <li className="nav-item">
                  <Link to="/logout" className="nav-link">
                    <i className="ti-unlock menu-icon"></i>
                    <span className="menu-title"> Sair </span>
                  </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}
