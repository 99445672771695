import React from 'react';
import { Link } from 'react-router-dom';

import AuthPage from '../../../../components/AuthPageAdmin';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import NavigationMenu from '../../../../components/AdminConsoleComponents/Navigation';
import UsersUpdate from '../../../../components/AdminConsoleComponents/UsersAdminConsole/Update';
import WelcomeConsole from '../../../../components/AdminConsoleComponents/WelcomeConsole';
import swal from 'sweetalert';
import api from '../../../../services/Users';
import Config from '../../../../services/config';

export default function Update(props) {

    const { match: { params } } = props;
    const customerId = params.id

    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userId = credencialdata._id;


    const handleChangePassword = () => {
        swal({
            title: "Alterar Senha",
            text: 'Lembre-se que o usuário deverá informar a nova senha no próximo login',
            content: "input",
            button: {
              text: "Alterar",
              closeModal: true,
            },
          })
          .then(password => {

            if (!password || password.length < 8){
                swal("Alterar Senha", "Não posso enviar sua solicitação", "error");
                swal.stopLoading();
                swal.close();
            } 
            return api.put(`/admin/password/update`, {
                securityPassword: `${password}`
            },{
                headers : {
                    authorization: `${authorization}`,
                    userId: `${userId}`,
                    customerId: `${customerId}`,
                },
            });
          })
          .then(results => {
            return swal("Alterar senha", "Senha alterada", "success");
          })
          .catch(err => {
            if (err) {
              return swal("Oops!", "A requisição falhou", "error");
            } else {
              swal.stopLoading();
              swal.close();
            }
        }
        );
           
    }

  return (
    <>
        <AuthPage />
        <div className="container-scroller">
            <HeaderAdminConsole />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div class="row">

                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-8">
                                        <UsersUpdate userId={customerId} />
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <div className="col-md-12">
                                            <Link to="/admin/users" className="btn btn-twitter btn-sm">
                                                Lista de Clientes
                                            </Link>
                                        </div>
                                        <div className="col-md-12">
                                            <button onClick={handleChangePassword} className="btn btn-pinterest btn-sm mt-3">
                                                Alterar Senha
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
