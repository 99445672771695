import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Navigation from '../navigation';
import Config from '../../../services/config';
import api from '../../../services/Users';
import Loader from 'react-loader-spinner';
import ContractStatus from '../../../components/Shared/Status';
import ReactHtmlParser from 'react-html-parser'; 

import './styles.css';

import wordpresslogo from '../../../assets/images/wordpress-logotipo-status.png'

export default class Contracts extends Component {

  constructor(props) {
    
    const { match: { params } } = props;
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    super(props);
    this.state = {
      contractdata: [],
      productdata: [],
      authorization,
      userid,
      contractId: params.id
    };
  }

  async loadContract(){
      const authorization = this.state.authorization;
      const userid = this.state.userid;
  
      await api.get(`/contract/${this.state.contractId}`,{
          headers: { authorization },
      })
      .then(res => {
          this.setState({ contractdata: res.data.contract });
          const productID = res.data.contract.productId;
          this.loadProductData(productID);
      });
      
  }

  loadProductData(id){
    const authorization = this.state.authorization;
    const userid = this.state.userid;

    api.get(`/product/detail/${id}`,{
      headers: { authorization, userid },
    })
    .then(res => {
        this.setState({ productdata: res.data.product });
    });

  }

  componentDidMount() {
    this.loadContract();
  }

  // 
  render() {
    return (
        <>
        <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <Header />
        <div className="container-fluid page-body-wrapper" id="fscroll">
         <div className="main-panel">
           <div className="content-wrapper">
             <div className="row">
               <div className="col-12">
                 <div className="mt-4">
                   <div className="">
                     <div className="row">
                       
                       <div className="col-lg-12">

                                <div className="row border-bottom">

                                  <div className="col-md-8">
                                    <h3> 
                                     {this.state.contractdata?.domainUrl}
                                    </h3>
                                  </div>

                                  <div className="col-md-4 text-right text-grey">
                                      <Link to="/user/contracts" className="btn btn-outline-dark btn-sm">
                                          Todos Contratos
                                    </Link>
                                  </div>

                                </div>

                                <div className="row">

                                  <div className="col-md-6">

                                    <div className="row mt-4">


                                        <a href="https://webmail.pagefai.com" className="col-md-4 mt-3" target="_blank">
                                              <div className="dash-button">
                                                Webmail
                                              </div>
                                          </a>


                                          <Link to={`/user/invoices/${this.state.contractId}/1`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                Faturas
                                              </div>
                                          </Link>

                                          <Link to={`/user/wcorders/${this.state.contractId}/${this.state.contractdata.userId}`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                Pedidos do <br/> E-commerce
                                              </div>
                                          </Link>

                                          <Link to={`/user/statments/${this.state.contractId}`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                Extrato
                                              </div>
                                          </Link>

                                          <Link to={`/user/tutorials`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                Cursos e <br/> Treinamentos
                                              </div>
                                          </Link>

                                          
                                          <Link to={`/user/push/${this.state.contractId}`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                  Push <br/> Notification
                                              </div>
                                          </Link>

                                          <Link to={`/user/wpstatus/${this.state.contractId}/${this.state.userid}`} className="col-md-4 mt-3">
                                            <div className="dash-button">
                                                Wordpress <br/> Diagnóstico
                                            </div>
                                          </Link>


                                          {this.state.contractdata?.dashboardUrl ? (
                                              <a href={this.state.contractdata?.dashboardUrl} className="col-md-4 mt-3" target="_blank">
                                                <div className="dash-button">
                                                    Wordpress <br/> Admin
                                                </div>
                                              </a>
                                          ) : ''}

                                          <Link to={`/user/myapp/${this.state.contractId}`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                Detalhes do <br/> Contrato
                                              </div>
                                          </Link>

                                          <Link to={`/user/app-configuration/${this.state.contractId}`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                Gerenciar <br/> Aplicativo
                                              </div>
                                          </Link>

                                          <Link to={`/user/commerce/main/${this.state.contractId}`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                Pagefai <br/> Commerce
                                              </div>
                                          </Link>

                                          {/* <Link to={`/user/commerce/orders/${this.state.contractId}`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                Pedidos <br/> Pagefai Commerce
                                              </div>
                                          </Link> */}

                                      </div>

                                  </div>
                          
                                  <div class="col-lg-6 grid-margin stretch-card mt-4 p-4 ">
                                    <div className="">
                                      <div className="">
                                        <div className="">


                                          <div>
                                            <h4> Código do Contrato</h4>
                                            <p><strong>{this.state.contractId}</strong></p>
                                          </div>

                                          <div className='mt-4'>
                                            <h4> Serviço Contratado</h4>
                                            <p>{this.state.productdata.title}</p>
                                          </div>

                                          <div className='mt-4'>
                                            <h4> Domínio </h4>
                                            <p>{this.state.contractdata?.domainUrl}</p>
                                          </div>

                                          <div className='mt-4'>
                                            <h4> Valor </h4>
                                            <p>{this.state.productdata?.price}</p>
                                          </div>

                                          <h4 className='mt-4'>Descrição dos  serviços </h4>
                                          <p dangerouslySetInnerHTML={{ __html: this.state.productdata?.content}} />
                                          
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/*  */}

                                </div>

                      </div>

                        </div>
                   </div>
                 </div>
               </div>
             </div>
         </div>
        </div>
       </div>
        <Footer />
       </>
    );
  }
}
