import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Config from '../../../../services/config';
import api from '../../../../services/Users';
import Alerts from '../../../../components/Alerts';

export default class Store extends Component {

    constructor(props) {
        
        const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));

        super(props);
        this.state = {
            userdata: {},
            authorization,
            userid: credencialdata._id
        };
    }

    handleChange = (e) => {
        const state = this.state.userdata
        state[e.target.name] = e.target.value;
        this.setState({userdata:state});
        console.log(state);
    }

    onSubmit = async (e) => {
    
        e.preventDefault();
        const authorization = this.state.authorization;
        const userid = this.state.userid;

        console.log(userid);

        const { 
            title, 
            description, 
            price,
            product_type,
            featured_image, 
            feaured_video, 
            content,
            release_date,
            categories,
            tags,
            product_reference,
            stock,
            status
          } = this.state.userdata;
    
        const response = await api.post('/admin/product/store', {
            title, 
            description, 
            price,
            product_type,
            featured_image, 
            feaured_video, 
            content,
            release_date,
            categories,
            tags,
            product_reference,
            stock,
            status
          }, {
            headers: { authorization, userid }
          });
    
          if(response.data.cb !== "error"){
              Alerts("Cadastro de Produto", "Cadastro efetuado com sucesso","success");
        } else {
            Alerts("Cadastro de Produto", response.data.message ,"error");
            //   history.push('/');
          }
      
      }

  render() {
    return (
        <>
        <div>
            <div>
            <div>

                <div className="row mb-3">
                    
                    <div className="col-md-6">
                        <h3> Produtos/ Serviços <br/> / Adicionar </h3>
                    </div>

                    <div className="col-md-6">
                        <div className="text-right">
                                <div className="dropdown">
                                    <Link to="/admin/products" className="btn btn-outline-twitter btn-sm">
                                        <i className="ti-list-ol"></i> Lista de Produtos
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                <div className="row">
                    <div className="col-md-12">

                        <form onSubmit={this.onSubmit}>

                            <div className="form-group mt-4">
                                <label htmlFor="product_type"> Tipo do Produto * </label>
                                <select 
                                id="product_type"
                                name="product_type"
                                value={this.state.userdata.product_type || ""}
                                onChange={this.handleChange}
                                className="form-control">
                                    <option value=""> Selecione o tipo de produto </option>
                                    <option value="payment"> Meios de Pagamento </option>
                                    <option value="digital"> Produto Digital </option>
                                    <option value="digital"> Produto Físico </option>
                                    <option value="download"> Download </option>
                                    <option value="cloudhosting"> Cloud Hosting </option>
                                    <option value="drive"> Cloud Drive  </option>
                                    <option value="ecommerce"> E-commerce  </option>
                                    <option value="donation"> Doações  </option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="title"> Título * </label>
                                <input 
                                placeholder="Título do produto... " 
                                type="text" 
                                className="form-control" 
                                name="title"
                                id="title"
                                value={this.state.userdata.title || ""}
                                onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="description"> Breve descrição * </label>
                                <input 
                                    placeholder="Descreva brevemente o produto" 
                                    type="text" 
                                    className="form-control" 
                                    name="description"
                                    id="description"
                                    value={this.state.userdata.description || ""}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="price"> Preço * </label>
                                <input 
                                    placeholder="Informe o preço ( apenas números )" 
                                    type="text" 
                                    className="form-control" 
                                    name="price"
                                    id="price"
                                    value={this.state.userdata.price || ""}
                                    onChange={this.handleChange}
                                    autoComplete="new-password"
                                />
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="release_date"> Data de Lançamento * </label>
                                        <input 
                                            placeholder="Ex: 21/05/2020..." 
                                            type="text" 
                                            className="form-control" 
                                            name="release_date"
                                            id="release_date"
                                            value={this.state.userdata.release_date || ""} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="feaured_video"> Vídeo de Instrodução * </label>
                                        <input 
                                            placeholder=" Link do vídeo do Youtube..." 
                                            name="feaured_video"
                                            id="feaured_video"
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.userdata.feaured_video || ""}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="product_reference"> Referência do Produto * </label>
                                        <input 
                                            placeholder="Informe um código de referência do produto..." 
                                            id="product_reference"
                                            name="product_reference"
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.userdata.product_reference || ""} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="stock"> Quantidade em Estoque * </label>
                                        <input 
                                            placeholder="Apenas números..." 
                                            id="stock"
                                            name="stock"
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.userdata.stock || ""} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="form-group">
                                <label htmlFor="tags"> Tags  * </label>
                                <input 
                                    placeholder="Insira as tags separadas com virgula..." 
                                    name="tags"
                                    id="tags"
                                    type="text" 
                                    className="form-control" 
                                    value={this.state.userdata.tags || ""} 
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="content"> Conteúdo * </label>
                                        <textarea 
                                            placeholder="Descreva seu produto..."
                                            id="content"
                                            name="content"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.userdata.content || ""} 
                                            onChange={this.handleChange}
                                            row="4"
                                        ></textarea>

                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="status"> Status do Cliente </label>
                                <select 
                                id="status"
                                name="status"
                                value={this.state.userdata.status || ""}
                                onChange={this.handleChange}
                                className="form-control">
                                    <option value=""> Selecione o Status </option>
                                    <option value="1"> Ativo </option>
                                    <option value="2"> Inativo </option>
                                </select>
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <button 
                                    type="submit" 
                                    className="btn btn-info mr-2"
                                    > Salvar </button>
                                </div>
                            </div>
                </form>
                </div>

              </div>
            </div>
        </div>
        </div>
    </>
    );
  }
}
