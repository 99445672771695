require('dotenv').config()

// const baseURL = process.env.REACT_APP_BE_DEVELOPMENT;
const baseURL = process.env.REACT_APP_BE_PRODUCTION;

const config = ({
    AuthApiSecretKey : `${process.env.REACT_APP_API_KEY}`,
    title : process.env.REACT_APP_TITLE,
    defaultWebsite : process.env.REACT_APP_WEBSITE_URL,
    defaultFooterText : 'We <3 Open Source',
    apiVersion : process.env.REACT_APP_VERSION,
    loaderTimeout : 1500,
    environment: process.env.REACT_APP_DASH_ENVIRONMENT,
    maintainceMode: process.env.REACT_APP_MAINTAINCE,
    ApiUrlAuth : baseURL, 
    PayLinkUrl : `${baseURL}/pay`,
    serviceApiUsersHomolog: baseURL,
    serviceApifinanceHomolog: baseURL,
    serviceApipushNotificationHomolog: baseURL,
    serviceApiWoocommerceHomolog: baseURL,
    serviceApicmsHomolog: baseURL,
    serviceApiUsersProd: baseURL,
    serviceApifinanceProd: baseURL,
    serviceApipushNotificationProd: baseURL,
    serviceApiCmsProd: baseURL,
    serviceApiWoocommerceProd: baseURL,
    tinyApiKey: process.env.REACT_TINY_API_KEY
});

export default config;
