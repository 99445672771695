import React from 'react';

export default function invoiceStatus(props) {

    const status = props.status;

    if(props.status === "RECEIVED"){
      return (
        <div className="badge badge-success badge-pill"> Recebido</div>
      );
    } else if(props.status === "PENDING"){
        return (
          <div className="badge badge-danger badge-pill"> Pendente </div>
        );
    } else if(props.status === "RECEIVED_IN_CASH"){
      return (
        <div className="badge badge-warning badge-pill"> Recebido via transferência </div>
      );
    }else {
          return (
              <span className="badge badge-danger badge-pill"> Pendente </span>
          );
      }
    
}
