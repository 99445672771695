import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link, Redirect } from 'react-router-dom';

import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPageAdmin';
import Footer from '../../../../components/Footer';
import api from '../../../../services/Users';
import ContractStatus from '../../../../components/Shared/Status';

// import woologo from '../../../../assets/images/woocommerce-logo.png'
export default class wcSettings extends Component {

  constructor(props) {
    
    const { match: { params } } = props;
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;
    
    super(props);
    this.state = {
      redirect: false,
      contractdata: [],
      authorization,
      userid,
      contractId: params.id
    };
  }

  loadContract(){
      const authorization = this.state.authorization;
      // const userid = this.state.userid;
  
      api.get(`/contract/${this.state.contractId}`,{
          headers: { authorization },
      })
      .then(res => {
          this.setState({ contractdata: res.data.contract });
      });
      
  }

  async handleRemove (itemId, mobcustomerid) {
    
    const authorization = this.state.authorization;

    await api.delete(`/contract/${itemId}`, {
      headers: { authorization, mobcustomerid },
    }).then(() => this.setState({ redirect: true }));
    console.log(this.state.redirect);
    

  }

  componentDidMount() {
    this.loadContract();
  }
    
    render(){

      if (this.state.redirect) {
        return <Redirect to={`/admin/contracts/${this.state.contractdata.userId}`} />
      }
        
      return (
        <>
          <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
          />

          <AuthPage />

          <div className="container-scroller">

            <HeaderAdminConsole />

            <div className="container-fluid page-body-wrapper" id="fscroll">
         <div className="main-panel">
           <div className="content-wrapper">
             <div className="row">
               <div className="col-12">
                 <div className="mt-4">
                   <div className="">
                     <div className="row">
                       
                       <div className="col-lg-8">
                               <div className="">
                                 <h3> {this.state.contractdata.productName}  </h3>

                                 <p className="text-grey mt-4"> 
                                    REF. Produto: {this.state.contractId} <br/>
                                    Desde:  <Moment format="DD/MM/YYYY" date={this.state.contractdata.createdAt}  />  <br/>
                                    Gerente da conta ou assessor comercial:  
                                      {this.state.contractdata.accountManager ? this.state.contractdata.accountManager : (' Nenhum gerente selecionado ')}  <br/>
                                    Status do contrato: 
                                    <strong>
                                      <ContractStatus status={this.state.contractdata.status} />
                                    </strong>
                                    <br/>
                                 </p>

                                 {this.state.contractdata.notes ? (
                                   <div>
                                    <h4 className="mt-4"> Notas do contrato </h4>

                                    <p className="text-grey mt-3 mb-4"> 
                                      {this.state.contractdata.notes}
                                    </p>

                                  </div>
                                 ) :('') }

                                 <h4 className="mt-4"> Custos e Taxas  </h4>

                                 <table className="table">
                                    <tbody>
                                        <tr>
                                            <td> Cartão de Crédito ( à vista ) </td>
                                            <td className="text-right"> <span className="text-grey"> {this.state.contractdata.taxCreditCartCash || "Taxas não configuradas"} % </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Cartao de Crédito Parcelado ( Até 12x ) </td>
                                            <td className="text-right"><span className="text-grey"> {this.state.contractdata.taxCreditCartInstallment || "Taxas não configuradas"} % </span> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Boleto Bancário ( Liquídado )</td>
                                            <td className="text-right"><span className="text-grey">R$ {this.state.contractdata.taxBankTicket || "Taxas não configuradas"} </span></td>
                                        </tr>
                                        <tr>
                                            <td> Custo Anti Fraude p/ Transação </td>
                                            <td className="text-right"><span className="text-grey">R$ {this.state.contractdata.taxAntiFraude || "Taxas não configuradas"} </span> </td>
                                        </tr>
                                        <tr>
                                            <td> Custo Transferência DOC/TED ( Bradesco s/ custo ) </td>
                                            <td className="text-right"><span className="text-grey"> R$ {this.state.contractdata.taxTransfer || "Taxas não configuradas"} </span> </td>
                                        </tr>
                                    </tbody>
                                </table>


                               </div>

                               
                      </div>
                       
                       <div className="col-md-4 text-right">

                          <div className="col-md-12 text-right">
                              <Link to={`/admin/contracts/${this.state.contractdata.userId}`} className="btn btn-twitter btn-sm text-left mb-4">
                                  Todos contratos do cliente 
                              </Link>
                          </div>

                          <div className="col-md-12 text-right mb-4">
                              <Link to={`/admin/contract/update/${this.state.contractId}/${this.state.contractdata.userId}`} className="btn btn-outline-twitter btn-sm">
                                  Editar este contrato
                              </Link>
                          </div>

                          {this.state.contractdata.productId !== "5f15d86de4a72cff0389bbae" && this.state.contractdata.status === "1" ? (
                            <>
                              
                              <div className="col-md-12 text-right mb-4">
                                  <Link to={`/admin/wcorders/${this.state.contractId}/${this.state.contractdata.userId}`} className="btn btn-outline-twitter btn-sm">
                                      Pedidos do Woocommerce
                                  </Link>
                              </div>
                              <div className="col-md-12 text-right mb-4">
                                  <Link to={`/admin/wcsettings/${this.state.contractId}/${this.state.contractdata.userId}`} className="btn btn-outline-twitter btn-sm">
                                      Configurações do Woocommerce
                                  </Link>
                              </div>
                            </>
                          ) : ''}

                          <div className="col-md-12 text-right ">
                              <Link to={`/admin/mobcustomer/${this.state.contractdata.userId}`} className="btn btn-outline-twitter btn-sm">
                                  Editar cadastro do cliente 
                              </Link>
                          </div>
                          
                       </div>

                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
       </div>
            <Footer />
        </div>



        </>
      )
    }
}