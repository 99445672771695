import React from 'react';

// import { Container } from './styles';

const d = new Date();
const year = d.getFullYear();

function FooterSlim() {
  return (
    <div className="col-lg-6 login-half-bg d-flex flex-row">
    </div>
  );
}

export default FooterSlim;