import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import Alerts from '../../../components/Alerts';
import Config from '../../../services/config';
import api from '../../../services/login';
// import PaymentStatusGtw1 from '../../ChargesComponents/PaymentStatusGtw1';
import PaymentStatusGtw1 from '../../AdminConsoleComponents/wcOrders/StatusPayment';

import './styles.css';

export default function ChargeDetailResume(props) {

  const orderId = props.orderId;
  const transactionID = props.orderId;
  const contractId = props.contractId;
  const userId = props.customerId;
  const [ paymentData, setpaymentData ] = useState();

  async function handleChargeback(){
    await Alerts("Estorno de Transação", `Sua transação ${transactionID}, para confirmar clique em  confirmar ` ,"warning");
  }

  useEffect(() => {
    
    window.scrollTo(0, 0);
    
    const authorization = Config.AuthApiSecretKey;

    async function loadData() {
          const response = await api.get(`wcOrderDetails/${contractId}/${orderId}`, {
              headers: { authorization, userId },
          })
          setpaymentData(response.data);
      }
      loadData();
    },[]);

  return (
    <>
     <div className="row">
        <div className="col-12">

            <div className="row mb-4 border-bottom">
                
               <div className="col-md-4 col-sm-12">
                      <h3 className="card-title"> 
                        <small> Pedido <span className="text-twitter"> 
                          {orderId}  </span> </small> <br/>
                      </h3>
                      <PaymentStatusGtw1 status={paymentData?.status || ""} />
                     
                </div>
                
                <div className="col-md-6 col-sm-12 text-left"> 

                  <div className="row">

                   <div className="col-md-6 col-sm-12 border-left">
                        <h3> R$ {paymentData?.total}  </h3>
                        <p className="text-grey mt-2">
                          Valor deste pedido <br/>
                          <span className="text-twitter"> {paymentData?.payment_method_title} </span>
                        </p>
                    </div>

                    <div className="col-md-6 col-sm-12 border-left">
                      <p className="">
                        <span className="text-dark title-trx"> Transação {paymentData?.transaction_id} </span> 
                      </p>
                      <p className="mt-2 text-grey">
                        Data do pedido: <Moment format="DD/MM/YYYY" date={paymentData?.date_created || ""} className="text-twitter"/>
                      </p>
                    </div>
                  </div>
                     
                </div>

                <div className="col-md-2 text-right"> 

                  <Link to={`/user/wcorders/${props?.contractId}/${userId}`} className="btn btn-outline-twitter btn-sm">
                      Todos pedidos  
                  </Link> 

                </div>

            </div>

            
            <div className="row">

                  <div className="col-md-8 col-sm-12">

                    <h4 className="text-dark text-weight-bolder mb-4 mt-4"> Dados do Cliente </h4>

                    <div className="row softGrey">

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> CLIENTE </p>
                          <p className="text-dark text-uppercase"> 
                            <strong> {paymentData?.billing.first_name} {paymentData?.billing.last_name} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> DOCUMENTO </p>
                          <p className="text-dark"> 
                            <strong> {paymentData?.billing.cpf} </strong> 
                            <strong> {paymentData?.billing.cnpj} </strong> 
                          </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> E-MAIL </p>
                          <p className="text-dark"> 
                            <strong> {paymentData?.billing.email} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> TELEFONE </p>
                          <p className="text-dark"> 
                            <strong> {paymentData?.billing.phone} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom text-uppercase"> Endereço do cliente </p>
                          <p className="text-dark"> 
                          <strong> {paymentData?.billing.address_1}, {paymentData?.billing.number} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom text-uppercase"> CEP </p>
                          <p className="text-dark"> 
                          <strong> {paymentData?.billing.postcode} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom text-uppercase"> Cidade </p>
                          <p className="text-dark"> 
                          <strong> {paymentData?.billing.city} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom text-uppercase"> Estado </p>
                          <p className="text-dark"> 
                          <strong> {paymentData?.billing.state} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> IP DO CLIENTE </p>
                          <p className="text-dark"> 
                            <strong> {paymentData?.customer_ip_address} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom text-uppercase"> Navegador do cliente </p>
                          <p className="text-dark"> 
                            <small> {paymentData?.customer_user_agent} </small> </p>
                      </div>

                    </div>
                    
                  </div>

                  <div className="col-md-4 col-sm-12">
                    <h4 className="text-dark text-weight-bolder mb-4 mt-4"> Histórico do pedido </h4>

                    <ul className="bullet-line-list">

                      <li>
                          <h6 className="text-grey text-uppercase"> Criado   </h6>
                          <p className="text-muted mb-4">
                              <Moment format="DD/MM/YYYY" date={paymentData?.date_created || ""} />
                          </p>
                      </li>

                      <li>
                          <h6 className="text-grey text-uppercase"> Atualizado  </h6>
                          <p className="text-muted mb-4">
                              <Moment format="DD/MM/YYYY" date={paymentData?.date_paid || ""} />
                          </p>
                      </li>

                      {paymentData?.date_paid ? (
                        <li>
                            <h6 className="text-grey text-uppercase"> Pago  </h6>
                            <p className="text-muted mb-4">
                                <Moment format="DD/MM/YYYY" date={paymentData?.date_paid || ""} />
                            </p>
                        </li>
                      ) : ''}

                    </ul>

                  </div>

            </div>

              <>

              <div className="row">

                <div className="col-md-8 col-sm-12">
                <h4 className="text-dark text-weight-bolder mb-4 mt-5"> Detalhes da Transação </h4>

                    <div className="row softGrey">

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom text-uppercase"> ID da transação </p>
                          <p className="text-dark"> 
                            <span className="text-uppercase"> <strong>{paymentData?.transaction_id} </strong> </span>
                          </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom text-uppercase"> Meio de Pagamento Escolhido </p>
                          <p className="text-dark"> 
                            <span className="text-uppercase"> <strong>{paymentData?.payment_method_title} </strong> </span>
                          </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark"> 
                            <Link to={`/user/transaction/${paymentData?.transaction_id}/${contractId}`} className="btn btn-outline-twitter btn-sm">
                                Detalhes da Transação
                            </Link> 
                          </p>
                      </div>

                    </div>
                </div>

              </div>

              <div className="row">

                <div className="col-md-8 col-sm-12">
                <h4 className="text-dark text-weight-bolder mb-4 mt-5"> Detalhes do Pedido </h4>

                    <div className="row clean-card ">

                        <div className="col-md-6 col-sm-12 mt-3">
                            <p className="text-dark border-bottom text-uppercase"> Total do pedido </p>
                            <p className="text-dark"> 
                              <span className="text-uppercase"> R$ <strong>{paymentData?.total} </strong> </span>
                            </p>
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3">
                            <p className="text-dark border-bottom text-uppercase"> Custo de entrega </p>
                            <p className="text-dark"> 
                              <span className="text-uppercase"> R$ <strong>{paymentData?.shipping_total} </strong> </span>
                            </p>
                        </div>

                        <div className="col-md-12 mt-3">
                          <h4 className="text-dark"> Ítens do pedido </h4>
                          <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>
                                    Ítem
                                  </th>
                                  <th>
                                    Quantidade
                                  </th>
                                  <th>
                                    Valor
                                  </th>
                                </tr>
                              </thead>
                          <tbody>
                            {paymentData?.line_items.map((item, index) => (
                                <tr key="index">
                                  <td className="py-1 text-dark">
                                    {item?.name} <br/>
                                    SKU: {item?.sku}
                                  </td>
                                  <td>
                                    {item?.quantity}
                                  </td>
                                  <td>
                                    R$ {item?.price}
                                  </td>
                                </tr>
                            ))}
                          </tbody>
                          </table>
                        </div>

                    </div>
                </div>

              </div>


                
              </>
            </div>
             
    </div>
    </>
  );
}
