import React from 'react';

export default function AntiFraudLevel(props) {

    const SwitchString = (str) => ({
      "low" : "Risco Baixo",
      "very_low" : "Risco Muito Baixo",
      "high" : "Risco Alto",
      "very_high" : "Risco Muito Alto",
      "unknown" : "Risco Desconhecido",
      "moderated" : "Risco Moderado",
    })[str] || '';

    let getString = props.level;

    return (
      <span className={`disabled`}>
        { SwitchString(getString)}
      </span>
  );

  } 