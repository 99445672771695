import React, { useEffect, useState } from 'react';

import Config from '../../../services/config';
import api from '../../../services/login';
import Moment from 'react-moment';

import './styles.css';

export default function TicketsReplies(props) {

  const updateList = props.update;
  const [ tickets, setTickets ] = useState([]);

  const replyId = props.replyId;
  

  useEffect(() => {
    
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    let ticketId = '';

    if( typeof ticketId !== 'undefined' ) {
      let ticketId = '';
      console.log(ticketId);
    }

    async function loadData() {
      try {
        const response = await api.get(`/ticket/reply/${replyId}`, {
          headers: { authorization, userid },
      });
      setTickets(response.data.tickets);
    } catch (error){
      // window.location='../404';
    }
  }
  loadData();
  },[updateList]);

  return (
    <>
      {tickets.map((ticket, index) => (
        <li className="d-flex profile-feed-item" key={ticket._id}>
          <div className="mb-1">
              <h6> 
                  {ticket.authorName}
                  <small className="ml-4 text-muted"><i className="ti-time mr-1"></i> 
                    <Moment format="DD/MM/YYYY hh:mm" date={ticket?.createdAt || ""} />
                  </small>
              </h6>
              <p className="text-grey">
                {ticket.description}
              </p>
          </div>
        </li>
      ))}
    </>
  );
}
