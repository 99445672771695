import React from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import ApiStatusResume from '../../../components/ApiStatus';

export default function ApiStatus() {
  return (
    <>
    <AuthPage />
    <div className="container-scroller">
        <Header />
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                <div className="content-wrapper">
                    <ApiStatusResume />
                </div>
            </div>
        </div>
        <Footer />
    </div>
    </>
  );
}
