import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Login from './pages/Login';
import Logout from './pages/Logout';
import signUp from './pages/SignUp';
import Main from './pages/Main';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordActivation from './pages/ResetPasswordActivation';
import About from './pages/About';
import WcOrders from './pages/MyAccount/WcOrders/List';

// User Account
import MyAccount from './pages/MyAccount';
import MyCompany from './pages/MyAccount/MyCompany';
import SuspendAccount from './pages/MyAccount/SuspendAccount';
import ChangeProfilePicture from './pages/MyAccount/ChangeProfilePicture';
import ChangeAddress from './pages/MyAccount/ChangeAddress/index';
import ChangePassword from './pages/MyAccount/ChangePassword';
import ChangeBank from './pages/MyAccount/ChangeBank';
import SignedContracts from './pages/MyAccount/SignedServices';
import Contracts from './pages/MyAccount/Contracts';
import Transfers from './pages/MyAccount/Transfers/Lists';
import Support from './pages/MyAccount/Support';
import SupportAdd from './pages/MyAccount/SupportAdd';
import SupportEdit from './pages/MyAccount/SupportEdit';
import Help from './pages/MyAccount/Help';
import ApiKeys from './pages/MyAccount/ApiKeys';
import AuditAccess from './pages/MyAccount/AuditAccess';
import WithDrawals from './pages/MyAccount/WithDrawals';
import WithDrawalsRequest from './pages/MyAccount/WithDrawalsRequest';
import DeveloperApiStatus from './pages/Developers/ApiStatus';
import Documentation from './pages/Developers/Documentation';
import AccountStatus from './pages/MyAccount/AccountStatus';
import ContractTransactions from './pages/MyAccount/Transactions/List';
import ContractTransactionDetail from './pages/MyAccount/Transactions/Details';
import Tutorials from './pages/MyAccount/Tutorials/List';
import TutorialsVideos from './pages/MyAccount/Tutorials/ListVideos';
import Backups from './pages/Backups/List';

//  Wordpress Features
import WordpressPostList from './pages/MyAccount/Wordpress/postList';
import WordpressPostUpdate from './pages/MyAccount/Wordpress/postUpdate';

// Push Notification Customers
import PushNotificationAdd from './pages/PushNotifications/Store';
import PushNotificationList from './pages/PushNotifications/List';
import PushContractsList from './pages/PushNotifications/Contracts';

// App Configuration
import appConfiguration from './pages/MyAccount/AppConfiguration';

// Apps General
// import EmailCheckPwn from './pages/EmailCheckPwn';

// Customers
import customerStore from './pages/Customers/Store';
import customerList from './pages/Customers/List';
import customerUpdate from './pages/Customers/Update';
import customerDetail from './pages/Customers/Detail';

// Payment Plataform
import Statements from './pages/Statement/Statements';
import StatementsReport from './pages/MyAccount/Statements/List';
import Transactions from './pages/Statement/Transactions';
import PaymentExpress from './pages/Pay/Express';
import PaymentExpressNext from './pages/Pay/Next';
import Charges from './pages/Charges/List';
import PaymentDetail from './pages/Charges/Detail';
import ChargeStore from './pages/Charges/Store';
import Pay from './pages/Pay';
import PayCheckout from './pages/Pay/Checkout';

// Donation Feature
import Given from './pages/Given/List';
import DonationDashboard from './pages/Donations/DonationDashboard';
import DonationCampaignsList from './pages/Donations/Campaigns/List';
import DonationCampaignsStore from './pages/Donations/Campaigns/Store';
import DonationCampaignsUpdate from './pages/Donations/Campaigns/Update';
import DonationCampaignsDetails from './pages/Donations/Campaigns/Details';
import DonationEventsList from './pages/Donations/Events/List';
import DonationEventsStore from './pages/Donations/Events/Store';
import DonationEventsUpdate from './pages/Donations/Events/Update';
import DonationEventsDetails from './pages/Donations/Events/Details';
import DonationEventsSubscribe from './pages/Donations/Events/Subscribe';   

// 3rd Party Youtube Search
import YoutubeSearchList from './pages/YoutubeSearchAds';   


// Publications
import PostList from './pages/Publications/Posts/List';
import PostStore from './pages/Publications/Posts/Store';
import PostUpdate from './pages/Publications/Posts/Update';
import PageList from './pages/Publications/DinacPages/List';
import PageStore from './pages/Publications/DinacPages/Store';
import PageUpdate from './pages/Publications/DinacPages/Update';
import VideoList from './pages/Publications/Videos/List';
import VideoStore from './pages/Publications/Videos/Store';
import VideoUpdate from './pages/Publications/Videos/Update';
import PhotoList from './pages/Publications/Photos/List';
import PhotoStore from './pages/Publications/Photos/Store';
import PhotoUpdate from './pages/Publications/Photos/Update';
import AudioList from './pages/Publications/Audios/List';
import AudioStore from './pages/Publications/Audios/Store';
import AudioUpdate from './pages/Publications/Audios/Update';

// Admin Console
import adminConsole from './pages/adminConsole/Main';
import adminConsoleUsersList from './pages/adminConsole/Users/List';
import adminConsoleUserStore from './pages/adminConsole/Users/Store';
import adminConsoleUserUpdate from './pages/adminConsole/Users/Update';
import adminConsoleCustomerList from './pages/adminConsole/Customers/List';
import adminConsoleCustomerList2 from './pages/Customers/Datatable';
import adminConsoleCustomerStore from './pages/adminConsole/Customers/Store';
import adminConsoleCustomerUpdate from './pages/adminConsole/Customers/Update';
import AdminConsoleTickets from './pages/adminConsole/Tickets/List';
import AdminConsoleTicketDetails from './pages/adminConsole/Tickets/Details';
import AdminCustomerAllContracts from './pages/adminConsole/allContracts';

import AdminCustomerContracts from './pages/adminConsole/Contracts/List';
import AdminCustomerContract from './pages/adminConsole/Contracts/Details';
import AdminCustomerContractStore from './pages/adminConsole/Contracts/Store';
import AdminCustomerContractUpdate from './pages/adminConsole/Contracts/Update';
import AdminTransactionList from './pages/adminConsole/Transactions/List';
import AdminTransactionDetail from './pages/adminConsole/Transactions/Details';
import AdminTransfers from './pages/adminConsole/Transfers/List';

// Admin Console Products
import adminConsoleProductsUpdate from './pages/adminConsole/Products/List';
import adminConsoleProductsStore from './pages/adminConsole/Products/Store';
import adminConsoleProductUpdate from './pages/adminConsole/Products/Update';
import adminConsoleTutorials from './pages/adminConsole/Tutorials/List';
import adminConsoleTutorialStore from './pages/adminConsole/Tutorials/Store';
import adminConsoleTutorialUpdate from './pages/adminConsole/Tutorials/Update';
import adminConsoleTutorialVideosList from './pages/adminConsole/TutorialsVideos/List';
import adminConsoleTutorialVideosStore from './pages/adminConsole/TutorialsVideos/Store';
import adminConsoleTutorialVideosUpdate from './pages/adminConsole/TutorialsVideos/Update';
import adminConsolewcOrders from './pages/adminConsole/wcOrders/List';
import adminConsolewcOrderDetails from './pages/adminConsole/wcOrders/Details';
import WcOrdersDetails from './pages/MyAccount/WcOrders/Details';
import wcsettings from './pages/adminConsole/wcSettings';
import wpStatus from './pages/MyAccount/WpStatus';
import Invoices from './pages/MyAccount/Invoices/List';
import CommerceOrdersList from './pages/Commerce/List';
import CommerceOrdersDetail from './pages/Commerce/Detail';
import CommerceMain from './pages/Commerce/CommerceMain';
import CommerceProductsCategories from './pages/Commerce/ProductsCategoriesList';
import CommerceProductsCategoryDetail from './pages/Commerce/ProductsCategoryDetails';
import CommerceProductsCategoryAdd from './pages/Commerce/ProductCategoriesAdd';
import CommerceProducts from './pages/Commerce/ProductsList';
import CommerceProductUpdate from './pages/Commerce/ProductsUpdate';
import CommerceProductAdd from './pages/Commerce/ProductsAdd';
import CmsMain from './pages/CMS/CmsMain';
import CmsCategoriesAdd from './pages/CMS/CategoriesAdd';
import CmsCategoriesList from './pages/CMS/CategoriesList';
import CmsCategoriesUpdate from './pages/CMS/CategoriesUpdate';
import CmsPagesAdd from './pages/CMS/PagesAdd';
import CmsPagesList from './pages/CMS/PagesList';
import CmsPagesUpdate from './pages/CMS/PagesUpdate';
import CmsPostsAdd from './pages/CMS/PostAdd';
import CmsPostsList from './pages/CMS/PostList';
import CmsPostsUpdate from './pages/CMS/PostUpdate';
import CmsSuperEditor from './pages/CMS/SuperEditor';
import emailsCreate from './pages/Emails/Create';
import emailsList from './pages/Emails/List';
import emailsDetails from './pages/Emails/Details';

// import onBoard from './pages/onBoard';
// 404 Page
import NoMatchPage from './pages/NoMatch404Page';

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>

                {/* Customer Routes */}
                <Route path="/"  exact component={Login} />
                <Route path="/signup" component={signUp} />
                <Route path="/main" component={Main} />
                <Route path="/reset/password" exact component={ResetPassword} />
                <Route path="/passwordReset/:id" component={ResetPasswordActivation} />
                <Route path="/ApiKeys" component={ApiKeys} />
                <Route path="/account/status" exact component={AccountStatus} />
                <Route path="/user/backups" exact component={Backups} />
                <Route path="/user/account" exact component={MyAccount} />
                <Route path="/user/access" component={AuditAccess} />
                <Route path="/apiStatus" exact component={DeveloperApiStatus} />
                <Route path="/user/account/thumbnail" component={ChangeProfilePicture} />
                <Route path="/user/account/address" exact component={ChangeAddress} />
                <Route path="/user/account/password" exact component={ChangePassword} />
                <Route path="/user/account/bank" exact component={ChangeBank} />
                <Route path="/user/account/company" exact component={MyCompany} />
                <Route path="/user/contracts" exact component={SignedContracts} />
                <Route path="/user/statments/:id" exact component={StatementsReport} />
                <Route path="/user/contract/:id" exact component={Contracts} />
                <Route path="/user/transfers/:id" exact component={Transfers} />
                <Route path="/user/transactions/:id" exact component={ContractTransactions} />
                <Route path="/user/transaction/:id/:contractId" exact component={ContractTransactionDetail} />
                <Route path="/user/account/withdrawals" exact component={WithDrawals} />
                <Route path="/user/account/suspend" component={SuspendAccount} />
                <Route path="/user/tickets" exact component={Support} />
                <Route path="/user/app-configuration/:contractId" exact component={appConfiguration} />
                <Route path="/user/ticket/store" exact component={SupportAdd} />
                <Route path="/user/ticket/:id" exact component={SupportEdit} />
                <Route path="/user/wpstatus/:contractId/:customerId" exact component={wpStatus} />
                <Route path="/user/tutorials" exact component={Tutorials} />
                <Route path="/user/tutorials/videos/:id" exact component={TutorialsVideos} />
                <Route path="/withDrawalsRequest" exact component={WithDrawalsRequest} />
                <Route path="/documentation" exact component={Documentation} />
                <Route path="/statements" exact component={StatementsReport} />
                <Route path="/transactions" exact component={Transactions} />
                <Route path="/donationDashboard" exact component={DonationDashboard} />
                <Route path="/campaigns" exact component={DonationCampaignsList} />
                <Route path="/campaign/store" exact component={DonationCampaignsStore} />
                <Route path="/campaign/update/:id" exact component={DonationCampaignsUpdate} />
                <Route path="/campaign/details/:id" exact component={DonationCampaignsDetails} />
                <Route path="/events" exact component={DonationEventsList} />
                <Route path="/event/store" exact component={DonationEventsStore} />
                <Route path="/event/update/:id" exact component={DonationEventsUpdate} />
                <Route path="/event/details/:id" exact component={DonationEventsDetails} />
                <Route path="/event/subscribe/:id" exact component={DonationEventsSubscribe} />
                <Route path="/posts" exact component={PostList} />
                <Route path="/post/store" exact component={PostStore} />
                <Route path="/post/update/:id" exact component={PostUpdate} />
                <Route path="/pages" exact component={PageList} />
                <Route path="/page/store" exact component={PageStore} />
                <Route path="/page/update/:id" exact component={PageUpdate} />
                <Route path="/videos" exact component={VideoList} />
                <Route path="/video/store" exact component={VideoStore} />
                <Route path="/video/update/:id" exact component={VideoUpdate} />
                <Route path="/photos" exact component={PhotoList} />
                <Route path="/photo/store" exact component={PhotoStore} />
                <Route path="/photo/update/:id" exact component={PhotoUpdate} />
                <Route path="/audios" exact component={AudioList} />
                <Route path="/audio/store" exact component={AudioStore} />
                <Route path="/audio/update/:id" exact component={AudioUpdate} />
                <Route path="/tools/youtube-search" exact component={YoutubeSearchList} />
                {/* <Route path="/onboard" exact component={onBoard} /> */}

                {/* Emails Manage */}
                <Route path="/user/emails/store" exact component={emailsCreate} />
                <Route path="/user/emails/list" exact component={emailsList} />
                <Route path="/user/emails/details/:id" exact component={emailsDetails} />

                {/* User Invoices */}
                <Route path="/user/invoices/:id/:page" exact component={Invoices} />

                {/* Wordpress Features */}
                <Route path="/user/wp-posts/:contractId/:page" exact component={WordpressPostList} />
                <Route path="/user/wp-post-update/:contractId/:id" exact component={WordpressPostUpdate} />

                {/* Push Notification */}
                <Route path="/user/push/:contractId" exact component={PushNotificationAdd} />
                <Route path="/user/push-campaigns/:contractId" exact component={PushNotificationList} />
                <Route path="/user/contracts/push" exact component={PushContractsList} />

                {/* Payments */}
                <Route path="/payments" exact component={Charges} />
                <Route path="/pay/:id" exact component={Pay} />
                <Route path="/pay/checkout/:id" exact component={PayCheckout} />
                <Route path="/charges/store/:id" exact component={ChargeStore} />
                <Route path="/payment/detail/:id" exact component={PaymentDetail} />
                <Route path="/payment/express" exact component={PaymentExpress} />
                <Route path="/payment/express/next" exact component={PaymentExpressNext} />
                <Route path="/user/wcorders/:contractId/:customerId" exact component={WcOrders} />
                <Route path="/user/wcorder/:contractId/:orderId/:customerId" exact component={WcOrdersDetails} />

                {/* Given */}
                <Route path="/given" exact component={Given} />

                {/* Cms */}
                <Route path="/user/cms/main" exact component={CmsMain} />
                <Route path="/user/cms/categories/add" exact component={CmsCategoriesAdd} />
                <Route path="/user/cms/categories" exact component={CmsCategoriesList} />
                <Route path="/user/cms/categories/:id" exact component={CmsCategoriesUpdate} />
                <Route path="/user/cms/pages/add" exact component={CmsPagesAdd} />
                <Route path="/user/cms/pages" exact component={CmsPagesList} />
                <Route path="/user/cms/pages/:id" exact component={CmsPagesUpdate} />
                <Route path="/user/cms/posts/add" exact component={CmsPostsAdd} />
                <Route path="/user/cms/posts" exact component={CmsPostsList} />
                <Route path="/user/cms/posts/:id" exact component={CmsPostsUpdate} />
                <Route path="/user/cms/post/editor/:id" exact component={CmsSuperEditor} />

                {/* Commerce */}
                <Route path="/user/commerce/main/:contractId" exact component={CommerceMain} />
                <Route path="/user/commerce/orders/:id" exact component={CommerceOrdersList} />
                <Route path="/user/commerce/order/:orderId/:contractId" exact component={CommerceOrdersDetail} />
                <Route path="/user/commerce/products-categories/:contractId" exact component={CommerceProductsCategories} />
                <Route path="/user/commerce/products-category-add/:contractId" exact component={CommerceProductsCategoryAdd} />
                <Route path="/user/commerce/category/:id/:contractId" exact component={CommerceProductsCategoryDetail} />
                <Route path="/user/commerce/products/:contractId" exact component={CommerceProducts} />
                <Route path="/user/commerce/product/:id/:contractId" exact component={CommerceProductUpdate} />
                <Route path="/user/commerce/product-add/:contractId" exact component={CommerceProductAdd} />

                {/* Admin Routes */}
                <Route path="/customers" exact component={customerList} />
                <Route path="/customer/store" exact component={customerStore} />
                <Route path="/customer/:id" exact component={customerDetail} />
                <Route path="/customer/update/:id" exact component={customerUpdate} />
                <Route path="/logout" exact component={Logout} />
                <Route path="/help" exact component={Help} />
                <Route path="/about" exact component={About} />

                {/*  Admin Console */}
                <Route path="/admin/main" exact component={adminConsole} />
                <Route path="/admin/customers" exact component={adminConsoleCustomerList} />
                <Route path="/admin/mobcustomer/store" exact component={adminConsoleCustomerStore} />
                <Route path="/admin/mobcustomer/:id" exact component={adminConsoleCustomerUpdate} />
                <Route path="/admin/users" exact component={adminConsoleUsersList} />
                <Route path="/admin/tickets" exact component={AdminConsoleTickets} />
                <Route path="/admin/ticket/:id" exact component={AdminConsoleTicketDetails} />
                <Route path="/admin/contracts" exact component={AdminCustomerAllContracts} />
                <Route path="/admin/contracts/:id" exact component={AdminCustomerContracts} />
                <Route path="/admin/contract/:id" exact component={AdminCustomerContract} />
                <Route path="/admin/contract/store/:id" exact component={AdminCustomerContractStore} />
                <Route path="/admin/contract/update/:id/:userId" exact component={AdminCustomerContractUpdate} />
                <Route path="/admin/user/store" exact component={adminConsoleUserStore} />
                <Route path="/admin/user/:id" exact component={adminConsoleUserUpdate} />
                <Route path="/adminConsoleCustomer/store" exact component={adminConsoleCustomerStore} />
                <Route path="/admin/products" exact component={adminConsoleProductsUpdate} />
                <Route path="/admin/product/store" exact component={adminConsoleProductsStore} />
                <Route path="/admin/product/:id" exact component={adminConsoleProductUpdate} />
                <Route path="/admin/transactions/:id/:customerId" exact component={AdminTransactionList} />
                <Route path="/admin/transaction/:id/:contractId/:customerId" exact component={AdminTransactionDetail} />
                <Route path="/admin/tutorials" exact component={adminConsoleTutorials} />
                <Route path="/admin/tutorial/store" exact component={adminConsoleTutorialStore} />
                <Route path="/admin/tutorial/update/:id" exact component={adminConsoleTutorialUpdate} />
                <Route path="/admin/tutorial/videos/:id" exact component={adminConsoleTutorialVideosList} />
                <Route path="/admin/tutorial/video/store/:id" exact component={adminConsoleTutorialVideosStore} />
                <Route path="/admin/tutorial/video/update/:id/:video" exact component={adminConsoleTutorialVideosUpdate} />
                <Route path="/admin/wcorders/:contractId/:customerId" exact component={adminConsolewcOrders} />
                <Route path="/admin/wcorder/:contractId/:orderId/:customerId" exact component={adminConsolewcOrderDetails} />
                <Route path="/admin/wcsettings/:contractId/:customerId" exact component={wcsettings} />
                <Route path="/admin/transfers/:id/:customerId" exact component={AdminTransfers} />

                <Route component={NoMatchPage} />
                {/* <Route path="/admin/customers2" exact component={adminConsoleCustomerList2} /> */}

            </Switch>
        </BrowserRouter>
    );
}