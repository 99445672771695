import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Moment from 'react-moment';

import Config from '../../services/config';
import api from '../../services/login';

const getFromCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
};

export default function ProductCategoriesList(props) {
  const [categories, setCategories] = useState([]);
  const [appAuthorization, setAppAuthorization] = useState('');
  const [appUserId, setAppUserId] = useState('');
  const [loading, setLoading] = useState(true);

  const id = props.catId;
  // const contractId = props?.contract;
  const contractId = getFromCookie('selectedContractId');
  const userId = props.user;

  const fetchData = async () => {
    try {
      const authorization = Config.AuthApiSecretKey;
      const credencialdata = JSON.parse(localStorage.getItem('credencialData'));
      const userid = credencialdata?._id;

      setAppAuthorization(authorization);
      setAppUserId(userid);

      console.log(`contract id: ${contractId}`)

      const response = await api.get(`/user/cms/categories`, {
        headers: {
          authorization,
          userId,
          contractid: contractId,
        },
      });

      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [contractId, userId]);

  const handleRemove = async (itemId, index) => {
    try {
      const response = await api.delete(`/user/cms/categories/${itemId}`, {
        headers: {
          authorization: appAuthorization,
          userid: appUserId,
          contractid: contractId,
        },
      });

      setCategories((prevCategories) => prevCategories.filter((_, i) => i !== index));
    } catch (error) {
      console.error('Error removing item:', error);
      swal('Erro', 'Erro ao remover item', 'error');
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-md-6">
                  <h3 className="card-title"> Categorias </h3>
                  <p className="text-grey"> Aqui você gerencia as categorias de conteúdo para <br/>
                  Contrato {contractId}</p>
                </div>

                <div className="col-md-6">
                  <div className="template-demo text-right">
                    <Link to={`/user/cms/categories/add`} className="btn btn-outline-dark btn-sm">
                      Adicionar Categoria
                    </Link>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th> Categoria </th>
                        <th> Criado em: </th>
                        <th className="text-center"> Ações </th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories?.length > 0 ? (
                        categories.map((category, index) => (
                          <tr key={category._id}>
                            <td>
                              <Link to={`/user/cms/categories/${category.id}`} className="text-grey">
                                <span className="text-grey">
                                  <strong className="text-dark"> {category.title} </strong> <br />
                                </span>
                              </Link>
                              <small>
                                ID: {category.id} <br />
                                Status: {category?.status}
                              </small>
                            </td>
                            <td>
                              <Moment format="DD/MM/YYYY hh:mm:ss" date={category.createdAt} />
                            </td>
                            <td className="text-right">
                              <Link to={`/user/cms/categories/${category.id}`} className="btn btn-outline-light btn-sm">
                                Editar
                              </Link>{' '}
                              &nbsp;
                              <button onClick={() => handleRemove(category._id, index)} className="remoteItemBt">
                                <i className="ti-close text-danger btn-icon-prepend ml-3"></i>
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">Não há dados para exibir aqui.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
