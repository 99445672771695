import React from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Loader from 'react-loader-spinner';
import Config from '../../../services/config';
import ProductsList from  '../../../components/CommerceProductsList';

export default function Given(props) {

  const { match: { params } } = props;
  const id = params.contractId

  return (
    <>
        <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <div className="container-scroller">
            <Header />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                    <div className="content-wrapper">
                        <ProductsList contract={id} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>
  );
}
