import React, { Component } from 'react';
import Config from '../../services/config';
import api from '../../services/Users';

export default class ApiKeysResume extends Component {

  // 
  constructor(props) {

    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    super(props);
    this.state = {
      userdata: {},
      authorization,
      userid
    };
  }

  componentDidMount() {
    
    const authorization = this.state.authorization;
    const userid = this.state.userid;

    api.get('/user/detail',{
        headers: { authorization, userid },
    })
    .then(res => {
        this.setState({ userdata: res.data.user });
        console.log(res.data.user);
    });

  }
  // 

  render() {
    return (
      <>
        <div className="row">
              <div className="col-12 mb-20 mt-20">

                <div className="row">
                  <div className="col-md-12">
                      <h3 className="card-title"> API Keys </h3>
                      <p className="text-grey"> As chaves serão adicionadas neste painel automáticamente na medida que contratar novos serviços. </p>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                          <th> Chave de Transação Pagefy </th>
                          <th> Criando em</th>
                          <th className="text-right">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td className="text-grey"> {this.state.userdata.apiMobKey || ""} </td>
                          <td> {this.state.userdata.createdAt || ""} </td>
                          <td className="text-right">
                            <label className="badge badge-success"> ATIVO </label>
                          </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
      </div>
      </>
    );
  }
}
