import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link, Redirect } from 'react-router-dom';

import HeaderAdminConsole from '../../../components/HeaderAdminConsole';
import Config from '../../../services/config';
import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import api from '../../../services/Users';
import ContractStatus from '../../../components/Shared/Status';

import woologo from '../../../assets/images/woocommerce-logo.png'
import './styles.css';

export default class Contracts extends Component {

  constructor(props) {
    
    const { match: { params } } = props;
    const authorization = Config.AuthApiSecretKey;
    
    super(props);
    this.state = {
      redirect: false,
      settingsdata: [],
      authorization,
      customerId: params.customerId,
      contractId: params.contractId,
      pluginsdata: []
    };
  }

  loadContract(){
      const authorization = this.state.authorization;
  
      api.get(`/wcSettings/${this.state.contractId}`,{
          headers: { authorization, userid: `${this.state.customerId}` },
      })
      .then(res => {
        this.setState({ settingsdata: [res.data] });
        this.setState({ pluginsdata: this.state.settingsdata[0].active_plugins });
      });
      
  }

  async handleRemove (itemId, mobcustomerid) {
    
    const authorization = this.state.authorization;

    await api.delete(`/contract/${itemId}`, {
      headers: { authorization, mobcustomerid },
    }).then(() => this.setState({ redirect: true }));
    console.log(this.state.redirect);

  }

  componentDidMount() {
    this.loadContract();
  }
    
    render(){

      if (this.state.redirect) {
        return <Redirect to={`/admin/contracts/${this.state.settingsdata.userId}`} />
      }
        
      return (
        <>
          <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
          />

          <AuthPage />

          <div className="container-scroller">

            <HeaderAdminConsole />

            <div className="container-fluid page-body-wrapper" id="fscroll">
                <div className="main-panel">
           <div className="content-wrapper">
             <div className="row">
               <div className="col-12">
                 <div className="mt-4">
                   <div className="">
                     <div className="row">
                       
                       <div className="col-lg-8">
                               <div className="">

                                 <div className="border-bottom mb-5">

                                  <img src={woologo} alt="Woocommerce Branding" className="col-md-12 branding" />

                                  <p className="text-grey mt-3"> 
                                      Contract ID: {this.state.contractId}
                                  </p>
                                 </div>

                                <h4 className="text-grey text-uppercase mb-4"> <strong> Configurações do Site </strong> </h4>

                                 <table className="table">
                                    <tbody>
                                        <tr>
                                            <td className="text-grey"> Endereço do Site </td>
                                              <td className="text-right"> 
                                                <span className="text-grey"> 
                                                  <a href={this.state.settingsdata[0]?.environment.home_url || ''} target="_blank"> {this.state.settingsdata[0]?.environment.home_url || "Dados inexistente"} </a>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-grey"> Tamanho Máximo para Upload </td>
                                            <td className="text-right"> <span className="text-grey"> {Math.round(this.state.settingsdata[0]?.environment.max_upload_size/1024) || "Dados inexistente"}  MB </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-grey"> Versão do Banco de Dados MySQL </td>
                                            <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.environment.mysql_version) || "Dados inexistente"} </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-grey"> Versão do PHP </td>
                                            <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.environment.php_version) || "Dados inexistente"} </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-grey"> Linguagem Padrão do Wordpress </td>
                                            <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.environment.language) || "Dados inexistente"} </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-grey"> Servidor Web </td>
                                            <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.environment.server_info) || "Dados inexistente"} </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-grey"> Versão do Wordpress </td>
                                            <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.environment.wp_version) || "Dados inexistente"} </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-grey"> Forçar Conexão Segura SSL  </td>
                                            <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.settings.force_ssl === true ? 'Sim' : 'Não') || "Dados inexistente"} </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-grey"> SSL Habilitado  </td>
                                            <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.settings.currency) || "Dados inexistente"} </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h4 className="text-grey text-uppercase mb-4 mt-4"> <strong> Banco de Dados </strong> </h4>

                                 <table className="table">
                                    <tbody>
                                        <tr>
                                            <td className="text-grey"> Tamanho do Banco de Dados </td>
                                            <td className="text-right"> <span className="text-grey"> {Math.round(this.state.settingsdata[0]?.database.database_size.data) || "Dados inexistente"}  MB </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-grey"> Prefix da base de dados </td>
                                            <td className="text-right"> <span className="text-grey"> {Math.round(this.state.settingsdata[0]?.database.database_prefix) || "Dados inexistente"}  MB </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h4 className="text-grey text-uppercase mt-4"> <strong> Plugins Instalados </strong> </h4>
                                <p className="text-grey mb-4"> Abaixo você encontra todos os plugins que estão instalados neste website </p>

                                 <table className="table">
                                    <tbody>
                                        {/* {this.state.settingsdata[0]?.active_plugins.map(plugin, index)} */}
                                        {/* {this.state.settingsdata[0]?.active_plugins.map(plugin, index) => ( */}
                                          {this.state.pluginsdata.map((plugin, index) => (
                                          <tr key={index}>
                                              <td className="text-grey"> 
                                                <strong> {plugin?.name} </strong> <br/>
                                                Versão: {plugin?.version} / {plugin?.version_latest} <br/>
                                                <a href={plugin?.url} className="text-twitter" target="_blank"> site do plugin </a> 
                                              </td>
                                              <td className="text-right text-grey"> 

                                                <br/>
                                                { plugin?.version < plugin?.version_latest ? (
                                                  <div class="badge badge-outline-danger badge-pill"> 
                                                    Desatualizado </div>
                                                ) : (
                                                  <div class="badge badge-outline-success badge-pill"> 
                                                    Atualizado </div>
                                                )}
                                              </td>
                                          </tr>
                                          ))}
                                         
                                    </tbody>
                                </table>


                               </div>

                               
                      </div>
                       
                       <div className="col-md-4 text-right">

                          <div className="col-md-12 text-right">
                              <Link to={`/admin/contract/${this.state.contractId}`} className="btn btn-outline-twitter btn-sm text-left mb-4">
                                  Detalhes do Contrato
                              </Link>
                          </div>

                       </div>

                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
            </div>
            <Footer />
        </div>



        </>
      )
    }
}