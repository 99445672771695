import React from 'react';
import Loader from 'react-loader-spinner';

import Config from '../../../services/config';
import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import ChargeStoreComponent from  '../../../components/ChargesComponents/Store';

export default function Store(props) {

  const { match: { params } } = props;
  console.log(params.id);

  return (
    <>
      <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner" //3 secs
      />
      <AuthPage />
      <div className="container-scroller">
          <Header />
              <div className="container-fluid page-body-wrapper">
                  <div className="main-panel">
                      <div className="content-wrapper">
                          <ChargeStoreComponent customerId={params.id} />
                      </div>
                  </div>
              </div>
          <Footer />
      </div>
    </>
  );
}
