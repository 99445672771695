import React from 'react';
import { Link } from 'react-router-dom';

import Alerts from '../../../components/Alerts/index';
import TicketStatus from '../../../components/Shared/TicketStatus';

export default function EventsSubscribe({match}) {

  const transactionID = '1111';
  const eventID = '1';
  const subscriberEmail = 'jaccon@gmail.com';
  const ticketStatus = 'Checkin não efetuado';

  async function handleChargeback(){
    await Alerts("Check In", `O Ingresso ${transactionID}, foi utilizado ` ,"success");
  }

  async function handleCancelSubscribe(){
    await Alerts("Cancelamento", `O Ingresso ${transactionID}, foi cancelado ` ,"warning");
  }

  async function handleSendTicket(){
    await Alerts("Reenvio do Ticket", `O Ticket do Ingresso ${transactionID}, foi reenviado para ${subscriberEmail} com sucesso ` ,"success");
  }

  return (
    <>
    <div className="row">
        <div className="col-12">
            <div className="row mb-4">
                
                <div className="col-md-6">
                      <h3 className="card-title"> Inscrição #{transactionID} </h3>
                      <p className="text-muted"> Inscrição efetuada em: 25/12/2019 as 20:42 <br/> 
                        <TicketStatus ticketStatus={ticketStatus} /> 
                      </p>
                </div>
                <div className="col-md-6 text-right">
                    
                    <Link to={`/event/details/${eventID}`}>
                        <button 
                        className="btn btn-outline-twitter"
                        > Voltar para Detalhes do Evento </button>
                    </Link>

                </div>

            </div>

            <div className="row">

                <div className="col-12 col-sm-4 col-md-12 col-xl-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        
                        <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-12">
                                  <h4 className="text-twitter mt-3">
                                    Dados da Inscrição
                                  </h4>
                              </div>
                              <div className="col-md-12">
                                  <div className="block-customer-data">
                                  <div className="table-responsive">
                                      <table className="table table-borderless">
                                        <tbody>
                                          <tr>
                                            <td className="font-weight-bold dataHeader"> Cliente </td>
                                            <td className="text-right text-muted"> IndieWorks Tecnologia </td>
                                          </tr>
                                          <tr>
                                            <td className="font-weight-bold dataHeader"> CPF/CNPJ </td>
                                            <td className="text-right text-muted"> 
                                              08.078.195/0001-02
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="font-weight-bold dataHeader"> E-mail </td>
                                            <td className="text-right text-muted"> 
                                              jaccon@gmail.com
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="font-weight-bold dataHeader"> Telefone </td>
                                            <td className="text-right text-muted"> 
                                              (11) 95569-6541
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="font-weight-bold dataHeader"> Endereço </td>
                                            <td className="text-right text-muted"> 
                                              Rua Dom Estevão Pimente, 351 <br/> Bairro: Vila Formosa / Cidade: São Paulo / Estado: São Paulo
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                              </div>
                            </div>


                            <div className="row mt-3">
                              <div className="col-md-12">
                                  <h4 className="text-twitter mt-3">
                                    Pagamento
                                  </h4>
                              </div>
                              <div className="col-md-12">
                                  <div className="block-customer-data">
                                  <div className="table-responsive">
                                      <table className="table table-borderless customerData">
                                        <tbody>
                                          <tr>
                                            <td className="font-weight-bold dataHeader"> Meio de Pagamento </td>
                                            <td className="text-right text-muted"> Boleto Bancário </td>
                                          </tr>
                                          <tr>
                                            <td className="font-weight-bold dataHeader"> Status do Pagamento </td>
                                            <td className="text-right text-muted"> Pago </td>
                                          </tr>
                                          <tr>
                                            <td className="font-weight-bold dataHeader"> Link do Boleto </td>
                                            <td className="text-right text-muted"> https://www.asaas.com.br/in/boleto/Yuaiaolpuaj </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                              </div>
                            </div>

                            

                        </div>

                        <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-12">

                                    <div className="grid-margin stretch-card">
                                        <div>
                                            <div className="card-body">
                                                <h4 className="text-twitter mb-4"> QRCode </h4>
                                                <div className="qrcode-container text-left">
                                                    <img src="http://2.bp.blogspot.com/_7rmjMTW9xYQ/S-xAl9roWbI/AAAAAAAAASI/OTXx798Rx80/s1600/qrcode.png" 
                                                        alt="QrCode" className="col-md-12" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                              </div>
                            </div>
                           
                            <div className="text-left ml-3">
                              <button 
                                className="btn btn-outline-success ml-2"
                                onClick={handleChargeback}
                              >
                                Checkin do Ingresso </button>
                            </div>

                            <div className="text-left ml-3 mt-4">
                              <button 
                                className="btn btn-outline-twitter ml-2"
                                onClick={handleSendTicket}
                              >
                                Reenviar Ingresso </button>
                            </div>
                            <div className="text-left ml-3 mt-4">
                              <button 
                                className="btn btn-outline-danger ml-2"
                                onClick={handleCancelSubscribe}
                              >
                                Cancelar este ingresso </button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
</>
  );
}
