import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPageAdmin';
import Footer from '../../../../components/Footer';
import api from '../../../../services/Users';
import Alerts from '../../../../components/Alerts';

export default class Store extends Component {

    constructor(props) {

        const { match: { params } } = props;
        
        const authorization = Config.AuthApiSecretKey;
        
        super(props);
        this.state = {
            contractdata: {},
            productdata: [],
            authorization,
            userid: params.id
        };
    }

    loadProducts(){
          const authorization = this.state.authorization;
      
          api.get(`/admin/products`,{
              headers: { authorization },
          })
          .then(res => {
              this.setState({ productdata: res.data.products });
          });

    }

    handleChange = async (e) => {
        const state = this.state.contractdata
        state[e.target.name] = e.target.value;
        this.setState({contractdata:state});

        // Zip auto complete API
        const authorization = this.state.authorization;
        const userid = this.state.userid;

        if(e.target.name === 'addrZip'){
        
        const queryZip = e.target.value

        if(queryZip.length >= 8){
            const response = await api.post('/user/address/zip', {
                zip: queryZip, 
            },{
            headers : { authorization, userid }
            });

            state.addr = response.data.logradouro;
            state.addrNeighborhood = response.data.bairro;
            state.addrCity = response.data.localidade;
            state.addrState = response.data.uf;
            this.setState({contractdata:state});

        }
        }
     // Zip auto complete API

    }

    onSubmit = async (e) => {
    
        e.preventDefault();
        const authorization = this.state.authorization;
        const mobcustomerid = this.state.userid;

        const { personType,
            productId,
            accountManager,
            taxCreditCartCash,
            taxCreditCartInstallment,
            taxBankTicket,
            taxAntiFraude,
            taxTransfer,
            transactionGtw, 
            transactionGtwKey,
            notes,
            domainUrl,
            wcConsumerKey, 
            wcConsumerSecret
            } = this.state.contractdata;
    
        const response = await api.post('/contracts', {
            personType,
            productId,
            accountManager,
            taxCreditCartCash,
            taxCreditCartInstallment,
            taxBankTicket,
            taxAntiFraude,
            taxTransfer,
            transactionGtw, 
            transactionGtwKey,
            notes,
            domainUrl,
            wcConsumerKey, 
            wcConsumerSecret
          }, {
            headers: { authorization, mobcustomerid }
          });
    
          if(response.data.cb !== "error"){
              Alerts("Contrato adicionado", "O contrato foi adicionado com sucesso ","success");
        } else {
              Alerts("Contrato adicionado", response.data.message ,"error");
            //   history.push('/');
          }
      
      }

      componentDidMount() {
        this.loadProducts();
      }

  render() {
    return (
        <>
           <AuthPage />
           <div className="container-scroller">
            <HeaderAdminConsole />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                            <div className="row">

                                <div className="col-md-12">

                                    <div className="row">

                                            <div className="col-md-8">

                                                <div className="row">
                                                      <div className="col-md-12">
                                                          <form onSubmit={this.onSubmit}>

                                                              <h3 className="mb-4 mt-4"> Adicionar Contrato </h3>

                                                              <div className="form-group mt-4">
                                                                  <label htmlFor="personType"> Selecionar o Produto * </label>
                                                                  <select 
                                                                    id="productId"
                                                                    name="productId"
                                                                    value={this.state.contractdata.productId || ""}
                                                                    onChange={this.handleChange}
                                                                    className="form-control">
                                                                        <option value="0" defaultValue> Selecione o produto</option>
                                                                        {this.state.productdata.map((product, index) => (
                                                                            <option value={product._id} key={product._id}> {product.title} </option>
                                                                        ))}

                                                                    </select>
                                                              </div>

                                                              <p className="text-twitter  mb-4 mt-4"> 
                                                                Informações Comerciais ( Contrato, Taxas e Custos de Transação ) </p>

                                                              <div className="form-group">
                                                                  <label htmlFor="addrComplement"> Gerente de Conta <small>*( Caso não tenha deixe em branco ) </small></label>
                                                                  <input 
                                                                      placeholder="ID do gerente de contas... "
                                                                      name="accountManager"
                                                                      id="accountManager"
                                                                      type="text" 
                                                                      className="form-control"
                                                                      value={this.state.contractdata.accountManager || ""} 
                                                                      onChange={this.handleChange}
                                                                      />
                                                              </div>

                                                              <div className="form-group">
                                                                    <label htmlFor="addrComplement"> Domínio do projeto </label>
                                                                    <input 
                                                                        placeholder="insira o apenas o domínios ex: Pagefy.com.br ... "
                                                                        name="domainUrl"
                                                                        id="domainUrl"
                                                                        type="text" 
                                                                        className="form-control"
                                                                        value={this.state.contractdata.domainUrl || ""} 
                                                                        onChange={this.handleChange}
                                                                        />
                                                                </div>

                                                              <div className="row">
                                                                  <div className="col-md-6">
                                                                      <div className="form-group">
                                                                          <label htmlFor="taxCreditCart"> Cartão de Crédito à vista ( % ) * </label>
                                                                          <input 
                                                                              placeholder="Taxa do cartão de crédito à vista"
                                                                              name="taxCreditCartCash"
                                                                              id="taxCreditCartCash"
                                                                              type="text" 
                                                                              className="form-control"
                                                                              value={this.state.contractdata.taxCreditCartCash || ""}
                                                                              onChange={this.handleChange}
                                                                              />
                                                                      </div>
                                                                  </div>

                                                                  <div className="col-md-6">
                                                                  <div className="form-group">
                                                                          <label htmlFor="taxCreditCart"> Cartão de Crédito Parcelado até 12x  ( % ) * </label>
                                                                          <input 
                                                                              placeholder="Taxa do cartão de crédito parcelado"
                                                                              name="taxCreditCartInstallment"
                                                                              id="taxCreditCartInstallment"
                                                                              type="text" 
                                                                              className="form-control"
                                                                              value={this.state.contractdata.taxCreditCartInstallment || ""}
                                                                              onChange={this.handleChange}
                                                                          />
                                                                      </div>
                                                                  </div>

                                                              </div>

                                                              <div className="row">
                                                                  <div className="col-md-6">
                                                                      <div className="form-group">
                                                                          <label htmlFor="taxCreditCart"> Boleto Bancário ( R$ ) * </label>
                                                                          <input 
                                                                              placeholder="Custo do boleto bancário ( Liquidado )"
                                                                              name="taxBankTicket"
                                                                              id="taxBankTicket"
                                                                              type="text" 
                                                                              className="form-control"
                                                                              value={this.state.contractdata.taxBankTicket || ""}
                                                                              onChange={this.handleChange}
                                                                          />
                                                                      </div>
                                                                  </div>

                                                                  <div className="col-md-6">
                                                                      <div className="form-group">
                                                                          <label htmlFor="taxCreditCart"> Custo Anti Fraude p/ Transação ( R$ ) * </label>
                                                                          <input 
                                                                              placeholder="Custo fixo do anti fraude"
                                                                              id="taxAntiFraude"
                                                                              name="taxAntiFraude"
                                                                              type="text" 
                                                                              className="form-control"
                                                                              value={this.state.contractdata.taxAntiFraude || ""}
                                                                              onChange={this.handleChange}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="row">
                                                                  <div className="col-md-6">
                                                                      <div className="form-group">
                                                                          <label htmlFor="taxTransfer"> Custo Transferência DOC/TED * </label>
                                                                          <input 
                                                                              placeholder="Custo da transferência ..."
                                                                              value={this.state.contractdata.taxTransfer || ""}
                                                                              id="taxTransfer"
                                                                              name="taxTransfer"
                                                                              type="text" 
                                                                              className="form-control"
                                                                              onChange={this.handleChange}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                                  <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="transactionGtw"> Payment Gateway * </label>
                                                                        <select 
                                                                        id="transactionGtw"
                                                                        name="transactionGtw"
                                                                        value={this.state.contractdata.transactionGtw || ""}
                                                                        onChange={this.handleChange}
                                                                        className="form-control">
                                                                            <option value=""> Selecione o gateway </option>
                                                                            <option value="gtw1"> gtw1 </option>
                                                                            <option value="gtw2"> gtw2 </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                              </div>

                                                              <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="paymentGatewayTrk"> Gateway Transaction Key * </label>
                                                                            <input 
                                                                                placeholder="Entre com a chave de transação..."
                                                                                id="transactionGtwKey"
                                                                                name="transactionGtwKey"
                                                                                value={this.state.contractdata.transactionGtwKey || ""}
                                                                                type="text" 
                                                                                className="form-control"
                                                                                onChange={this.handleChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-3 mb-3">
                                                                    <div className="col-md-12">
                                                                        <h4 className="text-twitter"> Configurações do Woocommerce </h4>
                                                                        <p> Necessário apenas para produtos que utilizarem o Woocommerce. Para cria a sua consumer Key acesse o seguinte link na loja do cliente: <br/>
                                                                            <pre> wp-admin/admin.php?page=wc-settings&tab=advanced&section=keys </pre>
                                                                        </p>
                                                                    </div>

                                                                  <div className="col-md-6">
                                                                      <div className="form-group">
                                                                          <label htmlFor="wcConsumerKey"> Consumer Key </label>
                                                                          <input 
                                                                              placeholder="Custo do boleto bancário ( Liquidado )"
                                                                              name="wcConsumerKey"
                                                                              id="wcConsumerKey"
                                                                              type="text" 
                                                                              className="form-control"
                                                                              value={this.state.contractdata.wcConsumerKey || ""}
                                                                              onChange={this.handleChange}
                                                                          />
                                                                      </div>
                                                                  </div>

                                                                  <div className="col-md-6">
                                                                      <div className="form-group">
                                                                          <label htmlFor="wcConsumerSecret"> Consumer Secret </label>
                                                                          <input 
                                                                              placeholder="Custo fixo do anti fraude"
                                                                              id="wcConsumerSecret"
                                                                              name="wcConsumerSecret"
                                                                              type="text" 
                                                                              className="form-control"
                                                                              value={this.state.contractdata.wcConsumerSecret || ""}
                                                                              onChange={this.handleChange}
                                                                          />
                                                                      </div>
                                                                  </div>


                                                              </div>

                                                              <div className="row">
                                                                  <div className="col-md-12">
                                                                      <div className="form-group">
                                                                          <label htmlFor="taxTransfer"> Observações sobre o pedido * </label>
                                                                          <textarea 
                                                                              placeholder="Anotações Importantes..."
                                                                              id="notes"
                                                                              name="notes"
                                                                              value={this.state.contractdata.notes || ""}
                                                                              type="text" 
                                                                              className="form-control"
                                                                              onChange={this.handleChange}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="row">
                                                                  <div className="col-md-12 text-left">
                                                                      <button 
                                                                      type="submit" 
                                                                      className="btn btn-info mr-2"
                                                                      > Salvar </button>
                                                                  </div>
                                                              </div>
                                                          </form>
                                                      </div>
                                                  </div>
                                            </div>

                                            <div className="col-md-4">
                                              <div className="">
                                                  <div className="text-right">
                                                      <Link to={'/admin/customers'} className="btn btn-outline-success btn-sm mr-4">
                                                          Lista de Clientes 
                                                      </Link>

                                                      <Link to={`/admin/contracts/${this.state.userid}`} className="btn btn-outline-twitter btn-sm mr-4 mt-4">
                                                          Voltar para contratos do cliente 
                                                      </Link>
                                                  </div>
                                              </div>
                                          </div>
                                            
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
          </div>
        </>
    )};
}
