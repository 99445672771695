import React from 'react';

import AuthPage from '../../../../components/AuthPageAdmin';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import TutorialVideoUpdate from '../../../../components/AdminConsoleComponents/TutorialVideos/Update';

export default function Store(props) {

  const { match: { params } } = props;
  const id = params.id
  const video = params.video

  return (
    <>
      <AuthPage />
        <div className="container-scroller">
            <HeaderAdminConsole />
              <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                          <div className="row">
                              <div className="col-md-12">
                                <TutorialVideoUpdate tutoId={id}  videoId={video} />
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
          </div>
      <Footer />
    </>
  );
}
