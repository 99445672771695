import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Config from '../../../../services/config';
import api from '../../../../services/Users';
import Alerts from '../../../../components/Alerts';

export default class Store extends Component {

    constructor(props) {
        
        const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));

        super(props);
        this.state = {
            tutorialdata: {},
            authorization,
            userid: credencialdata._id
        };
    }

    handleChange = (e) => {
        const state = this.state.tutorialdata
        state[e.target.name] = e.target.value;
        this.setState({tutorialdata:state});
        console.log(state);
    }

    onSubmit = async (e) => {
    
        e.preventDefault();
        const authorization = this.state.authorization;
        const userid = this.state.userid;

        console.log(userid);

        const { 
            title, 
            description, 
            displayToCustomer,
            customerId,
            thumbnail,
            status
          } = this.state.tutorialdata;
    
        const response = await api.post('/admin/tutorial', {
            title, 
            description, 
            displayToCustomer,
            customerId,
            thumbnail,
            status
          }, {
            headers: { authorization, userid }
          });
    
          if(response.data.cb !== "error"){
              Alerts("Tutorial", "Tutorial cadastrado com sucesso!","success");
        } else {
            Alerts("Tutorial", response.data.message ,"error");
          }
      
      }

  render() {
    return (
        <>
          <div className="row">
            <div className="col-md-8">

                <div className="row mb-3">
                    
                    <div className="col-md-6">
                        <h3> Tutorial </h3>
                    </div>

                   
                    </div>

                <div className="row">
                    <div className="col-md-12">

                        <form onSubmit={this.onSubmit}>

                            <div className="form-group">
                                <label htmlFor="title"> Título * </label>
                                <input 
                                placeholder="Título... " 
                                type="text" 
                                className="form-control" 
                                name="title"
                                id="title"
                                value={this.state.tutorialdata.title || ""}
                                onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="thumbnail"> Imagem de Destaque * </label>
                                <input 
                                placeholder="Thumbnail... " 
                                type="text" 
                                className="form-control" 
                                name="thumbnail"
                                id="thumbnail"
                                value={this.state.tutorialdata.thumbnail || ""}
                                onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="description"> Descrição * </label>
                                <textarea 
                                    placeholder="Descreva ..." 
                                    type="text" 
                                    className="form-control" 
                                    name="description"
                                    id="description"
                                    value={this.state.tutorialdata.description || ""}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group mt-4">
                                <label htmlFor="product_type"> Permissão da visualização * </label>
                                <select 
                                id="displayToCustomer"
                                name="displayToCustomer"
                                value={this.state.tutorialdata.displayToCustomer || ""}
                                onChange={this.handleChange}
                                className="form-control">
                                    <option value=""> Selecione a opção </option>
                                    <option value="public"> Público </option>
                                    <option value="private"> Privado </option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="title"> Customer Id * </label>
                                <input 
                                placeholder="Caso seja um treinamento privado informe o id do usuário... " 
                                type="text" 
                                className="form-control" 
                                name="customerId"
                                id="customerId"
                                value={this.state.tutorialdata.customerId || ""}
                                onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="status"> Status </label>
                                <select 
                                id="status"
                                name="status"
                                value={this.state.tutorialdata.status || ""}
                                onChange={this.handleChange}
                                className="form-control">
                                    <option value=""> Selecione o Status </option>
                                    <option value="1"> Ativo </option>
                                    <option value="2"> Inativo </option>
                                </select>
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <button 
                                    type="submit" 
                                    className="btn btn-info mr-2"
                                    > Salvar </button>
                                </div>
                            </div>
                </form>
                </div>

              </div>
            </div>
              
              <div className="col-md-4 text-right">
                <div className="dropdown">
                    <Link to="/admin/tutorials" className="btn btn-outline-twitter btn-sm">
                        Ver todos
                    </Link>
                </div>
              </div>
            </div>
    </>
    );
  }
}
