import React from 'react';

export default function StatementType(props) {

    const SwitchColor = (str) => ({
      "pagamento recebido" : "text-success",
      "transferência" : "text-danger",
      "" : "text-grey",
    })[str] || 'text-grey';

    let getType = props.stateType;

    return (
      <span className={`text-uppercase disabled ${SwitchColor(getType)}`}>
        { getType }
      </span>
  );

  } 