import React from 'react';

export default function Status(props) {

    const status = props.statusId;

    if(status === "transferred"){
      return (
          <span className="text-success"> Transferido </span>
      );
    } else if(status === "failed"){
        return (
            <span className="text-danger"> Transferência Falhou </span>
        );
    } else {
        return (
            <span className="text-warning"> Transferência pendente </span>
        );
    }
    
}
