import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import swal from 'sweetalert';

import Alerts from '../../components/Alerts';
import Config from '../../services/config';
import api from '../../services/login';

import './styles.css';

export default function CommerceProductCategoryDetail(props) {
  
  const authorization = Config.AuthApiSecretKey;

  const id = props.catId;
  const contractId = props.contract;
  const userId = props.user;
  
  const [category, setCategory] = useState();
  const [appData, setAppData] = useState({
    title: '',
    description: '',
    status: '',
    permLink: '',
    featuredImage: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppData((prevState) => ({ ...prevState, [name]: value }));
  };
  
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
    const authorization = Config.AuthApiSecretKey;
    async function loadData() {
      const response = await api.get(`/commerce/product-categories/${id}`, {
        headers: {
          authorization,
          userId,
          contractid: contractId,
        },
      });
      setAppData(response.data);
    }
    loadData();
    },[]);

    async function onSubmit(e){

      e.preventDefault();
    
      const authorization = Config.AuthApiSecretKey;
      const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
      const userid = credencialdata._id;

      const  { title, description, permLink, featuredImage, status } = appData;

      try {
        await api.post(`/commerce/product-categories`, {
          title, 
          description, 
          permLink, 
          featuredImage, 
          status
        }, {
          headers: { 
            authorization,
            userId,
            contractid: contractId,  
          }
        });
          
          swal({
            title: "Adicionando",
            text: "Adicionando Conteúdo",
            timer: 2000
          });
          setCategory('');
        } catch (error) {
          swal({
            title: "Adicionando",
            text: error.response.data.error,
            timer: 2000
          });
        }
    
    }

  return (
    <>
      <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-6">
                  <small> Adicionando Categoria de Produto </small> <br/>
                  <h3> {appData?.title || 'Sem título'} </h3>
                </div>
                <div className="col-md-6 text-right">
                    <Link to={`/user/commerce/products-categories/${contractId}`}>
                      <button className="btn btn-outline-dark btn-sm mt-3 mb-4 mr-3"> 
                        Todas Categorias
                      </button>
                    </Link>
                </div>
              </div>
              
              <form className="forms-sample" onSubmit={onSubmit} >
              
              <div className="row border-top">
                <div className="col-md-8 mt-4">
                 {/*  */}

                 <div className="form-group">
                    <label htmlFor="title"> Título </label>
                    <input 
                      placeholder="Título da categoria"
                      type="text" 
                      className="form-control" 
                      name="title" 
                      id="title"
                      value={appData?.title || ""} 
                      onChange={handleChange}
                    />
                  </div>
                 <div className="form-group">
                    <label htmlFor="description"> Descrição </label>
                    <input 
                      placeholder="Descrição"
                      type="text" 
                      className="form-control" 
                      name="description" 
                      id="description"
                      value={appData?.description || ""} 
                      onChange={handleChange}
                    />
                  </div>
                 <div className="form-group">
                    <label htmlFor="featuredImage"> Featured Image </label>
                    <input 
                      placeholder="Título da categoria"
                      type="text" 
                      className="form-control" 
                      name="featuredImage" 
                      id="featuredImage"
                      value={appData?.featuredImage || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-dark mr-2"> Salvar </button>
                  </div>
                 {/*  */}
                </div>
                <div className="col-md-4 mt-4 ">
                  <div className="form-group">
                      <label htmlFor="status"> Status </label>
                      <select id="status"
                          name="status"
                          className="form-control"
                          value={appData?.status || ""}
                          onChange={handleChange}
                        >
                          <option> Escolha o Status </option>
                          <option value="enabled"> Online </option>
                          <option value="disabled"> Offline </option>
                      </select>
                    </div>

                    <div className="form-group">
                    <label htmlFor="permLink"> Link Permanente </label>
                    <input 
                      placeholder="Link Permanante"
                      type="text" 
                      className="form-control" 
                      name="permLink" 
                      id="permLink"
                      value={appData?.permLink || ""} 
                      onChange={handleChange}
                    />
                  </div>
                </div>

              </div>
            
            </form>
            
            </div>
          </div>
        </div>
    </>
  );
}
