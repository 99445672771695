import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';


import Alerts from '../Alerts';
import Config from '../../services/config';
import api from '../../services/login';
// import axios from 'axios';

import './styles.css';

export default function CommerceProductproductDetail(props) {
  
  const authorization = Config.AuthApiSecretKey;
  const id = props.catId;
  const contractId = props.contract;
  const userId = props.user;

  const [product, setProduct] = useState();
  const [appData, setAppData] = useState({
    email: '',
    domain: '',
    firstname: '',
    lastname: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppData((prevState) => ({ ...prevState, [name]: value }));
  };

  
  useEffect(() => {
    window.scrollTo(0, 0);
    const authorization = Config.AuthApiSecretKey;
    async function loadData() {
      const response = await api.get(`/commerce/product/${id}`, {
        headers: {
          authorization,
          userId,
          contractid: contractId,
        },
      });
      setAppData(response.data);
    }
    loadData();
    },[]);

    async function onSubmit(e){

      e.preventDefault();
    
      const authorization = Config.AuthApiSecretKey;
      const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
      const userid = credencialdata._id;

      const  { title,
        email,
        firstname,
        lastname,
        password,
        domain } = appData;

      try {

        let [user, domain] = email.split('@');
        
        await api.post(`/user/emails/create`, {
          email,
          firstName : firstname,
          lastName : lastname,
          password,
          domain
        }, {
          headers: { 
            authorization,
            userId,
            contractid: contractId,  
          }
        });
          
        swal({
            title: "E-mails",
            text: "E-mail criado com sucesso",
            timer: 2000
          });
          setProduct('');
          window.location.href = `/user/emails/list`;
        } catch (error) {
          swal({
            title: "Criação de E-mails",
            text: "Erro a cadastrar o email",
            timer: 2000
          });
        }
    
    }

  return (
    <>
      <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-6">
                  <h3> Emails / Adicionar </h3>
                </div>
                <div className="col-md-6 text-right">
                    <Link to={`/user/emails/list`}>
                      <button className="btn btn-outline-dark btn-sm mt-3 mb-4 mr-3"> 
                        Todos Emails
                      </button>
                    </Link>
                </div>
              </div>
              
              <form className="forms-sample" onSubmit={onSubmit} >
              
              <div className="row border-top">
                <div className="col-md-8 mt-4">
                 {/*  */}

                 <div className="form-group">
                    <label htmlFor="email"> E-mail </label>
                    <input 
                      placeholder="Endereço de e-mail"
                      type="text" 
                      className="form-control" 
                      name="email" 
                      id="email"
                      value={appData?.email || ""} 
                      onChange={handleChange}
                    />
                  </div>

                 <div className="form-group">
                    <label htmlFor="firstname"> Nome </label>
                    <input 
                      placeholder="Nome do usuário"
                      type="text" 
                      className="form-control" 
                      name="firstname" 
                      id="firstname"
                      value={appData?.firstname || ""} 
                      onChange={handleChange}
                    />
                  </div>
                 <div className="form-group">
                    <label htmlFor="lastname"> Sobrenome </label>
                    <input 
                      placeholder="Sobrenome"
                      type="text" 
                      className="form-control" 
                      name="lastname" 
                      id="lastname"
                      value={appData?.lastname || ""} 
                      onChange={handleChange}
                    />
                  </div>
                 <div className="form-group">
                    <label htmlFor="password"> Senha ( * Utilizar senhas fortes )</label>
                    <input 
                      placeholder="Senha forte"
                      type="text" 
                      className="form-control" 
                      name="password" 
                      id="password"
                      value={appData?.password || ""} 
                      onChange={handleChange}
                    />
                  </div>
                 
                  <div className="form-group">
                    <button type="submit" className="btn btn-dark mr-2"> Salvar </button>
                  </div>
                 {/*  */}
                </div>
                <div className="col-md-4 mt-4 ">
                  

                  <div className="form-group">
                      <label htmlFor="highlightInCategory"> <strong> Informações sobre a caixa de e-mail </strong> </label>
                      <p> Tamanho padrão da caixa <strong> 5 GB </strong>. <br/> 
                      Para aumentar o tamanho da caixa abra um Ticket na plataforma solicitando o aumento de tamanho </p>
                      <br/>
                      <p>
                        <strong>Webmail</strong> <br/>
                        Você pode utilizar o webmail padrão da Pagefai acessando <a href="https://webmail.pagefai.com" target='_blank'> webmail.pagefai.com </a>
                      </p>
                      <br/>
                      <p>
                        <strong> Utilização da caixa </strong> <br/>
                         A Pagefai terá o direito de bloquear todo e qualquer acesso caso detectar qualquer utilização ilegal do e-mail. É considerado como utilização ilegal
                         o envio de SPAM, Mailing List ( emails com mais de 50 usuários em cópia ). Também é proíbido a utilização de envio de e-mail com qualquer tipo de conteúdo
                         ilícito perante a lei
                      </p>
                  </div>
                  
                
                </div>

              </div>
            
            </form>
            
            </div>
          </div>
        </div>
    </>
  );
}
