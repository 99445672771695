import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';

import AuthPage from '../../../components/AuthPage';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Config from '../../../services/config';
import PagesUpdate from '../../../components/CmsPagesUpdate';

export default function Detail(props) {

  const { match: { params } } = props;
  const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
  const userid = credencialdata._id;

  const [contractId, setContractId] = useState('');
  const id = params.id;

  const getFromCookie = (cookieName) => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name?.length, cookie?.length);
      }
    }
    return '';
  };

  useEffect(() => {
    const getContractId = getFromCookie('selectedContractId');
    if (getContractId) {
      setContractId(getContractId);
    }
  }, []);

  return (
    <>
        <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner"
        />
        <AuthPage />
        <div className="container-scroller">
            <Header />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>
                                                <PagesUpdate 
                                                  catId={id} 
                                                  contract={contractId}
                                                  user={userid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
