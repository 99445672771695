import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';

import HeaderAdminConsole from '../../../components/HeaderAdminConsole';
import Config from '../../../services/config';
import AuthPage from '../../../components/AuthPageAdmin';
import Footer from '../../../components/Footer';
import api from '../../../services/Users';
import Status from '../../../components/Common/Status';

export default class Contracts extends Component {

    constructor(props) {

        const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const userid = credencialdata._id;
        
        super(props);
            this.state = {
            contractsdata: [],
            contractsCount: {},
            authorization,
            userdata: {},
            authorizationKey: Config.AuthApiSecretKey,
            userId: userid,
            };
        }

      async countContracts(){
          
        //   const authorization = this.state.authorization;
          const userid = this.state.userId;

          await api.get('/contracts/count', {
            headers: { authorization : this.state.authorizationKey, userid },
          })
          .then(res => {
              this.setState({ contractsCount: res.data});
          })

      }

      async loadContracts(){
          const authorization = this.state.authorization;
      
          await api.get(`/contracts`,{
              headers: { authorization },
          })
          .then(res => {
              this.setState({ contractsdata: res.data.contracts });
          });
      }

      componentDidMount() {
        this.loadContracts();
        this.countContracts();
      }
    

    render(){

      return (
        <>
          <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
          />

          <AuthPage />

          <div className="container-scroller">

            <HeaderAdminConsole />

                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                            <div className="row">

                            <div className="col-md-12">

                            <div className="row">
                                <div className="col-md-12">
                                  <h4 className="font-weight-bold text-uppercase"> Contratos </h4>
                                </div>

                                <div className="col-md-12 mt-3">
                                    <Link to="/admin/contracts?filter=enabled" className="btn btn-outline-twitter btn-sm mr-2">
                                        Contratos Ativos ( {this.state.contractsCount.contractsEnabled} ) 
                                    </Link>
                                    <Link to="/admin/contracts?filter=disabled" className="btn btn-outline-secondary btn-sm">
                                    Contratos Inativos ( {this.state.contractsCount.contractsDisabled} ) 
                                    </Link>
                                </div>

                            </div>

                            <div className="col-md-12 mt-4 ">
                                <div className="row">

                                    <div className="col-md-12">
                                        
                                        <div className="table-responsive">
                                                        
                                                        {!this.state.contractsdata.length ? (
                                                            <p className="text-grey mt-3"> Este cliente não possui contratos ativos ;-( </p>
                                                        ) : (
                                                            <>
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="pt-1 pl-0">
                                                                                Contrato
                                                                            </th>
                                                                            <th className="pt-1">
                                                                                Contratado em 
                                                                            </th>
                                                                            <th className="pt-1 text-center">
                                                                                &nbsp;
                                                                            </th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        
                                                                        {this.state.contractsdata.map((contract, index) => (
                                                                            <tr key={contract._id}>
                                                                                <td> 
                                                                                    <div className="col-md-12">
                                                                                        <Link to={`/admin/contract/${contract._id}`} className="text-grey trLink">
                                                                                            {contract.productName} 
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="col-md-12 mt-2">
                                                                                        <span className="text-grey mt-2"> Contract ID: {contract._id} </span> <br/>

                                                                                        <span className="text-grey mt-2"> Domínio: {contract.domainUrl ? ( <span className="text-twitter"> {contract.domainUrl} </span> )  : 
                                                                                            ( <span className="text-danger"> Não configurado </span> ) } </span> <br/>

                                                                                        <span className="text-grey mt-2"> Status do contrato: 
                                                                                            <Status statusId={contract.status} />  </span>
                                                                                        
                                                                                    </div>
                                                                                </td>
                                                                                
                                                                                <td className="text-grey"> 
                                                                                    <Moment format="DD/MM/YYYY hh:mm" date={contract.createdAt}  /> 
                                                                                </td>
                                                                                
                                                                                <td className="text-right"> 

                                                                                    <Link to={`/admin/transactions/${contract._id}/${contract.userId}`} className="btn btn-outline-primary btn-sm mr-3">
                                                                                        Transações
                                                                                    </Link> 

                                                                                    <Link to={`/admin/contract/${contract._id}`} className="btn btn-outline-secondary btn-sm mr-3">
                                                                                        Detalhes
                                                                                    </Link> 

                                                                                    <Link to={`/admin/contract/update/${contract._id}/${contract.userId}`} className="btn btn-outline-secondary btn-sm">
                                                                                        <i className="ti-settings btn-icon-prepend"></i>  Editar
                                                                                    </Link> 
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>

                                                                <div className="mt-4">
                                                                    <p className="card-description text-grey mt-3 text-small">
                                                                        Os custos de transação são baseados no CNAE de cada cliente. <br/>
                                                                        Estes valores são negociados diretamente com o seu gerente de contas Pagefy 
                                                                    </p>
                                                                </div>
                                                            </>
                                                        )}
                                            </div>

                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                         </div>
                        </div>
                    </div>
                </div>
            <Footer />

        </>
      )
    }
}