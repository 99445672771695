import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import Alerts from '../../../../components/Alerts';

import Header from '../../../../components/Header';
import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPage';
import Footer from '../../../../components/Footer';
import api from '../../../../services/Users';
import WcOrderStatus from '../../../../components/ChargesComponents/WcOrderStatus';

import './styles.css';

export default class wcOrders extends Component {

  constructor(props) {

    const { match: { params } } = props;
    const authorization = Config.AuthApiSecretKey;
    const contractId = params.contractId;
    const customerId = params.customerId;

    super(props);
    this.state = {
      ordersdata: [],
      authorization,
      contractId,
      customerId,
      userdata: {},
      pageCounter: 1,
      wcreportdata: [],
      wcpiedata: {},
      loading: true,
      curTime: new Date().toLocaleString(),
      dataPie : {},
      filterrequest: {},
      filterStatusActive: [],
      filterStatusEvent: '',
      filterPaymentMethod: ''
    };
  }

  loadUserData(){
        
    const authorization = this.state.authorization;
    const customerId = this.state.customerId;

    api.get('/mobcustomer/detail', {
        headers: { authorization, userId: customerId },
    })
    .then(res => {
        this.setState({ userdata: res.data.user });
    });
  }

  loadTrx(p){

    const authorization = this.state.authorization;
    const customerId = this.state.customerId;

    const status = this.state.filterStatusEvent || 'any'
    const payMethod = this.state.filterPaymentMethod || 'any'

    api.get(`wcOrders/${this.state.contractId}/${p}`, {
      headers: { 
        authorization, 
        userId: customerId,
        status,
        paymentmethod:  payMethod
       },
    })
    .then(res => {
        this.setState({ ordersdata: res.data });
        this.setState({ loading: false });
    });

  }

  loadWcReports(){
    const authorization = this.state.authorization;
    const customerId = this.state.customerId;

    api.get(`wcReportOrderTotal/${this.state.contractId}`, {
      headers: { authorization, userId: customerId },
    })
    .then(res => {
        this.setState({ wcreportdata: res.data });
        
        const sDataPie = {
          labels: [
            'Pendentes', 
            'Processando', 
            'Aguardando',
            'Concluídos', 
            'Cancelados', 
            'Reembolsados', 
            'Malsucedidos'],
          datasets: [
            {
              label: 'Rainfall',
              backgroundColor: [
                '#C9DE00',
                '#2FDE00',
                '#00A6B4',
                '#ef8731',
                '#B21F00',
                '#000000',
                '#ccccccc',
              ],
              hoverBackgroundColor: [
                '#4B5000',
                '#175000',
                '#003350',
                '#a35616',
                '#501800',
                '#000000',
                '#ccccccc',
              ],
              data: [
                this.state.wcreportdata[0]?.total, 
                this.state.wcreportdata[1]?.total, 
                this.state.wcreportdata[2]?.total, 
                this.state.wcreportdata[3]?.total, 
                this.state.wcreportdata[4]?.total, 
                this.state.wcreportdata[5]?.total, 
                this.state.wcreportdata[6]?.total, 
              ],
            }
          ],
        }
        this.setState( sDataPie)

    });
  }

  handlePaginationPlus = async () => {
  this.setState({ loading: true });
  this.setState({ pageCounter: this.state.pageCounter + 1});
      await this.loadTrx(this.state.pageCounter + 1);
  }

  handlePaginationLess = async () => {
      if(this.state.pageCounter > 1){
          this.setState({ loading: true });
          this.setState({ pageCounter: this.state.pageCounter - 1});
          await this.loadTrx(this.state.pageCounter - 1);
      }
  }

  handlePaginationHome = async () => {
      if(this.state.pageCounter > 1){
          this.setState({ loading: true });
          this.setState({ pageCounter: 1});
          await this.loadTrx(1);
      }
  }

  componentDidMount() {
    this.loadUserData();
    this.loadTrx(1);
    this.loadWcReports();
  }


  handleChange = async (e) => {
    const state = this.state.filterrequest;
    state[e.target.name] = e.target.value;
    this.setState({filterrequest:state});
  }

  getFilterStatus = () => {
    let filterStatus = this.state.filterStatusEvent
    
    if(!filterStatus){
      return "Todos"
    } else if(filterStatus === 'any') {
      return "Todos"
    } else if(filterStatus === 'pending') {
      return "Pendentes"
    } else if(filterStatus === 'processing') {
      return "Processamento"
    } else if(filterStatus === 'on-hold') {
      return "Aguardando Pagamento"
    } else if(filterStatus === 'completed') {
      return "Concluídos"
    } else if(filterStatus === 'cancelled') {
      return "Cancelados"
    } else if(filterStatus === 'refunded') {
      return "Extornados"
    } else if(filterStatus === 'failed') {
      return "Malsucedidos"
    }
    
  }

  handleFilterPaymentMethod = async (e) => {
    console.log(e);
    await this.setState({ filterPaymentMethod: e });
    this.loadTrx(1);
  }

  handleFilterStatus = async (e, p) => {

    const authorization = this.state.authorization;
    const customerId = this.state.customerId;

    const page = p || 1;
    
    this.setState({ loading: true });
    this.setState({ filterStatusActive: 'btn btn-outline-primary btn-sm' });
    this.setState({ filterStatusEvent: e });

    api.get(`wcOrders/${this.state.contractId}/${page}`, {
      headers: { 
        authorization, 
        userId: customerId,
        status: e
      },
    })
    .then(res => {
        this.setState({ ordersdata: res.data });
        this.setState({ loading: false });
        console.log(this.state.ordersdata);
    });
    

  }

  onSubmit = async (e) => {

    e.preventDefault();

    const authorization = this.state.authorization;
    const customerId = this.state.customerId;
    const filter = this.state.filterrequest.filter;
    const filterquery = this.state.filterrequest.filterquery;

    this.setState({ loading: true });

    if(filter && filterquery){
      
      api.post(`wcOrders/filters/${this.state.contractId}/1`, {
        "filter" : filter,
        "filterquery" : filterquery
      },{
        headers: { authorization, userId: customerId },
      })
      .then(res => {
          this.setState({ ordersdata: res.data });
          this.setState({ loading: false });
          console.log(this.state.ordersdata.length);
      });

    } else {
      Alerts("Pedidos", "Por favor verifique o que deseja procurar... " ,"error");
      this.setState({ loading: false });
    }

  }

  render(){
        
    return (
      <>

        <AuthPage />

        <div className="container-scroller">

          <Header />

          <div className="container-fluid page-body-wrapper">
                  <div className="main-panel">
                      <div className="content-wrapper">

                          <div className="row">

                          <div className="col-md-12 border-bottom ">

                          <div className="row">
                                  <div className="col-md-4">
                                  <h3 className="font-weight-bold"> 
                                      Pedidos
                                  </h3>
                                  <p className="font-weight-normal mb-0 text-grey">
                                    <span className="text-twitter"> 
                                      Contrato: {this.state.contractId} </span> <br/>
                                    {this.state.userdata.companyName ? (
                                      <>
                                          <span className="text-grey"> Empresa: {this.state.userdata.companyName} </span> <br/>
                                      </>
                                    ) : ''}
                                    <span className="text-grey"> Responsável financeiro: {this.state.userdata.name} </span> 
                                  </p>
                                  </div>
                                  <div className="col-md-8 text-right">
                                    <Link to={`/user/contracts`} className="btn btn-outline-twitter btn-sm mr-3">
                                        Meus Contratos
                                    </Link>
                                  </div>
                              </div>
                          </div>

                          <div className="col-md-12">

                            <div className="row mb-4 pt-3 pb-3">

                              <div className="col-md bg-grey">

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos Pendentes </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[0]?.total} </strong></p>
                                  </div>

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos em processamento </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[1]?.total} </strong></p>
                                  </div>

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos Aguardando </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[2]?.total} </strong></p>
                                  </div>

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos Concluídos </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[3]?.total} </strong></p>
                                  </div>

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos Cancelados </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[4]?.total} </strong></p>
                                  </div>

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos Reembolsados </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[5]?.total} </strong></p>
                                  </div>

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos Malsucedidos </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[6]?.total} </strong></p>
                                  </div>

                              </div>

                              <div className="col-md mt-3">
                                  <Doughnut
                                    data={this.state}
                                    options={{
                                      responsive: true,
                                      legend:{
                                        display:true,
                                        position:'right'
                                      }
                                    }}
                                  />
                              </div>
                            </div>

                            {this.state.loading === false ? (
                              <>
                              <div className="row filter-custom">
                                  <div className="col-md-12 text-right mt-2">
                                      <div className="row">
                                        <form onSubmit={this.onSubmit} >

                                        <div className="block1">
                                          <select
                                          name="filterquery"
                                          id="filterquery"
                                          onChange={this.handleChange}
                                          className="filterSelect ml-3">
                                            <option value=""> Selecione o critério de pesquisa </option>
                                            <option value="order"> Número do pedido </option>
                                            <option value="string"> email</option>
                                            <option value="string"> nome</option>
                                          </select>
                                        </div>

                                        <div className="block2 ml-2">
                                          <input 
                                            type="text"
                                            placeholder="Entre com a pesquisa..." 
                                            className="filters"
                                            name="filter"
                                            id="filter"
                                            onChange={this.handleChange}
                                          />
                                          <button 
                                            className="filterSubmit"
                                            type="submit"
                                            >
                                            Pesquisar
                                          </button>
                                        </div>

                                        </form>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-md-12">
                                <p className="text-secondary mt-3"> Filtrar pedidos com status 
                                <strong> ( {this.getFilterStatus()} ) </strong> </p>
                              </div>

                              <div className="col-md-12 mb-4">

                                      <button 
                                        onClick={() => this.handleFilterStatus('any')} 
                                        className="btn btn-outline-secondary btn-sm">
                                        Todos </button>

                                      <button 
                                          onClick={() => this.handleFilterStatus('pending')} 
                                          className="btn btn-outline-secondary btn-sm ml-2">
                                          Pendentes </button>

                                      <button 
                                          onClick={() => this.handleFilterStatus('processing')} 
                                          className="btn btn-outline-secondary btn-sm ml-2">
                                          Processamento </button>

                                      <button 
                                        onClick={() => this.handleFilterStatus('on-hold')} 
                                        className="btn btn-outline-secondary btn-sm ml-2">
                                          Aguardando Pagamento </button>

                                      <button 
                                          onClick={() => this.handleFilterStatus('completed')} 
                                          className="btn btn-outline-secondary btn-sm ml-2">
                                          Concluídos </button>

                                      <button 
                                          onClick={() => this.handleFilterStatus('cancelled')} 
                                          className="btn btn-outline-secondary btn-sm ml-2">
                                          Cancelados </button>

                                      <button 
                                          onClick={() => this.handleFilterStatus('refunded')} 
                                          className="btn btn-outline-secondary btn-sm ml-2">
                                          Extornados </button>

                                      <button 
                                        onClick={() => this.handleFilterStatus('failed')} 
                                        className="btn btn-outline-secondary btn-sm ml-2">
                                          Malsucedidos </button>

                              </div>

                              {/* <div className="col-md-12">
                                <p className="text-secondary mt-3"> Filtrar pedidos por meio de pagamento  
                                <strong> ( {this.state.filterPaymentMethod} ) </strong> </p>

                                <button 
                                  onClick={() => this.handleFilterPaymentMethod('')} 
                                  className="btn btn-outline-secondary btn-sm mb-3">
                                    Todos </button>

                                <button 
                                  onClick={() => this.handleFilterPaymentMethod('credit-card')} 
                                  className="btn btn-outline-secondary btn-sm mb-3 ml-3">
                                    Cartão de Crédito </button>

                                <button 
                                  onClick={() => this.handleFilterPaymentMethod('boleto')} 
                                  className="btn btn-outline-secondary btn-sm mb-3 ml-3">
                                    Boleto Bancário </button>

                                <button 
                                  onClick={() => this.handleFilterPaymentMethod('cash-on-delivery')} 
                                  className="btn btn-outline-secondary btn-sm mb-3 ml-3">
                                    Pagamento na entrega </button>

                              </div> */}

                              </>
                            )
                            : ('')}
                              
                            <div className="table-responsive">
                              {!this.state.ordersdata.length && this.state.loading === false ? (
                                  <p className="text-grey mt-3"> Não encontrei nenhum pedido para apresentar ... </p>
                              ) : (
                                  <>
                                      {this.state.loading === false ? (
                                          <table className="table table-striped">
                                                  <tbody>
                                                      {this.state.ordersdata.map((trx, index) => (
                                                          <tr key={index}>
                                                              <td className="text-grey"> <strong> {trx?.id} </strong> </td>
                                                              <td className="text-grey"> 
                                                                {trx?.billing.first_name} {trx?.billing.last_name} <br/>
                                                                {trx?.billing.email}
                                                              </td>
                                                              <td className="text-grey"> R$ {trx?.total} </td>
                                                              <td className="text-grey"> <Moment format="DD/MM/YYYY" date={trx?.date_created}  />  </td>
                                                              <td className="text-grey">
                                                                {trx?.payment_method_title}
                                                              </td>
                                                              <td>
                                                                <WcOrderStatus status={trx?.status || ""} className="mr-3"/>
                                                              </td>
                                                              <td className="text-right"> 
                                                              <Link to={`/user/wcorder/${this.state.contractId}/${trx?.id}/${this.state.customerId}`} 
                                                                className="btn btn-outline-secondary btn-sm ml-3">
                                                                  Detalhes
                                                              </Link> 
                                                              </td>
                                                          </tr>
                                                      ))}
                                                  </tbody>
                                              </table>
                                              ) : (
                                                  <div className="loader"> Carregando dados, por favor aguarde ... </div>
                                              )}
                                            </>
                                        )}
                            </div>

                            {this.state.ordersdata.length >= 10 && this.state.loading === false ? (
                              <div className="row">
                                  <div className="col-md-6 mb-4"> </div>
                                  <div className="col-md-6 text-right mb-4">
                                      <nav className="paginationRight">
                                          <ul className="pagination flex-wrap pagination-flat pagination-twitter">
                                              <li className="page-item">
                                                  <button className="page-link pgLess" onClick={this.handlePaginationLess}>
                                                      <i className="ti-angle-left"></i>
                                                  </button> 
                                              </li>
                                              <li className="page-item">
                                                  <button className="page-link pgLess" onClick={this.handlePaginationHome}>
                                                      Primeira página
                                                  </button> 
                                              </li>
                                              <li className="page-item active">
                                                  <span className="page-link">{this.state.pageCounter}</span>
                                              </li>
                                              <li className="page-item">
                                                  <button className="page-link" onClick={this.handlePaginationPlus}>
                                                      <i className="ti-angle-right"></i>
                                                  </button> 
                                              </li>
                                          </ul>
                                      </nav>
                                  </div>
                              </div>
                            ) : ('')}

                          </div>

                      </div>
                      
                      </div>
                  </div>
              </div>
          <Footer />
      </div>

      </>
    )
  }


}