import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Moment from 'react-moment';

import Config from '../../../../services/config';
import api from '../../../../services/login';

import StatusIcon from '../../../Common/StatusIcon';
import UserGroup from '../../../Common/Usergroup';

export default function List() {

   const [ users, setUsers ] = useState([]);

   const authorization = Config.AuthApiSecretKey;
   const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
   const userid = credencialdata._id;
   
    useEffect(() => {
        const authorization = Config.AuthApiSecretKey;
        async function loadUsers() {
            const response = await api.get('/users', {
                headers: { authorization },
            })
            // console.log(response.data).users;
            setUsers(response.data.users);
        }
        loadUsers();
    },[])


    async function handleRemove(itemId, name, index) {

        swal(`Remover ${name}?`, {
            buttons: {
              cancel: "cancelar",
              catch: {
                text: "remover",
                value: "remover",
              },
            },
          })
          .then((value) => {
            switch (value) {

              case "cancelar": 
                break;
           
              case "remover":
                setUsers(users.filter(user => user._id !== itemId));
                swal("Remover Cliente", "cliente removido com sucesso", "success");
                async function removeUser() {
                    const response = await api.delete(`/mobcustomer/delete/${itemId}`, {
                        headers: { authorization, userid },
                    })
                    console.log(response);
                }
                removeUser();
                break;

                default:
                    swal(" OK, você escolheu cancelar... ");

            }
          });
    }

    
  return (
    <>
        { !users.length ? 'Não há dados para exibir aqui' : (
        <div className="table-responsive">
            <table className="table">
            <thead>
                <tr>
                <th className="pt-1 pl-0">
                    Nome
                </th>
                <th className="pt-1">
                    Grupo
                </th>
                <th className="pt-1 text-center">
                    Status
                </th>
                </tr>
            </thead>
            <tbody>
                {users.map((user, index) => (
                    <tr key={user._id}>
                        <td className="py-1 pl-0">
                            <div className="d-flex align-items-center">
                            <div className="ml-3">
                                <p className="mb-0 text-uppercase"> {user.name} </p>
                                <p className="mb-0 text-muted text-small"> 
                                    Atualizado:
                                    <Moment 
                                            format="DD/MM/YYYY" 
                                            date={user.updatedAt}
                                        /> <br/>
                                    E-mail: <span className="text-lowercase"> {user.email} </span>
                                </p>
                            </div>
                            </div>
                        </td>
                        <td>
                            <UserGroup dataUserGroup={user.group} />
                        </td>
                        <td className="text-right">

                            <Link to={'/admin/user/' + user._id} className="btn btn-outline-secondary btn-sm">
                                <i className="ti-settings btn-icon-prepend"></i>  Editar
                            </Link>

                            <StatusIcon iconStatus={user.status} /> 

                            <button onClick={() => handleRemove(user._id, user.name, index)} className="remoteItemBt">
                                <i className="ti-close text-danger btn-icon-prepend ml-3"></i>
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
        )}
    </>
  );
}
