import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles.css';
import Alerts from '../../components/Alerts';
import Config from '../../services/config';
import api from '../../services/login';
export default function CommerceProductCategoryDetail(props) {
  const authorization = Config.AuthApiSecretKey;
  const id = props.catId;
  const contractId = props.contract;
  const userId = props.user;
  const [appData, setAppData] = useState({
    title: '',
    slug: '',
    description: '',
    content: '',
    featuredImage: '',
    videoLink: '',
    showAppNavigation: '',
    showWebNavigation: '',
    parentCategory: '',
    featuredImageBackground: '',
    sitemap: '',
    status: ''
  });
  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(`/user/cms/categories/${id}`, {
          headers: {
            authorization,
            userId,
            contractid: contractId,
          },
        });
        setAppData(response?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    loadData();
  }, [contractId, id, userId]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleEditorChange = (value) => {
    setAppData((prevState) => ({ ...prevState, content: value }));
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    try {
      await api.post(`/user/cms/categories`, {
        ...appData,
        contractId: contractId,
        customerId: userid,
      }, {
        headers: {
          authorization,
          userId,
          contractid: contractId,
        }
      });
      swal({
        title: "Adicionando",
        text: "Adicionando Categoria",
        timer: 2000
      });
      setAppData({
        title: '',
        slug: '',
        description: '',
        content: '',
        featuredImage: '',
        videoLink: '',
        showAppNavigation: '',
        showWebNavigation: '',
        parentCategory: '',
        featuredImageBackground: '',
        sitemap: '',
        status: ''
      });
      window.location.href = `/user/cms/categories`;
    } catch (error) {
      swal({
        title: "Adicionando",
        text: error.response.data.error,
        timer: 2000
      });
    }
  };
  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-6">
                <h3>{appData?.title || 'Sem título'}</h3>
                <small>Adicionando nova categoria</small>
              </div>
              <div className="col-md-6 text-right">
                <Link to={`/user/cms/categories`}>
                  <button className="btn btn-outline-dark btn-sm mt-3 mb-4 mr-3">
                    Ver categorias
                  </button>
                </Link>
              </div>
            </div>
            <form className="forms-sample" onSubmit={onSubmit}>
              <div className="row border-top">
                <div className="col-md-8 mt-4">
                  <div className="form-group">
                    <label htmlFor="title">Título da categoria</label>
                    <input
                      placeholder="Título da categoria"
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      value={appData?.title || ""}
                      onChange={handleChange}
                    />
                  </div>
                  
                  <label htmlFor="content">Conteúdo</label>
                  <div className="form-group">
                    <ReactQuill
                      value={appData?.content || ""}
                      onChange={handleEditorChange}
                      placeholder="Conteúdo"
                      style={{ height: '300px' }}
                      modules={{
                        toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ 'size': [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                          ['link', 'image', 'video'],
                          ['clean']
                        ],
                      }}
                      formats={[
                        'header', 'font', 'size',
                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                        'list', 'bullet', 'indent',
                        'link', 'image', 'video'
                      ]}
                    />
                  </div>
                  <br/>
                  <br/>
                  <br/>

                  <div className="form-group">
                    <label htmlFor="slug">Slug</label>
                    <input
                      placeholder="Slug"
                      type="text"
                      className="form-control"
                      name="slug"
                      id="slug"
                      value={appData?.slug || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Descrição curta</label>
                    <input
                      placeholder="Descrição"
                      type="text"
                      className="form-control"
                      name="description"
                      id="description"
                      value={appData?.description || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="featuredImage">Featured Image</label>
                    <input
                      placeholder="Imagem de destaque desta categoria"
                      type="text"
                      className="form-control"
                      name="featuredImage"
                      id="featuredImage"
                      value={appData?.featuredImage || ""}
                      onChange={handleChange}
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="parentCategory">Selecionar uma categoria pai</label>
                    <input
                      placeholder="Digite o id da categoria pai"
                      type="text"
                      className="form-control"
                      name="parentCategory"
                      id="parentCategory"
                      value={appData?.parentCategory || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-dark mr-2">Salvar</button>
                  </div>
                </div>
                <div className="col-md-4 mt-4 ">
                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select
                      id="status"
                      name="status"
                      className="form-control"
                      value={appData?.status || ""}
                      onChange={handleChange}
                    >
                      <option>Escolha a opção</option>
                      <option value="enabled">Online</option>
                      <option value="disabled">Offline</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sitemap">Habilitar no Sitemap?</label>
                    <select
                      id="sitemap"
                      name="sitemap"
                      className="form-control"
                      value={appData?.sitemap || ""}
                      onChange={handleChange}
                    >
                      <option>Escolha a opção</option>
                      <option value="enabled">Sim</option>
                      <option value="disabled">Não</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="showAppNavigation">Mostrar no menu do app?</label>
                    <select
                      id="showAppNavigation"
                      name="showAppNavigation"
                      className="form-control"
                      value={appData?.showAppNavigation || ""}
                      onChange={handleChange}
                    >
                      <option>Escolha a opção</option>
                      <option value="enabled">Sim</option>
                      <option value="disabled">Não</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="showWebNavigation">Mostrar no menu do site?</label>
                    <select
                      id="showWebNavigation"
                      name="showWebNavigation"
                      className="form-control"
                      value={appData?.showWebNavigation || ""}
                      onChange={handleChange}
                    >
                      <option>Escolha a opção</option>
                      <option value="enabled">Sim</option>
                      <option value="disabled">Não</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}