import React, { Component } from 'react';
import Loader from 'react-loader-spinner';

import Config from '../../services/config';
import api from '../../services/Users';
import AuthPage from '../../components/AuthPage';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

import './styles.css';

export default class Main extends Component {

  constructor(props) {
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;
    super(props);
    this.state = {
      userdata: {},
      authorization,
      userid
    };
  }

  componentDidMount() {
        const authorization = this.state.authorization;
        const userid = this.state.userid;
        api.get('/user/detail',{
            headers: { authorization, userid },
        })
        .then(res => {
            this.setState({ userdata: res.data.user });
        });
        
    }

  render(){
    return (
      <>

        <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner"
        />


      
        <AuthPage />
        <div className="container-scroller">
            <Header />

              <div className="container-fluid page-body-wrapper "> 

                <div className="main-panel">
                  <div className="content-wrapper">
                      <div className="row text-left">
                          <div className="col-md-6 textWelcome">
                            <h2 className="text-clean"> Quem Somos </h2>
                            <p className="textWelcome3">
                            A Pagefy é uma startup com sede em São Paulo focada em desenvolver soluções de meios de pagamento digitais e e-commerce. <br/><br/>
                            Especializada no desenvolvimento de soluções em plataforma web, desktop e mobile.
Temos um portifólio de clientes dos mais diversos segmentos que hoje já somam mais de um milhão de reais em transações financeiras através de nossa plataforma.
                            </p>

                            <h3 className="text-clean mt-3 spacer"> Democratização da tecnologia </h3>
                            <p className="textWelcome3">
                            A Pagefy TECNOLOGIA é uma empresa de soluções digitais: integra meios de pagamento e vendas web/mobile com soluções 100% White Label. <br/>
                            Criamos uma plataforma fácil e inovadora, agregando soluções de baixo custo e com altos índices de conversões para B2B ou B2C.
                            </p>


                            <h3 className="text-clean mt-3 spacer"> Tendências </h3>
                            <h4 className="text-clean mt-3"> A ERA DO CONSUMO ATRAVÉS DO E-COMMERCE  </h4>
                            <p className="textWelcome3">
                            Dados da Anatel indicam que hoje há mais números habilitados no Brasil do que habitantes – muitos possuem mais de uma linha em seus aparelhos. <br/>
                            Os celulares estão chegando cada vez mais cedo nas mãos das crianças. Uma pesquisa da Mobile Time, realizada em parceria com Opinion Box, indicou que nas casas em que há smartphones, mais de 70% das crianças entre 10 e 12 anos já têm o seu próprio celular.
                            O mundo ainda conta com uma nova era pós Covid19, no qual o comportamento de consume já mostrou estar em mutação. As empresas do varejo e empreendedores viram o digital como opção principal para suas atividades.
                            </p>

                            <h3 className="text-clean mt-3 spacer"> Área de atuação  </h3>
                            <p className="textWelcome3">
                              <div className="row">
                                <div className="col-md-8">
                                  <ul>
                                    <li>
                                      PLATAFORMAS DE E-COMERCE E MOBILE COMMERCE INTEGRADOS
                                    </li>
                                    <li>
                                      SISTEMAS DE ASSINATURAS E VENDA DE VOUNCHERS
                                    </li>
                                    <li>
                                      SISTEMAS DE CAPTAÇÃO DE RECURSOS PARA ONGS E IGREJAS
                                    </li>
                                    <li>
                                      VENDAS DE INGRESSOS ONLINE
                                    </li>
                                    <li>
                                      SOLUÇÕES PARA MARKET PLACE
                                    </li>
                                    <li>
                                      INTEGRAÇÕES DE MEIOS DE PAGAMENTO
                                    </li>
                                  <li>
                                      AUTOMAÇÃO DE MEIOS DE PAGAMENTO DIGITAIS
                                  </li>
                                  <li>
                                    DESENVOLVIMENTO DE SOFTWARES E APPS
                                  </li>
                                  <li>
                                    SOLUÇÕES NA NUVEM PARA ARMAZENAMENTO, TRANSMISSÃO DE VÍDEO E INFRA ESTRUTURA
                                  </li>
                                </ul>
                                </div>
                              </div>
                            </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            <Footer />

        </div>

    </>
    );
  }

}
