import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import ReactPlayer from "react-player";


import Header from '../../../../components/Header';
import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPage';
import Footer from '../../../../components/Footer';
import api from '../../../../services/Users';
import PaymentStatusGtw1 from '../../../../components/ChargesComponents/PaymentStatusGtw1';

import './styles.css';

export default class Videos extends Component {

    constructor(props) {

        const { match: { params } } = props;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const authorization = Config.AuthApiSecretKey;
        const tutorialId = params.id;
        const customerId = credencialdata._id;

        super(props);
        this.state = {
          authorization,
          tutorialId,
          customerId,
          userdata: {},
          tutorialdata: {},
          videodata: [],
          videoPlay: [],
          onActive: []
        };
      }

      loadUserData(){
        const authorization = this.state.authorization;
        const customerId = this.state.customerId;

        api.get('/mobcustomer/detail', {
            headers: { authorization, userId: customerId },
        })
        .then(res => {
            this.setState({ userdata: res.data.user });
        });
      }

      loadTutorialData(){
        const authorization = this.state.authorization;
        const customerId = this.state.customerId;

        api.get(`/tutorial/${this.state.tutorialId}`, {
            headers: { authorization, userId: customerId },
        })
        .then(res => {
            this.setState({ tutorialdata: res.data.data });
        });
      }

      loadVideoPlay(src){
        if(src){
          this.setState({ videoPlay: this.state.videodata[0]?.videoUrl });
        } else {
          this.setState({ videoPlay: src });
        }

        return this.state.videoPlay;
        
      }

      async loadTutorialVideosData(){
        const authorization = this.state.authorization;
        const customerId = this.state.customerId;

        await api.get(`/tutorials/videos/${this.state.tutorialId}`, {
            headers: { authorization, userId: customerId },
        })
        .then(res => {
          this.setState({ videodata: res.data.videos });
        });

      }

      componentDidMount() {
        this.loadUserData();
        this.loadTutorialData();
        this.loadTutorialVideosData();
        this.loadVideoPlay()
      }

    render(){

      return (
        <>
          <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
          />

          <AuthPage />

          <div className="container-scroller">

            <Header />

                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                            <div className="row">

                                <div className="col-md-12">

                                      <div className="row border-bottom">
                                              <div className="col-md-6">
                                                  <h3> {this.state.tutorialdata?.title} </h3>
                                                  <p className="text-grey"> {this.state.tutorialdata?.description} </p>
                                              </div>
                                              <div className="col-md-6 text-right">
                                                <Link to='/user/tutorials/' className="btn btn-outline-twitter btn-sm">
                                                  Ver todos treinamentos
                                                </Link>
                                              </div>
                                      </div>
                                      
                                </div>

                            </div>

                            <div className="row mt-3">
                              <div className="col-md-8">
                                <div className="player-wrapper">
                                  <ReactPlayer
                                    className='react-player'
                                    url={this.state.videoPlay || this.state.videodata[0]?.videoUrl}
                                    width='100%'
                                    height='100%'
                                    playing
                                    controls = 'false'
                                    pip
                                    getDuration
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 border-left">
                                  <div className="video-list">
                                    <ul>
                                    {this.state.videodata.map((video, index) => (
                                      <li className={`video-item ${this.state.onActive}`} key={index} onClick={ () => 
                                        this.setState({ videoPlay : video.videoUrl })
                                      }>
                                          <strong> {index}. </strong> {video.title}
                                      </li>
                                    ))}
                                    </ul>
                                  </div>
                              </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
        </>
      )
    }
}