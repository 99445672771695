import React from 'react';

import { Link } from 'react-router-dom';
import notFound from '../../assets/images/notfound.svg';

export default function NoMatch404Page() {
  return (
    <div className="container-scroller">
    <div className="container-fluid page-body-wrapper full-page-wrapper">
      <div className="content-wrapper d-flex align-items-center text-center error-page">
        <div className="row flex-grow">
          <div className="col-lg-7 mx-auto text-white">
            <div className="row align-items-center d-flex flex-row">
              <div className="col-lg-12">
                <img src={notFound} alt="Pagefie" />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 text-center mt-xl-2">
              <Link to="/main" className="btn btn-outline-dark btn-fw">
                    Ir para Dashboard
               </Link>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 mt-xl-2">
                <p className="text-white font-weight-medium text-center">Copyright &copy; 2019  Pagefy Tecnologia | Todos os direitos reservados.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
