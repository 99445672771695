import React from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import StatementTransactionsResume from '../../../components/StatementTransactionsResume';

export default function Transactions() {
  return (
    <>
      <AuthPage />
      <div className="container-scroller">
          <Header />
              <div className="container-fluid page-body-wrapper">
                  <div className="main-panel">
                  <div className="content-wrapper">
                      <StatementTransactionsResume />
                  </div>
              </div>
          </div>
          <Footer />
      </div>
    </>
  );
}
