import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import Config from '../../services/config';
import api from '../../services/Users';
import Status from '../../components/Common/Status';

import './styles.css';
import notFound from '../../assets/images/list-empty.png';


export default class Contracts extends Component {

    constructor(props) {

        const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const userid = credencialdata._id;
        
        super(props);
            this.state = {
            contractsdata: [],
            authorization,
            userdata: {},
            authorizationKey: Config.AuthApiSecretKey,
            userId: userid,
            };
        }

        loadContracts(){
          const authorization = this.state.authorization;
          const userid = this.state.userid;
      
          api.get(`/contracts/user/${this.state.userId}`,{
              headers: { authorization },
          })
          .then(res => {
              this.setState({ contractsdata: res.data.contracts });
          });
      }

      componentDidMount() {
        this.loadContracts();
      }
    

    render(){

      return (
        <>

          <div className="container-scroller">

                <div className="container-fluid page-body-wrapper">

                    <div className="main-panel">

                        <div className="">

                            <div className="row">

                            <div className="col-md-12">

                            <div className="row">
                                <div className="col-md-12 mt-4 mb-4">
                                    <h3 className="titleMid mt-2"> Meus Contratos </h3>
                                </div>
                                <div className="col-md-12">
                                        
                                        <div className="table-responsive">
                                                        
                                          {!this.state.contractsdata.length ? (
                                              <>
                                                <p className="text-left text-grey p-4">
                                                    Não encontramos nenhum contrato para apresentar aqui ainda! <br/>
                                                    <img src={notFound} alt="List not Found" /> 
                                                </p>
                                              </>
                                          ) : (
                                              <>
                                                  <table className="table">
                                                      

                                                      <tbody>
                                                          
                                                          {this.state.contractsdata.map((contract, index) => (
                                                              <tr key={contract._id} className=''>

                                                                  <td> 
                                                                      <div className="col-md-12">
                                                                          <Link to={`/admin/contract/${contract._id}`} className="text-dark trLink">
                                                                              <h4> {contract.productName} </h4>
                                                                          </Link>
                                                                      </div>

                                                                      <div className="col-md-12 mt-2 text-grey">
                                                                          Data da Contratação: <strong> <Moment format="DD/MM/YYYY" date={contract.createdAt}  /> </strong> <br/>

                                                                          <span className="text-grey mt-2"> Descrição: {contract.domainUrl ? ( <span className="text-twitter"> {contract.domainUrl} </span> )  : 
                                                                              ( <span className="text-danger"> Não configurado </span> ) } </span> <br/>

                                                                          <span className="text-grey mt-2"> Status do contrato: 
                                                                              <Status statusId={contract.status} />  </span>
                                                                          
                                                                      </div>
                                                                  </td>
                                                                  
                                                                  <td className="text-right"> 

                                                                      <Link to={`/user/invoices/${contract._id}/0`} className="btn btn-dark btn-sm mr-3">
                                                                          Faturas
                                                                      </Link> 
                                                                      
                                                                      <Link to={`/user/contract/${contract._id}`} className="btn btn-outline-dark btn-sm mr-3">
                                                                          Detalhes
                                                                      </Link> 
                                                                      
                                                                  </td>
                                                              </tr>
                                                          ))}
                                                      </tbody>
                                                  </table>

                                                  <div className="mt-4">
                                                      <p className="card-description text-dark mt-3 text-small">
                                                          Evite a desconexão de seus serviços efetuando o pagamento regularmente de seus serviços. 
                                                      </p>
                                                  </div>
                                              </>
                                          )}
                                </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
        </>
      )
    }
}