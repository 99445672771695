import React from 'react';

import AuthPage from '../../../../components/AuthPageAdmin';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import NavigationMenu from '../../../../components/AdminConsoleComponents/Navigation';
import UsersStore from '../../../../components/AdminConsoleComponents/UsersAdminConsole/Store';
import WelcomeConsole from '../../../../components/AdminConsoleComponents/WelcomeConsole';

export default function Store() {
  return (
    <>
        <AuthPage />
        <div className="container-scroller">
            <HeaderAdminConsole />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                        <div class="row">

                            <div class="col-md-12 grid-margin">
                                <WelcomeConsole />
                            </div>

                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div class="card">
                                            <div class="card-body text-center">
                                                <NavigationMenu />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-8">
                                        <div class="card">
                                            <div class="card-body">
                                                <UsersStore />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
