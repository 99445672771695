import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import Alerts from '../../components/Alerts';
import Config from '../../services/config';
import api from '../../services/login';
import PaymentStatus from '../ChargesComponents/PaymentStatus';
import PaymentType from '../ChargesComponents/PaymentType';
import PaymentCycle from '../ChargesComponents/PaymentCycle';
import QrCode from '../Qrcode';

import './styles.css';
// import creditcardImg from '../../assets/cards/visa.png';

export default function ChargeDetailResume(props) {

  const transactionID = props.transactionId;
  const [ paymentData, setPaymentData ] = useState([]);

  async function handleChargeback(){
    await Alerts("Estorno de Transação", `Sua transação ${transactionID}, para confirmar clique em  confirmar ` ,"warning");
  }

  useEffect(() => {
    
    window.scrollTo(0, 0);
    
    // const results = props.results;
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;
    
    async function loadData() {
          const response = await api.get(`/payment/detail/${transactionID}`, {
              headers: { authorization, customerid: userid },
          })
          setPaymentData(response.data.payment);
          console.log(response.data.payment);
      }
      loadData();
    },[]);

  return (
    <>
    <div className="row">
        <div className="col-12">

            <div className="row mb-4 border-bottom">
                
                <div className="col-md-6 col-sm-12">

                      <h3 className="card-title"> 
                        <small> Cobrança <strong> <PaymentType paymentType={paymentData.chargeType || ""} />  </strong> </small> <br/>
                        <small> TID <strong> <span className="text-dark"> #{transactionID} </span> </strong> </small>
                      </h3>

                      <p className="text-muted mt-2"> 
                          Cobranças gerada em: <span className="text-twitter"> <Moment format="DD/MM/YYYY" date={paymentData.createdAt || ""} /> </span> 
                      </p>

                </div>

                <div className="col-md-3 col-sm-12 text-left border-left"> 

                  <h3> {paymentData.amount} </h3>

                  <p className="text-grey"> 
                    Vencimento: <span className="text-twitter"> {paymentData.duoDate || ""} </span> <br/>
                    Ciclo de Pagamento: <PaymentCycle status={paymentData.chargeCycle} /> <br/> 
                    Status: <PaymentStatus status={paymentData.status} />
                  </p>
                     
                </div>

                <div className="col-md-3 text-right"> 

                  <Link to="/payments" className="btn btn-outline-twitter btn-sm">
                      Suas Cobranças
                  </Link> 

                  <button className="btn btn-outline-danger btn-sm mt-3" onClick={handleChargeback}>
                      Extornar Transação
                  </button> 

                </div>

            </div>

            
            <div className="row">

                  <div className="col-md-8 col-sm-12">
                    <h4 className="text-dark text-weight-bolder mb-4 mt-4"> DETALHES DA COBRANÇA </h4>

                    <div className="row softGrey">

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> CLIENTE </p>
                          <p className="text-dark"> 
                            <strong> {paymentData.customerName} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> DOCUMENTO </p>
                          <p className="text-dark"> 
                            <strong> {paymentData.customerDocument} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> TID </p>
                          <p className="text-dark"> 
                            <strong> {paymentData._id} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> VALOR DA COBRANÇA </p>
                          <p className="text-dark"> 
                            <strong> {paymentData.amount} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> CICLO DE PAGAMENTO </p>
                          <p className="text-dark"> 
                            <strong> <PaymentCycle status={paymentData.chargeCycle} /> </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> NOSSO NÚMERO </p>
                          <p className="text-dark"> 
                            <strong> {paymentData.ourNumber || "Não informado"}  </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> TAXA DE JÚROS </p>
                          <p className="text-dark"> 
                            <strong> {paymentData.interest || "Não Informado"}  </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> STATUS </p>
                          <p className="text-dark"> 
                            <PaymentStatus status={paymentData.status} />  </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> PARCELAR EM ATÉ </p>
                          <p className="text-dark"> 
                            {paymentData.enableInstallments}x </p>
                      </div>

                      <div className="col-md-12 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> DESCRIÇÃO </p>
                          <p className="text-dark"> 
                          <strong> {paymentData.description || 'Não informado'}  </strong> </p>
                      </div>

                    </div>
                    
                  </div>

                  <div className="col-md-4 col-sm-12">
                    <h4 className="text-dark text-weight-bolder mb-4 mt-4"> HISTÓRICO DA COBRANÇA </h4>

                    <ul className="bullet-line-list">

                      <li>
                          <h6 className="chargeTitle text-twitter"> Cobrança Gerada </h6>
                          <p> Valor: {paymentData.amount} </p>
                          <p className="text-muted mb-4">
                              <i className="ti-time mr-2"></i>
                              <Moment format="DD/MM/YYYY" date={paymentData.createdAt || ""} />
                          </p>
                      </li>

                      { paymentData.status === 'PENDING' ? '' : (
                      <>
                      <li>
                          <h6 className="chargeTitle"> Pagamento Captura </h6>
                          <p> Valor: R$ 40,00 </p>
                          <p className="text-muted mb-4">
                              <i className="ti-time mr-2"></i>
                              09/12/2019 22:10
                          </p>
                      </li>
                      <li>
                          <h6 className="chargeTitle"> Anti Fraude </h6>
                          <p> Risco: Baixo </p>
                          <p className="text-muted mb-4">
                              <i className="ti-time mr-2"></i>
                              09/12/2019 22:10
                          </p>
                      </li>
                      <li>
                          <h6 className="chargeTitle"> Autorização </h6>
                          <p> Código de Autorização: 10233000 </p>
                          <p className="text-muted">
                              <i className="ti-time mr-2"></i>
                              09/12/2019 22:10
                          </p>
                      </li>
                      </>
                      ) }

                    </ul>


                  </div>

            </div>

            { paymentData.chargeType === 'BOLETO' ? (
              <>
                <div className="row">
                  <div className="col-md-8 col-sm-12">
                  <h4 className="text-dark text-weight-bolder mb-4 mt-5"> BOLETO </h4>

                      <div className="row softGrey">

                          <div className="col-md-6 col-sm-12 mt-3">
                              <p className="text-grey border-bottom"> Você pode baixar o boleto através do link abaixo, ou você pode scanear com seu celular o QRCode ao lado </p>
                              <p className="text-grey"> 
                                  <a href={`${Config.PayLinkUrl}/${paymentData._id} `} className="linkStyle">
                                    <strong> {`${Config.PayLinkUrl}/${paymentData._id} `} </strong> 
                                  </a>

                              </p>
                          </div>

                          <div className="col-md-6 col-sm-12 mt-3 text-right">
                            <QrCode 
                                  qrContent={`${Config.PayLinkUrl}/${paymentData._id}`} 
                                  qrW="120" 
                                  qrH="120" />
                          </div>

                      </div>

                  </div>
                </div>
              </>
              ) : ''}

              { paymentData.chargeType === 'CREDIT_CARD' || paymentData.chargeType === 'UNDEFINED' ? (
              <>
                <div className="row">
                  <div className="col-md-8 col-sm-12">
                  <h4 className="text-dark text-weight-bolder mb-4 mt-5"> LINK DA COBRANÇA </h4>

                      <div className="row softGrey">

                          <div className="col-md-6 col-sm-12 mt-3">
                              <p className="text-grey border-bottom"> Para acessar o boleto clique no link abaixo, ou você pode scanear com seu celular o QRCode ao lado </p>
                              <p className="text-grey"> 
                                  <a href={`${Config.PayLinkUrl}/${paymentData._id} `} className="linkStyle">
                                    <strong> {`${Config.PayLinkUrl}/${paymentData._id} `} </strong> 
                                  </a>

                              </p>
                          </div>

                          <div className="col-md-6 col-sm-12 mt-3 text-right">
                            <QrCode 
                                  qrContent={`${Config.PayLinkUrl}/${paymentData._id}`} 
                                  qrW="120" 
                                  qrH="120" />
                          </div>

                      </div>

                  </div>
                </div>
              </>
              ) : ''}
            
            </div>
             
    </div>
</>
  );
}
