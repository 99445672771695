import React from 'react';

import AuthPage from '../../../../components/AuthPageAdmin';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import NavigationMenu from '../../../../components/AdminConsoleComponents/Navigation';
import ProductUpdate from '../../../../components/AdminConsoleComponents/Products/Update';
import WelcomeConsole from '../../../../components/AdminConsoleComponents/WelcomeConsole';


export default function Update(props) {

  const { match: { params } } = props;
  const product_id = params.id

  return (
    <>
        <AuthPage />
        <div className="container-scroller">
            <HeaderAdminConsole />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                        <div className="row">

                            <div className="col-md-12 grid-margin">
                                <WelcomeConsole />
                                
                            </div>

                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <NavigationMenu />
                                            </div>
                                            </div>
                                        </div>

                                    <div className="col-md-8">
                                        <div className="card">
                                            <div className="card-body">
                                                <ProductUpdate productId={product_id} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
