import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Config from '../../../services/config';
import api from '../../../services/Users';
import QrCode from '../../Qrcode';

import './styles.css';

export default class Detail extends Component {

  constructor(props) {
        
    const authorization = Config.AuthApiSecretKey;

    super(props);
    this.state = {
        userdata: {},
        authorization,
        userid: this.props.userId,
        customerid: this.props.customerId
    };
    
}

componentDidMount() {

    const authorization = this.state.authorization;
    const userid = this.state.userid;
    const customerid = this.state.customerid;

    api.get(`/customer/${customerid}`, {
        headers: { authorization, mobcustomerid: userid },
    })
    .then(res => {
        this.setState({ userdata: res.data.user });
        // console.log(res.data.user);
    });

  }

  render() {
    return (
      <>
        <div>
                <div className="row mb-3">

                    <div className="col-md-1">
                        <QrCode 
                            qrContent={this.state.customerid} 
                            qrW="60" 
                            qrH="60" />
                    </div>

                    <div className="col-md-6">
                        <h3> <small> Cliente / </small>  <br/> <span className="text-twitter">  {this.state.userdata.name || "Não Informado"}  </span> </h3>
                    </div>

                    <div className="col-md-5 text-right">
                        <Link to="/customers" className="btn btn-outline-secondary btn-sm">
                            <i className="ti-list-ol"></i> Lista de Clientes
                        </Link>
                    </div>

                </div>

                <div className="row customerDetailHeaderBox mb-3">

                    <div className="col-md-6 text-left">
                        <p className="text-grey mt-2"> 
                        ID# {this.props.customerId} <br/>
                        Cliente desde: {this.state.userdata.createdAt} </p>
                    </div>

                    <div className="col-md-6 text-right mt-3 mb-3">
                        
                        <Link to="/customers" className="btn btn-outline-twitter btn-sm mr-2">
                            Recorrências
                        </Link>

                        <Link to="/customers" className="btn btn-outline-twitter btn-sm">
                            Wallet
                        </Link>

                        <Link to={`/charges/store/${this.props.customerId}`} className="btn btn-twitter btn-sm ml-2">
                            Gerar Cobrança
                        </Link>

                    </div>

                    

                </div>

                <div className="row">
                    <div className="col-md-6 border-right pr5">
                    <div className=" mt-3">
                        <div className="">

                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h4 className="text-twitter text-uppercase"> 
                                        Dados do Cliente 
                                    </h4>
                                </div>
                                <div className="col-md-6 text-right">

                                    { this.state.userdata.personType === 'person' ? (
                                        <div className="badge badge-outline-primary badge-pill text-uppercase"> 
                                            Pessoa Física
                                        </div>
                                    ):(
                                        <div className="badge badge-outline-success badge-pill text-uppercase"> 
                                            Pessoa Jurídica
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                        
                        <div className="customerItem">
                            <div className="row border-bottom">
                                <div className="col-md-6 pr3">
                                    <label className="text-uppercase"> 
                                        <strong> Nome </strong> 
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span className="card-text text-grey"> 
                                        {this.state.userdata.name || "Não Informado"}  </span>    
                                </div>
                            </div>
                        </div>

                        <div className="customerItem">
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <label className="text-uppercase"> 
                                        <strong> Documento </strong> 
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span className="card-text text-grey"> 
                                        {this.state.userdata.customerDocument || "Não Informado"}  </span>    
                                </div>
                            </div>
                        </div>

                        <div className="customerItem">
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <label className="text-uppercase"> 
                                        <strong> E-mail </strong> 
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span className="card-text text-grey"> 
                                        {this.state.userdata.email || "Não Informado"}  </span>    
                                </div>
                            </div>
                        </div>


                        <div className="customerItem">
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <label className="text-uppercase"> 
                                        <strong> Telefone Comercial </strong> 
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span className="card-text text-grey"> 
                                        {this.state.userdata.companyTel || "Não Informado"}  </span>    
                                </div>
                            </div>
                        </div>

                        <div className="customerItem">
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <label className="text-uppercase"> 
                                        <strong> Website </strong> 
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span className="card-text text-grey"> 
                                        {this.state.userdata.companyWebsite || "Não Informado"}  </span>    
                                </div>
                            </div>
                        </div>

                        <div className="customerItem">
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <label className="text-uppercase"> 
                                        <strong> Tipo de Empresa </strong> 
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span className="card-text text-grey text-uppercase"> 
                                        {this.state.userdata.companyType || "Não Informado"}  </span>  
                                </div>
                            </div>
                        </div>

                        <div className="customerItem">
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <label className="text-uppercase"> 
                                        <strong> Endereço </strong> 
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span className="card-text text-grey"> 
                                        {this.state.userdata.addr || "Não Informado"}, {this.state.userdata.addrNumber || "Não Informado"}   
                                    </span>  
                                </div>
                            </div>
                        </div>

                        <div className="customerItem">
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <label className="text-uppercase"> 
                                        <strong> CEP </strong> 
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span className="card-text text-grey"> 
                                        {this.state.userdata.addrZip || "Não Informado"}   </span>  
                                </div>
                            </div>
                        </div>

                        <div className="customerItem">
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <label className="text-uppercase">  <strong> Complemento  </strong> </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span className="card-text text-grey"> 
                                        {this.state.userdata.addrComplement || "Não Informado"}   </span>  
                                </div>
                            </div>
                        </div>

                        <div className="customerItem">
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <label className="text-uppercase"> 
                                        <strong> Bairro </strong> 
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span className="card-text text-grey"> 
                                        {this.state.userdata.addrNeighborhood || "Não Informado"}    </span>  
                                </div>
                            </div>
                        </div>

                        <div className="customerItem">
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <label className="text-uppercase"> 
                                        <strong> Cidade </strong> 
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span className="card-text text-grey"> 
                                        {this.state.userdata.addrCity || "Não Informado"}  </span>  
                                </div>
                            </div>
                        </div>

                        <div className="customerItem">
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <label className="text-uppercase"> 
                                        <strong> Estado </strong>  
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span className="card-text text-grey"> 
                                        {this.state.userdata.addrState || "Não Informado"}  </span>  
                                </div>
                            </div>
                        </div>

                        <div className="customerItem">
                            <div className="row">
                                <Link to={`/customer/update/${this.state.customerid}`} className="btn btn-outline-twitter btn-sm">
                                    Editar Cliente
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
                    
                    <div className="col-md-6 pl-5">
                        <div className="mt-3">

                            <div className="row">

                                <div className="col-md-6">
                                    <h4 className="text-twitter text-uppercase"> 
                                        Transações
                                    </h4>
                                </div>

                                <div className="col-md-12 mt-3 ">
                                    <div className="card data-icon-card-dark text-left">
                                        <div className="card-body">
                                            <div className="row text-right">
                                                <p className="card-title text-white"> Total Transacionado </p>                      
                                                <div className="col-8 text-white ">
                                                    <h3>R$ 0,00 </h3>
                                                    <p className="text-white font-weight-light mb-0">
                                                        Total transacionado pelo cliente até o momento. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-md-12">

                                    

                                </div>
                            </div>

                            <h5 className="customerItem mt-5 text-uppercase"> Histórico de Transações </h5>
                            
                            {/*  */}
                            <div className="customerItem">
                                <p className="card-title text-grey"> Transações realizadas pelo cliente nos últimos 30 dias </p>
                                    <div className="d-flex align-items-center border-top pt-3 mt-3 mb-3">
                                        <i className="ti-credit-card mdi-30px"></i>
                                        <div className="ml-3">
                                            <h6 className="mb-1 text-twitter"> Assinatura Plano Pagefy Cloud </h6>
                                            <small className="text-muted mb-0"> 
                                                Cartão de Crédito Á Vista - Valor R$ 300,00 <br/> 
                                                Vencimento: 21/02/2020 <br/>
                                                Status: Pagamento Aprovado
                                            </small>
                                        </div>
                                        <i className="ti-control-record font-weight-bold ml-auto px-1 py-1 text-success mdi-24px iconStatus"></i>
                                    </div>
                                    
                                    <div className="d-flex align-items-center border-top pt-3  mt-3 mb-3">
                                        <i className="ti-layout-column4-alt mdi-30px"></i>
                                        <div className="ml-3">
                                            <h6 className="mb-1 text-twitter"> Assinatura Pagefy Cloud Drive </h6>
                                            <small className="text-muted mb-0"> 
                                                Boleto Bancário - Valor R$ 150,00 <br/> 
                                                Vencimento: 21/01/2020 <br/>
                                                Status: Pagamento Recusado
                                            </small>
                                        </div>
                                        <i className="ti-control-record font-weight-bold ml-auto px-1 py-1 text-danger mdi-30px iconStatus"></i>
                                    </div>

                                    <div className="d-flex align-items-center border-top pt-3  mt-3 mb-3">
                                        <i className="ti-layout-column4-alt mdi-30px"></i>
                                        <div className="ml-3">
                                            <h6 className="mb-1 text-twitter"> Assinatura Pagefy Cloud Drive </h6>
                                            <small className="text-muted mb-0"> 
                                                Boleto Bancário - Valor R$ 150,00 <br/> 
                                                Vencimento: 21/01/2020 <br/>
                                                Status: Aguardando Pagamento
                                            </small>
                                        </div>
                                        <i className="ti-control-record font-weight-bold ml-auto px-1 py-1 text-warning mdi-30px iconStatus"></i>
                                    </div>


                                </div>
                            {/*  */}

                        </div>
                    </div>
              </div>
            </div>
        </>
    );
  }
}
