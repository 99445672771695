import React from 'react';

export default function OrderStatus(props) {

    const oStatus = props.status;

    switch(oStatus) {
      case 'created':
        return (<span className="text-grey"> Pedido Criado </span>);
      case 'waiting-payment':
        return (<span className="text-warning"> Aguardando Pagamento </span>);
      case 'paid':
        return (<span className="text-success"> Pedido Pago </span>);
      case 'cancelled':
        return (<span className="text-danger"> Pedido Cancelado </span>);
      default:
          return (<span className="text-gray"> Sem Status </span>);

    }
    
}
