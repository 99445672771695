import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import Config from '../../../services/config';
import api from '../../../services/login';
// import PaymentCycle from '../../../components/ChargesComponents/PaymentCycle';
// import PaymentType from '../../../components/ChargesComponents/PaymentType';

import './styles.css';

export default function Checkout(props) {

  const { match: { params } } = props;
  const id = params.id;

  const [ paymentData, setPaymentData ] = useState([]);
  const [ name, setName ] = useState([]);
  const [ cpfCnpj, setCpfCnpj ] = useState([]);
  const [ phone, setPhone ] = useState([]);
  const [ cep, setCep ] = useState([]);
  const [ email, setEmail ] = useState([]);
  const [ addNumber, setAddrNumber ] = useState([]);
  const [ addrComplement, setAddrComplement ] = useState([]);
  const [ cardNumber, setCardNumber ] = useState([]);
  const [ holderName, setHolderName ] = useState([]);
  const [ expiryMonth, setExpiryMonth ] = useState([]);
  const [ expiryYear, setExpiryYear ] = useState([]);
  const [ cvv, setCvv ] = useState([]);

  useEffect(() => {
    
  const authorization = Config.AuthApiSecretKey;

  console.log(name, cpfCnpj, phone, cep, email, addNumber, addrComplement, cardNumber, holderName, expiryMonth, expiryYear, cvv);

  async function loadData() {
    try {
      const response = await api.get(`/payment/detail/${id}`, {
          headers: { authorization },
      })
      setPaymentData(response.data.payment);
      } catch (error){ 
        window.location='../404';
    }
  }
    loadData();
  },[]);

  return (
    <>
      <Loader
       type="BallTriangle"
       color="#000000"
       height={60}
       width={60}
       timeout={Config.loaderTimeout}
       className="overlaySpinner" //3 secs
      />

      {!id ? (
        <>
          <div className="row">
            <div className="col-md-12 text-center mt-5">
              <h3> Oops! algo deu errado! </h3>
              <Link to="/" className="btn btn-twitter btn-sm ml-3 mt-3">
                Voltar para o Site
              </Link>
            </div>
          </div>
        </>
      ) : (

      <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
          <div className="row flex-grow">
           
            <div className="col-lg-6 d-flex align-items-center justify-content-center mt-2">

              <div className="auth-form-transparent text-left">
                
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="pb-1"> Checkout </h3>
                  </div>
                  <div className="col-md-6 text-right">
                    <strong className="text-twitter"> #{id} </strong> 
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6">
                    <p> Vencimento: <strong className="text-twitter"> {paymentData.duoDate} </strong> </p>
                  </div>
                  <div className="col-md-6 text-right">
                    <p> Valor: <strong className="text-twitter"> {paymentData.amount} </strong> </p>
                  </div>
                </div>

                <div className="row mt-4">

                    <div className="col-md-12 border-bottom col-sm-12 mb-3">
                      <h4> Dados do Cadastro </h4>
                    </div>

                    <div className="col-md-8 col-sm-12">
                      <label> 
                        <small> Nome Completo </small> 
                      </label>
                      <input 
                        type="text" 
                        id="name"
                        name="name"
                        className="form-control form-control-sm" 
                        placeholder="Nome Completo"
                        // value={name}
                        onChange={e => setName(e.target.value)}
                        required
                        />    
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <label> 
                        <small> CPF/CNPJ </small> 
                      </label>
                      <input 
                        type="text"
                        id="cpfCnpj"
                        name="cpfCnpj"
                        className="form-control form-control-sm" 
                        placeholder="Apenas Números"
                        // value={name}
                        onChange={e => setCpfCnpj(e.target.value)}
                        required
                      />    
                    </div>

                    <div className="col-md-4 mt-3 col-sm-12">
                      <label> 
                        <small> Telefone </small> 
                      </label>
                      <input 
                        id="phone"
                        name="phone"
                        type="text" 
                        className="form-control form-control-sm" 
                        placeholder=" Telefone com DDD "
                        // value={name}
                        onChange={e => setPhone(e.target.value)}
                        required
                      />    
                    </div>

                    <div className="col-md-4 mt-3 col-sm-12">
                      <label> 
                        <small> CEP </small> 
                      </label>
                      <input 
                        type="text" 
                        id="cep"
                        name="cep"
                        className="form-control form-control-sm" 
                        placeholder=" Digite o seu CEP"
                        // value={name}
                        onChange={e => setCep(e.target.value)}
                        required
                      />    
                    </div>
                    
                    <div className="col-md-4 mt-3 col-sm-12">
                      <label> 
                        <small> Número </small> 
                      </label>
                      <input 
                        type="text" 
                        id="addrNumber"
                        name="addrNumber"
                        className="form-control form-control-sm" 
                        placeholder=" Entre com seu de e-mail"
                        // value={name}
                        onChange={e => setAddrNumber(e.target.value)}
                        required
                        />    
                    </div>

                    <div className="col-md-4 mt-3 col-sm-12">
                      <label> 
                        <small> Complemento </small> 
                      </label>
                      <input 
                        id="addrComplement"
                        name="addrComplement"
                        type="text" 
                        className="form-control form-control-sm" 
                        placeholder=" Complemento do seu endereço "
                        // value={name}
                        onChange={e => setAddrComplement(e.target.value)}
                        required
                      />    
                    </div>

                    <div className="col-md-4 mt-3 col-sm-12">
                      <label> 
                        <small> E-mail </small> 
                      </label>
                      <input 
                        id="email"
                        name="email"
                        type="text" 
                        className="form-control form-control-sm" 
                        placeholder=" Entre com seu de e-mail válido "
                        // value={name}
                        onChange={e => setEmail(e.target.value)}
                        required
                      />    
                    </div>

                    {paymentData.enableInstallments ? (
                      <div className=" col-md-12 mt-4">
                        <div className="form-group">
                            <label htmlFor="enableInstallments"> Parcelar em até {paymentData.enableInstallments}x  </label>
                            <select className="form-control"
                              id="enableInstallments"
                              name="enableInstallments"
                              // onChange={this.handleChange}
                            >
                            <option value=""> Não Parcelar </option>
                            <option value="2" > Parcelar em 2x </option>
                            <option value="3">  Parcelar em 3x </option>
                            <option value="4"> Parcelar em 4x </option>
                            <option value="5"> Parcelar em 5x </option>
                            <option value="6"> Parcelar em 6x </option>
                            <option value="7"> Parcelar em 7x </option>
                            <option value="8"> Parcelar em 8x </option>
                            <option value="9"> Parcelar em 9x </option>
                            <option value="10"> Parcelar em 10x </option>
                            <option value="11"> Parcelar em 11x </option>
                            <option value="12"> Parcelar em 12x </option>

                            </select>
                          </div>
                      </div>
                    ) : ''}

                    <div className="col-md-12 mt-4 border-bottom col-sm-12">
                      <h4> Dados do Cartão de Crédito </h4>
                    </div>

                    <div className="col-md-6 mt-3 col-sm-12">
                      <label> 
                        <small> Nome do Portador do Cartão </small> 
                      </label>
                      <input 
                        id="cardNumber"
                        name="cardNumber"
                        type="text" 
                        className="form-control form-control-sm" 
                        placeholder=" Nome do portador "
                        // value={name}
                        // onChange={e => setName(e.target.value)}
                        onChange={e => setCardNumber(e.target.value)}
                        required
                      />    
                    </div>

                    <div className="col-md-6 mt-3 col-sm-12">
                      <label> 
                        <small> Cartão de Crédito ( Número ) </small> 
                      </label>
                      <input 
                        id="holderName"
                        name="holderName"
                        type="text" 
                        className="form-control form-control-sm" 
                        placeholder=" Apenas Números "
                        // value={name}
                        onChange={e => setHolderName(e.target.value)}
                        required
                      />    
                    </div>

                    <div className="col-md-4 mt-3 col-sm-12">
                      <label> 
                        <small> Mês de expiração  </small> 
                      </label>
                      <input 
                        id="expiryMonth"
                        name="expiryMonth"
                        type="text" 
                        className="form-control form-control-sm" 
                        placeholder=" Ex: 01 "
                        maxLength="2"
                        // value={name}
                        onChange={e => setExpiryMonth(e.target.value)}
                        required
                      />    
                    </div>

                    <div className="col-md-4 mt-3 col-sm-12">
                      <label> 
                        <small> Ano de Expiração </small> 
                      </label>
                      <input 
                        id="expiryYear"
                        name="expiryYear"
                        type="text" 
                        className="form-control form-control-sm" 
                        placeholder=" Ex: 20 "
                        maxLength="2"
                        // value={name}
                        onChange={e => setExpiryYear(e.target.value)}
                        required
                      />    
                    </div>

                    <div className="col-md-4 mt-3 col-sm-12">
                      <label> 
                        <small> CVV </small> 
                      </label>
                      <input 
                        id="cvv"
                        name="cvv"
                        type="text" 
                        className="form-control form-control-sm" 
                        placeholder=" CVV do Cartão "
                        maxLength="3"
                        // value={name}
                        onChange={e => setCvv(e.target.value)}
                        required
                      />    
                    </div>

                  {/*  */}

                  <div className="col-md-12 col-sm-12"> 

                  </div>

                    {paymentData.chargeType !== "BOLETO" ? (
                      <div className="col-md-6 mt-5">
                        <button className="btn btn-success mr-2"> EFETUAR PAGAMENTO </button>
                      </div>
                    ) : ''}

                  <div className="col-md-12 mt-5 text-grey">
                    <small> 
                      Powered by Pagefy Tecnologia ®
                    </small>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-6 register-half-bg d-flex flex-row">
              {/* <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; 2019 - Todos os direitos reservados.</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    )}
      </>
       
  );
}
