import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import Alerts from '../../../../components/Alerts';
import Config from '../../../../services/config';
import api from '../../../../services/login';
import PaymentType from '../../../ChargesComponents/PaymentType';
import PaymentCycle from '../../../ChargesComponents/PaymentCycle';
import PaymentStatusGtw1 from '../../../ChargesComponents/PaymentStatusGtw1';

import './styles.css';

export default function ChargeDetailResume(props) {

  const transactionID = props.transactionId;
  const contractId = props.contractId;
  const customerId = props.customerId;
  const [ paymentData, setPaymentData ] = useState();

  async function handleChargeback(){
    await Alerts("Estorno de Transação", 
      `Sua transação ${transactionID}, para confirmar clique em  confirmar ` ,"warning");
  }

  useEffect(() => {
    
    window.scrollTo(0, 0);
    
    // const results = props.results;
    const authorization = Config.AuthApiSecretKey;

    async function loadData() {
          const response = await api.get(`/transaction/gtw1/${transactionID}`, {
              headers: { authorization },
          })
          setPaymentData(response.data.response);
      }
      loadData();
    },[]);

  return (
    <>
     <div className="row">
        <div className="col-12">

            <div className="row mb-4 border-bottom">
                
                <div className="col-md-4 col-sm-12">
                      <h3 className="card-title"> 
                        <small> Transação <span className="text-twitter"> {transactionID}  </span> </small> <br/>
                      </h3>
                      <p className="text-muted mt-2"> 
                          Transação gerada em: <Moment format="DD/MM/YYYY" date={paymentData?.date_created || ""} />
                      </p>
                </div>
                
                <div className="col-md-6 col-sm-12 text-left border-left"> 

                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <h3> {paymentData?.amount} </h3>
                      <p className="text-grey"> 
                        Vencimento: <span className="text-twitter"> 
                          <Moment format="DD/MM/YYYY" date={paymentData?.date_created || ""} /> </span> <br/>
                        Pago com: {paymentData?.payment_method} 
                      </p>
                    </div>

                    <div className="col-md-6 col-sm-12">
                        
                    </div>

                  </div>
                     
                </div>

                <div className="col-md-2 text-right"> 

                  <Link to={`/admin/transactions/${props?.contractId}/${customerId}`} className="btn btn-outline-twitter btn-sm">
                      Todas Transações 
                  </Link> 

                  {/* <button className="btn btn-outline-danger btn-sm mt-3" onClick={handleChargeback}>
                      Extornar Transação
                  </button>  */}

                </div>

            </div>

            
            <div className="row">

                  <div className="col-md-8 col-sm-12">

                    <h4 className="text-dark text-weight-bolder mb-4 mt-4"> Dados do Cliente </h4>

                    <div className="row softGrey">

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> CLIENTE </p>
                          <p className="text-dark text-uppercase"> 
                            <strong> {paymentData?.customer_name} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> DOCUMENTO </p>
                          <p className="text-dark"> 
                            <strong> {paymentData?.document_number} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> TID </p>
                          <p className="text-dark"> 
                            <strong> {transactionID} </strong> </p>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                          <p className="text-dark border-bottom"> VALOR DA COBRANÇA </p>
                          <p className="text-dark"> 
                            <strong> {paymentData?.amount} </strong> </p>
                      </div>

                      <div className="col-md-12 col-sm-12 mt-3">
                          <p className="text-dark border-bottom text-uppercase"> Metadados </p>
                          <p className="text-dark"> 
                          <strong> Número da ordem: {paymentData?.metadata.order_number || 'Não informado'}  </strong> </p>
                      </div>

                    </div>
                    
                  </div>

                  <div className="col-md-4 col-sm-12">
                    <h4 className="text-dark text-weight-bolder mb-4 mt-4"> Histórico da transação </h4>

                    <ul className="bullet-line-list">

                      <li>
                          <h6 className="chargeTitle"> Cobrança Gerada </h6>
                          <p> Valor: {paymentData?.amount} </p>
                          <p className="text-muted mb-4">
                              <i className="ti-time mr-2"></i>
                              <Moment format="DD/MM/YYYY" date={paymentData?.date_created || ""} />
                          </p>
                      </li>

                      { paymentData?.status === 'PENDING' ? '' : (
                      <>
                      <li>
                          <h6 className="chargeTitle"> Liquidação </h6>
                          <p> Valor: R$ {paymentData?.amount || ""} </p>
                          <p className="text-muted mb-4">
                              <i className="ti-time mr-2"></i>
                              <Moment format="DD/MM/YYYY" date={paymentData?.date_updated} />
                          </p>
                      </li>

                      { paymentData?.authorization_code ? (
                        <li>
                            <h6 className="chargeTitle"> Autorização </h6>
                            <p> Código de Autorização: {paymentData?.authorization_code || ""} </p>
                            <p className="text-muted">
                                <i className="ti-time mr-2"></i>
                                <Moment format="DD/MM/YYYY" date={paymentData?.date_updated} />
                            </p>
                        </li>
                      ) : ''}


                      </>
                      ) }

                    </ul>

                  </div>

            </div>

              <>

                <div className="row">

                  <div className="col-md-8 col-sm-12">
                  <h4 className="text-dark text-weight-bolder mb-4 mt-5"> Detalhes da Cobrança </h4>

                      <div className="row softGrey">

                        <div className="col-md-6 col-sm-12 mt-3">
                            <p className="text-dark border-bottom text-uppercase"> Meio de Pagamento Utilizado </p>
                            <p className="text-dark"> 
                            <strong>{paymentData?.payment_method} </strong></p>
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3">
                            <p className="text-dark border-bottom"> STATUS </p>
                            <p className="text-dark"> 
                              <span className="text-uppercase"> <strong>{paymentData?.status} </strong> </span>
                            </p>
                        </div>

                        { paymentData?.payment_method === 'Cartão de Crédito' ? (
                          <>
                            <div className="col-md-6 col-sm-12 mt-3">
                                <p className="text-dark border-bottom text-uppercase"> Bandeira do Cartão </p>
                                <p className="text-dark"> 
                                <strong>{paymentData?.card_brand} </strong></p>
                            </div>

                            <div className="col-md-6 col-sm-12 mt-3">
                                <p className="text-dark border-bottom text-uppercase"> Cartão em nome de </p>
                                <p className="text-dark"> 
                                <strong>{paymentData?.card_holder_name} </strong> </p>
                            </div>
                            
                            <div className="col-md-6 col-sm-12 mt-3">
                                <p className="text-dark border-bottom text-uppercase"> Cartão Número </p>
                                <p className="text-dark"> 
                                  <strong>{paymentData?.credit_card} </strong> </p>
                            </div>
                            <div className="col-md-6 col-sm-12 mt-3">
                                <p className="text-dark border-bottom text-uppercase"> Adquirente </p>
                                <p className="text-dark text-uppercase"> 
                                  <strong>{paymentData?.gtw} </strong> </p>
                            </div>
                          </>
                        ) : ''}

                        { paymentData?.payment_method === 'Boleto Bancário'  ? (
                          <>
                            <div className="col-md-6 col-sm-12 mt-3">
                              <p className="text-dark border-bottom text-uppercase"> Vencimento do boleto </p>
                              <p className="text-dark"> 
                              <strong><Moment format="DD/MM/YYYY" date={paymentData?.boleto_expiration_date || ""} />  </strong></p>
                            </div>
                            <div className="col-md-6 col-sm-12 mt-3">
                              <p className="text-dark border-bottom text-uppercase"> Código de Barras do Boleto </p>
                              <p className="text-dark"> 
                              <strong>{paymentData?.boleto_barcode} </strong></p>
                            </div>
                            <div className="col-md-6 col-sm-12 mt-3 mb-3">
                              <a href={paymentData?.boleto_url} className="btn btn-dark btn-sm" target="_blank"> Link para download do boleto </a>
                            </div>
                          </>
                        ) : ''}

                      </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h4 className="text-dark text-weight-bolder mb-4 mt-5"> Regras de Split </h4>
                  </div>
                  {/*  */}
                  <div class="col-12">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                  <div class="card card-split">
                    <div class="card-body">
                      <h4 class="card-title"> <strong> Conta Primária </strong> </h4>
                      <div class="d-flex justify-content-between">
                        <p class="text-muted"> spread de transação </p>
                        <p class="text-muted"> {paymentData?.amount_primary} </p>
                      </div>
                      <div class="progress progress-md">
                        <div class="progress-bar bg-info w-100" role="progressbar" aria-valuenow="8" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                  <div class="card card-split">
                    <div class="card-body">
                      <h4 class="card-title"> <strong> Conta Recebedor </strong> </h4>                      
                      <div class="d-flex justify-content-between">
                        <p class="text-muted"> valor a ser creditado </p>
                        <p class="text-muted"> {paymentData?.amount_customer} </p>
                      </div>
                      <div class="progress progress-md">
                        <div class="progress-bar bg-success w-100" role="progressbar" aria-valuenow="92" aria-valuemin="92" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                  {/*  */}
                </div>
              </>
            </div>
             
    </div>
    </>
  );
}
