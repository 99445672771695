import React, { 
    useEffect, 
    useState } 
    from 'react';
import { Link } from 'react-router-dom';

import swal from 'sweetalert';

import Config from '../../../../services/config';
import api from '../../../../services/login';
import StatusIcon from '../../../Common/StatusIcon';
// import UserGroup from '../../../Common/Usergroup';

export default function List() {

   const [ users, setUsers ] = useState([]);

   const authorization = Config.AuthApiSecretKey;
   const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
   const userid = credencialdata._id;
   
   useEffect(() => {
       
       const authorization = Config.AuthApiSecretKey;
        async function loadUsers() {
            const response = await api.get('/mobcustomers', {
                headers: { authorization },
            })
            setUsers(response.data.users);
        }
        loadUsers();
    },[])

    async function handleRemove(itemId, name, index) {

        swal(`Remover ${name}?`, {
            buttons: {
              cancel: "cancelar",
              catch: {
                text: "remover",
                value: "remover",
              },
            },
          })
          .then((value) => {
            switch (value) {

              case "cancelar": 
                break;
           
              case "remover":
                setUsers(users.filter(user => user._id !== itemId));
                swal("Remover Cliente", "cliente removido com sucesso", "success");
                async function removeUser() {
                    const response = await api.delete(`/mobcustomer/delete/${itemId}`, {
                        headers: { authorization, userid },
                    })
                    console.log(response);
                }
                removeUser();
                break;

                default:
                    swal(" OK, você escolheu cancelar... ");

            }
          });
    }

  return (
    <>
          <div className="table-responsive-sm">

        { !users.length ? 'Não há dados para exibir aqui' : (
            <table className="table table-striped">
            <thead>
                <tr>
                <th className="pt-1 pl-0">
                    Nome
                </th>
                <th className="pt-1">
                    Documento
                </th>
                <th className="pt-1 text-center">
                    Status
                </th>
                </tr>
            </thead>
            <tbody>
                {users.map((user, index) => (
                    <tr key={user._id}>
                        <td >
                            <div className="d-flex align-items-center">
                            <div className="ml-3">
                                
                                <Link to={'/admin/mobcustomer/' + user._id} className="text-dark font-weight-bold">
                                    <p className="mb-0 text-uppercase">  
                                        {user.personType === 'person'  ? user.name : ''}
                                        {user.personType === 'company' ? user.companyName : ''}
                                    </p>
                                </Link>

                                <p className="mb-0 text-muted text-small"> 
                                    Atualizado: {user.updatedAt} <br/> 
                                    E-mail: <span className="text-lowercase">{user.email} </span> <br/>
                                    Tipo de conta: {user.personType} <br/>
                                </p>
                            </div>
                            </div>
                        </td>
                        <td >
                            {user.personType === 'person'  ? user.cpf : ''}
                            {user.personType === 'company'  ? user.companyCnpj : ''}
                        </td>
                        <td >

                            <Link to={'/admin/contracts/' + user._id} className="btn btn-outline-success btn-sm mr-3">
                                <i className="btn-icon-prepend"></i>  Contratos
                            </Link>

                            <Link to={'/admin/mobcustomer/' + user._id} className="btn btn-outline-secondary btn-sm">
                                <i className="ti-settings btn-icon-prepend"></i>  Editar
                            </Link>

                            <StatusIcon iconStatus={user.status} />

                            <button onClick={() => handleRemove(user._id, user.name, index)} className="remoteItemBt">
                                <i className="ti-close text-danger btn-icon-prepend ml-3"></i>
                            </button>

                        </td>
                    </tr>
                ))}
            </tbody>
            </table>
        )}
        </div>

    </>
  );

}
