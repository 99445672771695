import React from 'react';

import AuthPage from '../../../../components/AuthPage';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';

import VideoStoreComponent from '../../../../components/Publications/VideoStore'
// import { Container } from './styles';

export default function Store() {
  return (
    <>
        <AuthPage />
        <div className="container-scroller">
            <Header />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <VideoStoreComponent />
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
