import React, { Component } from 'react';


import Config from '../../../../services/config';
import api from '../../../../services/Users';
import Alerts from '../../../../components/Alerts';

export default class Store extends Component {

    constructor(props) {
        
        const authorization = Config.AuthApiSecretKey;
      
        super(props);
        this.state = {
            userdata: {},
            authorization,
            userid: this.props.customerId,
            showCompany: 'person'
        };
    }

    handleChange = async (e) => {
        const state = this.state.userdata
        state[e.target.name] = e.target.value;
        this.setState({userdata:state});
        
        // Show hide company data
        if(e.target.name === 'personType'){
            this.setState({
                showCompany: e.target.value
            })
        }

        // Zip auto complete API
        const authorization = this.state.authorization;
        const userid = this.state.userid;

        if(e.target.name === 'addrZip'){
        
        const queryZip = e.target.value

        if(queryZip.length >= 8){
            const response = await api.post('/user/address/zip', {
                zip: queryZip, 
            },{
            headers : { authorization, userid }
            });

            state.addr = response.data.logradouro;
            state.addrNeighborhood = response.data.bairro;
            state.addrCity = response.data.localidade;
            state.addrState = response.data.uf;
            this.setState({userdata:state});

        }
        }
     // Zip auto complete API

    }

    onSubmit = async (e) => {
    
        e.preventDefault();
        const authorization = this.state.authorization;
        const userid = this.state.userid;

        const { personType,
            cpf,
            name,
            email,
            phone,
            companyName,
            companyCnpj,
            companyTel,
            companyType,
            companyWebsite,
            addrZip,
            addr,
            addrNumber,
            addrNeighborhood,
            addrCity,
            addrState,
            addrComplement,
            password,
            status } = this.state.userdata;
    
        const response = await api.post('/mobcustomer/store', {
            personType,
            cpf,
            name,
            email,
            phone,
            companyName,
            companyCnpj,
            companyTel,
            companyType,
            companyWebsite,
            addrZip,
            addr,
            addrNumber,
            addrNeighborhood,
            addrCity,
            addrState,
            addrComplement,
            status,
            password
          }, {
            headers: { authorization, userid }
          });
    
          if(response.data.cb !== "error"){
              Alerts("Cadastro de Usuário", "Cadastro efetuado com sucesso, Entre com o usuário e senha nos campos abaixo para continuar ","success");
        } else {
            Alerts("Cadastro de Usuário", response.data.message ,"error");
            //   history.push('/');
          }
      
      }

  render() {
    return (
        <>
        <div>
            <div>
            <div>

                <div className="row mb-3">
                    
                    <div className="col-md-6">
                        <h3> Clientes /Adicionar </h3>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-12">

                        <form onSubmit={this.onSubmit}>

                            <h4 className="text-twitter mb-4 mt-4"> Dados Pessoais </h4>

                            <div className="form-group">
                                <label htmlFor="personType"> Pessoa Física / Pessoa Jurídica * </label>
                                <select 
                                id="personType"
                                name="personType"
                                value={this.state.userdata.personType || ""}
                                onChange={this.handleChange}
                                className="form-control">
                                    <option value="person"> Pessoa Física </option>
                                    <option value="company"> Pessoa Jurídica </option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="cpf"> CPF  ( Responsável financeiro ) * </label>
                                <input 
                                    placeholder="Insira o número do CPF..." 
                                    type="text" 
                                    className="form-control" 
                                    name="cpf"
                                    id="cpf"
                                    value={this.state.userdata.cpf || ""} 
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="name"> Nome ( Responsável financeiro ) * </label>
                                <input 
                                placeholder="Nome Completo" 
                                type="text" 
                                className="form-control" 
                                name="name"
                                id="name"
                                value={this.state.userdata.name || ""} 
                                onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Endereço de E-mail * </label>
                                <input 
                                    placeholder="E-mail" 
                                    type="text" 
                                    className="form-control" 
                                    name="email"
                                    id="email"
                                    value={this.state.userdata.email || ""}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email"> Telefone Pessoal * </label>
                                <input 
                                    placeholder="Telefone" 
                                    type="text" 
                                    className="form-control" 
                                    name="phone"
                                    id="phone"
                                    value={this.state.userdata.phone || ""}
                                    onChange={this.handleChange}
                                />
                            </div>

                            {this.state.showCompany === 'company' ? (
                                <>
                                    <h4 className="text-twitter  mb-4 mt-5"> Dados Comerciais </h4>

                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="companyName"> Razão Social da Empresa * </label>
                                                <input 
                                                    placeholder="Insira a razão social da empresa ..." 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="companyName"
                                                    id="companyName"
                                                    value={this.state.userdata.companyName || ""} 
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="companyCnpj"> CNPJ * </label>
                                                <input 
                                                    placeholder="Insira o número de CNPJ..." 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="companyCnpj"
                                                    id="companyCnpj"
                                                    value={this.state.userdata.companyCnpj || ""} 
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="UserName"> Tel. Comercial * </label>
                                                <input 
                                                    placeholder="Insira o telefone comercial..." 
                                                    name="companyTel"
                                                    id="companyTel"
                                                    type="text" 
                                                    className="form-control" 
                                                    value={this.state.userdata.companyTel || ""}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            
                                            <div className="form-group">
                                                <label htmlFor="companyType"> Tipo de Empresa * </label>
                                                <select 
                                                name="companyType"
                                                id="companyType"
                                                value={this.state.userdata.companyType || ""}
                                                onChange={this.handleChange}
                                                className="form-control">
                                                    <option value=""> Selecione o tipo da conta </option>
                                                    <option value="mei"> Micro Empreendedor Individual </option>
                                                    <option value="limited"> Empresa Limitada </option>
                                                    <option value="individual"> Empresa Individual </option>
                                                    <option value="association"> Associação </option>
                                                </select>
                                            </div>

                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="companyWebsite"> Website Comercial * </label>
                                                <input 
                                                    placeholder="Insira o endereço do website..." 
                                                    id="companyWebsite"
                                                    name="companyWebsite"
                                                    type="text" 
                                                    className="form-control" 
                                                    value={this.state.userdata.companyWebsite || ""} 
                                                    onChange={this.handleChange}
                                                />
                                            </div>

                                        </div>
                                       

                                    </div>
                                </>
                            ) : ''}

                            <h4 className="text-twitter  mb-4 mt-5"> Segurança </h4>
                                <div className="form-group">
                                    <label htmlFor="password"> Senha temmporária * </label>
                                    <input 
                                        placeholder="Informe a senha de 8 a 16 caracteres" 
                                        type="password" 
                                        className="form-control" 
                                        name="password"
                                        id="password"
                                        value={this.state.userdata.password || ""}
                                        onChange={this.handleChange}
                                        autoComplete="new-password"
                                    />
                            </div>


                            <h4 className="text-twitter  mb-3 mt-3"> Endereço </h4>

                            <div className="form-group">
                                <label htmlFor="addrZip"> CEP * </label>
                                <input 
                                    placeholder="Insira o número do CEP..." 
                                    name="addrZip"
                                    id="addrZip"
                                    type="text" 
                                    className="form-control" 
                                    value={this.state.userdata.addrZip || ""} 
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="row">
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label htmlFor="UserName"> Endereço * </label>
                                        <input 
                                            placeholder="Informe o endereço..."
                                            id="addr"
                                            name="addr"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.userdata.addr || ""} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="addNumber"> Número * </label>
                                        <input 
                                            placeholder="Informe o número..."
                                            name="addrNumber"
                                            id="addrNumber"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.userdata.addrNumber || ""} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="addrNeighborhood"> Bairro </label>
                                        <input 
                                            placeholder="Bairro"
                                            name="addrNeighborhood"
                                            id="addrNeighborhood"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.userdata.addrNeighborhood || ""}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="addrCity"> Cidade * </label>
                                        <input 
                                            placeholder="Cidade"
                                            name="addrCity"
                                            id="addrCity"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.userdata.addrCity || ""} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="addrState"> Estado * </label>
                                        <input 
                                            placeholder="Estado"
                                            name="addrState"
                                            type="text" 
                                            className="form-control"
                                            id="addrState"
                                            value={this.state.userdata.addrState || ""}
                                            onChange={this.handleChange} />
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label htmlFor="addrComplement"> Complemento </label>
                                        <input 
                                            placeholder="Informe o complemento caso tenha"
                                            name="addrComplement"
                                            type="text" 
                                            className="form-control"
                                            id="addrComplement"
                                            value={this.state.userdata.addrComplement || ""} 
                                            onChange={this.handleChange}
                                          />
                                      </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="status"> Status do Cliente </label>
                                <select 
                                id="status"
                                name="status"
                                value={this.state.userdata.status || ""}
                                onChange={this.handleChange}
                                className="form-control">
                                    <option value=""> Selecione o Status </option>
                                    <option value="1"> Ativo </option>
                                    <option value="2"> Inativo </option>
                                </select>
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <button 
                                    type="submit" 
                                    className="btn btn-info mr-2"
                                    > Salvar </button>
                                </div>
                            </div>
                </form>
                </div>

              </div>
            </div>
        </div>
        </div>
    </>
    );
  }
}
