import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';

import Config from '../../services/config';
import api from '../../services/login';
import notFound from '../../assets/images/list-empty.png';
import TicketStatus from '../../components/Common/TicketStatus';
import './styles.css';


export default function SupportResume() {

  const [ tickets, setTickets ] = useState([]);

  useEffect(() => {
       
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;
    
     async function loadTickets() {
         const response = await api.get('/tickets', {
             headers: { authorization, userid },
         })
         setTickets(response.data.tickets);
     }
     loadTickets();
  },[])

  return (
    <div className="row">
                <div className="col-12 mb-20 mt-20">

                  <div className="row border-bottom pb-1">
                    <div className="col-md-8">
                        <h3 className="card-title mt-3"> Chamados </h3>
                    </div>
                    <div className="col-md-4 text-right">

                      <Link to="/user/ticket/store">
                          <button className="btn btn-outline-dark btn-sm mt-3 mb-4"> 
                             Novo Chamado
                          </button>
                      </Link>
                        
                    </div>
                    
                  </div>

                  <div className="table-responsive">

                  { !tickets.length ? (
                        <>
                          <div className="col-md-12 mt-3 text-grey">
                              Não encontramos nenhum ticket para apresentar aqui ainda! <br/>
                              <img src={notFound} alt="Data not found " />
                          </div>
                        </>
                      ) : (

                        <table className="table">
                          
                            <tbody>
                              {tickets.map((ticket, index) => (
                                <tr key={ticket._id} className={ticket.status === "0" ? "disabled" : ""}>
                                    <td>
                                      <Link to={`/user/ticket/${ticket._id}`}>
                                        #{ticket._id}
                                      </Link>
                                    </td>
                                    <td className="text-grey">  
                                      <span className="text-dark"> 
                                        <strong> {ticket.title} </strong> 
                                      </span> <br/>
                                      Aberto em: <Moment format="DD/MM/YYYY hh:mm" date={ticket.createdAt || ""} />
                                    </td>
                                    <td className="text-right">
                                      
                                      <TicketStatus status={ticket.status} />

                                      <Link to={`/user/ticket/${ticket._id}`}>
                                        <button className="ml-3 btn btn-outline-dark btn-sm">
                                          Detalhes
                                        </button>
                                      </Link>
                                      

                                    </td>
                                </tr>
                              ))}

                            </tbody>


                        </table>
                    )}
                  </div>
                </div>
              </div>
  );
}
