import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Alerts from '../../components/Alerts/index';
// import ErrorMessages from '../../components/Common/ErrorHandler';

// Services
import LoginApi from '../../services/login';
import Config from '../../services/config';

// Assets
import '../../assets/vendors/iconfonts/ti-icons/css/themify-icons.css'
import '../../assets/vendors/css/vendor.bundle.base.css'
import '../../assets/css/horizontal-layout-light/style.css'
import './Login.css'
import LoginLeftComponent from '../../components/FooterSlim';

import logotipoLogin from '../../assets/images/pagefy-logo-dark.svg';

export default class Login extends React.Component {

    constructor(){
        super()
        let loggedIn = false;

        const token = localStorage.getItem("token");
        if(token) loggedIn = true;

        this.state = {
            username: "",
            password: "",
            loggedIn,
            error: ""
        }
        this.onChange =  this.onChange.bind(this)
        this.formSubmit = this.formSubmit.bind(this)
    }

    onChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async formSubmit(e){

        e.preventDefault();
        
        const {username, password} = this.state
        const authorization =  Config.AuthApiSecretKey;

        try {
            const response = await LoginApi.post("/login", {username, password}, {
                headers: { authorization }
            })

            console.log(response);
            
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("credencialData", JSON.stringify(response.data.credencialData));

            this.setState({
                loggedIn: true
            })
            
        } catch (err) {
            console.log(err.message);
            Alerts("Login", 'Oops!, alguma coisa deu errado' ,"error");
            this.setState({
                error: err.message
            })
        }
    }

    render(){

        if( this.state.error === "Network Error"){
            Alerts("Login Error", "Erro ao conectar ao servidor, por favor tente mais tarde" , "error");
        }

        // "Erro ao conectar com API" 
        if(this.state.loggedIn === true){
            return <Redirect to="/main" />
        }
        return (
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                    <div className="row flex-grow">
                        
                    

                    <div className="col-lg-6 d-flex align-items-center justify-content-center">
                        <div className="auth-form-transparent text-left p-3">
                            
                            <img src={logotipoLogin} alt="PAGEFAI" width="350" />

                            <h4 className="mt-10 text-grey"> {Config.title} </h4>
                            
                            <span className="spacer"> &nbsp;</span>
                                
                            <form onSubmit={this.formSubmit} autoComplete="off">
                                <div className="form-group">
                                <label htmlFor="exampleInputEmail">E-mail </label>
                                <div className="input-group">
                                    <input 
                                        type="text" 
                                        placeholder="username" 
                                        value={this.state.username} 
                                        onChange={this.onChange} 
                                        name="username" 
                                        className="form-control form-control-lg border-left-0"
                                        autoComplete="false"
                                    />
                                    
                                </div>
                                </div>
        
                                <div className="form-group">
                                <label htmlFor="exampleInputPassword">Senha</label>
                                <div className="input-group">
                                    <input 
                                        type="password" 
                                        placeholder="password" 
                                        value={this.state.password} 
                                        onChange={this.onChange} 
                                        name="password" 
                                        className="form-control form-control-lg border-left-0"
                                    />
                                    
                                </div>
                                </div>
                                
                                <div className="my-3">
                                <button type="submit" className="btn btn-block btn-dark btn-lg font-weight-medium auth-form-btn" > LOGIN </button>
                                </div>

                                {/* <div className="mb-2 d-flex">
                                    <Link to="signup" className="btn-block btn btn-outline-dark">
                                        <i className="ti-plus mr-2"></i>
                                        CRIAR MINHA CONTA
                                    </Link>
                                </div> */}

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="text-left mt-4 font-weight-light">
                                            <Link to="/reset/password" className="text-dark">
                                                Esqueceu sua senha ?
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="text-left mt-4 font-weight-light">
                                            <a href={Config.defaultWebsite} className="text-dark">
                                                Ir para o Website
                                            </a>
                                        </div>
                                    </div>
                                </div>


                                <div className="d-flex justify-content-between align-items-center"> </div>

                                <small className="text-grey"> Build {Config.apiVersion} ( {Config.environment} ) </small>
                                
                            </form>
                        </div>
                    </div>

                    <LoginLeftComponent />


                    </div>
                </div>
                </div>
            </div>
          );

    }
    
}
