import React from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import AuditAccessResume from '../../../components/AuditAccessResume';

export default function AuditAccess() {
  return (
    <>
      <AuthPage />
      <div className="container-scroller">
      <Header />
          <div className="container-fluid page-body-wrapper">
              <div className="main-panel">
              <div className="content-wrapper">
                  <AuditAccessResume />
              </div>
          </div>
      </div>
      <Footer />
      </div>
    </>
  );
}
