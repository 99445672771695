import React, { Component } from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Tickets from '../../../components/SupportResume';
import Config from '../../../services/config';
import Loader from 'react-loader-spinner';


export default class Support extends Component {
  render() {
    return (
      <>
          <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <Tickets />
            </div>
        </div>
    </div>
    <Footer />
    </div>
    </>
    );
  }
}
