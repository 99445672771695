import React, { Component } from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Navigation from '../navigation';
// import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Config from '../../../services/config';
import api from '../../../services/Users';
import Alerts from '../../../components/Alerts';

import './styles.css';

export default class ChangePassword extends Component {


    constructor(props) {

        const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const userid = credencialdata._id;
    
        super(props);
        this.state = {
          userdata: {},
          authorization,
          userid
        };
      }
    
      componentDidMount() {
        
        // const authorization = this.state.authorization;
        // const userid = this.state.userid;
    
        // api.get('/user/passwords',{
        //     headers: { authorization, userid },
        // })
        //   .then(res => {
        //     this.setState({ userdata: res.data.user });
        //   });
    
      }
    
      handleChange = (e) => {
        const state = this.state.userdata
        state[e.target.name] = e.target.value;
        this.setState({userdata:state});
        console.log(state);
      }
    
      onSubmit = (e) => {
    
        e.preventDefault();
        const authorization = this.state.authorization;
        const userid = this.state.userid;
        
        const { securityPassword, securityPin, currentPassword, securityPasswordConfirm } = this.state.userdata;

        if(securityPassword && securityPasswordConfirm && currentPassword) {

            if(securityPassword === securityPasswordConfirm){
                api.put('/user/password/update', { 
                   securityPassword, securityPin, currentPassword 
                },{ 
                   headers: { authorization, userid 
                  },
                })
                .then((result) => {
                    Alerts("Dados Pessoais", 'Dados atualizados com sucesso' ,"success");
                    setTimeout(function(){
                        window.location.reload(1);
                     }, 3000);
                })
                .catch((error) => {
                    Alerts("Dados Pessoais", `Erro ao atualizar` ,"error");
                });
            } else {
                Alerts("Segurança", 'Os dados fornecidos no campo nova senha e confirmação da senha são diferentes' ,"error");
            }
        } else {
                Alerts("Segurança", 'Preencha o formulário corretamente e tente novamente...' ,"error");
        }
        
      }

  render() {
    return (
        <>
            <Loader
                type="BallTriangle"
                color="#000000"
                height={60}
                width={60}
                timeout={Config.loaderTimeout}
                className="overlaySpinner" //3 secs
            />
            <AuthPage />
            <Header />
            <div className="container-fluid page-body-wrapper" id="fscroll">
            <div className="main-panel">
                <div className="content-wrapper">
                <div className="row">
                    <div className="col-12">
                    <div className="">
                        <div className="mt-4">
                        <div className="row">
                            
                            <div className="col-lg-8">
                                <div className="">
                                    <div className="">
                                    <h3> Segurança </h3>

                                    <form onSubmit={this.onSubmit} autoComplete="off">

                                        <h4 className="text-twitter mt-5 mb-3"> Senha Atual </h4>
                                        <p className="text-dark">  * Necessário confirmar a senha atual para atualizar a senha </p>

                                        <div className="form-group">
                                            <label htmlFor="passwordCurrent">  </label>
                                            <input 
                                                placeholder="Senha Atual"
                                                type="password"  
                                                className="form-control" 
                                                name="currentPassword"
                                                id="currentPassword"
                                                value={this.state.userdata.currentPassword || ""} 
                                                onChange={this.handleChange}
                                                autoComplete="false"
                                                minLength="8"
                                                maxLength="16"
                                            />
                                        </div>


                                       


                                        {(this.state.userdata.currentPassword) ? (
                                            <>
                                             <div className="row">
                                            <div className="col-md-12">
                                                <h4 className="text-twitter mt-3 mb-3"> Nova Senha <small className="text-dark">  ( Mínimo 8 e máximo 16 caracteres ) </small>  </h4>
                                            </div>
                                        </div>
                                            
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="passwordNew"> Nova Senha *  </label>

                                                        <input 
                                                            placeholder="Informar nova senha"
                                                            type="password" 
                                                            className="form-control" 
                                                            name="securityPassword"
                                                            id="securityPassword"
                                                            value={this.state.userdata.securityPassword || ""} 
                                                            onChange={this.handleChange}
                                                            minLength="8"
                                                            maxLength="16"
                                                        />



                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="securityPasswordConfirm"> Confirmar Nova Senha * </label>
                                                        <input 
                                                            placeholder="Confirmar a nova senha "
                                                            type="password" 
                                                            className="form-control" 
                                                            name="securityPasswordConfirm"
                                                            id="securityPasswordConfirm"
                                                            value={this.state.userdata.securityPasswordConfirm || ""} 
                                                            onChange={this.handleChange}
                                                            minLength="8"
                                                            maxLength="16"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                            <p className="text-grey mt-20">
                                                * Ao clicar em Salvar você deverá estar ciente de que será necessário utilizar a nova senha no próximo acesso.
                                                <br/>
                                                <br/>
                                            </p>
                                            </div>
                                        </div>

                                        </>
                                        ) : 
                                        ''}


                                        {(this.state.userdata.currentPassword && this.state.userdata.securityPassword &&  this.state.userdata.securityPasswordConfirm ) ? (

                                            <div className="row">
                                                <div className="col-md-12 text-left mt-20">
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-info mr-2"
                                                >  Salvar </button>
                                                </div>
                                            </div>

                                        ) : ''}

                                    </form>
                                    </div>
                                </div>
                            </div>
                            
                            <Navigation />

                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            </div>
        <Footer />
        </>
    );
  }
}
