import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';

import Config from '../../services/config';
import api from '../../services/Users';

export default class Welcome extends Component {

    constructor(props) {

        const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const userid = credencialdata._id;

        super(props);
        this.state = {
          userdata: {},
          authorization,
          userid,
          loading: true,
          contractsdata: [],
          selectedContractId: '',
          selectedProductName: ''
        };
      }

      async loadContracts(){

        const authorization = this.state.authorization;
        const userid = this.state.userid;
    
        await api.get(`/contracts/user/${userid}`,{
            headers: { authorization },
        })
        .then(res => {
            this.setState({ contractsdata: res.data.contracts });
        });
      }

      async componentDidMount() {
            const authorization = this.state.authorization;
            const userid = this.state.userid;
            await api.get('/user/detail',{
                headers: { authorization, userid },
            })
            .then(res => {
                this.setState({ userdata: res.data.user });
                this.setState({ loading: false });
            });

            // load contracts
            this.loadContracts();

            // Set the Selectect Contract
            const selectedContractId = this.getFromCookie('selectedContractId');
            if (selectedContractId) {
            this.setState({ selectedContractId });
            }
            
        }

        handleSelectChange = (event) => {
            const selectedContractId = event.target.value;
            const selectedProductName = this.getProductNameById(selectedContractId);
            this.saveToCookie(selectedContractId, selectedProductName);
            this.setState({ selectedContractId, selectedProductName });
            window.location.reload();
        };
        
        saveToCookie = (contractId, productName) => {
            const daysToExpire = 7; 
            const date = new Date();
            date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
            const expires = "expires=" + date.toUTCString();
            document.cookie = `selectedContractId=${contractId}; ${expires}; path=/`;
            document.cookie = `selectedProductName=${productName}; ${expires}; path=/`;
        };

        getFromCookie = (cookieName) => {
            // Get the value from the cookie by cookie name.
            const name = `${cookieName}=`;
            const decodedCookie = decodeURIComponent(document.cookie);
            const cookieArray = decodedCookie.split(';');
            for (let i = 0; i < cookieArray.length; i++) {
              let cookie = cookieArray[i];
              while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
              }
              if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
              }
            }
            return '';
        };

        getProductNameById = (contractId) => {
            const contract = this.state.contractsdata.find((contract) => contract?._id === contractId);
            return contract ? (contract.productName+" - "+contract.domainUrl) : '';
          };

    
  render() {

    return (
        <>
            {/* Transaction Enabler Check */}
            {(this.state.userdata.transactionEnable === '1' && this.state.loading === false) ? '' : (
                <div className="card card-inverse-warning mt-2 mb-5" id="context-menu-open">
                    <div className="card-body">
                        <p className="card-text text-center">
                            Sua conta Pagefy está em análise,  aguardando aprovação para transacionar <br/> Verifique o status de sua conta &nbsp; 
                            <Link to="/account/status" className="linkAccount"> 
                                clicando aqui </Link> </p>
                    </div>
                </div>
            )}

            <div className="row">

                <div className="col-md-9 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">Olá, {this.state.userdata.name || ""} </h3>
                    <p className="font-weight-normal mb-0 text-grey"> 
                        E-mail de autenticação: <span className="text-mutted"> {this.state.userdata.email || ""}  </span> <br/>
                        Cliente desde <Moment format="DD/MM/YYYY" date={this.state.userdata.createdAt || ""} />
                    </p>
                </div>

                <div className="col-md-3 text-right">
                    <div className="">

                        <div className='form-group'>
                            <select 
                                className='form-control selectContractId' 
                                onChange={this.handleSelectChange} 
                                value={this.state.selectedContractId}>
                                <option value=''> Selecionar outro contrato </option>
                                {this.state.contractsdata.map((contract, index) => (
                                    <option key={contract?._id} value={contract?._id}> {contract?.productName} - {contract?.domainUrl} </option>
                                ))}
                            </select>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
  }
}
