
import React, { useEffect, useState } from 'react';

// yoopta Editor
// import YooptaEditor from '@yoopta/editor';
// import Paragraph from '@yoopta/paragraph';
// import ImagePlugin from '@yoopta/image';  // Import the image plugin
// import VideoPlugin from '@yoopta/video';  // Import the video plugin


import Loader from 'react-loader-spinner';

import AuthPage from '../../../components/AuthPage';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Config from '../../../services/config';
import PostsAdd from '../../../components/CmsPostAdd';

export default function Detail(props) {
  const { match: { params } } = props;
  const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
  const userid = credencialdata._id;

  const [contractId, setContractId] = useState('');

  const getFromCookie = (cookieName) => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  };

  useEffect(() => {
    const getContractId = getFromCookie('selectedContractId');
    if (getContractId) {
      setContractId(getContractId);
    }
  }, []); // Empty dependency array to run the effect only once when the component mounts

  const id = params.id;

  // const plugins = [Paragraph, ImagePlugin, VideoPlugin];
  // const styles = { width: 640, margin: '0 auto', padding: '40px 10px' };
  
  const [value, setValue] = useState([
    {
      type: 'paragraph',
      children: [{ text: 'Your initial content here.' }],
    },
  ]);
  

  return (
    <>
      <Loader
        type="BallTriangle"
        color="#000000"
        height={60}
        width={60}
        timeout={Config.loaderTimeout}
        className="overlaySpinner" //3 secs
      />
      <AuthPage />
      <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                      <div className="row">
                          <div className="col-md-6">
                            <h3> Super Editor </h3>
                            <small> Edite fácilmente os seus conteúdos com o Super Editor </small> 
                          </div>
                          <div className="col-md-6 text-right">
                              {/* <Link to={`/user/cms/posts`}> */}
                                <button className="btn btn-dark btn-sm mt-3 mb-4 mr-3"> 
                                  Salvar Publicação
                                </button>
                              {/* </Link> */}
                          </div>
                          <div className="col-md-12">
                            {/* <YooptaEditor
                                value={value}
                                onChange={(val) => setValue(val)}
                                plugins={plugins}
                                placeholder="Type text.."
                            /> */}
                          </div>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </>
  );
}
