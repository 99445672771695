import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Navigation from '../navigation';
import Config from '../../../services/config';
import api from '../../../services/Users';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import ContractsResume from '../../../components/ContractsResume';

export default class SignedServices extends Component {

    constructor(props) {

        const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const userid = credencialdata._id;
    
        super(props);
        this.state = {
          contractsdata: [],
          authorization,
          userid
        };
      }

      loadContracts(){
          const authorization = this.state.authorization;
          const userid = this.state.userid;
      
          api.get(`/contracts/user/${userid}`,{
              headers: { authorization },
          })
          .then(res => {
              this.setState({ contractsdata: res.data.contracts });
          });
      }
    
      componentDidMount() {
        this.loadContracts();
      }

  render() {
    return (
        <>
            <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
            />
            <AuthPage />
            <Header />
            <div className="container-fluid page-body-wrapper" id="fscroll">
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <ContractsResume className="mt-2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
  }
}
