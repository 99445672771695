import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';

import Config from '../../services/config';
import api from '../../services/login';
import PaymentCycle from '../../components/ChargesComponents/PaymentCycle';
import PaymentType from '../../components/ChargesComponents/PaymentType';
import ExternalLink from '../../components/Common/ExternalLink';

import './styles.css';

export default function Pay(props) {

  const { match: { params } } = props;
  const id = params.id
  const [ paymentData, setPaymentData ] = useState([]);

  useEffect((id) => {
    
  const authorization = Config.AuthApiSecretKey;
  // const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
  // const userid = credencialdata._id;


  if( typeof id !== 'undefined' ) {
    let id = '';
    console.log(id);
  }
  
  async function loadData() {
    try {
      const response = await api.get(`/payment/detail/${id}`, {
        headers: { authorization },
    });
    setPaymentData(response.data.payment);
    } catch (error){
      window.location='../404';
    }
  }
    loadData();
  },[]);


  return (
    
    <>
      <Loader
       type="BallTriangle"
       color="#000000"
       height={60}
       width={60}
       timeout={Config.loaderTimeout}
       className="overlaySpinner" //3 secs
      />

      {id === 'undefined'  && paymentData.length <= 0 ? (
        <>
          <div className="row">
            <div className="col-md-12 text-center mt-5">
              <h3> Oops! algo deu errado! </h3>
              <Link to="/" className="btn btn-twitter btn-sm ml-3 mt-3">
                 Voltar para o Site
              </Link>
            </div>
          </div>
        </>
      ) : (

      <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
          <div className="row flex-grow">
           
            <div className="col-lg-6 d-flex align-items-center justify-content-center">

              <div className="auth-form-transparent text-left p-3">
                
                <h3> Link de Pagamento </h3>
                <p className="text-grey mt-3"> 
                  Transaction #ID {id} <br/>
                </p>

                <div className="row mt-4">

                  <div className="col-md-12 mt-3">
                    <div className="payContainer border-bottom">
                        <h4> Resumo da Cobrança </h4>
                    </div>
                  </div>

                  <div className="col-md-6 mt-4 border-bottom"> 
                    <h4 className="text-twitter"> {paymentData.customerName} </h4>
                  </div>
                  
                  <div className="col-md-6 mt-3 text-right border-bottom"> 
                      <h4> <small> Valor total </small> <br/> {paymentData.amount} </h4>
                  </div>

                  <div className="col-md-6 mt-3 text-dark border-bottom"> 
                    <h4> <small> Vencimento </small> <br/> {paymentData.duoDate} </h4>
                  </div>

                  <div className="col-md-6 mt-3 text-dark text-right border-bottom"> 
                    <h4> <small>  Ciclo de Cobrança </small> <br/> <PaymentCycle status={paymentData.chargeCycle}  /> </h4>
                  </div>

                  <div className="col-md-6 mt-3 text-dark border-bottom"> 
                    <h4> <small> Cobrança gerada em </small> <br/> <Moment format="DD/MM/YYYY" date={paymentData.createdAt || ""} />  </h4>
                  </div>

                  <div className="col-md-6 mt-3 text-dark text-right border-bottom"> 
                    <h4> <small>  Tipo de Cobrança </small> <br/> <PaymentType paymentType={paymentData.chargeType || "Link de Pagamento"} />  </h4>
                  </div>

                  {paymentData.chargeType === "BOLETO" ? (
                    <div className="col-md-12 mt-3 text-dark text-left border-bottom"> 
                      <h4> <small>  Código de Barras do Boleto </small> <br/> 00001.00001.00001.00001 </h4>
                    </div>
                  ) : ''}

                  <div className="col-md-12 mt-3 text-dark"> 
                    <h4> <small>  Descrição </small> <br/> {paymentData.description} </h4>
                  </div>

                  {paymentData.chargeType === "BOLETO" && paymentData.boletoUrl ? (
                    <div className="col-md-12 mt-5">
                      <ExternalLink to={`${paymentData.boletoUrl}`}>
                        <button className="btn btn-twitter mr-2"> 
                          DOWNLOAD DO BOLETO </button>
                      </ExternalLink>
                    </div>
                  ) : (
                    <h4 className="ml-3 mt-5 text-grey"> O link para download do boleto ainda não está disponível </h4>
                  )}

                  {paymentData.chargeType !== "BOLETO" ? (
                    <div className="col-md-12 mt-5">
                      <Link to={`/pay/checkout/${paymentData._id}`}>
                        <button className="btn btn-twitter mr-2"> 
                          EFETUAR PAGAMENTO </button>
                      </Link>
                    </div>
                  ) : ''}


                  <div className="col-md-12 mt-5 text-grey">
                    <small> 
                      Powered by Pagefy Tecnologia ®
                    </small>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-6 register-half-bg d-flex flex-row">
              <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; 2019 - Todos os direitos reservados.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
      
      )}

    </>
  );
}
