import React from 'react';
import { Link } from 'react-router-dom';


export default function Store() {
  return (
    <>
        <div >
        <div>
            <div>

                <div className="row mb-3">
                    
                <div className="col-md-6">
                    <h3> Usuários / Adicionar </h3>
                    <p className="text-grey"> Preencha todos os campos abaixo para criar o usuário  </p>
                </div>

                <div className="col-md-6">
                    <div className="text-right">
                            <div className="dropdown">
                                <Link to="/admin/users" className="btn btn-outline-twitter btn-sm">
                                    <i className="ti-list-ol"></i> Lista de Usuários
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <form className="forms-sample">

                            <div className="form-group">
                                <label htmlFor="UserName"> Nome Completo * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="UserName" 
                                    placeholder="Entre com o nome completo do usuário..." 
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="UserName"> E-mail * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="UserName" 
                                    placeholder="Informe um endereço de e-mail válido ..." 
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="description"> Cargo </label>
                                <textarea className="form-control" 
                                    id="description" 
                                    placeholder="Breve descrição do conteúdo  ..." 
                                    rows="3"></textarea>
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect2"> Grupo de Acesso * </label>
                                <select className="form-control" id="exampleFormControlSelect2">
                                    <option> Super Usuário </option>
                                    <option> Desenvolvimento </option>
                                    <option> Gerente </option>
                                    <option> Vendedor </option>
                                    <option> Atendimento </option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="endCampaign"> Regras de Acesso </label>
                                <div className="container-categories">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" />
                                            Acesso Completo ao Console
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" />
                                           Produtos ( Listar )
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" />
                                            Produtos ( criar, Alterar, Excluir )
                                            <i className="input-helper"></i></label>
                                    </div>
                                    
                                </div>
                            </div>

                            

                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect2"> Status do Usuário </label>
                                <select className="form-control" id="exampleFormControlSelect2">
                                    <option> Ativo </option>
                                    <option> Inativo </option>
                                </select>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <p className="text-grey mt-20">
                                    * Note que ao adicionar este usuário ele terá acesso ao console de administrador.
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <button 
                                    type="submit" 
                                    className="btn btn-info mr-2"
                                    > Salvar </button>
                                </div>
                            </div>
                </form>
                </div>

              </div>
            </div>
        </div>
    </div>
    </>
  );
}
