import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InputMask from "react-input-mask";
import IntlCurrencyInput from "react-intl-currency-input"

import Config from '../../../services/config';
import api from '../../../services/Users';
import Alerts from '../../../components/Alerts';

import './styles.css';

export default class Update extends Component {

    constructor(props) {
        
        const authorization = Config.AuthApiSecretKey;

        super(props);

        this.state = {
            userdata: {},
            authorization,
            userid: this.props.userId,
            customerid: this.props.customerId
        };
        
    }

    componentDidMount() {

        const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const userid = credencialdata._id;
        const customerid = this.state.customerid;

        api.get(`/customer/${customerid}`, {
            headers: { authorization, mobcustomerid : userid },
        })
        .then(res => {
            this.setState({ userdata: res.data.user });
        });

        console.log(this.state.userdata.customerEmail);
    
      }
    
      handleChange = (e, value, maskedValue) => {

        e.preventDefault();

        const state = this.state.userdata

        if(e.target.name === 'amount'){
          state[e.target.name] = maskedValue;
        } else {
          state[e.target.name] = e.target.value;
        }

        this.setState({userdata:state});
        // console.log(value); // value without mask (ex: 1234.56)
        // console.log(maskedValue); // masked value (ex: R$1234,56)
      };

      onSubmit = (e) => {
    
        e.preventDefault();

        const authorization = this.state.authorization;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const userid = credencialdata._id;
        const customerid = this.state.customerid;

        const { customerEmail, 
          chargeType,
          chargeCycle,
          description,
          duoDate,
          interest,
          customerDocument,
          ourNumber,
          amount
          } = this.state.userdata;


        // const amountFixed = amount.replace(/\D+/g, '');
        console.log(customerEmail);

        api.post(`/payment/store`, { mobCustomerId: customerid,
          customerEmail,
          chargeType,
          chargeCycle,
          description,
          duoDate,
          interest,
          walletId: '00000001',
          customerDocument,
          ourNumber,
          amount,
          customerId : userid
          }, { headers: { authorization },
        }).then((result) => {
            Alerts("Cobrança", `Cobrança efetuada com sucesso` ,"success");
        });

      }

  render() {

    // 
    const currencyConfig = {
      locale: "pt-BR",
      formats: {
        number: {
          BRL: {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
      },
    };
    // 

    return (
      <>
      <div className="row">
        <div className="col-12">

        <div className="row mb-3 ">

            <div className="col-md-6">
                <h3> Cobranças </h3>
            </div>

            <div className="col-md-6 text-right ">

                <Link to="/payments" className="btn btn-outline-twitter btn-sm mr-2">
                    Suas Cobranças
                </Link>

                <Link to={`/customer/${this.state.customerid}`} className="btn btn-twitter btn-sm ">
                    Detalhes do Cliente
                </Link>
            </div>

        </div>

          <div className="row billingBg">

            <div className="col-md-6">
                <h3> <small> Favorecido / </small>  <br/> <span className="text-twitter">  
                  {this.state.userdata.name || " Nome do cliente não informado..."}   
                </span> </h3>  
            </div>

            <div className="col-md-6 text-right">
                <h4> CPF/CNPJ<br/>{this.state.userdata.customerDocument || ""} </h4>
            </div>

              <div className="col-md-6 mt-3">

                <div className="dataContainer">
                    <div className="form-group">
                          <label htmlFor="customerDocument"> CPF/CNPJ: </label>
                          <input 
                            id="customerDocument"
                            name="customerDocument"
                            value={this.state.userdata.customerDocument || ""}
                            type="number" 
                            className="form-control form-control-sm" 
                            placeholder="Informe o CPF/CNPJ do seu cliente" 
                            disabled
                            />
                      </div>
                </div>

                <div className="dataContainer">
                      <div className="form-group">
                          <label htmlFor="customerEmail"> E-mail para envio da cobrança </label>
                          <input 
                            id="customerEmail"
                            name="customerEmail"
                            defaultValue={this.state.userdata.email || ""}
                            type="text" 
                            onChange={this.handleChange}
                            className="form-control form-control-sm" 
                            placeholder="Endereço de E-mail"
                            />
                      </div>
                  </div>

                  <div className="dataContainer">
                    <div className="form-group">

                        <label htmlFor="chargeType"> Cobrar através de * </label>
                        <select 
                            className="form-control" 
                            name="chargeType"
                            id="chargeType"
                            onChange={this.handleChange}
                            >
                            <option value=""> Selecione uma opção </option>
                            <option value="CREDIT_CARD"> Cartão de Crédito </option>
                            <option value="BOLETO"> Boleto Bancário </option>
                            <option value="UNDEFINED"> O cliente escolhe como pagar  </option>
                        </select>
                    </div>
                  </div>

                  <div className="dataContainer">
                    <div className="form-group">
                        <label htmlFor="chargeCycle"> Ciclo de Cobrança:  </label>
                        <select className="form-control"
                          id="chargeCycle"
                          name="chargeCycle"
                          onChange={this.handleChange}
                        >
                            <option value=""> Selecione uma opção </option>
                            <option value="onetime" > Cobrança Única </option>
                            <option value="monthly"> Cobança Recorrente ( Mensal ) </option>
                            <option value="biannual"> Cobança Recorrente ( Semestral ) </option>
                            <option value="annual"> Cobança Recorrente ( Anual ) </option>
                            <option value="weekly"> Cobança Recorrente ( Semanal ) </option>
                            <option value="daily"> Cobança Recorrente ( Diária ) </option>
                        </select>
                    </div>
                  </div>

                  <div className="dataContainer">
                    <div className="form-group">
                        <label htmlFor="billingAmount"> Nosso Número </label>
                        <input 
                          id="ourNumber"
                          name="ourNumber"
                          type="number" 
                          className="form-control form-control-sm" 
                          placeholder="Sua referência para identificação da Cobrança ( Nosso Número )"
                          onChange={this.handleChange}
                          />
                    </div>
                  </div>

                  <div className="dataContainer">

                        <div className="row">
                          <div className="col-md-6">
                              <label> Salvar esta cobrança como modelo ? </label>
                          </div>
                          <div className="col-md-6">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="checkbox" className="form-check-input" />
                                  Sim
                                <i className="input-helper"></i></label>
                              </div>

                            </div>

                          </div>

                        </div>

                  </div>
                  
              <div className="col-md-6 mt-4 border-left p-4">
                  
                  <div className="dataContainer">
                    <div className="form-group">
                        <label htmlFor="amount"> Valor Total </label>

                        {/* <input 
                          id="amount"
                          name="amount"
                          type="number" 
                          className="form-control form-control-lg inputblue" 
                          placeholder="R$ Informe o valor"
                          onChange={this.handleChange}
                          /> */}

                          <IntlCurrencyInput 
                            id="amount"
                            name="amount"
                            type="text" 
                            className="form-control form-control-lg inputblue" 
                            placeholder="Informe o valor da cobrança"
                            currency="BRL" 
                            config={currencyConfig}
                            onChange={this.handleChange} 
                            />



                    </div>
                  </div>

                  <div className="dataContainer">
                    <div className="form-group">
                        <label htmlFor="interest"> Júros </label>

                        {/* <input 
                          id="interest"
                          name="interest"
                          type="number" 
                          className="form-control form-control-sm" 
                          placeholder="Porcentagem de júros após vencimento %"
                          onChange={this.handleChange}
                          // onChange={handleChange}
                          /> */}

                        <InputMask 
                          mask="99\%" 
                          id="interest"
                          name="interest"
                          className="form-control form-control-sm" 
                          placeholder=" %"
                          onChange={this.handleChange}
                        />


                    </div>
                  </div>

                  <div className="dataContainer">
                    <div className="form-group">
                        <label htmlFor="duoDate"> Vencimento </label>
                        
                        <InputMask 
                          mask="99/99/9999" 
                          placeholder="dd/mm/aa" 
                          id="duoDate"
                          name="duoDate"
                          className="form-control form-control-sm" 
                          onChange={this.handleChange}
                        />

                        {/* <input 
                          id="duoDate"
                          name="duoDate"
                          type="number" 
                          className="form-control form-control-sm" 
                          placeholder="dia/mês/ano "
                          onChange={this.handleChange}
                          /> */}

                    </div>
                  </div>

                  <div className="dataContainer">
                    <div className="form-group">
                        <label htmlFor="description"> Descrição da Cobrança: </label>
                          <textarea
                            id="description"
                            name="description"
                            className="form-control form-control-lg"
                            placeholder="Descreva em até 41 caracteres..."
                            maxLength="80"
                            row="3"
                            onChange={this.handleChange}
                            ></textarea>
                      </div>
                    </div>

                    <div className="dataContainer text-right">
                      <div className="form-group">
                        {/* <button type="submit" className="btn btn-lg btn-twitter mt-3" onClick={onSubmit}> EMITIR COBRANÇA </button> */}
                        <button 
                            type="submit" 
                            className="btn btn-info mr-2"
                            onClick={this.onSubmit}
                            > EMITIR COBRANÇA </button>
                      </div>
                    </div>

                  {/*  */}
              </div>

              </div>
              
              
            </div>

            </div>
    </>
    );
  }
}