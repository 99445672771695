import React, { useState, useEffect }   from 'react';
import swal from 'sweetalert';
import Loader from 'react-loader-spinner';

import AuthPage from '../../components/AuthPage';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import api from '../../services/Users';
import Config from '../../services/config';
import './styles.css'

export default function List() {

  const [ videos, setVideos ] = useState([]);
  const [ authorization, setAuthorization ] = useState([]);
  const [ userid, setUserid ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [appData, setAppData] = useState({
    query: '',
    country: ''
  });

  
  useEffect(() => {
    const authorizationId = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userId = credencialdata._id;

    setAuthorization(authorizationId);
    setUserid(userId);
     
  },[]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleModalVideo = (videoId) => {
    if (videoId) {
      const videoUrl = `https://www.youtube.com/watch?v=${videoId}`;
      console.log(videoUrl);

      swal({
        title: "Informações",
        text: `Informações do vídeo: ${videoUrl} <br/> `,
      });
     
      
    } else {
      swal({
        title: "Error",
        text: "Identificamos um erro na sua pesquisa",
        icon: "error",
      });
    }
  };
  
  async function onSubmit(e){

    e.preventDefault();
    setLoading(true);

    const  { query, country } = appData;

    try {

      const response = await api.get(`/user/3rdparty/ytSearchAds`, {
        params: { query, country },
        headers: { authorization, userid }
      });

      setVideos(response.data);
      setLoading(false);
        
      } catch (error) {
        console.log(authorization);
        swal({
          title: "Pesquisa",
          text: "Identificamos um erro na sua pesquisa"
        });
      }
  
  }


  return (
    <>

        <Loader
          type="BallTriangle"
          color="#000" 
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner"
        />

    <AuthPage />
    <div className="container-scroller">
        <Header />
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                    <div className="content-wrapper">


                    <div className="row">
                      <div className="col-lg-12">
                        
                          <div className="card-body">
                            <div className="row">

                                <div className="col-12 mb-2">
                                    <h3> Top Youtube Patrocinados </h3>
                                    <p className="text-muted"> Os vídeos patrocinados mais pesquisados </p>
                                  </div>

                                  <div className="col-12">
                                    <form className="pt-3" onSubmit={onSubmit}>
                                      <div className="form-group d-flex">
                                        <input type="text" 
                                          className="form-control col-md-8 mr-4" 
                                          placeholder="informe o tema que deseja pesquisar..." 
                                          name="query" 
                                          id="query"
                                          value={appData?.query || ""} 
                                          onChange={handleChange}
                                        />
                                        
                                        <button type="submit" className="btn btn-dark ml-3"> Pesquisar </button>
                                      </div>
                                    </form>
                                  </div>

                                  {loading && (
                                      <Loader
                                        type="BallTriangle"
                                        color="#000"
                                        height={60}
                                        width={60}
                                        timeout="3600"
                                        className="overlaySpinner"
                                      />
                                   )}
                                  
                                  {videos.map((video, index) => (

                                    <div className="row vItem" key={index}>
                                      <div className="d-flex flex-row mb-3">
                                        <div className="p-2">
                                        <a href={`https://www.youtube.com/watch?v=`+video?.videoId} target="_blank">
                                            <img 
                                              src={video?.thumbnailUrl} 
                                              alt={video?.title}
                                              width="130"
                                          />
                                        </a>
                                        </div>
                                        <div className="p-2 vItemDesc">
                                          <div className="d-block h4 vItemTitle" href="search-results.html#">
                                            <p>{video?.title}</p>
                                            <p className='text-grey'>{video?.description}</p>
                                          </div>
                                          <p>
                                            <small> 
                                                Publicação: {video?.publishedDate} | 
                                                Visualizações: {video?.viewCount}  | 
                                                Comentários: {video?.commentCount}  | 
                                                Canal: <a href={`https://www.youtube.com/channel/`+video?.channelId} target="_blank"> {video?.channelTitle} </a> 
                                              </small> <br/>
                                            <a href={`https://www.youtube.com/watch?v=`+video?.videoId} target="_blank"> Assistir Vídeo </a>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                            </div>

                          </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        <Footer />
    </div>
    </>
  );
}
