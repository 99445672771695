import React from 'react';

import AuthPage from '../../../../components/AuthPageAdmin';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import TutorialUpdate from '../../../../components/AdminConsoleComponents/Tutorials/Update';

export default function Store(props) {

  const { match: { params } } = props;
  const id = params.id

  return (
    <>
      <AuthPage />
        <div className="container-scroller">
            <HeaderAdminConsole />
              <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                          <div className="row">
                              <div className="col-md-12">
                                <TutorialUpdate tutoId={id} />
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
          </div>
      <Footer />
    </>
  );
}
