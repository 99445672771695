import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

export default function VideosList() {
  return (
    <>
        
        {/* <HeaderDonation title="/Campanhas"/> */}

        <div className="row">
            <div className="col-md-6">
                <h3 className="card-title"> Vídeos </h3>
                <p className="text-grey"> Você poderá utilizar vídeos do Youtube e Vimeo em seus apps. <br/> Para mais instruções veja no menu ajuda  </p>
            </div>

            <div className="col-md-6">
                <div className="text-right">
                    <div className="dropdown">
                        <Link to="/video/store" className="btn btn-outline-twitter btn-sm">
                            <i className="ti-plus"></i> Novo
                        </Link>
                    </div>
                </div>
            </div>

        </div>

        <div className="table-responsive mt-3">
                    <table className="table">
                      <thead>
                        <tr>
                            <th> #ID </th>
                            <th> Título </th>
                            <th className="text-left"> Autor </th>
                            <th className="text-left"> Criado em </th>
                            <th className="text-center"> Ações </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td className="text-muted"> #111 </td>
                            <td className="text-muted"> Reforma da Instituição </td>
                            <td className="text-left text-muted"> André Jaccon </td>
                            <td className="text-left text-muted"> 25/12/2019 15:42 </td>
                            <td className="text-right text-twitter"> 
                                <Link to="video/update/1">
                                  <button type="button" className="btn btn-outline-secondary btn-sm"> 
                                  <i className="ti-settings btn-icon-prepend"></i> Editar </button>
                                </Link>

                                <i className="ti-control-record text-success btn-icon-prepend ml-3 status-icon"></i>
                                <i className="ti-close text-danger btn-icon-prepend ml-3"></i>

                            </td>
                        </tr>
                        <tr>
                            <td className="text-muted"> #111 </td>
                            <td className="text-muted"> Pagamento de tríbutos </td>
                            <td className="text-left text-muted"> André Jaccon </td>
                            <td className="text-left text-muted"> 25/12/2019 15:42 </td>
                            <td className="text-right text-twitter"> 
                                <Link to="video/update/1">
                                  <button type="button" className="btn btn-outline-secondary btn-sm"> 
                                  <i className="ti-settings btn-icon-prepend"></i> Editar </button>
                                </Link>

                                <i className="ti-control-record text-danger btn-icon-prepend ml-3 status-icon"></i>

                                <button id="10" className="removeButton">
                                    <i className="ti-close text-danger btn-icon-prepend ml-3 "></i>
                                </button>

                            </td>
                        </tr>
                      </tbody>
                    </table>
        </div>
    </>
  );
}
