import React from 'react';

// import { Container } from './styles';

export default function ApiStatus() {
  return (
    <div className="row">
                <div className="col-12 mb-20 mt-20">

                  <div className="row">
                    <div className="col-md-12">
                        <h3 className="card-title"> API Status </h3>
                        <p className="text-grey"> Aqui você encontra o status de todas as APIs Pagefy. </p>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                            <th> API </th>
                            <th> Atualizado em</th>
                            <th className="text-right">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td> Cartão de Crédito </td>
                            <td> 06/11/2019 as 20:30 </td>
                            <td className="text-right">
                              <label className="badge badge-success"> Online </label>
                            </td>
                        </tr>
                        <tr>
                            <td> Boleto Bancário </td>
                            <td> 06/11/2019 as 20:30 </td>
                            <td className="text-right">
                              <label className="badge badge-success"> Online </label>
                            </td>
                        </tr>
                        <tr>
                            <td> Assinaturas </td>
                            <td> 06/11/2019 as 20:30 </td>
                            <td className="text-right">
                              <label className="badge badge-success"> Online </label>
                            </td>
                        </tr>
                        <tr>
                            <td> Autênticação de Usuário </td>
                            <td> 06/11/2019 as 20:30 </td>
                            <td className="text-right">
                              <label className="badge badge-success"> Online </label>
                            </td>
                        </tr>
                        <tr>
                            <td> Anti Fraude </td>
                            <td> 06/11/2019 as 20:30 </td>
                            <td className="text-right">
                              <label className="badge badge-success"> Online </label>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
  );
}
