import React from 'react';

export default function Usergroup(props) {

    let getUsergroup = props.dataUserGroup;

    // console.log(props.dataUserGroup);

    if(getUsergroup === 'root') {
        return (
            <span className="text-grey"> Administrador do Sistema </span>
        );
    } else if( getUsergroup === 'customers' ) {
        return (
            <span className="text-twitter"> Cliente Pagefy </span>
        );
    } else if( getUsergroup === 'sale' ) {
        return (
            <span className="text-twitter"> Vendedores </span>
        );
    } else {
        return (
            <span className="text-twitter"> Não especificado </span>
        )
    }
    
    
}
