import React from 'react';

export default function PaymentStatus(props) {

    const Switch = (str) => ({
      "PENDING" : "Aguardando pagamento",
      "RECEIVED" : "Recebida (saldo já creditado na conta)",
      "CONFIRMED" : "Pagamento confirmado (saldo ainda não creditado)",
      "OVERDUE" : "Vencida",
      "REFUNDED" : "Estornada",
      "RECEIVED_IN_CASH" : "Recebida em dinheiro (não gera saldo na conta)",
      "REFUND_REQUESTED" : "Estorno Solicitado",
      "CHARGEBACK_REQUESTED" : "Recebido chargeback",
      "CHARGEBACK_DISPUTE" : "Em disputa de chargeback",
      "AWAITING_CHARGEBACK_REVERSAL" : "Disputa vencida, aguardando repasse da adquirente",
      "DUNNING_REQUESTED" : "Em processo de recuperação",
      "AWAITING_RISK_ANALYSIS" : "Pagamento em análise",
    })[str] || '';

    const SwitchColor = (str) => ({
      "PENDING" : "text-twitter",
      "RECEIVED" : "text-success",
      "CONFIRMED" : "text-success",
      "OVERDUE" : "text-danger",
      "REFUNDED" : "text-grey",
    })[str] || 'text-grey';

    let getStatus = props.status;

    return (
      <span className={SwitchColor(getStatus)}>
        { Switch(getStatus) }
      </span>
  );

  } 

