import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Moment from 'react-moment';

import Config from '../../services/config';
import api from '../../services/login';
import './styles.css';

const getFromCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
};

export default function ProductemailsList(props) {
  const [emails, setEmails] = useState([]);
  const [contractInfo, setContractInfo] = useState([]);
  const [appAuthorization, setAppAuthorization] = useState('');
  const [appUserId, setAppUserId] = useState('');
  const [loading, setLoading] = useState(true);

  const id = props.catId;
  const contractId = getFromCookie('selectedContractId');
  const userId = props.user;

  const fetchData = async () => {
    try {
      const authorization = Config.AuthApiSecretKey;
      const credencialdata = JSON.parse(localStorage.getItem('credencialData'));
      const userid = credencialdata?._id;

      setAppAuthorization(authorization);
      setAppUserId(userid);

      console.log(`contract id: ${contractId}`)

      // Get Emails
      const response = await api.get(`/user/emails/accounts`, {
        headers: {
          authorization,
          userId,
          contractid: contractId,
        },
      });
      setEmails(response.data);

      // Get Contract Info
      const contractInformation = await api.get(`/contract/${contractId}`, {
        headers: {
          authorization,
          userId,
          contractid: contractId,
        },
      });
      setContractInfo(contractInformation.data);
      console.log(contractInformation.data);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [contractId, userId]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-md-6">
                  <h3 className="card-title"> E-mails </h3>
                  <p> E-mails do contrato: <strong> {contractId} </strong></p>
                  <p>
                    Quantidade de caixas contratadas: <strong> ( {contractInfo.contract?.maxEmailAccount} ) </strong> <br/>
                    Total de caixas utilizadas: <strong> {emails?.length} </strong> <br/>
                    Webmail: <a href="https://webmail.pagefai.com" target='_blank'> https://webmail.pagefai.com </a>
                  </p>
                </div>

                <div className="col-md-6">
                  <div className="template-demo text-right">

                  {emails?.length <=  contractInfo.contract?.maxEmailAccount ? (
                    <Link to={`/user/emails/store`} className="btn btn-outline-dark btn-sm">
                      Criar novo e-mail
                    </Link>
                   ) : (
                    <Link to={`/user/emails/store`} className="btn btn-outline-dark btn-sm disabled">
                      Criar novo e-mail
                    </Link>
                  )}


                  </div>
                </div>
              </div>

              <div className="mt-5">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th> E-mail </th>
                        <th> Data de criação: </th>
                        <th className="text-right"> Ações </th>
                      </tr>
                    </thead>
                    <tbody>
                      {emails?.length > 0 ? (
                        emails.map((email, index) => (
                          <tr key={email._id}>
                            <td>
                              <Link to={`/user/emails/details/${email?.email}`} className="text-grey">
                                <span className="text-grey">
                                  <strong className="text-dark emailStyle"> {email?.email} </strong> <br />
                                </span>
                              </Link>
                            </td>
                            <td>
                              <Moment format="DD/MM/YYYY hh:mm:ss" date={email.createdAt} />
                            </td>
                            <td className="text-right">
                              <Link to={`/user/emails/details/${email.email}`} className="btn btn-outline-light btn-sm">
                                Editar
                              </Link>{' '}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">Não há dados para exibir aqui.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
