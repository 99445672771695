import React, { Component } from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Navigation from '../navigation';

function scrollWin() {
  window.scrollTo(0,0);
}

export default class SuspendAccount extends Component {

  componentDidMount(){
    scrollWin();
  }

  render() {
    return (
    <>
     <AuthPage />
     <Header />
     <div className="container-fluid page-body-wrapper" id="fscroll">
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="mt-4">
                  <div className="row">
                      
                    <div className="col-lg-8">
                        <div className="border-left-grey">
                            <div className="">
                              <h3> Encerramento de Conta </h3>
                              <p className="card-description text-grey mt-3 mb-4">
                                    Sentimos muito que queira encerrar a sua conta.
                                <br/>
                                <br/>
                              </p>
                              <form className="forms-sample">

                                  <div className="form-group">
                                    <label for="exampleInputEmail1"> Qual o motivo do encerramento da conta ? </label>
                                    <select class="form-control">
                                        <option></option>
                                        <option> Estou mudando de fornecedor </option>
                                        <option> Não gostei do serviço prestado </option>
                                        <option> Tive problemas com a integração </option>
                                        <option> Desisti do projeto </option>
                                        <option> Encerramento do Projeto/Empresa </option>
                                    </select>
                                  </div>

                                  <div className="form-group">
                                    <label for="exampleInputEmail1"> Você indicaria a Pagefy para um amigo ? </label>
                                    <select class="form-control">
                                        <option></option>
                                        <option> Sim </option>
                                        <option> Não </option>
                                    </select>
                                  </div>

                                  <div className="form-group">
                                    <label for="exampleInputEmail1"> Seus dados bancários estão atualizados para a transferência do saldo disponível ?  </label>
                                    <select class="form-control">
                                        <option></option>
                                        <option> Sim </option>
                                        <option> Não </option>
                                    </select>
                                  </div>

                                  <div className="form-group">
                                    <label> Informe a senha atual para encerramento da conta </label>
                                    <input type="password" className="form-control" placeholder="Sua Senha" value="" />
                                  </div>

                                  <div className="row">
                                    <div className="col-md-12">
                                      <p className="text-danger mt-20">
                                        * Ao encerrar sua conta o saldo disponível em conta será transferido automáticamente seguindo a periocidade dos pagamentos. <br/> <br/>
                                        Caso tenha assinaturas ou pagamentos parcelados estes serão automáticamente cancelados. Caso sua conta tenha débitos pendentes o valor
                                        será abatido do valor do saldo automáticamente.
                                        <br/>
                                        <br/>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-12 text-left mt-20">
                                      <button 
                                        type="submit" 
                                        className="btn btn-danger mr-2"
                                      > Encerrar conta </button>
                                    </div>
                                  </div>
                              </form>
                            </div>
                        </div>
                    </div>
                    
                    <Navigation />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
     <Footer />
    </>
    );
  }
}
