import React from 'react';

import AuthPage from '../../../../components/AuthPageAdmin';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import TutorialStore from '../../../../components/AdminConsoleComponents/Tutorials/Store';

export default function Store() {
  return (
    <>
      <AuthPage />
        <div className="container-scroller">
            <HeaderAdminConsole />
              <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                          <div class="row">
                              <div className="col-md-12">
                                <TutorialStore />
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
          </div>
      <Footer />
    </>
  );
}
