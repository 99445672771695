import React from "react"
import {Redirect} from "react-router-dom"
import Alerts from '../../components/Alerts/index';

export default class Logout extends React.Component{

    constructor(){
        super()
        // token remove
        localStorage.removeItem("token");
        localStorage.removeItem("credencialData");
        
        document.cookie = `selectedContractId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        document.cookie = `selectedProductName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        
        Alerts("Logout", 'Logout efetuado com sucesso, volte sempre! ' ,"success");


    }

    render(){
        return <Redirect to="/" />
    }
} 