import React from 'react';

export default function PaymentStatusGtw1(props) {

    const Switcher = (str) => ({
      "on-hold" : "Pedido Aguardando Pagamento",
      "processing" : "Pedido Processado",
      "pending" : "Pedido Pendente",
      "completed" : "Pedido Completo",
      "cancelled" : "Pedido Cancelado",
      "refunded" : "Pedido Extornado",
      "failed" : "Pedido Falhou",
      "trash" : "Pedido na Lixeira",
      "any" : "Pedido Aguardando status",
      "" : "btn btn-sm btn-grey",
    })[str] || 'text-grey';

    let getStatus = props.status;

    return (
      <span className={`text-uppercase disabled btn btn-sm  btn-twitter `}>
        {Switcher(getStatus)}
      </span>
  );

  } 