import React from 'react';

import './WithDrawalsRequestComponent.css';
// import './css/skeleton.css';

export default function WithDrawalsRequestComponent() {
  return (
    <>
    <div className="row">
        <div className="col-12">
            <div className="row mb-4">
                
                <div className="col-md-12">
                    <h3 className="card-title"> Solicitação de Saque </h3>
                    <p className="text-grey"> O horário máximo para que esse saque ocorra no mesmo dia é até as 15h. <br/>
                      Após esse horário, os valores serão transferidos pra sua conta bancária no próximo dia útil.  </p>
                </div>

            </div>

            <div className="row">

                <div className="col-12 col-sm-4 col-md-6 col-xl-4 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <h4 className="text-left"> <strong> Escolha o Valor </strong> </h4>
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="form-group">
                            <input type="text" className="form-control form-control-lg" placeholder="R$ "  />
                          </div>
                          <div className="form-group">
                            <label> Informe sua senha</label>
                            <input type="text" className="form-control form-control-lg" placeholder="Password"  />
                          </div>
                        </div>
                        <div className="col-md-12">
                          
                          <div className="form-group">
                            <button type="submit" className="btn btn-success mr-2"> Confirmar Saque </button>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-8 col-md-8 col-xl-8 grid-margin stretch-card">
                  <div className="card block-bg">
                    <div className="card-body">
                      <div className="row">
                        
                        <div className="col-md-6 text-left mt-2">
                            <p className="text-muted"> Valor Solicitado <br/> <strong> R$ 2.000,00 </strong> </p>
                            <p className="text-muted mt-3"> Custo da Transferência <br/> <strong> R$ 3,65 </strong> </p>
                            <p className="text-muted mt-3"> Valor a Creditar <br/> <strong> R$ 2.000,00 </strong> </p>
                        </div>

                        <div className="col-md-6 text-left mt-2">
                           <h5 className="text-muted"> Dados da Conta Bancária </h5>
                           <p className="mt-3 text-muted"> 
                             Razão Social: xxxx <br/>
                             Banco: Banco Original <br/>
                             CPF/CNPJ: 00.000.000/0000-00 <br/>
                             Agência: 0001 <br/>
                             Conta Bancária:: 01291-3 <br/>
                           </p>
                        </div>
                      </div>
                      <div className="d-flex text-right">
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
    </div>
    
</>
  );
}
