import React, { Component } from 'react';

import AuthPage from '../../../../components/AuthPageAdmin';
import Footer from '../../../../components/Footer';
import Header from '../../../../components/HeaderAdminConsole';
import TicketsList from '../../../../components/AdminConsoleComponents/Tickets/List';
import Config from '../../../../services/config';
import Loader from 'react-loader-spinner';

export default class List extends Component {
  render() {
    return (
      <>
        <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <div className="container-scroller">
          <Header />
          <div className="container-fluid page-body-wrapper">
            <div className="main-panel">
              <div className="content-wrapper">
                <TicketsList />
              </div>
            </div>
          </div>
          <Footer />
        </div>
    </>
    );
  }
}
