import React, { Component } from 'react';

import Config from '../../../services/config';
import AuthPage from '../../../components/AuthPage';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Navigation from '../navigation';
import Loader from 'react-loader-spinner';
import api from '../../../services/Users';
import Alerts from '../../../components/Alerts';

export default class ChangeBank extends Component {

    // 
    constructor(props) {

        const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const userid = credencialdata._id;
    
        super(props);
        this.state = {
          userdata: {},
          authorization,
          userid
        };
      }
    
      componentDidMount() {
        
        const authorization = this.state.authorization;
        const userid = this.state.userid;
    
        api.get('/user/bank/',{
            headers: { authorization, userid },
        })
          .then(res => {
            this.setState({ userdata: res.data.user });
            // console.log(res.data.user);
          });
    
      }
    
      handleChange = (e) => {
        const state = this.state.userdata
        state[e.target.name] = e.target.value;
        this.setState({userdata:state});
        // console.log(state);
      }
    
      onSubmit = (e) => {
    
        e.preventDefault();
        const authorization = this.state.authorization;
        const userid = this.state.userid;
        const { bankNumber, bankAgency, bankAccount } = this.state.userdata;
    
        api.put('/user/bank/', { bankNumber, bankAgency, bankAccount }, { headers: { authorization, userid },
    
        })
          .then((result) => {
            Alerts("Dados Pessoais", 'Dados atualizados com sucesso' ,"success");
            this.props.history.push("/user/account/bank");
          });
      }
    // 
  render() {
    return (
        <>
            <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
            />
            <AuthPage />
            <Header />
                <div className="container-fluid page-body-wrapper" id="fscroll">
                <div className="main-panel">
                    <div className="content-wrapper">
                    <div className="row">
                        <div className="col-12">
                        <div className="mt-4">
                            <div className="">
                            <div className="row">
                                
                                <div className="col-lg-8">
                                    <div className="">
                                        <div className="">
                                        <h3> Dados Bancários </h3>
                                        <p className="card-description text-grey mt-3 mb-4">
                                            É importante verificar os dados bancários estejam informados corretamente. 
                                            Apenas são aceitos o cadastramento de <strong> contas correntes </strong>
                                            <br/>
                                            <br/>
                                        </p>

                                        <form onSubmit={this.onSubmit}>

                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"> Banco ( Número ) * </label>

                                                <input 
                                                    placeholder="Número do seu Banco"
                                                    name="bankNumber"
                                                    type="text" 
                                                    className="form-control"
                                                    id="bankNumber"
                                                    value={this.state.userdata.bankNumber || ""} 
                                                    onChange={this.handleChange}
                                                />

                                            </div>

                                            <div className="row">
                                        
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label htmlFor="agency"> Agência * </label>

                                                    <input 
                                                        placeholder="Agências Número"
                                                        name="bankAgency"
                                                        type="text" 
                                                        className="form-control"
                                                        id="bankAgency"
                                                        value={this.state.userdata.bankAgency || ""} 
                                                        onChange={this.handleChange}
                                                    />

                                                </div>
                                                </div>

                                                <div className="col-md-8">
                                                <div className="form-group">
                                                    <label htmlFor="bankaccount"> Conta Corrente * </label>

                                                    <input 
                                                        placeholder="Conta Corrente Número"
                                                        name="bankAccount"
                                                        type="text" 
                                                        className="form-control"
                                                        id="bankAccount"
                                                        value={this.state.userdata.bankAccount || ""} 
                                                        onChange={this.handleChange}
                                                    />

                                                </div>
                                                </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                    <p className="text-grey mt-20">
                                                        * Ao clicar em Salvar você deverá estar ciente de que será necessário utilizar a nova senha no próximo acesso.
                                                        <br/>
                                                        <br/>
                                                    </p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 text-left mt-20">
                                                    <button 
                                                        type="submit" 
                                                        className="btn btn-info mr-2">  
                                                        Salvar </button>
                                                    </div>
                                                </div>

                                            </form>
                                       </div>
                                    </div>
                                </div>
                                
                                <Navigation />

                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                </div>
            <Footer />
        </>
    );
  }
}
