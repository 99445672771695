import React from 'react';

export default function CustomerPersonType(props) {

  let getPerson = props.personType;

  // console.log(getPerson);
  if(getPerson === "person"){
    return (
      <>
        <div className="badge badge-outline-primary badge-pill text-uppercase"> Pessoa Fisíca </div>
      </>
    )
  } else if(getPerson === "company"){
      return (
        <>
          <div className="badge badge-outline-success badge-pill text-uppercase"> Pessoa Jurídica </div>
        </>
      )
  }
  
}
