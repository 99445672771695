import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPage';
import Footer from '../../../../components/Footer';
import api from '../../../../services/Users';
import PaymentStatusGtw1 from '../../../../components/ChargesComponents/PaymentStatusGtw1';

import './styles.css';

export default class Contracts extends Component {

    constructor(props) {

        const { match: { params } } = props;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const authorization = Config.AuthApiSecretKey;
        const contractId = params.id;
        const customerId = credencialdata._id;

        super(props);
        this.state = {
          authorization,
          contractId,
          customerId,
          userdata: {},
          tutorialsdata: {}
        };
      }

      loadUserData(){
        const authorization = this.state.authorization;
        const customerId = this.state.customerId;

        api.get('/mobcustomer/detail', {
            headers: { authorization, userId: customerId },
        })
        .then(res => {
            this.setState({ userdata: res.data.user });
        });
      }

      loadThumbnail(src){
        if(!src){
          return "https://www.Pagefy.com.br/resources/tutorial-Pagefy.jpg"
        } else {
          return src
        }
      }

      loadTutorialsData(){

        const authorization = this.state.authorization;
        const customerId = this.state.customerId;

        api.get('/tutorials', {
            headers: { authorization, userId: customerId },
        })
        .then(res => {
            this.setState({ tutorialsdata: res.data.tutorials });
        });

      }

      componentDidMount() {
        this.loadUserData();
        this.loadTutorialsData();
      }

    render(){
        
      return (
        <>
          <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
          />

          <AuthPage />

          <div className="container-scroller">

            <Header />

                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                            <div className="row">

                            <div className="col-md-12">

                            <div className="row border-bottom">
                                    <div className="col-md-4">
                                        <h3> Treinamentos </h3>
                                        <p className="text-grey"> Seja bem vindo a área de treinamentos da Pagefy </p>
                                    </div>
                                    <div className="col-md-8 text-right">
                                        <p className="font-weight-normal mb-0 text-grey">
                                          <span className="text-grey"> Cliente: {this.state.userdata.name} </span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-4 ">
                                {/*  */}
                                  <div className="row portfolio-grid">

                                  {!this.state.tutorialsdata.length ? (
                                    <p className="text-grey mt-3"> Não há treinamentos para apresentar neste momento </p>
                                  ) : (
                                      <>
                                      {this.state.tutorialsdata.map((tutorial, index) => (
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12" key={index}>
                                           <Link to={`/user/tutorials/videos/${tutorial._id}`}>
                                              <figure className="effect-text-in">
                                                <img src={this.loadThumbnail(tutorial?.thumbnail)} alt="image" width="500" />
                                                <figcaption>
                                                  <h4 className="tutorialTitle">{tutorial?.title}</h4>
                                                  <p className="tutorialBg">{tutorial?.description}</p>
                                                </figcaption>
                                              </figure>
                                          </Link>

                                        </div>
                                      ))}
                                      </>

                                  )}


                                </div>
                                {/*  */}
                                
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
        </>
      )
    }
}