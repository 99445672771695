import React from 'react';

// import { Container } from './styles';

export default function TicketStatus(props) {

  const ticketStatus = props.ticketStatus;

  return (
    <div className="text-muted"> Status do Ingresso: <span className="text-warning"> {ticketStatus} </span> </div>
  );
}
