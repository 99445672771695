// import React, { useState, useEffect } from 'react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import Loader from 'react-loader-spinner';

import Config from '../../../services/config';
import api from '../../../services/Users';
import './styles.css';

import sslImg from '../../../assets/images/secure-ssl.png';

export default function Express({ history }) {

  const [customerDocument, setCustomerDocument] = useState('');
  const [showResults, setShowResults ] = useState(`${false}`);
  const [customerData, setCustomerData] = useState([]);

  async function handleSubmit(e) {
    e.preventDefault();

    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    setShowResults(true);

    if(customerDocument){

      try {
        const response = await api.get(`/customer/search?document=${customerDocument}`, {
          headers: { authorization, mobcustomerid : userid }
        });
        setCustomerData(response.data);
                    

        
      } catch (error) { 
        setCustomerData('customer not exists');
        console.log(showResults);
      }

    }
    // history.push('/dashboard');
  }

  return (
    <>
      <div className="row">
        <div className="col-12">

        <div className="row mb-3 border-bottom pb-3">

            <div className="col-md-6 linkItalic">
              <h3> <i className="ti-bolt-alt"></i>  Cobrança Express </h3>
            </div>

            <div className="col-md-6 text-right ">
                <Link to="/payments" className="btn btn-outline-twitter btn-sm mr-2 ">
                    Suas Cobranças
                </Link>
            </div>

        </div>

          <div className="row  p-3">


              <div className="col-md-6">

                <p className="text-grey mb-4"> Entre com o número de CPF ou CNPJ do cliente cadastrado, ou clique para consultar os clientes já cadastrados </p>

                <div className="dataContainer mt-3">

                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                            <label htmlFor="customerDocument"> CPF/CNPJ: </label>
                            <input 
                              id="customerDocument"
                              name="customerDocument"
                              type="number" 
                              className="form-control form-control-lg" 
                              placeholder="Informe o CPF/CNPJ do seu cliente" 
                              onChange={event => setCustomerDocument(event.target.value)}
                            />
                      </div>
                      <button className="btn btn-twitter btn-md ">
                          PESQUISAR </button>
                    </form>

                    <p className="mt-5"> 
                      <img src={sslImg} alt="Ssl Protection" width="80%" /> </p>

                    <p className="mt-4 text-grey"> 
                      Cobrança Powered <br/> by Pagefy ® </p>

                  </div>
                <div>

                </div>

              </div>
                  
              <div className="col-md-6 text-center text-grey greyBg p-5">

                  

                  <div className="searchContainer">
                    
                  <div className="row">

                  { !customerData._id && customerData !== 'customer not exists'? (
                    <>
                    <div className="col-md-12 text-center mt-2">
                      <p> Entre com o CPF ou CNPJ do cliente <br/> que deseja cobrar  
                          <br/> <br/> <i className="ti-wallet menu-icon iconLg mt-3"></i>
                      </p>
                    </div>
                    </>
                  ) : '' }

                  { !customerData._id ? '' : (
                    <>
                      <div className="col-md-12  text-left ml-3">
                        <>
                          <h4 className="text-dark mt-2 border-bottom"> {customerData.name} </h4>
                          <p className="mt-3 text-dark"> 
                            CPF/CNPJ: {customerData.customerDocument} <br/>
                            E-mail: {customerData.email} <br/>
                            Endereço: {customerData.addr}, {customerData.addrNumber} <br/>
                            Cep: {customerData.addrZip}
                          </p>
                          <Link to={`/charges/store/${customerData._id}`} className="btn btn-dark btn-lg mt-3">
                              GERAR COBRANÇA
                          </Link>
                        </>
                       
                      </div>
                    </>
                  )}

                  { customerData === 'customer not exists' ? (
                    <>
                      <div className="col-md-12  text-left ml-3 text-center">
                        <p className="ml-3 mt-4 text-dark col-md-12"> 
                          Não encontrei nenhum cliente com este documento, sem problema clique no botão abaixo para continuar </p>
                          <Link to={`/payment/express/next`} className="btn btn-dark btn-lg mt-3">
                              Gerar Cobrança
                          </Link>
                      </div>
                      </>
                  ) : ''}

                  </div>

                  </div>

              </div>

              </div>
              
              
            </div>

            </div>
    </>
  );
}
