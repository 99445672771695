import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Config from '../../../services/config';
import api from '../../../services/Users';
import Alerts from '../../../components/Alerts';

export default class Update extends Component {

    constructor(props) {
        
        const authorization = Config.AuthApiSecretKey;

        super(props);
        this.state = {
            userdata: {},
            authorization,
            userid: this.props.userId,
            customerid: this.props.customerID
        };
        
    }

    componentDidMount() {

        const authorization = this.state.authorization;
        const userid = this.state.userid;
        const customerid = this.state.customerid;

        api.get(`/customer/${customerid}`, {
            headers: { authorization, mobcustomerid: userid },
        })
        .then(res => {
            this.setState({ userdata: res.data.user });
            // console.log(res.data.user);
        });
    
      }
    
      handleChange = async (e) => {
        const state = this.state.userdata
        state[e.target.name] = e.target.value;
        this.setState({userdata:state});

        // Zip auto complete API
            const authorization = this.state.authorization;
            const userid = this.state.userid;

            if(e.target.name === 'addrZip'){
            
            const queryZip = e.target.value

            if(queryZip.length >= 8){
                const response = await api.post('/user/address/zip', {
                    zip: queryZip, 
                },{
                headers : { authorization, userid }
                });

                state.addr = response.data.logradouro;
                state.addrNeighborhood = response.data.bairro;
                state.addrCity = response.data.localidade;
                state.addrState = response.data.uf;
                this.setState({userdata:state});
            }
        }
        // Zip auto complete API

      }
    
      onSubmit = (e) => {
    
        e.preventDefault();
        const authorization = this.state.authorization;
        const userid = this.state.userid;
        const customerid = this.state.customerid;

        const { personType,
            customerDocument,
            name,
            email,
            companyTel,
            companyType,
            companyWebsite,
            addrZip,
            addr,
            addrNumber,
            addrNeighborhood,
            addrCity,
            addrState,
            addrComplement,
            comercialManager,
            taxCreditCartCash,
            taxCreditCartInstallment,
            taxBankTicket,
            taxAntiFraude,
            taxTransfer,
            bankNumber,
            bankAgency,
            bankAccount,
            apiMobKey,
            apiWalletKey,
            status } = this.state.userdata;
    
        api.put(`/customer/update/${customerid}`, { personType,
            mobcustomerid: userid,
            customerDocument,
            name,
            email,
            companyTel,
            companyType,
            companyWebsite,
            addrZip,
            addr,
            addrNumber,
            addrNeighborhood,
            addrCity,
            addrState,
            addrComplement,
            comercialManager,
            taxCreditCartCash,
            taxCreditCartInstallment,
            taxBankTicket,
            taxAntiFraude,
            taxTransfer,
            bankNumber,
            bankAgency,
            bankAccount,
            apiMobKey,
            apiWalletKey,
            status }, { headers: { authorization },
        }).then((result) => {
            Alerts("Cliente", `Dados atualizados com sucesso` ,"success");
          });
      }

  render() {

    return (
        <>
        <div>
            <div>
            <div>

                <div className="row mb-3" id="scrollUp">
                    
                    <div className="col-md-6">
                        <h3> Clientes / <span className="text-twitter">  {this.state.userdata.name || ""}  </span>  </h3>
                    </div>

                    <div className="col-md-6">
                        <div className="text-right">
                                <div className="dropdown">
                                    <Link to="/customers" className="btn btn-outline-twitter btn-sm">
                                        <i className="ti-list-ol"></i> Lista de Clientes
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                <div className="row">
                    <div className="col-md-12">

                        <form onSubmit={this.onSubmit}>

                            <h4 className="text-twitter mb-4 mt-4"> Dados Pessoais </h4>

                            <div className="form-group">
                                <label htmlFor="personType"> Pessoa Física / Pessoa Jurídica * </label>
                                <select 
                                id="personType"
                                name="personType"
                                value={this.state.userdata.personType || ""}
                                onChange={this.handleChange}
                                className="form-control">
                                    <option value=""> Selecione o tipo da conta </option>
                                    <option value="person"> Pessoa Física </option>
                                    <option value="company"> Pessoa Jurídica </option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="customerDocument"> Documento  * </label>
                                <input 
                                    placeholder="Insira o número do CPF..." 
                                    type="text" 
                                    className="form-control" 
                                    name="customerDocument"
                                    id="customerDocument"
                                    value={this.state.userdata.customerDocument || ""} 
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="name"> Nome * </label>
                                <input 
                                placeholder="Nome Completo" 
                                type="text" 
                                className="form-control" 
                                name="name"
                                id="name"
                                value={this.state.userdata.name || ""} 
                                onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Endereço de E-mail * </label>
                                <input 
                                    placeholder="E-mail" 
                                    type="text" 
                                    className="form-control" 
                                    name="email"
                                    id="email"
                                    value={this.state.userdata.email || ""}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <h4 className="text-twitter  mb-4 mt-5"> Dados Comerciais </h4>

                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="companyWebsite"> Website Comercial * </label>
                                        <input 
                                            placeholder="Insira o endereço do website..." 
                                            id="companyWebsite"
                                            name="companyWebsite"
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.userdata.companyWebsite || ""} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="UserName"> Tel. Comercial * </label>
                                        <input 
                                            placeholder="Insira o telefone comercial..." 
                                            name="companyTel"
                                            id="companyTel"
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.userdata.companyTel || ""}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    
                                    <div className="form-group">
                                        <label htmlFor="companyType"> Tipo de Empresa * </label>
                                        <select 
                                        name="companyType"
                                        id="companyType"
                                        value={this.state.userdata.companyType || ""}
                                        onChange={this.handleChange}
                                        className="form-control">
                                            <option value=""> Selecione o tipo da conta </option>
                                            <option value="mei"> Micro Empreendedor Individual </option>
                                            <option value="limited"> Empresa Limitada </option>
                                            <option value="individual"> Empresa Individual </option>
                                            <option value="association"> Associação </option>
                                        </select>
                                    </div>

                                </div>
                               
                            </div>

                            <h4 className="text-twitter  mb-3 mt-3"> Endereço </h4>

                            <div className="form-group">
                                <label htmlFor="addrZip"> CEP * </label>
                                <input 
                                    placeholder="Insira o número do CEP..." 
                                    name="addrZip"
                                    id="addrZip"
                                    type="text" 
                                    className="form-control" 
                                    value={this.state.userdata.addrZip || ""} 
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="row">
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label htmlFor="UserName"> Endereço * </label>
                                        <input 
                                            placeholder="Informe o endereço..."
                                            id="addr"
                                            name="addr"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.userdata.addr || ""} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="addNumber"> Número * </label>
                                        <input 
                                            placeholder="Informe o número..."
                                            name="addrNumber"
                                            id="addrNumber"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.userdata.addrNumber || ""} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="addrNeighborhood"> Bairro </label>
                                        <input 
                                            placeholder="Bairro"
                                            name="addrNeighborhood"
                                            id="addrNeighborhood"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.userdata.addrNeighborhood || ""}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="addrCity"> Cidade * </label>
                                        <input 
                                            placeholder="Cidade"
                                            name="addrCity"
                                            id="addrCity"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.userdata.addrCity || ""} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="addrState"> Estado * </label>
                                        <input 
                                            placeholder="Estado"
                                            name="addrState"
                                            type="text" 
                                            className="form-control"
                                            id="addrState"
                                            value={this.state.userdata.addrState || ""}
                                            onChange={this.handleChange} />
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label htmlFor="addrComplement"> Complemento </label>
                                        <input 
                                            placeholder="Informe o complemento caso tenha"
                                            name="addrComplement"
                                            type="text" 
                                            className="form-control"
                                            id="addrComplement"
                                            value={this.state.userdata.addrComplement || ""} 
                                            onChange={this.handleChange}
                                          />
                                      </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="status"> Status do Cliente </label>
                                <select 
                                id="status"
                                name="status"
                                value={this.state.userdata.status || ""}
                                onChange={this.handleChange}
                                className="form-control">
                                    <option value=""> Selecione o Status </option>
                                    <option value="1"> Ativo </option>
                                    <option value="2"> Inativo </option>
                                </select>
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <button 
                                    type="submit" 
                                    className="btn btn-info mr-2"
                                    > Salvar </button>
                                </div>
                            </div>
                </form>
                </div>

              </div>
            </div>
        </div>
        </div>
        </>
    );
  }
}