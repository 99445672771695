import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

import camera from '../../../assets/images/camera.svg';

export default function PhotoStore() {

  const [thumbnail, setThumbnail] = useState(null);
  
  const preview = useMemo(() => {
    return thumbnail ? URL.createObjectURL(thumbnail) : null;
  }, [thumbnail])


  return (
    <>
        <div className="col-lg-12">
        <div>
            <div>

                <div className="row mb-3">
                    
                    <div className="col-md-6">
                        <h3 className="card-title"> Foto / Nova </h3>
                        <p className="text-grey"> As imagens devem conter no máximo 2MB <br/> e que estejam no formato .JPG ou .PNG  </p>
                    </div>

                    <div className="col-md-6">
                        <div className="text-right">
                                <div className="dropdown">
                                    <Link to="/photos" className="btn btn-outline-twitter btn-sm">
                                        <i className="ti-list-ol"></i> Lista de Fotos
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                <div className="row">
                    <div className="col-md-8 borderRight">
                        <form className="forms-sample">

                            <h4 className="card-title text-twitter mb-4 mt-4"> Media </h4>

                            <div className="form-group">
                                <label htmlFor="campaignTitle"> Título * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="campaignTitle" 
                                    placeholder="Informe o título da campanha..." 
                                />
                            </div>

                            <div className="form-group">
                            <label htmlFor="thumbnail"> Upload de Imagem * </label>
                            <label 
                                id="thumbnail" 
                                style={{ backgroundImage: `url(${preview})` }}
                                className={thumbnail ? 'has-thumbnail' : ''}
                            >
                                <input type="file" onChange={event => setThumbnail(event.target.files[0])} />
                                <img src={camera} alt="Select img" />
                            </label>
                            </div>

                            <div className="form-group">
                                <label htmlFor="description"> Descrição </label>
                                <textarea className="form-control" 
                                    id="description" 
                                    placeholder="Breve descrição do conteúdo  ..." 
                                    rows="3"></textarea>
                            </div>

                </form>
                </div>
                    <div className="col-md-4 border-left-grey pd-l2">

                    <div className="row ">
                        <div className="col-md-12">

                            <h4 className="card-title text-twitter"> Tags e Categorias </h4>

                            <div className="form-group mt-4">
                                <label htmlFor="endCampaign"> Informe as tags separadas por vírgula </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="endCampaign" 
                                    placeholder="Ex: e-commerce, publicação sobre mercado..."
                                />
                            </div>


                            <div className="form-group">
                                <label htmlFor="endCampaign"> Categorias </label>
                                <div className="container-categories">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" />
                                          Publicação Comum
                                        <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" />
                                            Vídeos
                                        <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" />
                                        Disabled
                                        <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" />
                                        Disabled checked
                                        <i className="input-helper"></i></label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect2"> Status da Publicação </label>
                                <select className="form-control" id="exampleFormControlSelect2">
                                    <option> Publicado </option>
                                    <option> Offline </option>
                                    <option> Rascunho </option>
                                </select>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <p className="text-grey mt-20">
                                    * Saiba mais sobre SEO entrando em contato com nossos consultores.
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <button 
                                    type="submit" 
                                    className="btn btn-info mr-2"
                                    > Salvar </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}
