import React, { Component } from 'react';
const QRCode = require('qrcode.react');

export default class Qrcode extends Component {
  render(props) {

    const qrContent = this.props.qrContent;
    const qrW = Number(this.props.qrW);

    return (
      <QRCode 
        value={qrContent} 
        size={qrW}
        />
    )
  }
}
