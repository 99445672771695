import React from 'react';

export default function DocumentationResume() {
  return (
    <div className="row">
    <div className="col-12 mb-20 mt-20">

      <div className="row">
        <div className="col-md-12">
            <h3 className="card-title"> Documentação </h3>
            <p className="text-grey"> Abaixo você encontra a documentanção de nossa plataforma para desenvolvedores e usuário final. </p>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
                <th> Documento </th>
                <th className="text-right"> Data de Atualização </th>
                <th className="text-right"> </th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td> Gerando Boletos através da Dashboard </td>
                <td className="text-right text-grey"> 06/11/2019 as 20:30 </td>
                <td className="text-right">
                  <button class="mr-3 btn btn-outline-primary btn-sm"> <i className="ti-cloud-down primary"></i> Download </button>
                </td>
            </tr>
            <tr>
                <td> API Pagefy Versão 1.1 </td>
                <td className="text-right text-grey"> 06/11/2019 as 20:30 </td>
                <td className="text-right">
                  <button class="mr-3 btn btn-outline-primary btn-sm"> <i className="ti-cloud-down primary"></i> Download </button>
                </td>
            </tr>
            <tr>
                <td> Instalando Plugin do Woocommerce </td>
                <td className="text-right text-grey"> 06/11/2019 as 20:30 </td>
                <td className="text-right">
                  <button class="mr-3 btn btn-outline-primary btn-sm"> <i className="ti-cloud-down primary"></i> Download </button>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  );
}
