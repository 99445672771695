import React from 'react';

import Config from '../../../services/config';
import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import ExpressComponent from  '../../../components/Payment/Express';
import Loader from 'react-loader-spinner';

export default function Express() {

  return (
    <>
    
    <Loader
       type="BallTriangle"
       color="#000000"
       height={60}
       width={60}
       timeout={Config.loaderTimeout}
       className="overlaySpinner" //3 secs
   />

    <AuthPage />

    <div className="container-scroller">
        <Header />
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                <div className="content-wrapper">
                    <ExpressComponent />
                </div>
            </div>
        </div>
        <Footer />
    </div>

    </>
  );
}
