import React from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Axios from 'axios';

import AuthPage from '../../../../components/AuthPageAdmin';
import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Footer from '../../../../components/Footer';
import CustomerUpdate from '../../../../components/AdminConsoleComponents/Customers/Update';
import NavigationMenu from '../../../../components/AdminConsoleComponents/Navigation';
import WelcomeConsole from '../../../../components/AdminConsoleComponents/WelcomeConsole';
import api from '../../../../services/Users';
import Config from '../../../../services/config';

export default function Update(props) {

    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userId = credencialdata._id;

    const { match: { params } } = props;
    const customerId = params.id

    const handleChangePassword = () => {
        swal({
            title: "Alterar Senha",
            text: 'Lembre-se que o usuário deverá informar a nova senha no próximo login',
            content: "input",
            button: {
              text: "Alterar",
              closeModal: true,
            },
          })
          .then(password => {

            if (!password || password.length < 8){
                swal("Alterar Senha", "Não posso enviar sua solicitação", "error");
                swal.stopLoading();
                swal.close();
            } 
            return api.put(`/admin/password/update`, {
                securityPassword: `${password}`
            },{
                headers : {
                    authorization: `${authorization}`,
                    userId: `${userId}`,
                    customerId: `${customerId}`,
                },
            });
          })
          .then(results => {
            return swal("Alterar senha", "Senha alterada", "success");
          })
          .catch(err => {
            if (err) {
              return swal("Oops!", "A requisição falhou", "error");
            } else {
              swal.stopLoading();
              swal.close();
            }
        }
        );
           
    }
    
    return (
    <>
        <AuthPage />
        <div className="container-scroller">
            <HeaderAdminConsole />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                        <div className="row">

                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-8">
                                        <CustomerUpdate customerId={customerId} />
                                    </div>
                                    <div className="col-md-4">
                                        {/*  */}
                                        <div className="text-right">
                                            <div className="dropdown">
                                                
                                                <div className="col-md-12">
                                                    <Link to="/admin/customers" className="btn btn-outline-secondary btn-sm">
                                                        Lista de Clientes
                                                    </Link>
                                                </div>

                                                <div className="col-md-12 mt-3">
                                                    <Link to={`/admin/contracts/${customerId}`} className="btn btn-outline-success btn-sm ml-4">
                                                        Contratos deste cliente
                                                    </Link>
                                                </div>

                                                <div className="col-md-12 mt-3">
                                                    <button onClick={handleChangePassword} className="btn btn-outline-pinterest btn-sm ml-4">
                                                        Alterar Senha
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {/*  */}
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
