import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';

import Config from '../../../../services/config';
import api from '../../../../services/login';
import notFound from '../../../../assets/images/notfound.svg';

import TicketStatus from '../../../../components/Common/TicketStatus';

export default function SupportResume() {

  const [ tickets, setTickets ] = useState([]);

  useEffect(() => {
       
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;
    
     async function loadTickets() {
         const response = await api.get('/admin/tickets', {
            headers: { authorization, userid },
         })
         setTickets(response.data.tickets);
         console.log(tickets);
     }
     loadTickets();
  },[])

  return (
    <div className="row">
                <div className="col-12 mb-20 mt-20">

                  <div className="row mb-3">
                    <div className="col-md-8">
                        <h3 className="card-title"> Tickets Abertos </h3>
                        <p className="text-grey"> Abaixo você encontra a lista de tickets abertos no sistema por ordem de abertura </p>
                    </div>
                    <div className="col-md-4 text-right">

                      <Link to="/admin/main">
                          <button className="btn btn-outline-dark btn-sm mt-3 mb-4"> 
                             Voltar Home Admin  
                          </button>
                      </Link>
                        
                    </div>
                    
                  </div>

                  <div className="table-responsive">

                  { !tickets.length ? (
                        <>
                          <div className="col-md-12">
                              <img src={notFound} alt="Data not found " />
                          </div>
                        </>
                      ) : (

                        <table className="table">
                          
                            <tbody>
                              {tickets.map((ticket, index) => (
                                <tr key={ticket._id}>
                                    <td>
                                      Ticket ID # 
                                      <Link to={`/admin/ticket/${ticket._id}`}>
                                        {ticket._id}
                                      </Link>
                                      <br/><br/> 
                                      <span className="mt-2 text-grey"> 
                                        Cliente: {ticket.authorName} <br/> 
                                        E-mail: {ticket.authorEmail} 
                                      </span>
                                    </td>
                                    <td className="text-grey">  
                                      <span className="text-dark"> 
                                        <strong> {ticket.title} </strong>
                                      </span> <br/><br/>
                                      Aberto em: <Moment format="DD/MM/YYYY hh:mm" date={ticket.createdAt || ""} /> <br/>
                                      Atualizado: <Moment format="DD/MM/YYYY hh:mm" date={ticket.updatedAt || ""} />
                                    </td>
                                    <td className="text-right">
                                      <TicketStatus status={ticket.status} />

                                      <Link to={`/admin/ticket/${ticket._id}`}>
                                        <button className="ml-3 btn btn-outline-twitter btn-sm">
                                          Detalhes
                                        </button>
                                      </Link>

                                    </td>
                                </tr>
                              ))}

                            </tbody>


                        </table>
                    )}
                  </div>
                </div>
              </div>
  );
}
