import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import Loader from 'react-loader-spinner';
import swal from 'sweetalert';

import Config from '../../../services/config';
import api from '../../../services/login';
import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Alerts from '../../../components/Alerts';
import image1 from '../../../assets/images/app-configuration.svg'

require('./style.css');

export default function SupportEdit(props) {

  const { match: { params } } = props;
  const [appData, setAppData] = useState({
    appTitle: '',
    description: '',
    status: '',
    button01Link: '',
    button01Title: '',
    button02Link: '',
    button02Title: '',
    button03Link: '',
    button03Title: '',
    button04Link: '',
    button04Title: '',
    contactNumber: '',
    contactNumberText: '',
    description: '',
    donationLink: '',
    donationLinkText: '',
    liveLink: '',
    liveLinkText: '',
    modalDescription: '',
    modalTitle: '',
    enableModal: '',
    wazeLink: '',
    wazeLinkText: '',
    whatsAppMessage: '',
    whatsAppNumber: '',
    whatsAppText: '',
    instagramLink: '',
    youtubeLink: '',
  });

  const [description, setDescription] = useState('')

  const authorization = Config.AuthApiSecretKey;
  const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
  const userid = credencialdata._id;

  const contractId = params.contractId;

useEffect(() => {
       
  const authorization = Config.AuthApiSecretKey;
  const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
  const userid = credencialdata._id;

   async function loadData() {
       const response = await api.get(`/app-configurations/${contractId}`, {
           headers: { authorization, userid },
       })
       setAppData(response.data);
   }
   loadData();
},[]);

async function onSubmit(e){

  e.preventDefault();

  const {  appTitle, description, status, button01Link, button01Title, button02Link, button02Title, button03Link,
    button03Title, button04Link, button04Title, contactNumber, contactNumberText, donationLink, donationLinkText,
    liveLink, liveLinkText, modalDescription, modalTitle, enableModal, wazeLink, wazeLinkText, whatsAppMessage,
    whatsAppNumber, whatsAppText, instagramLink, youtubeLink
  } = appData
  
  try {
    await api.put(`/user/app-configurations/${contractId}`, {
      appTitle, description, status, button01Link, button01Title, button02Link, button02Title, button03Link,
      button03Title, button04Link, button04Title, contactNumber, contactNumberText, donationLink, donationLinkText,
      liveLink, liveLinkText, modalDescription, modalTitle, enableModal, wazeLink, wazeLinkText, whatsAppMessage,
      whatsAppNumber, whatsAppText, instagramLink, youtubeLink
    }, {
      headers: { authorization, userid  }
    });
      
    swal({
      title: "Aplicativo",
      text: "Configurações Salvas com Sucesso",
      timer: 1000
    });

    setDescription('');
    const upDate= new Date();
    // setUpdateList(upDate);

    } catch (error) {
      Alerts("Tickets ", error.response.data.error ,"error");
    }

}

const handleChange = (e) => {
  const { name, value } = e.target;
  setAppData((prevState) => ({ ...prevState, [name]: value }));
};


  return (
    <>
        <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <Header /> 

        <div className="container-fluid page-body-wrapper">
            <div className="main-panel">
              <div className="content-wrapper">
                
                <div className="row">
                  <div className="col-md-6">
                    <h3> Gerenciar Aplicativo </h3>
                    {/*  */}
                      <form className="forms-sample" onSubmit={onSubmit} className='mt-4'>
                        <div className="row">
                          <div className="col-md-12">
                            
                            <h4> Informações do Aplicativo </h4>
                            
                            <div className="form-group mt-4">
                                <input 
                                  placeholder="Tìtulo do Aplicativo"
                                  id="appTitle"
                                  name="appTitle"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.appTitle}
                                  onChange={handleChange}
                                  required
                                />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                                <input 
                                  placeholder="Descrição do aplicativo"
                                  id="description"
                                  name="description"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.description}
                                  onChange={handleChange}
                                  />
                            </div>
                          </div>
                          <div className="col-md-12">

                            <h4> Botão Doação </h4>
                            <div className="form-group">
                                <input 
                                  placeholder="Texto do Botão de doação"
                                  id="donationLinkText"
                                  name="donationLinkText"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.donationLinkText}
                                  onChange={handleChange}
                                  />
                            </div>
                            <div className="form-group">
                                <input 
                                  placeholder="Link do botão doação"
                                  id="donationLink"
                                  name="donationLink"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.donationLink}
                                  onChange={handleChange}
                                />
                            </div>

                            <h4> Janela Modal no Aplicativo </h4>
                            <p className='text-grey'> Esta funcionalidade habilita uma janel modal no aplicativo toda vez que o usuário abrir o aplicativo </p>
                            <div className="form-group">
                              <input 
                                  placeholder="Título da Janela"
                                  id="modalTitle"
                                  name="modalTitle"
                                  className="form-control" 
                                  rows="7" 
                                  value={appData?.modalTitle}
                                  onChange={handleChange}
                                />  
                            </div>
                            <div className="form-group">
                                <input 
                                  placeholder="Descrição da Janela"
                                  id="modalDescription"
                                  name="modalDescription"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.modalDescription}
                                  onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <select 
                                id="enableModal"
                                name="enableModal"
                                  value={appData?.enableModal}
                                  onChange={handleChange}
                                className="form-control">
                                    <option value=""> Habilitar Janel Modal </option>
                                    <option value="false"> Não </option>
                                    <option value="true"> Sim </option>
                                </select>
                            </div>
                            
                            <h4> Transmissão ao Vivo </h4>
                            <p className='text-grey'> A funcionalidade de transmissão ao vivo é 
                              apresentada no aplicativo quando os campos de texto do botão e link são preenchidos</p>
                            <div className="form-group">
                                <input 
                                  placeholder="Texto do botão de transmissão ao vivo "
                                  id="liveLinkText"
                                  name="liveLinkText"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.liveLinkText}
                                  onChange={handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <input 
                                  placeholder="Link do botão de transmissão ao vivo"
                                  id="liveLink"
                                  name="liveLink"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.liveLink}
                                  onChange={handleChange}
                                />
                            </div>

                            <h4> Botão Contato por Telefone </h4>
                            <p className='text-grey'> Esta funcionalidade habilita um botão de ligação automática quando clicado </p>
                            <div className="form-group">
                                <input 
                                  placeholder="Texto do botão"
                                  id="contactNumberText"
                                  name="contactNumberText"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.contactNumberText}
                                  onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <input 
                                  placeholder="Número de telefone"
                                  id="contactNumber"
                                  name="contactNumber"
                                  
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.contactNumber}
                                  onChange={handleChange}
                                
                                />
                            </div>

                            <h4> Localização Waze </h4>
                            <p className='text-grey'> Esta funcionalidade permite que o usuário dirija até o local utilizando
                            a integração com o aplicativo Waze </p>
                            <div className="form-group">
                                <input 
                                  placeholder="Texto do botão"
                                  id="wazeLinkText"
                                  name="wazeLinkText"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.wazeLinkText}
                                  onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <input 
                                  placeholder="Link do botão"
                                  id="wazeLink"
                                  name="wazeLink"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.wazeLink}
                                  onChange={handleChange}
                                />
                            </div>

                            <h4> Redes Sociais </h4>
                            <div className="form-group">
                                <input 
                                  placeholder="Link Instagram"
                                  id="instagramLink"
                                  name="instagramLink"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.instagramLink}
                                  onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <input 
                                  placeholder="Link Youtube"
                                  id="description"
                                  name="description"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.instagramLink}
                                  onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <input 
                                  placeholder="Link Facebook"
                                  id="facebookLink"
                                  name="facebookLink"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.facebookLink}
                                  onChange={handleChange}
                                />
                            </div>

                            <h4> Whatsapp </h4>
                            <p className='text-grey'> Esta funcionalidade permite a integração com o aplicativo Whatsapp. 
                            Quando preenchido o número ele habilita no app, um botão para entrar em contato</p>
                            <div className="form-group">
                                <input 
                                  placeholder="Whatsapp número do telefone, Ex: +5511999999999"
                                  id="whatsAppNumber"
                                  name="whatsAppNumber"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.whatsAppNumber}
                                  onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <input 
                                  placeholder="Texto do Botão do Whatsapp"
                                  id="whatsAppText"
                                  name="whatsAppText"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.whatsAppText}
                                  onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <input 
                                  placeholder="Whatsapp Mensagem"
                                  id="whatsAppMessage"
                                  name="whatsAppMessage"
                                  className="form-control" 
                                  rows="7"
                                  value={appData?.whatsAppMessage}
                                  onChange={handleChange}
                                />
                            </div>

                            <h4> Botões Principais </h4>
                            <p className='text-grey'> Aqui é configurado os botões que ficam na parte inferior do aplicativo </p>

                            <div className="form-group">
                              <h5 className='text-grey mt-3'> Botão 01 </h5>
                                  <input 
                                    placeholder="Botão 01 Texto"
                                    id="button01Title"
                                    name="button01Title"
                                    className="form-control" 
                                    rows="7"
                                    value={appData?.button01Title}
                                    onChange={handleChange}
                                  />
                            </div>
                            <div className="form-group">
                                  <input 
                                    placeholder="Botão 01 Link"
                                    id="button01Link"
                                    name="button01Link"
                                    className="form-control" 
                                    rows="7"
                                    value={appData?.button01Link}
                                    onChange={handleChange}
                                  />
                            </div>

                            <h5 className='text-grey mt-3'> Botão 02 </h5>
                            <div className="form-group">
                                  <input 
                                    placeholder="Botão 02 Texto"
                                    id="button02Title"
                                    name="button02Title"
                                    className="form-control" 
                                    rows="7"
                                    value={appData?.button02Title}
                                    onChange={handleChange}
                                  />
                            </div>
                            <div className="form-group">
                                  <input 
                                    placeholder="Botão 02 Link"
                                    id="button02Link"
                                    name="button02Link"
                                    className="form-control" 
                                    rows="7"
                                    value={appData?.button02Link}
                                    onChange={handleChange}
                                  />
                            </div>

                            <h5 className='text-grey mt-3'> Botão 03 </h5>
                            <div className="form-group">
                                  <input 
                                    placeholder="Botão 03 Texto"
                                    id="button03Title"
                                    name="button03Title"
                                    className="form-control" 
                                    rows="7"
                                    value={appData?.button03Title}
                                    onChange={handleChange}
                                  />
                            </div>
                            <div className="form-group">
                                  <input 
                                    placeholder="Botão 03 Link"
                                    id="button03Link"
                                    name="button03Link"
                                    className="form-control" 
                                    rows="7"
                                    value={appData?.button03Link}
                                    onChange={handleChange}
                                  />
                            </div>

                            <h5 className='text-grey mt-3'> Botão 04 </h5>

                            <div className="form-group">
                                  <input 
                                    placeholder="Botão 04 Texto"
                                    id="button04Title"
                                    name="button04Title"
                                    className="form-control" 
                                    rows="7"
                                    value={appData?.button04Title}
                                    onChange={handleChange}
                                  />
                            </div>
                            <div className="form-group">
                                  <input 
                                    placeholder="Botão 04 Link"
                                    id="button04Link"
                                    name="button04Link"
                                    className="form-control" 
                                    rows="7"
                                    value={appData?.button04Link}
                                    onChange={handleChange}
                                  />
                            </div>
                        
                          </div>


                        </div>

                        <div className="row">
                          <div className="col-md-12 text-left mt-20">
                            <button 
                              type="submit" 
                              className="btn btn-dark mr-2" > 
                              Enviar </button>
                          </div>
                        </div>
                    </form>
                    {/*  */}

                  </div>
                  <div className="col-md-6 text-right mt-4">
                    <img src={image1} alt="App configuration illustration" width="100%" />
                  </div>
                </div>
            </div>
          </div>
        </div>

        <Footer />
    </>
  );
}