import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link, Redirect } from 'react-router-dom';

import Header from '../../../components/Header';
import Config from '../../../services/config';
import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import api from '../../../services/Users';

import StatusIcon from '../../../components/Common/StatusIcon';

export default class Backups extends Component {

  constructor(props) {
    
    const { match: { params } } = props;
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;
    
    super(props);
    this.state = {
      redirect: false,
      backups: [],
      authorization,
      userid,
      contractId: params.id
    };
  }

  loadBackups(){
      const authorization = this.state.authorization;
      const userid = this.state.userid;
  
      api.get(`/mybackups`,{
          headers: { authorization, userid },
      })
      .then(res => {
          this.setState({ backups: res.data.backups });
      });
      
  }

  componentDidMount() {
    this.loadBackups();
  }
    
    render(){

      return (
        <>
          <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
          />

          <AuthPage />

          <div className="container-scroller">

            <Header />

            <div className="container-fluid page-body-wrapper" id="fscroll">
         <div className="main-panel">
           <div className="content-wrapper">
             <div className="row">
               <div className="col-12">
                 <div className="mt-4">
                   <div className="">
                     <div className="row">
                       
                       <div className="col-lg-12">
                               <div className="">

                                 <h3 className='mb-4'> Histórico de Backups  </h3>

                                 <p className='text-gray mb-4'> Os planos de backup variam de acordo com o plano 
                                  contratado consulte as condições do seu serviço contratado </p>

                                 <table className="table">
                                    <tbody>
                                      {this.state.backups.map((backup, index) => (
                                        <tr key={backup._id}>
                                          <td className='text-black'> 
                                            <Moment format="DD/MM/YYYY - hh:mm:ss" date={backup?.createdAt || ""} /> - {backup?.machine} 
                                          </td>
                                          <td className="text-right"> 
                                            <StatusIcon iconStatus={backup?.status} />
                                          </td>
                                        </tr>
                                       ))}
                                    </tbody>
                                </table>
                               </div>
                      </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
       </div>
            <Footer />
        </div>



        </>
      )
    }
}