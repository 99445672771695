import React, { useEffect, useState } from 'react';


import Config from '../../../services/config';
import api from '../../../services/Users';

function CustomerBalance(props) {
  
  const [ balances, setBalances ] = useState([]);
  const contractId = props.contractId;
  const customerId = props.customerId;

  useEffect(() => {
    const authorization = Config.AuthApiSecretKey;

    async function loadBalance(){
        await api.get(`/transactions/balance/${contractId}`,{
          headers: { authorization, user: customerId },
      })
      .then(res => {
          setBalances(res.data);
      });
    }
    loadBalance()
    console.log(balances)
 },[])

  return (
    <>
      <div className="row mt-3">
        <div className="col-md-4 grid-margin">
            <div className="card d-flex align-items-center">
                <div className="card-body">
                    <div className="d-flex flex-row align-items-center">
                        <i className="ti-time text-twitter icon-md"></i>
                        <div className="ml-3">
                            <h4 className="text-twitter"> {balances?.waiting_funds} </h4>
                            <p className="mt-2 text-muted card-text">  Aguardando Liberação </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-4 grid-margin">
            <div className="card d-flex align-items-center">
                <div className="card-body">
                    <div className="d-flex flex-row align-items-center">
                        <i className="ti-check text-twitter icon-md"></i>
                        <div className="ml-3">
                            <h4 className="text-twitter"> {balances?.available}  </h4>
                            <p className="mt-2 text-muted card-text"> Saldo Disponível 
                                
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-4 grid-margin">
            <div className="card d-flex align-items-center">
                <div className="card-body">
                    <div className="d-flex flex-row align-items-center">
                        <i className="ti-pulse text-twitter icon-md"></i>
                        <div className="ml-3">
                            <h4 className="text-twitter"> {balances?.transfered}  </h4>
                            <p className="mt-2 text-muted card-text"> Saldo Transferido </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default CustomerBalance;