import React from 'react';
import {Redirect} from "react-router-dom"
import Config from '../../services/config';
import api from '../../services/Users';

export default function AuthPageAdmin(props) {

    const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const userid = credencialdata._id;

        async function loadGroup() {
            
            await api.get('/user/group', {
                headers: { authorization, userid },
            }).then(response => {
              if(response.data.user.group !== "root"){
                  window.location='../404';
              }
            })

        }

      loadGroup();

    const token = localStorage.getItem("token");

    if(!token){
        // console.log('Login Status : ERROR');
        return <Redirect to="/" />
    }else{
        // console.log('Login Status : OK');
        return '';
    }

}
