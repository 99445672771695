import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Config from '../../../../services/config';
import api from '../../../../services/Users';
import Alerts from '../../../../components/Alerts';

export default class Update extends Component {

    constructor(props) {
        
        const authorization = Config.AuthApiSecretKey;
        // const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        // const userid = this.props.customerId;

        super(props);
        this.state = {
            userdata: {},
            contractdata: {},
            productdata: [],
            authorization,
            userid: this.props.customerId,
            contractId: this.props.contractId,
            showCompany: false
        };
        
    }

      handleChange = async (e) => {
        const state = this.state.contractdata
        state[e.target.name] = e.target.value;
        this.setState({contractdata:state});
       
      }
    
      loadContract(){
        const authorization = this.state.authorization;
        // const userid = this.state.userid;
    
        api.get(`/contract/${this.state.contractId}`,{
            headers: { authorization },
        })
        .then(res => {
            this.setState({ contractdata: res.data.contract });
        });
        
      }

      loadProducts(){
        const authorization = this.state.authorization;
    
        api.get(`/admin/products`,{
            headers: { authorization },
        })
        .then(res => {
            this.setState({ productdata: res.data.products });
        });

      }
    
      onSubmit = async (e) => {
    
        e.preventDefault();
        const authorization = this.state.authorization;
        const mobcustomerid = this.state.userid;

        const { productId,
            accountManager,
            taxCreditCartCash,
            taxCreditCartInstallment,
            taxBankTicket,
            taxAntiFraude,
            taxTransfer,
            notes,
            domainUrl,
            transactionGtw, 
            transactionGtwKey, 
            status,
            wcConsumerKey, 
            wcConsumerSecret,
            subscriptionId
            } = this.state.contractdata;
    
        const response = await api.put(`/contract/${this.state.contractId}`, {
            productId,
            accountManager,
            taxCreditCartCash,
            taxCreditCartInstallment,
            taxBankTicket,
            taxAntiFraude,
            taxTransfer,
            notes,
            domainUrl,
            transactionGtw, 
            transactionGtwKey, 
            status,
            wcConsumerKey, 
            wcConsumerSecret,
            subscriptionId
          }, {
            headers: { authorization, mobcustomerid }
          });
    
          if(response.data.cb !== "error"){
              Alerts("Contrato adicionado", "O contrato foi adicionado com sucesso ","success");
        } else {
              Alerts("Contrato adicionado", response.data.message ,"error");
            //   history.push('/');
        }

        
       
      }

      componentDidMount() {

        const authorization = this.state.authorization;
        const userid = this.state.userid;

        api.get('/mobcustomer/detail', {
            headers: { authorization, userid },
        })
        .then(res => {
            this.setState({ userdata: res.data.user });
            console.log(res.data.user);
        });
    
        this.loadProducts();
        this.loadContract();
    
    }

    

  render() {

    return (
        <>
        
        <div>
        <div>
            <div>

                <div className="row mb-3">
                    
                    <div className="col-md-10">

                        <h3> Atualização de Contrato </h3>
                        <h3> {this.state.contractdata.domainUrl ? ( <span className="text-twitter"> {this.state.contractdata.domainUrl} </span>  ) : 'Domínio não configurado'} </h3>
                        <h4> PageFy Contract ID: {this.state.contractId} </h4>

                        {/* form  */}
                        <form onSubmit={this.onSubmit}>

                            <div className="form-group mt-4">
                                <label htmlFor="personType"> Selecionar o Produto * </label>
                                <select 
                                  id="productId"
                                  name="productId"
                                  value={this.state.contractdata.productId || ""}
                                  onChange={this.handleChange}
                                  className="form-control">
                                      <option value="0" defaultValue> Selecione o produto</option>
                                      {this.state.productdata.map((product, index) => (
                                          <option value={product._id} key={product._id}> {product.title} </option>
                                      ))}

                                  </select>
                            </div>

                            <p className="text-twitter  mb-4 mt-4"> 
                              Informações Comerciais ( Contrato, Taxas e Custos de Transação ) </p>

                            <div className="form-group">
                                <label htmlFor="addrComplement"> Gerente de Conta <small>*( Caso não tenha deixe em branco ) </small></label>
                                <input 
                                    placeholder="ID do gerente de contas... "
                                    name="accountManager"
                                    id="accountManager"
                                    type="text" 
                                    className="form-control"
                                    value={this.state.contractdata.accountManager || ""} 
                                    onChange={this.handleChange}
                                    />
                            </div>

                            <div className="form-group">
                                <label htmlFor="addrComplement"> Domínio do projeto </label>
                                <input 
                                    placeholder="insira o apenas o domínios ex: PageFy.com.br ... "
                                    name="domainUrl"
                                    id="domainUrl"
                                    type="text" 
                                    className="form-control"
                                    value={this.state.contractdata.domainUrl || ""} 
                                    onChange={this.handleChange}
                                    />
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="taxCreditCart"> Cartão de Crédito à vista ( % ) * </label>
                                        <input 
                                            placeholder="Taxa do cartão de crédito à vista"
                                            name="taxCreditCartCash"
                                            id="taxCreditCartCash"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.contractdata.taxCreditCartCash || ""}
                                            onChange={this.handleChange}
                                            />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                <div className="form-group">
                                        <label htmlFor="taxCreditCart"> Cartão de Crédito Parcelado até 12x  ( % ) * </label>
                                        <input 
                                            placeholder="Taxa do cartão de crédito parcelado"
                                            name="taxCreditCartInstallment"
                                            id="taxCreditCartInstallment"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.contractdata.taxCreditCartInstallment || ""}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="taxCreditCart"> Boleto Bancário ( R$ ) * </label>
                                        <input 
                                            placeholder="Custo do boleto bancário ( Liquidado )"
                                            name="taxBankTicket"
                                            id="taxBankTicket"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.contractdata.taxBankTicket || ""}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="taxCreditCart"> Custo Anti Fraude p/ Transação ( R$ ) * </label>
                                        <input 
                                            placeholder="Custo fixo do anti fraude"
                                            id="taxAntiFraude"
                                            name="taxAntiFraude"
                                            type="text" 
                                            className="form-control"
                                            value={this.state.contractdata.taxAntiFraude || ""}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="taxTransfer"> Custo Transferência DOC/TED * </label>
                                        <input 
                                            placeholder="Custo da transferência ..."
                                            value={this.state.contractdata.taxTransfer || ""}
                                            id="taxTransfer"
                                            name="taxTransfer"
                                            type="text" 
                                            className="form-control"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="transactionGtw"> Payment Gateway * </label>
                                        <select 
                                        id="transactionGtw"
                                        name="transactionGtw"
                                        value={this.state.contractdata.transactionGtw || ""}
                                        onChange={this.handleChange}
                                        className="form-control">
                                            <option value=""> Selecione o gateway </option>
                                            <option value="gtw1"> gtw1 </option>
                                            <option value="gtw2"> gtw2 </option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="transactionGtwKey"> Transaction Key * </label>
                                        <input 
                                            placeholder="Entre com a chave de transação..."
                                            id="transactionGtwKey"
                                            name="transactionGtwKey"
                                            value={this.state.contractdata.transactionGtwKey || ""}
                                            type="text" 
                                            className="form-control"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="subscriptionId"> ID da Assinatura ( identificação do id da assinatura no gateway ) </label>
                                        <input 
                                            placeholder="Informe o ID da assinatura ..."
                                            id="subscriptionId"
                                            name="subscriptionId"
                                            value={this.state.contractdata.subscriptionId || ""}
                                            type="text" 
                                            className="form-control"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="text-twitter"> Configurações do Woocommerce </h4>
                                    <p className="text-grey"> Necessário apenas para produtos que utilizarem o Woocommerce. Para cria a sua consumer Key acesse o seguinte link na loja do cliente: <br/>
                                        <pre> wp-admin/admin.php?page=wc-settings&tab=advanced&section=keys </pre>
                                    </p>
                                </div>
                                <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="wcConsumerKey"> Consumer Key </label>
                                            <input 
                                                placeholder="Custo do boleto bancário ( Liquidado )"
                                                name="wcConsumerKey"
                                                id="wcConsumerKey"
                                                type="text" 
                                                className="form-control"
                                                value={this.state.contractdata.wcConsumerKey || ""}
                                                onChange={this.handleChange}
                                            />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="wcConsumerSecret"> Consumer Secret </label>
                                                <input 
                                                    placeholder="Custo fixo do anti fraude"
                                                    id="wcConsumerSecret"
                                                    name="wcConsumerSecret"
                                                    type="text" 
                                                    className="form-control"
                                                    value={this.state.contractdata.wcConsumerSecret || ""}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        
                                </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="taxTransfer"> Observações sobre o pedido * </label>
                                        <textarea 
                                            placeholder="Anotações Importantes..."
                                            id="notes"
                                            name="notes"
                                            value={this.state.contractdata.notes || ""}
                                            type="text" 
                                            className="form-control"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="status"> Status do Cliente </label>
                                <select 
                                id="status"
                                name="status"
                                value={this.state.contractdata.status || ""}
                                onChange={this.handleChange}
                                className="form-control">
                                    <option value=""> Selecione o Status </option>
                                    <option value="1"> Ativo </option>
                                    <option value="2"> Inativo </option>
                                </select>
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <button 
                                    type="submit" 
                                    className="btn btn-info mr-2"
                                    > Salvar </button>
                                </div>
                            </div>

                            </form>

                        {/*  */}
                    </div>

                    <div className="col-md-2">
                            <div className="text-right">
                                <Link to={`/admin/contracts/${this.state.userid}`} className="btn btn-twitter btn-sm ml-4">
                                    Listar todos contratos
                                </Link>
                            </div>
                            <div className="text-right mt-3">
                                <Link to={`/admin/mobcustomer/${this.state.userid}`} className="btn btn-outline-twitter btn-sm ml-4">
                                    Consultar cadastro do cliente
                                </Link>
                            </div>
                        </div>
                    </div>
                
            </div>
        </div>
    </div>
    </>
    );
  }
}