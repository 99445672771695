import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
// import Moment from 'react-moment';
// import AuthData from '../../components/Auth';
import Config from '../../services/config';
import api from '../../services/Users';

import './styles.css';
import imageNavigation from '../../assets/images/Pagefy-myaccount-illustration.svg';

export default class MyAccount extends Component {

  constructor(props) {

    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    super(props);
    this.state = {
      userdata: {},
      authorization,
      userid
    };
  }

  componentDidMount() {
    
    const authorization = this.state.authorization;
    const userid = this.state.userid;

    api.get('/user/detail',{
        headers: { authorization, userid },
    })
    .then(res => {
        this.setState({ userdata: res.data.user });
    });

  }

  render() {
    return (
        <>
        <div className="col-lg-4 border-left-grey">
          <div className="row">
            <div className="col-md-12">
                <NavLink to="/user/account" className="col-md-12 border-bottom btn text-left main-nav" 
                  activeClassName="active">
                  Dados Cadastrais
                </NavLink>

                {this.state.userdata.personType === 'company' ? (
                  <NavLink to="/user/account/company" className="col-md-12 border-bottom btn text-left main-nav" 
                      activeClassName="active">
                      Dados Comerciais 
                  </NavLink>
                ) : ''}

                <NavLink to="/user/account/address" className="col-md-12 border-bottom btn text-left main-nav" 
                    activeClassName="active">
                    Endereço de cadastro
                </NavLink>

                <NavLink to="/user/account/bank" className="col-md-12 border-bottom btn text-left main-nav" 
                    activeClassName="active">
                    Dados Bancários
                </NavLink>

                <NavLink to="/user/account/withdrawals" className="col-md-12 border-bottom btn text-left main-nav" 
                    activeClassName="active">
                    Transferência e Saques
                </NavLink>

                <NavLink to="/user/account/password" className="col-md-12 border-bottom btn text-left main-nav" 
                    activeClassName="active">
                    Senhas
                </NavLink>

                <NavLink to="/user/account/services" className="col-md-12 border-bottom btn text-left main-nav" 
                    activeClassName="active">
                    Meus Contratos
                </NavLink>

                <NavLink to="/user/account/suspend" className="col-md-12 border-bottom btn text-left main-nav" 
                    activeClassName="active">
                    Encerrar Conta
                </NavLink>

            </div>
          </div>
        </div>
        </>
    );
  }
}
