import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { Link, Redirect } from 'react-router-dom';

import Config from '../../services/config';
import api from '../../services/Users';
import AuthPage from '../../components/AuthPage';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Welcome from '../../components/Welcome';
// import ContractsResume from '../../components/ContractsResume';

import illustration01 from '../../assets/images/welcome-illustration.svg';
import illustration02 from '../../assets/images/dashboard.svg';
import './styles.css';

export default class Main extends Component {

  constructor(props) {

    if (localStorage.getItem("credencialData") === null) {
      window.location="/"
    }

    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    super(props);
    this.state = {
      userdata: {},
      authorization,
      userid,
      loading: true,
      contractsdata: [],
      selectedContractIdByCookie: '',
      selectedProductName: ''
    };
  }

  async loadContracts(){

    const authorization = this.state.authorization;
    const userid = this.state.userid;

    await api.get(`/contracts/user/${userid}`,{
        headers: { authorization },
    })
    .then(res => {
        this.setState({ contractsdata: res.data.contracts });
    });
  }

  getFromCookie = (cookieName) => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  };

  saveToCookie = (contractId, productName) => {
    const daysToExpire = 7; 
    const date = new Date();
    date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = `selectedContractId=${contractId}; ${expires}; path=/`;
    document.cookie = `selectedProductName=${productName}; ${expires}; path=/`;
};

handleSelectChange = (selectedContractId) => {
  const selectedProductName = this.getProductNameById(selectedContractId);
  this.saveToCookie(selectedContractId, selectedProductName);
  this.setState({ selectedContractId, selectedProductName });
  window.location.reload();
};

  componentDidMount() {

      const selectedContractId = this.getFromCookie('selectedContractId');
      if (selectedContractId) {
        this.setState({ selectedContractIdByCookie: selectedContractId });
      }

      const selectedProduct = this.getFromCookie('selectedProductName');
      if (selectedProduct) {
        this.setState({ selectedProductName: selectedProduct });
      }

      const authorization = this.state.authorization;
      const userid = this.state.userid;

      api.get('/user/detail',{
          headers: { authorization, userid },
      })
      .then(res => {
          this.setState({ userdata: res.data.user });
          this.setState({ loading: false });
      });

      this.loadContracts();


    }

    getProductNameById = (contractId) => {
      const contract = this.state.contractsdata.find((contract) => contract?._id === contractId);
      return contract ? (contract.productName+" - "+contract.domainUrl) : '';
    };

    
  render(){

    return (
      <>

        <Loader
          type="BallTriangle"
          color="#000" 
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner"
          
        />

        <AuthPage />
        
        <div className="container-scroller">

            <Header />

            {(this.state.userdata.transactionEnable !== "1" && this.state.loading === false) ? (
              <div className="container-fluid page-body-wrapper "> 
                <div className="main-panel welcome">
                  <div className="content-wrapper">
                      <div className="row text-left">
                          <div className="col-md-6 textWelcome">
                            <h2 className="text-clean"> Olá {this.state.userdata?.name}, </h2>
                            <p className="textWelcome2 mt-4">seja bem vindo a Pagefai. <br/> 
                            Estamos preparando sua conta para você começar a vender em nossa plataforma. </p>
                            <p className="textWelcome3"> Você receberá um e-mail com a confirmação da ativação de sua conta. Caso tenha alguma dúvida fale conosco através do e-mail feedback@pagefy.me </p>

                            <h4 className="mt-4"> IMPORTANTE </h4> 
                            <p> algumas funcionalidades estarão disponíveis quando sua conta for ativada. </p>
                          </div>
                          <div className="col-md-6 imgWelcome">
                            <img src={illustration01} alt="Illustration" />
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
            ) : (
              <div className="container-fluid page-body-wrapper">
                  <div className="main-panel">
                    <div className="content-wrapper">
        
                      <div className="row">
                        <div className="col-md-12 grid-margin">
                            <Welcome />
                        </div>
                      </div>

                      <div className="row">
                          <div className="col-md-6 cardContracts">
                          {!this.state.selectedContractIdByCookie ? (
                            <div className="row mt-4">
                              <div className="col-md-12">
                                <h3 className='productTitleMain'> Meus Planos </h3> <br/>
                                <p> Para começar você precisa selecionar um dos planos que queira gerenciar. Após selecionar o plano outras opções serão ativadas no menu <br/> 
                                </p>
                              </div>
                            </div>
                            ) : ''}

                            <div className="row mt-4">

                              {this.state.selectedContractIdByCookie ? (
                                <div className="col-md-12 mb-4">
                                  <h3 className='productTitleMain mb-4'> {this.state.selectedProductName} </h3>
                                  <p> Contrato Id: {this.state.selectedContractIdByCookie} </p>
                                </div>
                              ) : ''}

                                {!this.state.selectedContractIdByCookie ? (
                                  <>
                                     <div className="col-md-12 mb-4">
                                      <table className="table">
                                        <tbody> 
                                          {this.state.contractsdata.map((contract, index) => (
                                              <tr key={contract?._id} className='p-3'>
                                                <td className="py-1 pl-0"> 
                                                  <strong> {contract?.productName} </strong> <br/>{contract?.domainUrl} 
                                                </td>
                                                <td className="text-right"> 
                                                  <button 
                                                    className="btn btn-outline-primary btn-sm" 
                                                    onClick={() => this.handleSelectChange(contract?._id)}>
                                                     Gerenciar plano
                                                </button>
                                                </td>
                                              </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                     </div>
                                    {/* <Link to="/payment/express" className="col-md-4">
                                        <div className="dash-button">
                                          Cobranças
                                        </div>
                                    </Link>

                                    <Link to="/user/tickets" className="col-md-4">
                                        <div className="dash-button">
                                          Tickets 
                                        </div>
                                    </Link>

                                    <Link to="/user/tutorials" className="col-md-4">
                                      <div className="dash-button">
                                        Treinamentos
                                      </div>
                                    </Link>  */}
                                   
                                  </>
                                ) : ''}
                                
                              </div>

                            {/* {!this.state.selectedContractIdByCookie ? (
                                <div className="row mt-4"> 
                                  <Link to="/user/backups" className="col-md-4">
                                      <div className="dash-button">
                                        Backups
                                      </div>
                                  </Link>
                                </div>
                            ) : ''} */}

                            {this.state.selectedContractIdByCookie ? (
                              <>
                              <div className="row">
                                <Link to={`/user/invoices/${this.state.selectedContractIdByCookie}/0`} className="col-md-4 dark">
                                      <div className="dash-button">
                                        Faturas
                                      </div>
                                </Link>
                                <Link to={`/user/emails/list`} className="col-md-4 dark">
                                    <div className="dash-button">
                                      E-mails
                                    </div>
                                </Link>
                                <Link to="/user/tickets" className="col-md-4">
                                      <div className="dash-button">
                                        Tickets
                                      </div>
                                  </Link>
                              </div>

                              <div className="row mt-4">
                                <Link to={`/user/contract/${this.state.selectedContractIdByCookie}`} className="col-md-4">
                                      <div className="dash-button">
                                        Detalhes <br/> do Contrato
                                      </div>
                                </Link>
                                <Link to={`user/commerce/main/${this.state.selectedContractIdByCookie}`} className="col-md-4">
                                      <div className="dash-button">
                                        Gerenciar <br/> E-commerce
                                      </div>
                                </Link>
                                <Link to={`/user/app-configuration/${this.state.selectedContractIdByCookie}`} className="col-md-4">
                                      <div className="dash-button">
                                        Gerenciar <br/> Aplicativo
                                      </div>
                                </Link>
                              </div>
                              <div className="row mt-4">
                                <Link to="/user/tutorials" className="col-md-4">
                                        <div className="dash-button">
                                          Cursos <br/> e Treinamentos
                                        </div>
                                  </Link>
                                <Link to="/user/cms/main" className="col-md-4">
                                        <div className="dash-button">
                                          Gerenciar <br/> Site e Aplicativo
                                        </div>
                                  </Link>
                              </div>
                              </>
                            ) : ''}

                          </div>
                          
                            <div className="col-md-6 d-flex justify-content-center text-center">
                              <img src={illustration01} alt="Illustration" />
                            </div>

                      </div>

                      <div className="row mt-4">

                      </div>
        
                  </div>
                </div>
              </div>
            )}

            <Footer />

        </div>

    </>
    );
  }

}
