import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import AuthPage from '../../../../components/AuthPage';
import Footer from '../../../../components/Footer';
import Header from '../../../../components/Header';
import Navigation from '../../navigation';
import Config from '../../../../services/config';
import api from '../../../../services/Users';
import Loader from 'react-loader-spinner';
import ContractStatus from '../../../../components/Shared/Status';
import Status from '../../../../components/Shared/Status/invoiceStatus';

import './styles.css';

export default class Invoices extends Component {

  constructor(props) {
    
    const { match: { params } } = props;
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    super(props);
    this.state = {
      contractId: params.id,
      page: params.page,
      invoicedata: [],
      rawdata: [],
      authorization,
      userid,
      offset: 0,
      hasMore: false,
      setError: false
    };
  }

  async loadInvoices(){
      const authorization = this.state.authorization;
      const userid = this.state.userid;
      const offset = this.state.offset;

      try {
        // 
        await api.get(`/invoices/${this.state.contractId}/${this.state.page}`,{
            headers: { authorization, userId : userid },
        })
        .then(res => {
          this.setState({ hasMore : res.data.hasMore })
          this.setState({ invoicedata: res.data.data });
        });
  
        if(this.state.hasMore === true){
          this.setState({offset : Number(this.state.offset) + 10})
        }
        // 
      } catch (error) {
        this.setState({ setError : true})
      }
  }

  componentDidMount() {
    this.loadInvoices();
  }

  render() {
    return (
        <>
        <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <Header />
        <div className="container-fluid page-body-wrapper" id="fscroll">
         <div className="main-panel">
           <div className="content-wrapper">
             <div className="row">
               <div className="col-12">
                 <div className="mt-4">
                   <div className="">
                     <div className="row">
                       
                       <div className="col-lg-12">

                                <div className="row mb-3">

                                  <div className="col-md-8">
                                    <h4 className='text-twitter'>
                                      Contrato: {this.state?.contractId}
                                    </h4>
                                    <p> Para evitar o bloqueio de seus serviços mantenha suas faturas em dia. </p>
                                  </div>

                                  <div className="col-md-4 text-right text-grey">

                                      <Link to={`/user/contract/${this.state?.contractId}`} className="btn btn-outline-dark btn-sm">
                                        Voltar    
                                      </Link>

                                  </div>

                                </div>

                                <div className="row">

                                  {this.state?.setError == true ? (
                                    <p className='p-4 text-danger'> Encontrei um problema para resgatar suas cobranças, entre em contato com o suporte da Pagefai </p>
                                  ) : (
                                    <>
                                        <table className="table table-striped">
                                        <tbody>
                                          <tr className="text-dark">
                                            <td> <strong> Vencimento </strong> </td>
                                            <td> <strong> Emissão </strong> </td>
                                            <td> <strong> Valor </strong> </td>
                                            <td> <strong> Descrição da cobrança </strong> </td>
                                            <td> <strong> Status </strong> </td>
                                            <td> </td>
                                          </tr>
                                            
                                            {this.state.invoicedata.map((trx, index) => ( 
                                            <tr key={index}>
                                                    
                                                    <td className="text-dark block-bank-account">
                                                        <Moment format="DD/MM/YYYY" date={trx?.dueDate}  /> 
                                                    </td>

                                                    <td className="text-grey"> 
                                                        <Moment format="DD/MM/YYYY" date={trx?.dateCreated}  /> 
                                                    </td>

                                                    <td className="text-dark"> 
                                                      R$ {trx?.value}
                                                    </td>

                                                    <td className="text-dark">
                                                      {trx?.description}
                                                    </td>

                                                    <td className="text-dark">
                                                      <Status status={trx?.status} />
                                                    </td>
                                                    
                                                    <td>
                                                      <a href={trx?.invoiceUrl} target='_blank' className='btn btn-outline-dark btn-sm'> Invoice </a>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                      </table>

                                      <nav className='mt-3'>
                                        <ul className="pagination flex-wrap">

                                        {this.state.page >= 10 ? (
                                          <li className="page-item"><a className="page-link" href={Number(this.state.page)-10}><i className="ti-angle-left"></i></a></li>
                                        ) : ''}
                                          
                                          <li className="page-item active"><span className="page-link"> {this.state?.page} </span></li>
                                          
                                          {this.state.hasMore === true ? (
                                            <li className="page-item"><a className="page-link" href={Number(this.state.page)+10}><i className="ti-angle-right"></i></a></li>
                                          ) : ''}

                                        </ul>
                                      </nav>

                                    </>
                                  )}
                                  
                                

                                 
                                  
                                </div>
                          </div>
                        </div>
                   </div>
                 </div>
               </div>
             </div>
         </div>
        </div>
       </div>
        <Footer />
       </>
    );
  }
}
