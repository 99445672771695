import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import Config from '../../services/config';
import api from '../../services/Users';
import Status from '../../components/Common/Status';

import './styles.css';

export default class Contracts extends Component {

    constructor(props) {

        const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const userid = credencialdata._id;
        
        super(props);
            this.state = {
            contractsdata: [],
            authorization,
            userdata: {},
            authorizationKey: Config.AuthApiSecretKey,
            userId: userid,
            };
        }

        loadContracts(){
          const authorization = this.state.authorization;
          const userId = this.state.userId;

          api.get(`/contracts/search/60b5720b1ca76b001f04923f`,{
              headers: { authorization, userId },
          })
          .then(res => {
              this.setState({ contractsdata: res.data.contracts });
          });
      }

      componentDidMount() {
        this.loadContracts();
      }
    

    render(){

      return (
        <>

          <div className="container-scroller">

                <div className="container-fluid page-body-wrapper">

                    <div className="main-panel">

                        <div className="">

                            <div className="row">

                            <div className="col-md-12">

                            <div className="row">
                                <div className="col-md-12 mt-4 mb-4">
                                    <h3 className="titleMid mt-2"> Push Notification </h3>
                                    <p className="text-grey"> Selecione o contrato no qual deseja programar um envio </p>
                                </div>
                                <div className="col-md-12">
                                        
                                        <div className="table-responsive">
                                                        
                                          {!this.state.contractsdata.length ? (
                                              <>
                                                <p className="text-left text-grey card p-4">
                                                    Não encontramos nenhum contrato para apresentar aqui ainda!
                                                </p>
                                              </>
                                          ) : (
                                              <>
                                                  <table className="table table-pagefy">
                                                      

                                                      <tbody>
                                                          
                                                          {this.state.contractsdata.map((contract, index) => (
                                                              <tr key={contract._id}>

                                                                  <td className="text-center">
                                                                    <i className="ti-email menu-icon text-grey icon-package-custom"> </i>
                                                                  </td>

                                                                  <td> 
                                                                      <div className="col-md-12">
                                                                        <strong> {contract.domainUrl} </strong>
                                                                      </div>
                                                                      <div className="col-md-12 mt-2 text-grey">
                                                                          Contratado em: <Moment format="DD/MM/YYYY" date={contract.createdAt}  />  <br/>

                                                                          <span className="text-grey mt-2"> Status do contrato: 
                                                                              <Status statusId={contract.status} />  </span>
                                                                          
                                                                      </div>
                                                                  </td>
                                                                  
                                                                  <td className="text-right"> 

                                                                      <Link to={`/user/push-campaigns/${contract._id}`} className="btn btn-outline-secondary btn-sm mr-3">
                                                                          Histórico de Mensagens
                                                                      </Link> 

                                                                      <Link to={`/user/push/${contract._id}`} className="btn btn-dark btn-sm mr-3">
                                                                          Enviar
                                                                      </Link> 
                                                                      
                                                                  </td>
                                                              </tr>
                                                          ))}
                                                      </tbody>
                                                  </table>

                                                  <div className="mt-4">
                                                      <p className="card-description text-grey mt-3 text-small">
                                                          Consulte os limites de envio para cada um dos contratos. <br/> 
                                                          Caso seu limite tenha excedido faça um upgrade no painel de controle
                                                      </p>
                                                  </div>
                                              </>
                                          )}
                                </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
        </>
      )
    }
}