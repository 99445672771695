
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Config from '../../services/config';
import api from '../../services/Users';

import logoWhite from '../../assets/images/pagefy-logo-light.svg';
import './Header.css';

export default class Header extends Component {

  constructor(props) {
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;
    super(props);
    this.state = {
      userdata: {},
      authorization,
      userid,
      selectedCookie: '',
    };
  }

  componentDidMount() {
        const authorization = this.state.authorization;
        const userid = this.state.userid;
        api.get('/user/detail',{
            headers: { authorization, userid },
        })
        .then(res => {
            this.setState({ userdata: res.data.user });
        });

        
      }

    render(){
    
    return (
      <>
         <div className="horizontal-menu">
        <nav className="navbar top-navbar col-lg-12 col-12 p-0 Pagefynav">
          <div className="container">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">

              <Link to="/main" className="navbar-brand brand-logo">
                <img src={logoWhite} alt="logo" width="200" />
              </Link>
              
              <Link to="/main" className="navbar-brand brand-logo-mini">
                <img src={logoWhite} alt="logo" width="200" />
              </Link>

            </div>
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
              
              <ul className="navbar-nav navbar-nav-right">
                <li className="nav-item nav-profile dropdown">
                    <span className="text-clean"> Logado como ( <strong> {this.state.userdata?.email} </strong> ) </span> 
                </li>
              </ul>
              <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
                <span className="ti-menu"></span>
              </button>
            </div>
          </div>
        </nav>
        <nav className="bottom-navbar">
          <div className="container">
            <ul className="nav page-navigation">
              <li className="nav-item">
                  <Link to="/main" className="nav-link">
                    <i className="ti-home menu-icon"></i>
                    <span className="menu-title">Dashboard</span>
                  </Link>
              </li>
              <li className="nav-item">
                  <Link to="/user/contracts" className="nav-link">
                    <i className="ti-wallet menu-icon"></i>
                    <span className="menu-title"> Meus Contratos </span>
                  </Link>
              </li>


              <li className="nav-item">
                  <span className="nav-link">
                    <i className="ti-package menu-icon"></i>
                    <span className="menu-title"> CMS </span>
                    <i className="menu-arrow"></i>
                  </span>

                <div className="submenu">
                  <ul className="submenu-item">

                      <li className="nav-item">
                        <strong> CMS </strong>
                      </li>

                      <li className="nav-item">
                        <Link to="/user/cms/categories" className="nav-link">
                          Categorias
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/user/cms/pages" className="nav-link">
                          Páginas
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/user/cms/posts" className="nav-link">
                          Posts
                        </Link>
                      </li>

                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <Link to="/user/tickets" className="nav-link">
                  <i className="ti-package menu-icon"></i>
                  <span className="menu-title"> Tickets </span>
                  <i className="menu-arrow"></i>
                </Link>

                <div className="submenu">
                  <ul className="submenu-item">
                    <li className="nav-item">
                    <Link to="/user/tickets" className="nav-link">
                      Meus Tickets 
                    </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/user/ticket/store" className="nav-link">
                        Novo Ticket
                      </Link>
                    </li>
                  </ul>
                </div>

              </li>
              
              

              <li className="nav-item">
                <span className="nav-link">
                  <i className="ti-bar-chart-alt menu-icon"></i>
                  <span className="menu-title"> Relatórios </span>
                  <i className="menu-arrow"></i>
                </span>

                <div className="submenu" >
                  <ul className="submenu-item">
                    <li className="nav-item">
                        <Link to="/user/access" className="nav-link"> 
                          Últimos Acessos 
                        </Link> 
                      </li>
                  </ul>
                </div>

              </li>
              
              <li className="nav-item">
                <span className="nav-link">
                  <i className="ti-user menu-icon"></i>
                  <span className="menu-title"> Minha Conta </span>
                  <i className="menu-arrow"></i>
                </span>
                <div className="submenu">
                  <ul className="submenu-item">

                    
                    <li className="nav-item">
                      <Link to="/user/account"> Dados Cadastrais </Link> 
                    </li>

                    <li className="nav-item"> 
                      <Link to="/user/contracts">  Meus Contratos </Link> 
                    </li>

                    <li className="nav-item"> 
                      <Link to="/user/tickets">  Tickets </Link> 
                    </li>
                    
                    <li className="nav-item"> 
                      <Link to="/user/access"> Últimos Acessos </Link> 
                    </li>

                    { this.state.userdata?.group === 'root' ? (
                      <>
                        <li><hr/></li>
                        <li className="nav-item">
                            <Link to="/admin/main" className="adminConsole"> Admin Console </Link> </li>
                      </>
                    ) : ''} 

                  </ul>
                </div>
              </li>
              <li className="nav-item">
                  <Link to="/logout" className="nav-link">
                    <i className="ti-unlock menu-icon"></i>
                    <span className="menu-title"> Sair </span>
                  </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
    )
  }

}