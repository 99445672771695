import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Config from '../../../services/config';
import api from '../../../services/Users';
import Loader from 'react-loader-spinner';
import swal from 'sweetalert';

import './styles.css';

export default class Contracts extends Component {

  constructor(props) {
    
    const { match: { params } } = props;
    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    super(props);
    this.state = {
      contractdata: [],
      productdata: [],
      authorization: Config.AuthApiSecretKey,
      userid,
      contractId: props.match.params.contractId
    };

    this.updateCache = this.updateCache.bind(this);
  }

  async loadContract(){
      const authorization = this.state.authorization;
      const userid = this.state.userid;
  
      await api.get(`/contract/${this.state.contractId}`,{
          headers: { authorization, userid },
      })
      .then(res => {
          this.setState({ contractdata: res.data.contract });
          const productID = res.data.contract.productId;
          this.loadProductData(productID);
      });
  }

  loadProductData(id){
    const authorization = this.state.authorization;
    const userid = this.state.userid;

    api.get(`/product/detail/${id}`,{
      headers: { authorization, userid },
    })
    .then(res => {
        this.setState({ productdata: res.data.product });
    });

  }

  async updateCache() {
    console.log('Update Cache start...');
  
    const authorization = this.state.authorization;
    const userid = this.state.userid;
    const contractid = this.state.contractId;

    await api.put(`/admin/commerce/update-cache`,{},{
      headers: { 
        authorization, 
        userid, 
        contractid, 
        'content-type' : 'application/json;charset=UTF-8' },
    })
    .then(res => {
      swal({
        title: "Rebuild Cache",
        text: "O cache do seu website foi atualizado com sucesso",
      });
    });
  }
  

  componentDidMount() {
    this.loadContract();
  }

  // 
  render() {
    return (
        <>
        <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <Header />
        <div className="container-fluid page-body-wrapper" id="fscroll">
         <div className="main-panel">
           <div className="content-wrapper">
             <div className="row">
               <div className="col-12">
                 <div className="mt-4">
                   <div className="">
                     <div className="row">
                       
                       <div className="col-lg-12">

                                <div className="row border-bottom">

                                  <div className="col-md-8">
                                    <h3> 
                                     Gerenciar Site e Aplicativo
                                    </h3>
                                  </div>

                                  <div className="col-md-4 text-right text-grey">
                                      <Link to="/main" className="btn btn-outline-dark btn-sm">
                                          Voltar para Início
                                      </Link>
                                      <button 
                                          className="btn btn-dark btn-sm ml-3"
                                          onClick={this.updateCache}
                                      >
                                          Rebuild Cache
                                      </button>
                                  </div>

                                </div>

                                <div className="row">

                                  <div className="col-md-6">

                                    <div className="row mt-4">


                                          <Link to={`/user/cms/categories`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                  Categorias
                                              </div>
                                          </Link>

                                          <Link to={`/user/cms/posts`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                Posts
                                              </div>
                                          </Link>

                                          <Link to={`/user/cms/pages`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                Páginas
                                              </div>
                                          </Link>

                                          <Link to={`/user/cms/image-gallery`} className="col-md-4 mt-3">
                                              <div className="dash-button">
                                                  Galerias de <br/> Imagem
                                              </div>
                                          </Link>

                                          <Link to={`/user/cms/slideshows`} className="col-md-4 mt-3">
                                            <div className="dash-button">
                                                Banners <br/> Slideshow
                                            </div>
                                          </Link>

                                      </div>

                                  </div>
                                  {/*  */}

                                </div>

                      </div>

                        </div>
                   </div>
                 </div>
               </div>
             </div>
         </div>
        </div>
       </div>
        <Footer />
       </>
    );
  }
}
