import React from 'react';

export default function Status(props) {

    const status = props.status;

    if(props.status === "1"){
      return (
          <span className="text-success" status={status}> Ativo </span>
      );
    } else if(props.status === "0"){
        return (
            <span className="text-danger" status={status}> Inativo </span>
        );
    } else {
        return (
            <span className="text-danger"> Inativo </span>
        );
    }
    
}
