import React, { Component } from 'react';
import Loader from 'react-loader-spinner';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import AccountStatusComponent from '../../../components/AccounStatusComponent';
import Config from '../../../services/config';

export default class AccountStatus extends Component {
  render() {
    return(
      <>
        <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner"
        />
        <AuthPage />
          <div className="container-scroller">
              <Header />
                  <div className="container-fluid page-body-wrapper">
                      <div className="main-panel">
                      <div className="content-wrapper">
                          <AccountStatusComponent />
                      </div>
                  </div>
              </div>
              <Footer />
          </div>
      </> 
    );
  }
}
