import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';

export default function HeaderDonation(props) {

  const title = props.title;

  return (
    <>
      <div className="row mb-4 donationMenu">
          <div className="col-md-12">
              <Link to="donationDashboard">
                <button type="button" className="btn btn-inverse-secondary btn-fw btn-m">
                  <i className="ti-heart btn-icon-prepend"></i>  Doações
                </button>
              </Link>
              <Link to="campaigns">
                  <button type="button" className="btn btn-inverse-secondary btn-fw btn-m">
                    <i className="ti-announcement btn-icon-prepend"></i> Campanhas
                  </button>
              </Link>
              <Link to="events">
                  <button type="button" className="btn btn-inverse-secondary btn-fw btn-m">
                    <i className="ti-calendar btn-icon-prepend"></i>  Eventos
                  </button>
              </Link>
              <Link to="posts">
                <button type="button" className="btn btn-inverse-secondary btn-fw btn-m">
                  <i className="ti-pencil btn-icon-prepend"></i> Posts
                </button>
              </Link>
              <Link to="publications">
                <button type="button" className="btn btn-inverse-secondary btn-fw btn-m">
                  <i className="ti-pencil btn-icon-prepend"></i> Páginas
                </button>
              </Link>
              
              <button type="button" className="btn btn-inverse-secondary btn-fw btn-m">
                <i className="ti-camera btn-icon-prepend"></i>  Fotos
              </button>
              <button type="button" className="btn btn-inverse-secondary btn-fw btn-m">
                <i className="ti-music btn-icon-prepend"></i> Músicas 
              </button>
              <button type="button" className="btn btn-inverse-secondary btn-fw btn-m">
                <i className="ti-video-clapper btn-icon-prepend"></i> Vídeos
              </button>
          </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-4">
            <h3 className="card-title"> Doações {title} </h3>
            <p className="text-grey"> Powered by Doamob ® </p>
        </div>
      </div>

      
    </>
  );
}
