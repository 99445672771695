import React from 'react';
import { Link } from 'react-router-dom';

import HeaderDonation from '../HeaderDonation';

export default function Campaigns() {
  return (
    <>
        
        <HeaderDonation title="/Eventos"/>

        <div className="row">
            <div className="col-md-6">
                <h4 className="card-title text-twitter"> Resumo dos Eventos </h4>
                <p className="text-grey"> Aqui você encontra todas as campanhas cadastradas em sua plataforma  </p>
            </div>
            <div className="col-md-6 text-right">
                    <div className="dropdown">
                        <Link to="/event/store" className="btn btn-outline-twitter btn-sm mt-3 mb-4">
                            <i className="ti-plus"></i> Novo Evento
                        </Link>
                    </div>
            </div>
        </div>

        <div className="table-responsive mt-3">
                    <table className="table">
                      <thead>
                        <tr>
                            <th> Título </th>
                            <th className="text-left"> Data </th>
                            <th className="text-left"> Valor </th>
                            <th className="text-left"> Inscritos </th>
                            <th className="text-center"> Ações </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td className="text-muted"> Reforma da Instituição </td>
                            <td className="text-left text-muted"> 25/12/2019 </td>
                            <td className="text-left text-muted"> R$ 500,00 </td>
                            <td className="text-left text-muted"> 120 </td>
                            <td className="text-right text-twitter"> 
                                <Link to="event/update/1">
                                  <button type="button" className="btn btn-outline-secondary btn-sm"> 
                                  <i className="ti-settings btn-icon-prepend"></i> Editar </button>
                                </Link>

                                <Link to="event/details/1">
                                  <button type="button" className="btn btn-outline-secondary btn-sm ml-3"> 
                                  <i className="ti-eye btn-icon-prepend"></i> Detalhes </button>
                                </Link>

                                <i className="ti-control-record text-success btn-icon-prepend ml-3 status-icon"></i>

                            </td>
                        </tr>
                        <tr>
                            <td className="text-muted"> Pagamento de tríbutos </td>
                            <td className="text-left text-muted"> 25/12/2019 </td>
                            <td className="text-left text-muted"> Gratuíto </td>
                            <td className="text-left text-muted"> 300 </td>
                            <td className="text-right text-twitter"> 
                                <Link to="event/update/1">
                                  <button type="button" className="btn btn-outline-secondary btn-sm"> 
                                  <i className="ti-settings btn-icon-prepend"></i> Editar </button>
                                </Link>

                                <Link to="event/details/1">
                                  <button type="button" className="btn btn-outline-secondary btn-sm ml-3"> 
                                  <i className="ti-eye btn-icon-prepend"></i> Detalhes </button>
                                </Link>

                                <i className="ti-control-record text-danger btn-icon-prepend ml-3 status-icon"></i>

                            </td>
                        </tr>
                      </tbody>
                    </table>
        </div>
    </>
  );
}
