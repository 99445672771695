import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Config from '../../../services/config';
import api from '../../../services/Users';
import Alerts from '../../../components/Alerts';

import AuthPage from '../../../components/AuthPage';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

export default class SupportAdd extends Component {

  constructor(props) {
        
    const authorization = Config.AuthApiSecretKey;
    
    const { match: { params } } = props;
    const contractId = params.contractId;

    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userId = credencialdata._id;

    super(props);
    this.state = {
        userdata: {},
        authorization,
        contractId,
        userId,
        totaldevices: {},
        userid: this.props.customerId,
        redirect: false
    };
  }

  handleChange = async (e) => {
    const state = this.state.userdata
    state[e.target.name] = e.target.value;
    this.setState({userdata:state});
    const authorization = this.state.authorization;
    const userid = this.state.userid;
  }

  loadDeviceCounter(){
        
    const authorization = this.state.authorization;
    const userId = this.state.userId;

    api.get(`devices/counter/${this.state.contractId}`, {
        headers: { authorization, userId },
    })
    .then(res => {
        this.setState({ totaldevices: res.data });
    });
  }

  onSubmit = async (e) => {

    e.preventDefault();

    const authorization = this.state.authorization;
    // const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userId = this.state.userId;

    const { 
      content,
      status } = this.state.userdata;
    try {
      const response = await api.post(`/devices/sender/${this.state.contractId}`, {
        content,
        status : "1"
      }, {
        headers: { authorization, userId }
      });

      Alerts("Push Notification", "Mensagem enviada com sucesso!","success");
      console.log(response);
      
      } catch (error) {
          Alerts("Push Notification", error.response.data.error ,"error");
      }
  }

  componentDidMount() {
    this.loadDeviceCounter();
  }

  render() {
    return (
      <>
        <AuthPage />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="">
                      <div className="row">

                        <div className="col-md-8">
                            <div className="border-bottom">
                                <h3> Push Notification </h3>
                                <p className="card-description text-grey mt-3 mb-3">
                                  Para enviar uma notificação push para os dispositivos deste aplicativo, descreva a mensagem abaixo
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 text-right border-bottom">
                          <div className="">
                            <Link to={`/user/push-campaigns/${this.state.contractId}`}>
                                <button className="btn btn-outline-dark btn-sm mt-3 mb-3"> 
                                  Histórico de Envios
                                </button>
                            </Link>
                          </div>
                        </div>
                        
                        <div className="col-lg-6 mt-4">
                            <div>
                                <div className="">
                                  
                                  <form onSubmit={this.onSubmit} className="forms-sample">
    
                                      <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="message"> Mensagem a ser enviada ( até 178 caracteres ) </label>
                                                <textarea 
                                                  id="content"
                                                  name="content"
                                                  className="form-control" 
                                                  rows="10" 
                                                  onChange={this.handleChange}
                                                  maxLength="178"
                                                ></textarea>
                                            </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-12 text-left mt-20">
                                          <button 
                                            type="submit" 
                                            className="btn btn-dark mr-2"> 
                                            Enviar </button>
                                        </div>
                                      </div>
    
                                  </form>
                                </div>
                            </div>
                        </div>
                          <div className="col-md-6 mt-4">
                            <div className="p-4 mt-4">
                                <h3 className="card p-4"> {this.state.totaldevices?.total} dispositivo(s) </h3>
                                <p className="mt-4"> irão receber esta mensagem </p>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <Footer />
      </>
    );
  }
}
