import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import Loader from 'react-loader-spinner';
import swal from 'sweetalert';

import Config from '../../../services/config';
import api from '../../../services/login';
import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import TicketType from '../../../components/Common/TicketType';
import TicketStatus from '../../../components/Common/TicketStatus';
import Alerts from '../../../components/Alerts';
import TicketReplies from '../../../components/Support/TicketsReplies'

require('./SupportEdit.css');

export default function SupportEdit(props) {

  const { match: { params } } = props;
  const [ ticketData, setTicketData ] = useState([]);
  const [ ticketId, setTicketId ] = useState([]);
  const [ ticketSubject, setTicketSubject ] = useState([]);
  const [description, setDescription] = useState('');
  const [updateList, setUpdateList] = useState('');

  const authorization = Config.AuthApiSecretKey;
  const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
  const userid = credencialdata._id;

  const ticket = params.id;

useEffect(() => {
       
  const authorization = Config.AuthApiSecretKey;
  const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
  const userid = credencialdata._id;

   async function loadData() {
       const response = await api.get(`/ticket/${ticket}`, {
           headers: { authorization, userid },
       })
       setTicketData(response.data.ticket);
   }
   loadData();
},[]);

async function onSubmit(e){

  e.preventDefault();
  
  try {
    await api.post(`/ticket/reply/${ticket}`, {
      description,
      authorName: credencialdata.name
    }, {
      headers: { authorization, userid  }
    });
      
    swal({
      title: "Tickets",
      text: "Comentário Salvo com sucesso",
      timer: 1000
    });

    setDescription('');
    const upDate= new Date();
    setUpdateList(upDate);

    } catch (error) {
      Alerts("Tickets ", error.response.data.error ,"error");
    }

}

  return (
    <>
        <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="">
                      <div className="row pb-4">
                          <div className="col-md-8">
                                <div className="col-md-12">
                                  <h3> 
                                  <i className="ti-headphone-alt"></i> Ticket <small> #{params.id} </small> </h3>
                                </div>
                                <div className="col-md-6">
                                  Assunto: <TicketType subject={ticketData.subject} /> <br/>
                                    Status do chamado: <TicketStatus status={ticketData.status} />
                                </div>
                                
                          </div>
                          <div className="col-md-4 text-right">
                            <Link to="/user/tickets">
                                <button className="btn btn-outline-twitter btn-sm mt-3 mb-4"> 
                                  Todos Chamados
                                </button>
                            </Link>
                          </div>
                      </div>

                      <div className="row mt-4">

                      {ticketData.status !== "2" ? (
                          <div className="col-md-6"> 
                            <h4 className="text-grey ml-4 mt-4"> Este chamado já foi fechado </h4>
                          </div>
                      ) :(
                      <>
                        <div className="col-md-12 mb-4">
                            <div className="card p-4">
                              <h6> 
                                  <h4> Descrição do Chamado </h4>
                                  <small className="text-muted">
                                    Abertura do Chamado <Moment format="DD/MM/YYYY" date={ticketData?.createdAt || ""} /> 
                                  </small>
                              </h6>
                              <p className="text-grey">
                                {ticketData?.description}
                              </p>
                            </div>
                        </div>

                        <div className="col-md-6">
                          <form className="forms-sample" onSubmit={onSubmit} >
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="description"> Mensagem * </label>
                                    <textarea 
                                      id="description"
                                      name="description"
                                      className="form-control" 
                                      rows="7"
                                      value={description}
                                      onChange={e => setDescription(e.target.value)}
                                      ></textarea>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 text-left mt-20">
                                <button 
                                  type="submit" 
                                  className="btn btn-dark mr-2" > 
                                  Enviar </button>
                              </div>
                            </div>
                        </form>
                        </div>
                      </> 
                      )}

                      <div className="col-md-6">
                          <h4 className="text-dark ml-3"> 
                            Histórico deste chamado </h4>
                            <div className="ticket-scroll">
                              <ul>
                                <TicketReplies replyId={ticket} update={updateList} />
                              </ul>
                            </div>
                      </div>

                     </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <Footer />
    </>
  );
}
