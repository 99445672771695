import React from 'react';

import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import WithDrawalsRequestComponent from '../../../components/WithDrawalsRequestComponent';

export default function WithDrawalsRequest() {
  return (
    <>
      <AuthPage />
      <div className="container-scroller">
          <Header />
              <div className="container-fluid page-body-wrapper">
                  <div className="main-panel">
                  <div className="content-wrapper">
                      <WithDrawalsRequestComponent />
                  </div>
              </div>
          </div>
          <Footer />
      </div>
    </>
  );
}
