import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import {CSVLink, CSVDownload} from 'react-csv';

import Header from '../../../../components/Header';
import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPage';
import Footer from '../../../../components/Footer';
import api from '../../../../services/Users';
import PaymentStatusGtw1 from '../../../../components/ChargesComponents/PaymentStatusGtw1';
import TransferStatus from '../../../../components/Common/TransferStatus';
import CustomerBalances from '../../../../components/Common/CustomerBalance';


import './styles.css';
import badget from '../../../../assets/images/badget.svg';

export default class Contracts extends Component {

    constructor(props) {

        const { match: { params } } = props;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const authorization = Config.AuthApiSecretKey;
        const contractId = params.id;
        const customerId = credencialdata._id;

        super(props);
        this.state = {
          trxdata: [],
          balances: [],
          authorization,
          contractId,
          customerId,
          userdata: {},
          trxdetail: [],
          gtw: {},
          pageCounter: 1,
          loading: true,
        };
      }

      loadTrx(p){
          const authorization = this.state.authorization;
          const customerId = this.state.customerId;

          api.get(`/transactions/transfers/${this.state.contractId}`,{
              headers: { authorization, user: customerId },
          })
          .then(res => {
              this.setState({ trxdata: res.data });
              this.setState({ loading: false });
          });
      }

      loadUserData(){
        
        const authorization = this.state.authorization;
        const customerId = this.state.customerId;

        api.get('/mobcustomer/detail', {
            headers: { authorization, userId: customerId },
        })
        .then(res => {
            this.setState({ userdata: res.data.user });
        });
      }

      transactionDetails(id, gtw){
        const authorization = this.state.authorization;

        api.get(`/transfers/${id}`,{
            headers: { authorization },
        })
        .then(res => {
            console.log(res);
            this.setState({ trxdetail: res.data.response });
        });
      }
    
      componentDidMount() {
        this.loadTrx(1);
        this.loadUserData();
      }

    render(){
        
      return (
        <>
          <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
          />

          <AuthPage />

          <div className="container-scroller">

            <Header />

                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">

                            <div className="row">

                            <div className="col-md-12">

                            <div className="row">

                                <div className="col-md-4">
                                    <h3> Transferências <br/>
                                        <span className="text-twitter smallTitle"> 
                                            Contrato {this.state.contractId}  </span>
                                    </h3>
                                    <h4 className="text-dark"> {this.state.userdata.name} </h4>
                                </div>

                                <div className="col-md-8 text-right">
                                    <CSVLink filename={`Pagefy-transfer-report-${this.state.contractId}`} data={this.state.trxdata} className="btn btn-sm btn-outline-twitter" > Exportar Relatório </CSVLink>
                                </div>

                                </div>
                            </div>

                            <div className="col-md-12">
                                <CustomerBalances 
                                    contractId={this.state.contractId} 
                                    customerId={this.state.customerId} 
                                />
                            </div>

                            <div className="col-md-12 mt-4 ">

                                <div className="row mt-2">

                                    <div className="col-md-12">
                                        <div className="">
                                        <div className="">

                                            <div className="table-responsive">
                                                        
                                                        {!this.state.trxdata.length ? (
                                                            <p className="text-grey mt-3"> Este cliente não possui transações para exibir neste momento</p>
                                                        ) : (
                                                            <>
                                                                {this.state.loading === false ? (
                                                                    <table className="table table-striped">
                                                                        <tbody>

                                                                          <tr className="text-dark">
                                                                            <td> Banco </td>
                                                                            <td> Taxa </td>
                                                                            <td> Solicitado em </td>
                                                                            <td> Transferido em </td>
                                                                            <td className="text-center"> Valor </td>
                                                                            <td className="text-right"> Status </td>
                                                                          </tr>
                                                                            
                                                                            {this.state.trxdata.map((trx, index) => (
                                                                            <tr key={index}>
                                                                                    
                                                                                    <td className="text-grey block-bank-account">
                                                                                       Banco: {trx?.bank_code} <br/>
                                                                                       Agência: {trx?.bank_agency} / Conta: {trx?.bank_account} <br/>
                                                                                       Tipo de transferência: <span className="text-uppercase"> {trx?.transfer_type} </span>
                                                                                    </td>

                                                                                    <td className="text-grey">
                                                                                      {trx?.transfer_tax}
                                                                                    </td>

                                                                                    <td className="text-grey"> 
                                                                                        <Moment format="DD/MM/YYYY" date={trx?.transfer_date}  /> 
                                                                                    </td>

                                                                                    <td className="text-grey"> 
                                                                                        <Moment format="DD/MM/YYYY" date={trx?.transfer_estimate_date}  /> 
                                                                                    </td>

                                                                                    <td className="text-grey text-right">
                                                                                      {trx?.amount}
                                                                                    </td>

                                                                                    <td className="text-grey text-right">
                                                                                      <TransferStatus statusId={trx?.status} />
                                                                                    </td>

                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                ) : (
                                                                    <div className="loader"> Carregando dados, por favor aguarde ... </div>
                                                                )}
                                                                
                                                            </>
                                                        )}
                                            </div>

                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
        </>
      )
    }
}