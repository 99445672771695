import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

import './styles.css';
import camera from '../../../assets/images/camera.svg';

export default function CampaignNew() {

  const [thumbnail, setThumbnail] = useState(null);
  
  const preview = useMemo(() => {
    return thumbnail ? URL.createObjectURL(thumbnail) : null;
  }, [thumbnail])

  return (
    <>
        <div className="col-lg-12">
        <div>
            <div>

                <div className="row mb-3">
                    
                    <div className="col-md-6">
                        <h3 className="card-title"> Doações /Nova Campanha </h3>
                        <p className="text-grey"> Para criar sua campanha é muito simples, basta preencher os dados no formulário abaixo.  </p>
                    </div>

                    <div className="col-md-6">
                        <div className="text-right">
                                <div className="dropdown">
                                    <Link to="/campaigns" className="btn btn-outline-twitter btn-sm">
                                        <i className="ti-list-ol"></i> Lista de Campanhas
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                <div className="row">
                    <div className="col-md-6 borderRight">
                        <form className="forms-sample">

                            <h4 className="card-title text-twitter mb-4 mt-4"> Dados da Campanha </h4>
                            <div className="form-group">
                                <label htmlFor="campaignTitle"> Título da Campanha * </label>
                                <input type="text"
                                    className="form-control" 
                                    id="campaignTitle" 
                                    placeholder="Informe o título da campanha..." 
                                    />
                            </div>

                            <div className="form-group">
                                <label htmlFor="description"> Descrição da Campanha * </label>
                                <textarea className="form-control" 
                                    id="description" 
                                    placeholder="Descreva as informações da campanha ..." 
                                    rows="10"></textarea>
                            </div>

                            <div className="form-group">
                                <label htmlFor="campaignMeta"> Meta da Campanha * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="campaignMeta" 
                                    placeholder="Qual o valor a ser atingido da campanha..." 
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="startCampaign"> Começo da campanha: * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="startCampaign" 
                                    placeholder="00/00/0000"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="endCampaign"> Fim da campanha: * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="endCampaign" 
                                    placeholder="00/00/0000"
                                />
                            </div>

                            <h4 className="card-title text-twitter mt-4"> E-mail </h4>

                            <div className="form-group">
                                <label htmlFor="thanksNotes"> Notas de agradecimento da campanha (opcional) </label>
                                <textarea className="form-control" 
                                    id="thanksNotes" 
                                    placeholder="Você pode criar uma nota de agradecimento para quem doar ..." 
                                    rows="3"></textarea>
                            </div>

                </form>
                </div>
                    <div className="col-md-6 border-left-grey pd-l2">

                    <div className="row ">
                        <div className="col-md-12">

                            <h4 className="card-title text-twitter mb-4 mt-4"> Media </h4>

                            <div className="form-group">
                            <label htmlFor="thumbnail"> Imagem de Capa * </label>
                            <label 
                                id="thumbnail" 
                                style={{ backgroundImage: `url(${preview})` }}
                                className={thumbnail ? 'has-thumbnail' : ''}
                            >
                                <input type="file" onChange={event => setThumbnail(event.target.files[0])} />
                                <img src={camera} alt="Select img" />
                            </label>
                            <p className="text-grey"> Especificações: Imagem no formato JPG ou PNG com tamanho até 1.5MB </p>
                               
                            </div>

                            

                            <div className="form-group mb-5">
                                <label htmlFor="videoUrl"> Vídeo de Apresentação </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="videoUrl" 
                                    placeholder="https://www.youtube.com/watch?v=DnFAHHrS_no"
                                />
                            </div>

                            <h4 className="card-title text-twitter"> Mídias Sociais </h4>

                            <div className="form-group mt-4">
                                <label htmlFor="instaUrl"> Instagram URL </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="instaUrl" 
                                    placeholder="https://www.instagram.com/doamob"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="facebookUrl"> Facebook Page URL  </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="facebookUrl" 
                                    placeholder="https://www.facebook.com/#/doamob"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="whatsAppUrl"> WhatsApp </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="whatsAppUrl" 
                                    placeholder="11999999999"
                                />
                            </div>

                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <p className="text-grey mt-20">
                                    * É importante que todos os seus dados estejam atualizados em nosso cadastro para evitar qualquer tipo de inconformidade
                                    com o status de sua conta.
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <button 
                                    type="submit" 
                                    className="btn btn-info mr-2"
                                    > Salvar </button>
                                </div>
                            </div>

                            


                        </div>
                    </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}
