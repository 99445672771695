import React from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import Config from '../../../services/config';
import AuthPage from '../../../components/AuthPage';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import CustomerList from '../../../components/Customers/List';

export default function List() {
  return (
    <>
    <Loader
       type="BallTriangle"
       color="#000000"
       height={60}
       width={60}
       timeout={Config.loaderTimeout}
       className="overlaySpinner" //3 secs
   />
     <AuthPage />
     <div className="container-scroller">
         <Header />
             <div className="container-fluid page-body-wrapper">
                 <div className="main-panel">
                     <div className="content-wrapper">

                         <div className="row">

                         <div className="col-md-12">
                             <div className="row">

                                 <div className="col-md-12">
                                     <div>
                                     <div>
                                         
                                         <div className="row mb-4">
                                         
                                            <div className="col-md-8">

                                                <h3 className=""> Clientes </h3>
                                                    <p className="text-grey mt-3"> Gerencie sua carteira de clientes através da Dashboard da Pagefy  </p>
                                                </div>

                                                <div className="col-md-4 text-right">
                                                    <Link to="/customer/store" className="btn btn-outline-twitter btn-sm">
                                                        <i className="ti-plus btn-icon-prepend"></i> Adicionar Cliente 
                                                    </Link>
                                                </div>
                                         </div>

                                         <CustomerList />

                                     </div>
                                 </div>
                                 </div>
                             </div>
                         </div>

                     </div>
                     
                     </div>
                 </div>
             </div>
         <Footer />
     </div>
 </>
  );
}
