import React from 'react';
import { Link } from 'react-router-dom';

import personIcon from '../../../assets/images/faces/Pagefy.svg';

const credencialData = JSON.parse(localStorage.getItem("credencialData"));

export default function Navigation() {
  return (
      <>
        <div>
            <img src={personIcon} className="img-lg rounded-circle mb-2" alt="Pagefy Tecnologia" />
            <h4> { credencialData.name } </h4>
            <p className="text-twitter mb-0"> Perfil de usuário { credencialData.group } </p>
        </div>

        <p className="mt-2 card-text text-grey border-top pt-3 mb-4">
                Selecione a opção desejada no menu de administrador abaixo:
        </p>

        <Link to="/admin/main" className="btn btn-outline-secondary btn-icon-text btn-block">
            Home
        </Link>

        <Link to="/admin/customers" className="btn btn-outline-secondary btn-icon-text btn-block">
            Clientes
        </Link>

        <Link to="/admin/tickets" className="btn btn-outline-secondary btn-icon-text btn-block">
            Suporte ( Tickets )
        </Link>

        <button className="btn btn-outline-secondary btn-icon-text btn-block">
            Cobranças
        </button>

        <Link to="/admin/products" className="btn btn-outline-secondary btn-icon-text btn-block">
            Produtos
        </Link>

        <button className="btn btn-outline-secondary btn-icon-text btn-block">
            Doamob
        </button>

        <button className="btn btn-outline-secondary btn-icon-text btn-block">
            Relatórios
        </button>

        <Link to="/admin/users" className="btn btn-outline-secondary btn-icon-text btn-block">
                Usuários
        </Link>

        <button className="btn btn-outline-secondary btn-icon-text btn-block">
            Sistema
        </button>
    </>
  );
}
