import React, { Component } from 'react';

import { Link, Redirect } from 'react-router-dom';
import Alerts from '../../components/Alerts/index';

// Services
import api from '../../services/login';
import Config from '../../services/config';

// Assets
import '../../assets/vendors/iconfonts/ti-icons/css/themify-icons.css'
import '../../assets/vendors/css/vendor.bundle.base.css'
import '../../assets/css/horizontal-layout-light/style.css'
import './ResetPassword.css';

import FooterLine from '../../components/FooterSlim';


export default class ResetPassword extends Component {

    constructor(){
        super()

        // let loggedIn = false
        // const token = localStorage.getItem("token")
        // if(token) loggedIn = true

        this.state = {
            username: "",
            error: ""
        }
        this.onChange =  this.onChange.bind(this)
        this.formSubmit = this.formSubmit.bind(this)
    }

    onChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async formSubmit(e){

        e.preventDefault()
        const {username} = this.state
        const authorization =  Config.AuthApiSecretKey;

        try {
            const response = await api.post("/passwordReset", {email : username}, {
                headers: { authorization }
            })
            
            if(response.data.message === 'request success'){
                Alerts("Recuperação de Senha", 'As instruções para recuperação de senha foram enviadas para o seu endereço de e-mail' ,"success");
            } 

            this.setState({
                loggedIn: true
            })

        } catch (err) {

            console.log(err.message);
            Alerts("Recuperação de Senha", 'Oops!, alguma coisa deu errado, verifique o endereço de e-mail e tente novamente...' ,"error");
            this.setState({
                error: err.message
            })

        }
    }

  render() {
    
    if(this.state.loggedIn === true){
        return <Redirect to="/" />
    }

    return (
        <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
            <div className="row flex-grow">
            
            <FooterLine />

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <div className="auth-form-transparent text-left p-3">
                
                <h3 className="mt-30"> Quer recuperar a senha ? </h3>
                <span className="spacer"> &nbsp;</span>
                <h6 className="font-weight-light text-grey mt-30 mb-4"> Vamos lá se perdeu sua senha informe o email de cadastro para que possamos te ajudar  </h6>
                
                <form onSubmit={this.formSubmit}>
                    <div className="form-group">
                    
                    <div className="input-group">
                        <input 
                            type="text" 
                            placeholder="Informe o e-mail de cadastro..." 
                            value={this.state.username} 
                            onChange={this.onChange} 
                            name="username" 
                            className="form-control form-control-lg border-left-0" 
                        />
                    </div>
                    </div>
                    
                    <div className="my-3">
                        {/* <a className="btn btn-block btn-info btn-lg font-weight-medium auth-form-btn" href="../../index.html"> ENVIAR </a> */}
                        <button className="btn btn-block btn-dark btn-lg font-weight-medium auth-form-btn" > ENVIAR </button>
                    </div>
                    
                    <div className="text-left font-weight-light">
                        <Link to="/" className="text-dark">
                            Já tenho a conta fazer login 
                        </Link>
                    </div>
                </form>


                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    );

  }
}
