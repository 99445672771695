import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link, Redirect } from 'react-router-dom';

import Header from '../../../../components/Header';
import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPage';
import Footer from '../../../../components/Footer';
import api from '../../../../services/Users';

import './styles.css';

export default class Contracts extends Component {

  constructor(props) {
    
    const { match: { params } } = props;
    const authorization = Config.AuthApiSecretKey;

    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;
    
    super(props);

    this.state = {
      redirect: false,
      postData: [],
      authorization,
      pageCounter: 1,
      customerId: userid,
      contractId: params.contractId,
      loading: 1,
      loader: 'enable'
    };
  }

  loadTrx(p){

      this.setState({ loading: true });

      const authorization = this.state.authorization;
  
      api.get(`/wpPosts/${this.state.contractId}/${p}`,{
          headers: { authorization, userid: `${this.state.customerId}` },
      })
      .then(res => {
        this.setState({ postData: res.data });
        this.setState({ loader: 'disable' });
      });


  }

  async handleRemove (itemId, mobcustomerid) {
    
    const authorization = this.state.authorization;

    await api.delete(`/contract/${itemId}`, {
      headers: { authorization, mobcustomerid },
    }).then(() => this.setState({ redirect: true }));
    console.log(this.state.redirect);

  }

  handlePaginationPlus = () => {
    this.setState({ loader: 'enable' });
    this.setState({ pageCounter: this.state.pageCounter + 1});
    this.loadTrx(this.state.pageCounter + 1);
  }

  handlePaginationLess = () => {
    if(this.state.pageCounter > 1){
        this.setState({ loader: 'enable' });
        this.setState({ pageCounter: this.state.pageCounter - 1});
        this.loadTrx(this.state.pageCounter - 1);
      }
  }

  componentDidMount() {
    this.loadTrx(1);
  }
    
    render(){

      if (this.state.redirect) {
        return <Redirect to={`/admin/contracts/${this.state.settingsdata.userId}`} />
      }
        
      return (
        <>

          {this.state.loader == 'enable' ? (        
            <Loader
              type="BallTriangle"
              color="#000000"
              height={60}
              width={60}
              timeout={Config.loaderTimeout}
              className="overlaySpinner" //3 secs
            />
          ) : ('')}

          <AuthPage />

          <div className="container-scroller">

              <Header />

              <div className="container-fluid page-body-wrapper" id="fscroll">
                  <div className="main-panel">
                  <div className="content-wrapper">
              
              <div className="row">
                <div className="col-12">
                  <div className="mt-4">
                    <div className="">

                      <div className="row">
                        <div className="col-md-8">
                          <h3>
                            Posts  / {this.state.pageCounter}
                          </h3>
                        </div>

                        <div className="col-md-4 text-right">
                            <Link to={`/user/contract/${this.state.contractId}`} className="btn btn-outline-dark btn-sm text-left">
                               Adicionar Post
                            </Link>
                        </div>
                      </div>


                      <div className="row mt-4">

                        <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="text-uppercase">Post Id</th>
                                  <th className="text-uppercase">Título</th>
                                  <th className="text-uppercase">Publicação</th>
                                  <th className="text-uppercase">Propriedades</th>
                                </tr>
                              </thead>

                              <tbody>
                                {!this.state.postData.length ? (
                                  <>
                                    <p className="text-left text-grey p-4">
                                        Não encontramos nenhum contrato para apresentar aqui ainda!
                                    </p>
                                  </>
                                ) : (
                                <>
                                {this.state.postData.map((post, index) => (

                                  <tr key={index}>
                                    <td><strong>{post?.id} </strong></td>
                                    <td>
                                      <Link to={`/user/wp-post-update/${this.state.contractId}/${post?.id}`} className="text-dark">
                                        {post?.title.rendered}
                                      </Link>
                                      </td>
                                    <td>
                                      <Moment format="DD/MM/YYYY - hh:mm" date={post?.date} /> 
                                    </td>
                                    <td className="text-center">
                                      <a href={post?.link} target="_blank"> 
                                        <i className="ti-eye icon-status-grey mr-2"></i> </a>
                                      
                                      <i className="ti-trash icon-status-grey mr-2"></i>

                                      {post?.status === "publish" ? (
                                        <i className="ti-control-record icon-status-enable "></i>
                                        ) : (
                                        <i className="ti-control-record icon-status-disable "></i>
                                      )}

                                    </td>
                                  </tr>

                                ))}
                              </>
                              )}
                              </tbody>

                            </table>
                        </div>

                      </div>

                      <div className="row">

                          <nav className="paginationRight">
                              <ul className="pagination flex-wrap pagination-flat pagination-twitter">
                                  <li className="page-item">
                                      <button className="page-link pgLess" onClick={this.handlePaginationLess}>
                                          <i className="ti-angle-left"></i>
                                      </button> 
                                  </li>
                                  <li className="page-item active">
                                      <span className="page-link">{this.state.pageCounter}</span>
                                  </li>
                                  <li className="page-item">
                                      <button className="page-link" onClick={this.handlePaginationPlus}>
                                          <i className="ti-angle-right"></i>
                                      </button> 
                                  </li>
                              </ul>
                          </nav>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
                  </div>
              </div>
            </div>
            <Footer />

        </>
      )
    }
}