import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';

export default function LastDonations() {
  return (
    <>
        
        <div className="row">
            <div className="col-md-6">
                <h4 className="card-title text-twitter"> Resumo de Doações </h4>
                <p className="text-grey"> Resumo das doações recebidas  </p>
            </div>
            <div className="col-md-6 text-right">
                    <div className="dropdown">
                        <button className="btn btn-outline-info btn-sm dropdown-toggle" type="button" id="dropdownMenuSizeButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                         Filtrar tipo de doação
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuSizeButton2" x-placement="bottom-start" >
                          <a className="dropdown-item" href="dropdowns.html#"> Doação Avulsa </a>
                          <a className="dropdown-item" href="dropdowns.html#"> Campanhas </a>
                          <a className="dropdown-item" href="dropdowns.html#"> Presencial ( POS ) </a>
                        </div>
                    </div>
            </div>
        </div>

        <div className="table-responsive mt-3">
                    <table className="table">
                      <thead>
                        <tr>
                            <th>Transação</th>
                            <th> Usuário </th>
                            <th className="text-left"> Tipo de Doação </th>
                            <th className="text-left"> Valor </th>
                            <th className="text-right"> Status do Pagamento </th>
                            <th className="text-left">  </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td className="text-muted">06/11/2019</td>
                            <td className="text-left text-muted"> R$ 1.000,00 </td>
                            <td className="text-left text-muted"> R$ 1.000,00 </td>
                            <td className="text-left text-muted"> R$ 1.000,00 </td>
                            <td className="text-right text-twitter"> R$ 1.500,00</td>
                            <td className="text-right text-twitter"> 
                                <Link to="chargeDetail">
                                  <button type="button" className="btn btn-outline-secondary btn-sm"> Detalhes </button>
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-muted">05/11/2019</td>
                            <td className="text-left text-muted"> R$ 1.000,00 </td>
                            <td className="text-left text-muted"> R$ 1.000,00 </td>
                            <td className="text-left text-muted"> R$ 1.000,00 </td>
                            <td className="text-right text-twitter"> R$ 2.500,00</td>
                            <td className="text-right text-twitter">
                                <Link to="chargeDetail">
                                  <button type="button" className="btn btn-outline-secondary btn-sm"> Detalhes </button>
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-muted">04/11/2019</td>
                            <td className="text-left text-muted"> R$ 1.000,00 </td>
                            <td className="text-left text-muted"> R$ 1.000,00 </td>
                            <td className="text-left text-muted"> R$ 1.000,00 </td>
                            <td className="text-right text-twitter"> R$ 3.500,00 </td>
                            <td className="text-right text-twitter"> 
                                <Link to="chargeDetail">
                                  <button type="button" className="btn btn-outline-secondary btn-sm"> Detalhes </button>
                                </Link>
                            </td>
                        </tr>
                      </tbody>
                    </table>
        </div>
    </>
  );
}
