import React from 'react';
import {Redirect} from "react-router-dom"
import Config from '../../services/config';
import api from '../../services/Users';

export default function AuthPage(props) {

    const privateMode = props.privateMode;

    if(privateMode && privateMode === 'enable'){

        const authorization = Config.AuthApiSecretKey;
        const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
        const userid = credencialdata._id;

        async function loadGroup() {
            
            const response = await api.get('/user/group', {
                headers: { authorization, userid },
            });

            if(response.data.user.group === "customer"){
                window.location='../404';
            }
        }

        loadGroup();

    }

    const token = localStorage.getItem("token");
    const onboard = JSON.parse(localStorage.getItem("credencialData")).onboard;

    console.log(onboard)

    if(!token){
        return <Redirect to="/" />
    }else{
        return '';
    }

}


// {(this.state.userdata.onboard == true ) ? (
//     <div className="onboard">
//         <h2> Onboard aqui...... </h2>
//     </div>
//   ) : (
//     <></>
//   )}