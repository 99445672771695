import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

import './styles.css';
import camera from '../../../assets/images/camera.svg';


export default function EventsUpdate() {

    const [thumbnail, setThumbnail] = useState(null);

    const preview = useMemo(() => {
        return thumbnail ? URL.createObjectURL(thumbnail) : null;
    }, [thumbnail])

  return (
    <>
        <div className="col-lg-12">
        <div>
            <div>

                <div className="row mb-3">
                    
                    <div className="col-md-6">
                        <h3 className="card-title"> Doações /Editar Evento </h3>
                        <p className="text-grey"> Você pode criar eventos pagos ou gratuítos. Preencha os campos abaixo com os dados do evento  </p>
                    </div>

                    <div className="col-md-6">
                        <div class="text-right">
                                <div class="dropdown">
                                    <Link to="/events" className="btn btn-outline-twitter btn-sm">
                                        <i className="ti-list-ol"></i> Lista de Eventos
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                <div className="row">
                    <div className="col-md-6 borderRight">
                        <form className="forms-sample">

                            <h4 class="card-title text-twitter mb-4 mt-4"> Dados da Evento </h4>
                            <div className="form-group">
                                <label for="campaignTitle"> Título * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="campaignTitle" 
                                    placeholder="Informe o título da campanha..." 
                                    value="" 
                                />
                            </div>
                            
                            <div class="form-group">
                                <label for="exampleFormControlSelect2"> Tipo do Evento *</label>
                                <select class="form-control" id="exampleFormControlSelect2">
                                    <option> Presencial </option>
                                    <option> Evento Online </option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label for="campaignTitle"> Limite de Ingressos </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="campaignTitle" 
                                    placeholder="Número máximo de ingressos. Deixe vazio caso não tenha limite" 
                                    value="" 
                                />
                            </div>

                            <div className="form-group">
                                <label for="campaignTitle"> Nome do Organizador * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="campaignTitle" 
                                    placeholder="Quem está organizando o evento" 
                                    value="" 
                                />
                            </div>

                            <div className="form-group">
                                <label for="campaignTitle"> Localização * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="Location" 
                                    placeholder="Localização do Evento..." 
                                    value="" 
                                />
                            </div>

                            <div className="form-group">
                                <label for="description"> Descrição * </label>
                                <textarea class="form-control" 
                                    id="description" 
                                    placeholder="Descreva as informações da campanha ..." 
                                    rows="10"></textarea>
                            </div>

                            <div className="form-group">
                                <label for="campaignMeta"> Valor do Evento* </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="campaignMeta" 
                                    placeholder="Caso o evento seja gratuíto deixe em branco..."
                                />
                            </div>

                            <div className="form-group">
                                <label for="startCampaign"> Início do Evento: * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="startCampaign" 
                                    placeholder="00/00/0000"
                                />
                            </div>

                            <div className="form-group">
                                <label for="endCampaign"> Fim do Evento: * </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="endCampaign" 
                                    placeholder="00/00/0000"
                                />
                            </div>

                            <h4 class="card-title text-twitter mt-4"> E-mail </h4>

                            <div className="form-group">
                                <label for="thanksNotes"> E-mail de instruções para o evento (opcional) </label>
                                <textarea class="form-control" 
                                    id="thanksNotes" 
                                    placeholder="Você pode criar um email com instruções que será enviado quando o usuário se inscrever ..." 
                                    rows="3"></textarea>
                            </div>

                </form>
                </div>
                    <div className="col-md-6 border-left-grey pd-l2">

                    <div className="row ">
                        <div className="col-md-12">

                            <h4 class="card-title text-twitter mb-4 mt-4"> Media </h4>

                            <div className="form-group">
                            <label for="thumbnail"> Imagem de Capa * </label>
                            <label 
                                id="thumbnail" 
                                style={{ backgroundImage: `url(${preview})` }}
                                className={thumbnail ? 'has-thumbnail' : ''}
                            >
                                <input type="file" onChange={event => setThumbnail(event.target.files[0])} />
                                <img src={camera} alt="Select img" />
                            </label>
                            <p className="text-grey"> Especificações: Imagem no formato JPG ou PNG com tamanho até 1.5MB </p>
                               
                            </div>

                            

                            <div className="form-group mb-5">
                                <label for="endCampaign"> Vídeo de Apresentação </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="endCampaign" 
                                    placeholder="https://www.youtube.com/watch?v=DnFAHHrS_no"
                                />
                            </div>

                            <h4 class="card-title text-twitter"> Mídias Sociais </h4>

                            <div className="form-group mt-4">
                                <label for="endCampaign"> Instagram URL </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="endCampaign" 
                                    placeholder="https://www.instagram.com/doamob"
                                />
                            </div>

                            <div className="form-group">
                                <label for="endCampaign"> Facebook Page URL  </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="endCampaign" 
                                    placeholder="https://www.facebook.com/#/doamob"
                                />
                            </div>

                            <div className="form-group">
                                <label for="endCampaign"> WhatsApp </label>
                                <input type="text" 
                                    className="form-control" 
                                    id="endCampaign" 
                                    placeholder="11999999999"
                                />
                            </div>

                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <p className="text-grey mt-20">
                                    * É importante que todos os seus dados estejam atualizados em nosso cadastro para evitar qualquer tipo de inconformidade
                                    com o status de sua conta.
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <button 
                                    type="submit" 
                                    className="btn btn-info mr-2"
                                    > Salvar </button>
                                </div>
                            </div>

                            


                        </div>
                    </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}
