import React, { Component } from 'react';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';
import { Link, Redirect } from 'react-router-dom';

import Header from '../../../components/Header';
import Config from '../../../services/config';
import AuthPage from '../../../components/AuthPage';
import Footer from '../../../components/Footer';
import api from '../../../services/Users';

import './styles.css';

export default class Contracts extends Component {

  constructor(props) {
    
    const { match: { params } } = props;
    const authorization = Config.AuthApiSecretKey;
    
    super(props);
    this.state = {
      redirect: false,
      settingsdata: [],
      authorization,
      customerId: params.customerId,
      contractId: params.contractId,
      pluginsdata: []
    };
  }

  loadContract(){

      const authorization = this.state.authorization;
  
      api.get(`/wcSettings/${this.state.contractId}`,{
          headers: { authorization, userid: `${this.state.customerId}` },
      })
      .then(res => {
        this.setState({ settingsdata: [res.data] });
        this.setState({ pluginsdata: this.state.settingsdata[0].active_plugins });
      });

      console.log(this.state.settingsdata.data)

     
      
  }

  async handleRemove (itemId, mobcustomerid) {
    
    const authorization = this.state.authorization;

    await api.delete(`/contract/${itemId}`, {
      headers: { authorization, mobcustomerid },
    }).then(() => this.setState({ redirect: true }));
    console.log(this.state.redirect);

  }

  componentDidMount() {
    this.loadContract();
  }
    
    render(){

      if (this.state.redirect) {
        return <Redirect to={`/admin/contracts/${this.state.settingsdata.userId}`} />
      }
        
      return (
        <>
          <Loader
            type="BallTriangle"
            color="#000000"
            height={60}
            width={60}
            timeout={Config.loaderTimeout}
            className="overlaySpinner" //3 secs
          />

          <AuthPage />

          <div className="container-scroller">
              <Header />

              <div className="container-fluid page-body-wrapper" id="fscroll">
                  <div className="main-panel">
                  <div className="content-wrapper">
              
              <div className="row">
                <div className="col-12">
                  <div className="mt-4">
                    <div className="">

                      <div className="row">
                        <div className="col-md-8">
                          <h3>
                            Status do Wordpress
                          </h3>
                        </div>

                        <div className="col-md-4 text-right">
                            <Link to={`/user/contract/${this.state.contractId}`} className="btn btn-outline-dark btn-sm text-left">
                               Voltar
                            </Link>
                        </div>
                      </div>


                      <div className="row mt-4">

                          <div className="col-md-3 grid-margin">
                            <div className="card d-flex align-items-center">
                              <div className="">
                                <div className="d-flex flex-row align-items-center">
                                  <i className="ti-book text-dark icon-md icon-circle"></i>
                                  <div className="ml-3">
                                    <h3 className="text-dark"> {(this.state.settingsdata[0]?.post_type_counts[17]?.count) || "Dados inexistente"} </h3>
                                    <p className="mt-2 text-uppercase"> Publicações </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3 grid-margin">
                            <div className="card d-flex align-items-center">
                              <div className="">
                                <div className="d-flex flex-row align-items-center">
                                  <i className="ti-cloud text-dark icon-md icon-circle"></i>
                                  <div className="ml-3">
                                    <h3 className="text-dark"> {(this.state.settingsdata[0]?.post_type_counts[15]?.count) || "Dados inexistente"} </h3>
                                    <p className="mt-2 text-uppercase"> Páginas </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 grid-margin">
                            <div className="card d-flex align-items-center">
                              <div className="">
                                <div className="d-flex flex-row align-items-center">
                                  <i className="ti-gallery text-dark icon-md icon-circle"></i>
                                  <div className="ml-3">
                                    <h3 className="text-dark"> {(this.state.settingsdata[0]?.post_type_counts[6]?.count) || "Dados inexistente"} </h3>
                                    <p className="mt-2 text-uppercase"> Galerias de Imagem </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3 grid-margin">
                            <div className="card d-flex align-items-center">
                              <div className="">
                                <div className="d-flex flex-row align-items-center">
                                  <i className="ti-comments text-dark icon-md icon-circle"></i>
                                  <div className="ml-3">
                                    <h3 className="text-dark"> {(this.state.settingsdata[0]?.post_type_counts[22]?.count) || "Dados inexistente"} </h3>
                                    <p className="mt-2 text-uppercase"> Comentários </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                      </div>

                      <div className="row ">
                        <div className="col-md-6">
                          <div className="card-info">
                            <h4 className="text-dark p-2 card-info-header"> 
                              Configurações do Site </h4>
                            
                              <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="text-grey"> Endereço do Site </td>
                                          <td className="text-right"> 
                                            <span className="text-grey"> 
                                              <a href={this.state.settingsdata[0]?.environment.home_url || ''} target="_blank"> {this.state.settingsdata[0]?.environment.home_url || "Dados inexistente"} </a>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-grey"> Tamanho Máximo para Upload </td>
                                        <td className="text-right"> <span className="text-grey"> {Math.round(this.state.settingsdata[0]?.environment.max_upload_size/1024) || "Dados inexistente"}  MB </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-grey"> Versão do PHP </td>
                                        <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.environment.php_version) || "Dados inexistente"} </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-grey"> Linguagem Padrão do Wordpress </td>
                                        <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.environment.language) || "Dados inexistente"} </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-grey"> Servidor Web </td>
                                        <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.environment.server_info) || "Dados inexistente"} </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-grey"> Versão do Wordpress </td>
                                        <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.environment.wp_version) || "Dados inexistente"} </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-grey"> Forçar Conexão Segura SSL  </td>
                                        <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.settings.force_ssl === true ? 'Sim' : 'Não') || "Dados inexistente"} </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-grey"> SSL Habilitado  </td>
                                        <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.settings.currency) || "Dados inexistente"} </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                          </div>
                        
                          <div className="mt-4 card-info">
                            <h4 className="text-dark p-2"> 
                                Banco de Dados </h4>
                            <table className="table">
                                  <tbody>
                                      <tr>
                                          <td className="text-grey"> Tamanho do Banco de Dados </td>
                                          <td className="text-right"> <span className="text-grey"> {Math.round(this.state.settingsdata[0]?.database.database_size.data) || "Dados inexistente"}  MB </span>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td className="text-grey"> Prefix da base de dados </td>
                                          <td className="text-right"> <span className="text-grey"> {Math.round(this.state.settingsdata[0]?.database.database_prefix) || "Dados inexistente"}  MB </span>
                                          </td>
                                      </tr>
                                      <tr>
                                        <td className="text-grey"> Versão do Banco de Dados MySQL </td>
                                        <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.database.wc_database_version) || "Dados inexistente"} </span>
                                        </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>

                          <div className="mt-4 card-info">
                            <h4 className="text-dark p-2"> 
                                Publicações </h4>
                            <table className="table">
                                  <tbody>
                                      <tr>
                                          <td className="text-grey"> Total de Publicações </td>
                                          <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.post_type_counts[17]?.count) || "Dados inexistente"} </span>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td className="text-grey"> Total de Páginas </td>
                                          <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.post_type_counts[15]?.count) || "Dados inexistente"} </span>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td className="text-grey"> Total de galerias de imagem </td>
                                          <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.post_type_counts[6]?.count) || "Dados inexistente"} </span>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td className="text-grey"> Total de comentários </td>
                                          <td className="text-right"> <span className="text-grey"> {(this.state.settingsdata[0]?.post_type_counts[22]?.count) || "Dados inexistente"} </span>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                        
                        </div>

                        <div className="col-md-6">
                          <div className="p-2 card-info">
                            <h4 className="text-dark"> 
                              Plugins Instalados </h4>
                            <p className="text-grey mb-4"> Abaixo você encontra todos os plugins que estão instalados neste website </p>

                            {/*  */}
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="pt-1 pl-0">
                                     Nome
                                  </th>
                                  <th className="pt-1">
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              <tbody>

                              {this.state.pluginsdata.map((plugin, index) => (
                                <tr key={index}>
                                  <td className="py-1 pl-0">
                                    <div className="d-flex align-items-center">
                                      <div className="ml-3">
                                        <p className="mb-0"> {plugin?.name} </p>
                                        <p className="mb-0 text-muted text-small">  
                                          Versão {plugin?.version} / {plugin?.version_latest} ( URL )</p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    { plugin?.version < plugin?.version_latest ? (
                                      <div className="badge badge-outline-danger badge-pill"> 
                                        Desatualizado </div>
                                    ) : (
                                      <div className="badge badge-outline-success badge-pill"> 
                                        Atualizado </div>
                                    )}
                                  </td>
                                </tr>
                              ))}

                              </tbody>
                    </table>
                            {/*  */}
                          </div>
                        </div>

                      </div>

                      <div className="row">
                        
                        <div className="col-lg-8">

                                  


                                </div>

                                
                        </div>
                        
                        <div className="col-md-4 text-right">

                            

                        </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
                  </div>
              </div>
            </div>
            <Footer />

        </>
      )
    }
}