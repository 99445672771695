import React from 'react';

export default function PaymentType(props) {
  
  const Switch = (str) => ({
    "BOLETO" : "Boleto Bancário",
    "CREDIT_CARD" : "Cartão de Crédito",
    "UNDEFINED" : "Link de Pagamento",
  })[str] || '';

  let paymentType = props.paymentType;

  return (
    <span>
      { Switch(paymentType) }
    </span>
);
  

}
