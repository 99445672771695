import React, { useEffect, useState } from 'react';

import Config from '../../../services/config';
import api from '../../../services/Users';

const credencialData = JSON.parse(localStorage.getItem("credencialData"));

export default function WellcomeAdmin() {

  const [ customerCount, setCustomerCount ] = useState([]);
  const [ platformStatus, setPlataformStatus ] = useState([]);

  useEffect(() => {
      
      const authorization = Config.AuthApiSecretKey;
      const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
      const userid = credencialdata._id;

      async function loadCountCustomer() {
          const response = await api.get('/mobcustomer/count', {
              headers: { authorization, userid },
          })
          // console.log(response.data.customers);
          setCustomerCount(response.data.customers);
      }

      async function plataformStatus() {
        const rStatus = await api.get('/platform/status', {
          headers: { authorization },
        })
          // console.log(`${rStatus.data.status}`);
          setPlataformStatus(rStatus.data.status);
      }

      loadCountCustomer();
      plataformStatus();

  },[])

  return (
    <>
     <div className="row">
        <div className="col-12 col-xl-5 mb-4 mb-xl-0">
        <h4 className="font-weight-bold">Olá, { credencialData?.name }!</h4>
        <p className="font-weight-normal mb-0 text-grey"> 
          Você está logado em uma área restrita <br/>
        </p>
        </div>
        <div className="col-12 col-xl-7">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="border-right pr-4 mb-3 mb-xl-0 text-right">
            <p className="text-muted"> Total de Clientes Cadastrados </p>
            <h4 className="mb-0 font-weight-bold"> {customerCount} </h4>
            </div>
            <div className="border-right pr-4 mb-3 mb-xl-0 text-right">
            <p className="text-muted"> Status da Plataforma </p>


            <h4 className={platformStatus === 'Online' ? 'mb-0 font-weight-bold text-success' : 'mb-0 font-weight-bold text-danger'}>  {platformStatus} </h4>


            </div>
            <div className="border-right pr-4 mb-3 mb-xl-0 text-right">
            <p className="text-muted"> Ambiente </p>
            <h4 className="mb-0 font-weight-bold">  {Config.environment} </h4>
            </div>
        </div>
        </div>
    </div>
                          
    </>
  );
}
