import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import Alerts from '../../components/Alerts';
import Config from '../../services/config';
import api from '../../services/login';
import OrderStatus from '../../components/CommerceOrderStatus';

import './styles.css';

export default function OrderPGDetail(props) {
  
  const authorization = Config.AuthApiSecretKey;

  const orderId = props.orderId;
  const contractId = props.contractId;
  const userId = props.customerId;
  const [ paymentData, setpaymentData ] = useState();
  const [orderStatus, setOrderStatus] = useState();

  const handleChange = async (e) => {

    const state = {...orderStatus, [e.target.name]: e.target.value};
    setOrderStatus(state.orderStatus);

    let orderStatusChange = state.orderStatus

    console.log(setOrderStatus);
    
    // 
    api.put(`/admin/commerce/order/${orderId}`,{
      orderStatus : orderStatusChange
    }, { headers: { 
      authorization, 
      userid : userId, 
      contractid : contractId, 
      'content-type' : 'application/json;charset=UTF-8' },
  }).then((result) => {
      Alerts("Pedido",`Status de pedido atualizado`, "success");
      console.log(result);
    });
    // 

  };

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const authorization = Config.AuthApiSecretKey;

    async function loadData() {
      const response = await api.get(`/user/commerce/order/${orderId}`, {
        headers: {
          authorization,
          userId,
          contractid: contractId,
          "Content-Type": "application/json",
        },
      });
      setpaymentData(response.data);
    }
    loadData();
    },[]);

  return (
    <>
     <div className="row">
        <div className="col-12">

              <>
              <div className="row">
                <div className="col-md-6">
                  <h4> Pedido </h4>
                  <strong> {orderId} </strong>
                </div>
                <div className="col-md-6 text-right">
                    <Link 
                      to={`/user/commerce/orders/${contractId}`} 
                      className="btn btn-outline-dark btn-sm">
                      Ver pedidos    
                    </Link>
                </div>
              </div>

              <div className="row mt-4">
                
                <div className="col-md-6 mb-4 card p-4">
                  <h4 className="text-dark text-weight-bolder"> Dados do Cliente </h4>
                  <p className="text-dark"> 
                      <span className="text-uppercase"> Nome: <strong>{paymentData?.name} </strong> </span> <br/>
                      <span className="text-uppercase"> Email: <strong>{paymentData?.email} </strong> </span> <br/>
                      <span className="text-uppercase"> Telefone: <strong>{paymentData?.phone} </strong> </span> <br/>
                      <span className="text-uppercase"> CPF: <strong>{paymentData?.document} </strong> </span> <br/>
                      <span className="text-uppercase"> Endereço: <strong>{paymentData?.addr} </strong> - Número: <strong>{paymentData?.addrNumber} </strong> </span> <br/>
                      <span className="text-uppercase"> CEP: <strong>{paymentData?.addrPostalCode} </strong> </span> <br/>
                      <span className="text-uppercase"> Cidade: <strong>{paymentData?.addrCity} </strong> - Estado:  <strong>{paymentData?.addrState} </strong> </span> <br/>
                    </p>
                </div>

                <div className="col-md-6 p-4 pl-3 ">
                  <h4 className="text-dark text-weight-bolder"> Detalhes do Pedido </h4>
                  <p className="text-dark mt-4"> 
                    <strong> Total do pedido: R$ {paymentData?.orderAmount}  </strong> <br/>
                    Método de pagamento: <strong>{paymentData?.paymentMethod} </strong>  <br/><br/>
                    Método de entrega: <strong>{paymentData?.shippingMethod} </strong>  <br/>
                    Valor da entrega: <strong>{paymentData?.shippingAmount} </strong> <br/><br/>
                    Status: <strong><OrderStatus orderStatus={paymentData?.orderStatus} /> </strong> 
                  </p>
                </div>
              </div>

              <div className="row mt-3 order-status">
                <div className="col-md-6">
                  <h4> Status do Pedido </h4>

                  <div className="form-group">
                      <label htmlFor="chargeCycle"> Ciclo de Cobrança:  </label>
                      <select className="form-control"
                        id="orderStatus"
                        name="orderStatus"
                        value={paymentData?.orderStatus || ""}
                        onChange={handleChange}
                      >
                          <option value=""> Selecione uma opção de status do pedido </option>
                          <option value="created" > Criado </option>
                          <option value="cancelled" > Cancelado </option>
                          <option value="waiting-payment"> Aguardando Pagamento </option>
                          <option value="paid"> Pago </option>
                          <option value="shipped"> Enviado </option>
                          <option value="reversed"> Estornado </option>
                          <option value="shipped"> Entregue </option>
                      </select>

                      <small className='text-grey mt-4'> Para alterar o status do pedido basta selecionar o status desejado </small>
                  </div>

                  {/* <button 
                    type="submit" 
                    className="btn btn-dark"
                    // onClick={this.handleSubmit}
                    > Salvar </button> */}

                </div>
                <div className="col-md-6 text-center mt-4" >
                  <p>Status do pedido atualizado em:</p>
                  <Moment format="DD/MM/YYYY hh:mm" date={paymentData?.orderStatusUpdatedAt || ""} />
                </div>
              </div>

              <div className="row mt-3">

                <div className="col-md-12 col-sm-12">

                    <h4 className="text-dark text-weight-bolder "> Produtos </h4>

                    <div className="row">
                      <div className="col-md-12 mt-3">
                        
                          <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>
                                    Ítem
                                  </th>
                                  <th>
                                    Valor Unit.
                                  </th>
                                  <th>
                                    Quantidade
                                  </th>
                                  <th>
                                    Total produto
                                  </th>
                                </tr>
                              </thead>
                          <tbody>

                            {paymentData?.products.map((item, index) => (
                                <tr key={index}>
                                  <td className="py-1 text-dark">
                                    {item?.title} <br/>
                                    <small> 
                                      sku: {item?.productId} 
                                    </small>
                                  </td>
                                  <td>
                                    R$ {item?.price}
                                  </td>
                                  <td>
                                    {item?.quantity}
                                  </td>
                                  <td>
                                    R$ {parseFloat(item?.price * item?.quantity).toFixed(2)}
                                  </td>
                                </tr>
                            ))}
                          </tbody>
                          </table>
                        </div>
                    </div>

                    
                </div>

              </div>


                
              </>
            </div>
             
    </div>
    </>
  );
}
