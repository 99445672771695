import React from 'react';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';

import Config from '../../../services/config';
import HeaderAdminConsole from '../../../components/HeaderAdminConsole';
import Footer from '../../../components/Footer';
import WelcomeConsole from '../../../components/AdminConsoleComponents/WelcomeConsole';
import AuthPage from '../../../components/AuthPageAdmin';

export default function Main() {
  return (
    <>
    
     <Loader
        type="BallTriangle"
        color="#000000"
        height={60}
        width={60}
        timeout={Config.loaderTimeout}
        className="overlaySpinner"
    />

    <AuthPage />

    <div className="container-scroller">
        <HeaderAdminConsole />
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                    <div className="content-wrapper">

                    <div className="row">
                        <div className="col-md-12 grid-margin">
                            <WelcomeConsole />
                        </div>

                        <div className="col-md-12 grid-margin">

                        <div className="row mb-3">

                            <Link to="/admin/users" className="col-md-4 mt-3">
                                <div className="dash-button">
                                    Usuários
                                </div>
                            </Link>

                            <Link to="/admin/customers" className="col-md-4 mt-3">
                                <div className="dash-button">
                                     Clientes
                                </div>
                            </Link>

                            <Link to="/user/account/services" className="col-md-4 mt-3">
                                <div className="dash-button">
                                    Cobranças
                                </div>
                            </Link>

                            <Link to="/admin/contracts" className="col-md-4 mt-3" aria-disabled>
                                <div className="dash-button">
                                     Contratos
                                </div>
                            </Link>

                            <Link to="/admin/products" className="col-md-4 mt-3">
                                <div className="dash-button">
                                     Produtos
                                </div>
                            </Link>

                            <Link to="/user/account/services" className="col-md-4 mt-3">
                                <div className="dash-button">
                                    Relatórios
                                </div>
                            </Link>

                            <Link to="/user/account/services" className="col-md-4 mt-3">
                                <div className="dash-button">
                                    Realtime Transactions
                                </div>
                            </Link>

                            <Link to="/admin/tickets" className="col-md-4 mt-3">
                                <div className="dash-button">
                                    Suporte
                                </div>
                            </Link>

                            <Link to="/admin/tutorials" className="col-md-4 mt-3">
                                <div className="dash-button">
                                    Vídeos Tutoriais
                                </div>
                            </Link>

                        </div>
                        </div>
                     </div>
                    </div>
                </div>
            </div>
        <Footer />
    </div>
    </>
  );
}
