import React from 'react';

export default function StatusIcon(props) {

    let getStatus = props.iconStatus;
    console.log(getStatus);

    return (
        <i className={ getStatus === "2" ? 'ti-control-record btn-icon-prepend ml-3 status-icon  text-danger' : 'ti-control-record btn-icon-prepend ml-3 status-icon  text-success' }></i>
    );
        
}
