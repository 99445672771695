import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import Config from '../../services/config';
import api from '../../services/login';
import Moment from 'react-moment';

import './styles.css';

export default function ProductCategoriesList(props) {

  const contractid = props.contract;
  
  const [ categories, setCategories ] = useState([]);
  const [ appAuthorization, setAppAuthorization ] = useState([]);
  const [ appUserId, setAppUserId ] = useState([]);
  const [ appContractId, setAppContractId ] = useState([]);
  

   useEffect(() => {
       
    const authorization = Config.AuthApiSecretKey;
    
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;
    
    setAppAuthorization(authorization);
    setAppUserId(userid);
    setAppContractId(contractid);

     async function loadPayments() {
         const response = await api.get(`/commerce/product-categories`, {
             headers: { authorization, userid, contractid },
         })
         setCategories(response.data);
     }
     loadPayments();
 },[]);

 async function handleRemove(itemId, index, userid, authorization) {

  swal(`Remover`, {
      buttons: {
        cancel: "cancelar",
        catch: {
          text: "remover",
          value: "remover",
        },
      },
    })
    .then((value) => {
      switch (value) {

        case "cancelar": 
          break;
     
        case "remover":
          // setCategories(categories.filter(user => category._id !== itemId));
          swal("Remover Cliente", "cliente removido com sucesso", "success");
          async function removeUser() {
              const response = await api.delete(`/commerce/product-categories/${itemId}`, {
                  headers: { 
                    authorization : appAuthorization, 
                    userid : appUserId, 
                    contractid : appContractId },
              })
              console.log(response);
              window.location.reload();
          }
          removeUser();
          break;

          default:
              return "";

      }
    });
}

  return (
    <>
    
    <div className="row">
        <div className="col-12">
            <div className="row">
        <div className="col-12">
            <div className="row mb-3">
                
                <div className="col-md-6">
                    <h3 className="card-title"> Categorias de Produtos </h3>
                    <p className="text-grey"> Aqui você gerencia as categorias dos produtos da sua loja  </p>
                </div>

                <div className="col-md-6">
                  <div className="template-demo text-right">
                    <Link to={`/user/commerce/main/${contractid}`} className="btn btn-dark btn-sm"> Painel Commerce </Link>
                    <Link to={`/user/commerce/products-category-add/${contractid}`} className="btn btn-outline-dark btn-sm"> Adicionar Categoria </Link>
                </div>
              </div>
            </div>

            <div className="mt-5">
                  { !categories.length ? (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <p> Não há dados para exibir aqui. <br/> Quer saber como engajar o seu público com sua causa ? </p>
                          </div>
                        </div>
                      </>
                    ) : (
                    <table className="table">
                      <thead>
                        <tr>
                            <th> Categoria </th>
                            <th> Criado em: </th>
                            <th className='text-center'> Ações </th>
                        </tr>
                      </thead>
                      <tbody>
                      {categories.map((category, index) => (
                          <tr key={category._id}>
                              <td> 
                                <Link to={`/user/commerce/category/${category.id}/${contractid}`} className="text-grey">
                                  <span className="text-grey"> 
                                    <strong className="text-dark">  {category.title}  </strong>  <br/></span>
                                  <small> 
                                    ID: {category.id} <br/>
                                    Status: {category?.status}
                                  </small> 
                                </Link>
                              </td>
                              <td> <Moment format="DD/MM/YYYY hh:mm:ss" date={category.createdAt}  />  </td>
                              <td className='text-right'> 
                              <Link to={`/user/commerce/category/${category.id}/${contractid}`} className="btn btn-outline-light btn-sm">
                                Editar
                              </Link> &nbsp;
                              <button onClick={() => handleRemove(category.id, index)} className="remoteItemBt">
                                <i className="ti-close text-danger btn-icon-prepend ml-3"></i>
                              </button>
                              </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                  )}
                  </div>
            </div>
      </div>
    </div>
  </div>
</>
  );
}
