import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link, Redirect } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';

import HeaderAdminConsole from '../../../../components/HeaderAdminConsole';
import Config from '../../../../services/config';
import AuthPage from '../../../../components/AuthPageAdmin';
import Footer from '../../../../components/Footer';
import api from '../../../../services/Users';
import WcOrderStatus from '../../../../components/ChargesComponents/WcOrderStatus';
import Alerts from '../../../../components/Alerts';

import './styles.css';

export default class wcOrders extends Component {

  constructor(props) {

    const { match: { params } } = props;
    const authorization = Config.AuthApiSecretKey;
    const contractId = params.contractId;
    const customerId = params.customerId;

    super(props);
    this.state = {
      ordersdata: [],
      authorization,
      contractId,
      customerId,
      userdata: {},
      pageCounter: 1,
      wcreportdata: [],
      wcpiedata: {},
      loading: true,
      curTime: new Date().toLocaleString(),
      dataPie : {},
      errordata: {}
    };
  }

  loadUserData(){
        
    const authorization = this.state.authorization;
    const customerId = this.state.customerId;

    api.get('/mobcustomer/detail', {
        headers: { authorization, userId: customerId },
    })
    .then(res => {
        this.setState({ userdata: res.data.user });
    });
  }

  loadTrx(p){

    const authorization = this.state.authorization;
    const customerId = this.state.customerId;

    api.get(`wcOrders/${this.state.contractId}/${p}`, {
      headers: { authorization, userId: customerId },
    })
    .then(res => {
        this.setState({ ordersdata: res.data });
        this.setState({ loading: false });
    }).catch(error => {
      this.setState({ errordata: true });
      Alerts("Erro", 'Oops!, encontrei um erro ao carregar os dados. Pode ser que este recurso esteja indisponível no momento' ,"error");
    });
  }

  loadWcReports(){
    const authorization = this.state.authorization;
    const customerId = this.state.customerId;

    api.get(`wcReportOrderTotal/${this.state.contractId}`, {
      headers: { authorization, userId: customerId },
    })
    .then(res => {

        this.setState({ wcreportdata: res.data });

        const sDataPie = {
          labels: [
            'Pendentes', 
            'Processando', 
            'Aguardando',
            'Concluídos', 
            'Cancelados', 
            'Reembolsados', 
            'Malsucedidos'],
          datasets: [
            {
              label: 'Rainfall',
              backgroundColor: [
                '#C9DE00',
                '#2FDE00',
                '#00A6B4',
                '#ef8731',
                '#B21F00',
                '#000000',
                '#ccccccc',
              ],
              hoverBackgroundColor: [
                '#4B5000',
                '#175000',
                '#003350',
                '#a35616',
                '#501800',
                '#000000',
                '#ccccccc',
              ],
              data: [
                this.state.wcreportdata[0]?.total, 
                this.state.wcreportdata[1]?.total, 
                this.state.wcreportdata[2]?.total, 
                this.state.wcreportdata[3]?.total, 
                this.state.wcreportdata[4]?.total, 
                this.state.wcreportdata[5]?.total, 
                this.state.wcreportdata[6]?.total, 
              ],
            }
          ],
        }
        this.setState( sDataPie)

    });
  }

  handlePaginationPlus = async () => {
    this.setState({ loading: true });
    this.setState({ pageCounter: this.state.pageCounter + 1});
        await this.loadTrx(this.state.pageCounter + 1);
    }

    handlePaginationLess = async () => {
        if(this.state.pageCounter > 1){
            this.setState({ loading: true });
            this.setState({ pageCounter: this.state.pageCounter - 1});
            await this.loadTrx(this.state.pageCounter - 1);
        }
    }

  componentDidMount() {
    this.loadUserData();
    this.loadTrx(1);
    this.loadWcReports();
  }

  render(){

    if(this.state.errordata === true){
      return <Redirect to={'/admin/main'} />
    }
        
    return (

      <>
        <AuthPage />

        <div className="container-scroller">

          <HeaderAdminConsole />

              <div className="container-fluid page-body-wrapper">
                  <div className="main-panel">
                      <div className="content-wrapper">

                          <div className="row">

                          <div className="col-md-12 border-bottom ">

                          <div className="row">
                                  <div className="col-md-4">
                                  <h3 className="font-weight-bold"> 
                                      Pedidos
                                  </h3>
                                  <p className="font-weight-normal mb-0 text-grey">
                                    <span className="text-twitter"> Contrato: {this.state.contractId} </span> <br/>
                                    {this.state.userdata.companyName ? (
                                      <>
                                          <span className="text-grey"> Empresa: {this.state.userdata.companyName} </span> <br/>
                                      </>
                                    ) : ''}
                                    <span className="text-grey"> Responsável financeiro: {this.state.userdata.name} </span> 
                                  </p>
                                  </div>
                                  <div className="col-md-8 text-right">
                                    <Link to={`/admin/contracts/${this.state.customerId}`} className="btn btn-twitter btn-sm mr-3">
                                        Outros contratos deste cliente 
                                    </Link>
                                    <Link to={`/admin/contract/update/${this.state.contractId}/${this.state.customerId}`} className="btn btn-outline-twitter btn-sm mr-3">
                                        Detalhes deste contrato
                                    </Link>
                                  </div>
                              </div>
                          </div>

                          <div className="col-md-12">

                            <div className="row mb-3 border-bottom pt-3 pb-3">

                              <div className="col-md bg-grey">

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos Pendentes </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[0]?.total} </strong></p>
                                  </div>

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos em processamento </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[1]?.total} </strong></p>
                                  </div>

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos Aguardando </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[2]?.total} </strong></p>
                                  </div>

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos Concluídos </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[3]?.total} </strong></p>
                                  </div>

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos Cancelados </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[4]?.total} </strong></p>
                                  </div>

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos Reembolsados </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[5]?.total} </strong></p>
                                  </div>

                                  <div className="pay-item">
                                    <p className="text-grey"> Pedidos Malsucedidos </p>
                                    <p className="text-muted amount-item"> <strong> 
                                      {this.state.wcreportdata[6]?.total} </strong></p>
                                  </div>

                              </div>

                              <div className="col-md">
                                                               
                                {/*  */}
                                  <Doughnut
                                    data={this.state}
                                    options={{
                                      responsive: true,
                                      legend:{
                                        display:true,
                                        position:'right'
                                      }
                                    }}
                                  />
                                {/*  */}

                              </div>

                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-4">
                                  <p className="text-grey"> Todos os saldos estão atualizados <br/> até a data de &nbsp;
                                    <Moment 
                                      format="DD/MM/YYYY" 
                                      date={this.state.curTime} />
                                  </p>
                                </div>
                                <div className="col-md-6 text-right mb-4">
                                    <nav className="paginationRight">
                                        <ul className="pagination flex-wrap pagination-flat pagination-twitter">
                                            <li className="page-item">
                                                <button className="page-link pgLess" onClick={this.handlePaginationLess}>
                                                    <i className="ti-angle-left"></i>
                                                </button> 
                                            </li>
                                            <li className="page-item active">
                                                <span className="page-link">{this.state.pageCounter}</span>
                                            </li>
                                            
                                            <li className="page-item">
                                                <button className="page-link" onClick={this.handlePaginationPlus}>
                                                    <i className="ti-angle-right"></i>
                                                </button> 
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                              
                            <div className="table-responsive">

                              {!this.state.ordersdata.length && this.state.loading === false ? (
                                  <p className="text-grey mt-3"> Este cliente não possui transações para exibir neste momento</p>
                              ) : (
                                  <>
                                      {this.state.loading === false ? (
                                          <table className="table table-striped">
                                                  <tbody>
                                                      {this.state.ordersdata.map((trx, index) => (
                                                          <tr key={index}>
                                                              <td className="text-grey"> <strong> {trx?.id} </strong> </td>
                                                              <td className="text-grey"> 
                                                                {trx?.billing.first_name} {trx?.billing.last_name} <br/>
                                                                {trx?.billing.email}
                                                              </td>
                                                              <td className="text-grey"> R$ {trx?.total} </td>
                                                              <td className="text-grey"> 
                                                                <Moment format="DD/MM/YYYY" date={trx?.date_created}  /> 
                                                              </td>
                                                              <td>
                                                                <WcOrderStatus status={trx?.status || ""} className="mr-3"/> <br/>
                                                                 <span className="text-twitter"> {trx?.payment_method_title} </span>
                                                              </td>
                                                              <td className="text-right"> 
                                                              <Link to={`/admin/wcorder/${this.state.contractId}/${trx?.id}/${this.state.customerId}`} 
                                                                className="btn btn-outline-secondary btn-sm ml-3">
                                                                  Detalhes
                                                              </Link> 
                                                              </td>
                                                          </tr>
                                                      ))}
                                                  </tbody>
                                              </table>
                                              ) : (
                                                  <div className="loader"> Carregando dados, por favor aguarde ... </div>
                                              )}
                                            </>
                                        )}
                            </div>
                            {/*  */}

                          </div>

                      </div>
                      
                      </div>
                  </div>
              </div>
          <Footer />
      </div>

      </>
    )
  }


}