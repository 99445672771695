import React from 'react';

import Config from '../../../../services/config';
import AuthPageAdmin from '../../../../components/AuthPageAdmin';
import Footer from '../../../../components/Footer';
import Header from '../../../../components/HeaderAdminConsole';
import TrxDetails from  '../../../../components/AdminConsoleComponents/Transactions/TransactionDetails';
import Loader from 'react-loader-spinner';

export default function ChargeDetail(props) {

  const { match: { params } } = props;
  const trxId = params.id
  const customer = params.customerId
  const contract = params.contractId

  return (
    <>

    <Loader
       type="BallTriangle"
       color="#000000"
       height={60}
       width={60}
       timeout={Config.loaderTimeout}
       className="overlaySpinner" //3 secs
   />

    <AuthPageAdmin />

    <div className="container-scroller">
        <Header />
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                <div className="content-wrapper">
                    <TrxDetails transactionId={trxId} customerId={customer} contractId={contract} />
                </div>
            </div>
        </div>
        <Footer />
    </div>

    </>
  );
}
