import React from 'react';

export default function GetPaymentMethod(props) {

    const SwitchString = (str) => ({
      "credit_card" : "Cartão de Crédito",
      "boleto" : "Boleto Bancário",
    })[str] || '';

    let getString = props.string;

    return (
      <span className={`disabled`}>
        { SwitchString(getString)}
      </span>
  );

  } 