import React from 'react';
import Loader from 'react-loader-spinner';

import AuthPage from '../../../components/AuthPage';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import CustomerDetail from '../../../components/Customers/Detail';
import Config from '../../../services/config';

export default function Detail(props) {

  const { match: { params } } = props;
  const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
  const userid = credencialdata._id;
  
  return (
    <>
        <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner" //3 secs
        />
        <AuthPage />
        <div className="container-scroller">
            <Header />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>
                                                <CustomerDetail userId={userid} customerId={params.id} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    </>
  );
}
