import React from 'react';
import { Link } from 'react-router-dom';

import imageSample from '../../../assets/images/sample.jpg';
import './styles.css';

export default function PhotoList() {
  return (
    <>
        <div className="row">
            <div className="col-md-6">
                <h3 className="card-title"> Fotos </h3>
                <p className="text-grey"> Você pode adicionar fotos ao seu app e compartilhar no site e aplicativo automáticamente  </p>
            </div>

            <div className="col-md-6">
                

                <div className="template-demo mt-2 text-right">
                        <Link to="/photo/store" className="btn btn-outline-twitter btn-sm">
                            <i className="ti-plus"></i> Nova
                        </Link>
                        <Link to="#" className="btn btn-outline-twitter btn-sm">
                            <i className="ti-list-ol"></i> Galerias de Imagem
                        </Link>
                </div>

            </div>

        </div>
        <div className="row mt-4">
            <div className="col-md-12">
            <div className="container-fluid page-body-wrapper">
            <div>
                <div>
					<div className="row">
                    <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                            <div className="row portfolio-grid">
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <figure className="effect-text-in">
                                    <img src={imageSample} alt="Pagefy Photos" />
                                    <figcaption>
                                    <h4>Photography</h4>
                                    <p>
                                    <Link to="photo/update/1">
                                            <button type="button" className="btn btn-outline-secondary btn-sm mr-3"> 
                                            Editar </button>
                                        </Link>

                                        <Link to="photo/update/1">
                                            <button type="button" className="btn btn-outline-danger btn-sm"> 
                                            Remover </button>
                                        </Link>
                                    </p>
                                    </figcaption>
                                </figure>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <figure className="effect-text-in">
                                    <img src={imageSample} alt="Pagefy Photos"/>
                                    <figcaption>
                                    <h4>Lifestyle</h4>
                                    <p>
                                    <Link to="photo/update/1">
                                            <button type="button" className="btn btn-outline-secondary btn-sm mr-3"> 
                                            Editar </button>
                                        </Link>

                                        <Link to="photo/update/1">
                                            <button type="button" className="btn btn-outline-danger btn-sm"> 
                                            Remover </button>
                                        </Link>
                                    </p>
                                    </figcaption>
                                </figure>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <figure className="effect-text-in">
                                    <img src={imageSample} alt="Pagefy Photos"/>
                                    <figcaption>
                                    <h4>Tech Geeks</h4>
                                    <p>
                                    <Link to="photo/update/1">
                                            <button type="button" className="btn btn-outline-secondary btn-sm mr-3"> 
                                            Editar </button>
                                        </Link>

                                        <Link to="photo/update/1">
                                            <button type="button" className="btn btn-outline-danger btn-sm"> 
                                            Remover </button>
                                        </Link>
                                    </p>
                                    </figcaption>
                                </figure>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <figure className="effect-text-in">
                                    <img src={imageSample} alt="Pagefy Photos"/>
                                    <figcaption>
                                    <h4>Tech Geeks</h4>
                                    <p>
                                        
                                        <Link to="photo/update/1">
                                            <button type="button" className="btn btn-outline-secondary btn-sm mr-3"> 
                                            Editar </button>
                                        </Link>

                                        <Link to="photo/update/1">
                                            <button type="button" className="btn btn-outline-danger btn-sm"> 
                                            Remover </button>
                                        </Link>
                                    </p>
                                    </figcaption>
                                </figure>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
        
      </div>
      </div>
      </div>
      </div>
    
    </>
  );
}
