import React, { Component } from 'react';
import Loader from 'react-loader-spinner';

import AuthPage from '../../components/AuthPage';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Navigation from './navigation';
import Config from '../../services/config';
import api from '../../services/Users';
import Alerts from '../../components/Alerts/index';

import './styles.css';

export default class MyAccount extends Component {

  constructor(props) {

    const authorization = Config.AuthApiSecretKey;
    const credencialdata = JSON.parse(localStorage.getItem("credencialData"));
    const userid = credencialdata._id;

    super(props);
    this.state = {
      userdata: {},
      authorization,
      userid
    };
  }

  componentDidMount() {
    
    const authorization = this.state.authorization;
    const userid = this.state.userid;

    api.get('/user/detail',{
        headers: { authorization, userid },
    })
    .then(res => {
        this.setState({ userdata: res.data.user });
    });

  }

  handleChange = (e) => {
    const state = this.state.userdata
    state[e.target.name] = e.target.value;
    this.setState({userdata:state});
    console.log(state);
  }

  onSubmit = (e) => {

    e.preventDefault();
    const authorization = this.state.authorization;
    const userid = this.state.userid;
    const { personType, name, email, emailSupport } = this.state.userdata;

    api.put('/user/detail/', { personType, name, email, emailSupport }, { headers: { authorization, userid },
    })
      .then((result) => {
        Alerts("Dados Pessoais", 'Dados atualizados com sucesso' ,"success");
        this.props.history.push("/user/account");
      });
  }

  render() {
    return (
      <>
        <Loader
          type="BallTriangle"
          color="#000000"
          height={60}
          width={60}
          timeout={Config.loaderTimeout}
          className="overlaySpinner"
        />
        <AuthPage />
        <Header />
          <div className="container-fluid page-body-wrapper">
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-12">
                    <div className="mt-4">
                      <div className="">
                        <div className="row">
                        <div className="col-lg-8">
                              <div className="border-right-grey">
                                  <div className="">
                                    <h3> Dados Cadastrais </h3>
                                    
                                    <form onSubmit={this.onSubmit} className="mt-4">

                                        <div className="form-group">

                                          <label htmlFor="personType"> Pessoa Física / Pessoa Jurídica * </label>

                                          <select 
                                            id="personType"
                                            name="personType"
                                            value={this.state.userdata.personType || ""}
                                            onChange={this.handleChange}
                                            className="form-control"
                                            disabled
                                          >
                                            <option value=""> Selecione o tipo da conta </option>
                                            <option value="person"> Pessoa Física </option>
                                            <option value="company"> Pessoa Jurídica </option>

                                          </select>


                                        </div>

                                        <div className="form-group">

                                          <label htmlFor="cpf"> CPF do Responsável Financeiro * </label>

                                          <input 
                                            placeholder="CPF"
                                            type="text" 
                                            className="form-control" 
                                            name="cpf" 
                                            id="cpf"
                                            value={this.state.userdata.cpf || ""} 
                                            onChange={this.handleChange}
                                            disabled
                                          />

                                        </div>

                                        <div className="form-group">

                                          <label htmlFor="name"> Nome * </label>

                                          <input 
                                            placeholder="Nome Completo" 
                                            type="text" 
                                            className="form-control" 
                                            name="name"
                                            id="name"
                                            value={this.state.userdata.name || ""} 
                                            onChange={this.handleChange}
                                            />

                                        </div>

                                        <div className="form-group">
                                          <label htmlFor="addr">Endereço de E-mail * </label>
                                          <input 
                                            placeholder="E-mail" 
                                            type="text" 
                                            className="form-control" 
                                            name="email"
                                            id="email"
                                            value={this.state.userdata.email || ""} 
                                            onChange={this.handleChange}
                                            />
                                        </div>

                                        <div className="form-group">
                                          <label htmlFor="emailSupport">E-mail de resposta * <span className="text-twitter"> ( E-mail no qual seus clientes entrarão em contato com sua empresa ) </span> </label>
                                          <input 
                                            placeholder="Ex: email@corporativo.com.br" 
                                            type="text" 
                                            className="form-control" 
                                            name="emailSupport"
                                            id="emailSupport"
                                            value={this.state.userdata.emailSupport || ""} 
                                            onChange={this.handleChange}
                                            />
                                        </div>

                                        <div className="row">
                                          <div className="col-md-12 text-left mt-20">
                                            <button type="submit" className="btn btn-dark mr-2"> Salvar </button>
                                          </div>

                                          <p className="text-grey mt-3 mb-4 text-small mt-4 ml-3">
                                          * É importante que todos os seus dados estejam atualizados em nosso cadastro para evitar qualquer tipo de inconformidade
                                                com o status de sua conta.
                                          </p>
                                        </div>

                                    </form>
                                  </div>
                              </div>
                          </div>
                     
                          <Navigation />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        <Footer />
    </>
    );
  }
}
